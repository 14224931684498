export const QUERIES_ADMIN = {
  ADDRESS: {
    module: 'address',
  },
  AUTH: {
    module: 'auth',
  },
  PROFILE: {
    module: 'profile',
  },
  FINANCE_PROFILE: {
    module: 'finance-profile',
  },
  SUPPLIER_ORDER: {
    module: 'supplier-order',
  },
  SUPPLIER_TRANSACTION: {
    module: 'supplier-transaction',
  },
  GENERATE_PURCHASE_ORDER: {
    module: 'generate-purchase-order',
  },
  GENERATE_PURCHASE_ORDER_PREVIEW: {
    module: 'generate-purchase-order-preview',
  },
  GENERATE_PURCHASE_ORDER_PDF_PREVIEW: {
    module: 'generate-purchase-order-pdf-preview',
  },
  QUOTATION: {
    module: 'quotation',
  },
  QUOTATION_CLASSIFICATION: {
    module: 'quotation-classification',
  },
  QUOTATION_SLAS: {
    module: 'quotation-slas',
  },
  QUOTATION_REQUEST: {
    module: 'quotation-request',
  },
  QUOTATION_REQUEST_CLASSIFICATION: {
    module: 'quotation-request-classification',
  },
  QUOTATION_REQUEST_SLAS: {
    module: 'quotation-request-slas',
  },
  QUOTATION_REQUEST_SPLIT: {
    module: 'quotation-request-split',
  },
  WHO_AM_I: {
    module: 'who-am-i',
  },
  CATALOG_CATEGORY: {
    module: 'catalog-category',
  },
  CATALOG_PRODUCT: {
    module: 'catalog-product',
  },
  FILE: {
    module: 'file',
  },
  ORDER: {
    module: 'order',
  },
  ORDER_ITEM: {
    module: 'order-item',
  },
  USER: {
    module: 'user',
  },
  ACCOUNT_LEVEL_QUESTION: {
    module: 'account-level-question',
  },
  SEARCH_ENGINE: {
    module: 'search-engine',
  },
  SUPPLIER: {
    module: 'supplier',
  },
  SUPPLIER_WAREHOUSE: {
    module: 'supplier-warehouse',
  },
  SUPPLIER_PRODUCTS: {
    module: 'supplier-products',
  },
  TRANSPORT_COMPANY: {
    module: 'transport-company',
  },
  ROUTE: {
    module: 'routes',
  },
  VEHICLES: {
    module: 'vehicles',
  },
  TRANSPORT_COMPANY_ROUTE: {
    module: 'transport-company-routes',
  },
  ORDER_TRACKER: {
    module: 'order-tracker',
  },
  KAI_PROCESSOR: {
    module: 'kai-processor',
  },
  DOLLAR_QUOTATION: {
    module: 'dollar-quotation',
  },
  ORDER_CENTER: {
    module: 'order-center',
  },
  ORDER_CENTER_LOGISTIC_GENERATE_REMISSION: {
    module: 'order-center-logistic-generate-remission',
  },
  CREDIT_ORDER: {
    module: 'credit-order',
  },
  TRANSACTION: {
    module: 'transaction',
  },
  PROFILE_USER: {
    module: 'profile-user',
  },
  SALE_BOARD: {
    module: 'sale-board',
  },
  CALCULATE_MARGIN: {
    module: 'calculate-margin',
  },
  CALCULATE_UNIT_PRICE_URL: {
    module: 'calculate-unit-price',
  },
  AI_AGENT: {
    module: 'ai-agent',
  },
  AI_CONVERSATION: {
    module: 'ai-conversation',
  },
  AI_CONVERSATION_METRICS: {
    module: 'ai-conversation-metrics',
  },
  AI_MESSAGE: {
    module: 'ai-message',
  },
  AI_EVALUATION: {
    module: 'ai-evaluation',
  },
  AI_REGENERATE: {
    module: 'ai-regenerate',
  },
}
