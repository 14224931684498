import { ReactNode } from 'react'
import { Button, Drawer } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { appOrderLink } from '@/constants'

import {
  useOrderCenterLogisticGenerateRemission,
  useOrderCenterSaleOrderDetail,
} from '@/services'

import {
  OrderFinance,
  OrderHeader,
  OrderStatus,
  OrderProfile,
  OrderProfileStatusHistory,
  OrderQuotation,
  OrderSummaryValues,
  OrderItems,
  OrderFinanceStatusHistory,
  OrderLogisticStatusHistory,
  OrderLogistic,
  OrderLogisticCost,
  OrderLogisticTransportOption,
  OrderLogisticDocuments,
} from './components'

import { contents } from './order-center.constants'
import styles from './order-center-show.module.css'

import type { OrderCenterShowProps } from './order-center-show.type'
import { OrderPayment } from './components/order-payment'
import { OrderFinanceDocuments } from './components/order-finance-documents'
export const OrderCenterShow = ({
  isOpen,
  onHandleOpen,
  saleOrderUuid,
  type = 'profile',
}: OrderCenterShowProps) => {
  const { t } = useTranslation('componentOrderCenterShow')

  const { mutateAsync: generateRemissionAsync, isPending } =
    useOrderCenterLogisticGenerateRemission()

  const { data: order, isLoading } =
    useOrderCenterSaleOrderDetail(saleOrderUuid)

  const totalQuantity = order?.data?.items?.reduce(
    (acc, item) => acc + item.quantity,
    0,
  )

  const components: Record<string, ReactNode> = {
    'order-profile': (
      <OrderProfile
        key="order-profile"
        companyName={order?.data?.buyer?.companyName}
        email={order?.data?.buyer?.email}
        usersNames={order?.data?.buyer?.usersNames}
        phone={order?.data?.buyer?.phone}
        customerLevel={order?.data?.buyer?.customerLevel as string}
        moscowClassification={order?.data?.buyer?.moscowClassification}
        taxStatus={order?.data?.buyer?.taxStatus}
        uuid={order?.data?.buyer?.uuid}
        isLoading={isLoading}
      />
    ),
    'order-status': (
      <OrderStatus
        key="order-status"
        orderReference={order?.data?.orderReference}
        orderStatus={order?.data?.status}
      />
    ),
    'order-profile-status-history': (
      <OrderProfileStatusHistory
        isLoading={isLoading}
        taxStatusHistory={order?.data?.buyer?.statusHistory}
      />
    ),
    'order-quotation': (
      <OrderQuotation
        isLoading={isLoading}
        createdAt={order?.data?.quotation?.createdAt}
        creatorEmail={order?.data?.quotation?.creatorUser}
        reference={order?.data?.quotation?.reference}
        uuid={order?.data?.quotation?.uuid}
      />
    ),
    'order-summary-values': (
      <OrderSummaryValues
        discount={order?.data?.discount ? +order.data.discount : 0}
        dollarQuotation={
          order?.data?.payments?.[0]?.dollarExchangeRate
            ? +order?.data?.payments?.[0]?.dollarExchangeRate
            : 0
        }
        isLoading={isLoading}
        iva={order?.data?.taxPrice ? +order?.data?.taxPrice : 0}
        subTotal={
          order?.data?.totalProductPrice ? +order?.data?.totalProductPrice : 0
        }
        total={order?.data?.totalOrderPrice ? +order?.data?.totalOrderPrice : 0}
        totalFreight={
          order?.data?.totalFreightPrice ? +order?.data?.totalFreightPrice : 0
        }
      />
    ),
    'order-finance': (
      <OrderFinance
        invoiceStatus={order?.data?.invoices?.[0]?.status}
        invoicePdf={order?.data?.invoices?.[0]?.pdfFile}
        invoiceXml={order?.data?.invoices?.[0]?.xmlFile}
        calculatePaymentStatus={order?.data?.paymentsStatus}
        paymentProof={order?.data?.payments?.[0]?.proof}
        paymentMethod={order?.data?.buyer?.paymentMethod}
        paymentForm={order?.data?.buyer?.paymentForm}
        useCfdi={order?.data?.buyer?.cfdi}
        financingCost={order?.data?.financingCost}
        isLoading={isLoading}
      />
    ),
    'order-items': (
      <OrderItems
        dollarQuotation={order?.data?.payments?.[0]?.dollarExchangeRate}
        isLoading={isLoading}
        items={order?.data?.items}
        supplierName={order?.data?.shipments?.[0]?.supplier?.name}
      />
    ),
    'order-finance-status': (
      <OrderFinanceStatusHistory
        isLoading={isLoading}
        invoiceStatusHistory={order?.data?.invoices?.[0]?.statusHistory}
        paymentStatusHistory={order?.data?.payments?.[0]?.statusHistory}
      />
    ),
    'order-logistic-status': (
      <OrderLogisticStatusHistory
        isLoading={isLoading}
        shipmentStatusHistory={order?.data?.shipments?.[0]?.statusHistory}
      />
    ),
    'order-logistic': (
      <OrderLogistic
        isLoading={isLoading}
        shipment={order?.data?.shipments?.[0]}
      />
    ),
    'order-logistic-cost': (
      <OrderLogisticCost
        isLoading={isLoading}
        shipment={order?.data?.shipments?.[0]}
        totalQuantityShipment={totalQuantity}
      />
    ),
    'order-logistic-transport-option': (
      <OrderLogisticTransportOption
        isLoading={isLoading}
        shipment={order?.data?.shipments?.[0]}
      />
    ),
    'order-logistic-documents': (
      <OrderLogisticDocuments shipment={order?.data?.shipments?.[0]} />
    ),
    'order-finance-documents': (
      <OrderFinanceDocuments
        invoicePdf={order?.data?.invoices?.[0]?.pdfFile}
        invoiceXml={order?.data?.invoices?.[0]?.xmlFile}
        taxSituation={order?.data?.buyer?.taxSituation}
        payments={order?.data?.payments || []}
      />
    ),
    'order-payments': (
      <OrderPayment saleOrder={order?.data} isLoading={isLoading} />
    ),
  }

  const handleGenerateRemissionFile = async () => {
    const { data } = await generateRemissionAsync(saleOrderUuid)

    if (!data) {
      return
    }

    const url = window.URL.createObjectURL(data)

    window.open(url, '_blank')

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 10000)
  }

  return (
    <Drawer
      isOpen={isOpen}
      onHandleOpen={() => onHandleOpen(false)}
      className={styles.drawer}
    >
      <OrderHeader
        reference={order?.data?.reference || ''}
        createdAt={order?.data?.createdAt || ''}
        updatedAt={order?.data?.updatedAt || ''}
        onHandleOpen={onHandleOpen}
        isLoading={isLoading}
      />

      <div className={styles.content}>
        {contents[type]?.map(componentKey => (
          <div key={componentKey}>{components?.[componentKey]}</div>
        ))}
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('buttonClose')}
        </Button>

        <Button
          href={appOrderLink(order?.data?.orderUuid || '')}
          target="_blank"
        >
          {t('buttonAppLink')}
        </Button>

        <Button
          onClick={() => handleGenerateRemissionFile()}
          isActive={!isPending}
          disabled={isPending}
          variant="success-light"
        >
          {t('buttonRemissionLink')}
        </Button>
      </div>
    </Drawer>
  )
}
