import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { Drawer } from '@mercai/clever'

import {
  useAICreateConversation,
  useAICreateMessage,
  useAIConversationDetail,
  QUERIES_ADMIN,
} from '@/services'

import { Chat, Header } from './components'

import type { IAMessageProps } from '@/types'
import type { DrawerFormAIChatProps } from './drawer-form-ai-chat.type'

import styles from './drawer-form-ai-chat.module.css'
import { useAuth } from '@/providers'

export const DrawerFormAIChat = ({
  uuid,
  onChangeUuid,
  email,
  isOpen,
  onClose,
}: DrawerFormAIChatProps) => {
  const { t } = useTranslation('componentsDrawerFormAI')

  const queryClient = useQueryClient()

  const [conversationUuid, setConversationUuid] = useState<string>('')
  const [messages, setMessages] = useState<Partial<IAMessageProps>[]>([])

  const { whoAmI } = useAuth()

  const { mutateAsync: createConversation, isPending: isCreatingConversation } =
    useAICreateConversation()

  const { mutateAsync: createMessage, isPending: isCreatingMessage } =
    useAICreateMessage()

  const { data: conversationDetail } = useAIConversationDetail(uuid, email)

  const onInitConversation = async (content: string) => {
    const responseConversation = await createConversation({
      createdBy: whoAmI?.email || '',
    })

    setMessages([
      ...messages,
      {
        conversationUuid: responseConversation.data?.uuid || '',
        content,
        author: 'user',
        createdAt: new Date(),
      },
    ])

    if (responseConversation.success) {
      setConversationUuid(responseConversation.data?.uuid || '')
      onChangeUuid?.(responseConversation.data?.uuid || '')
      const responseMessage = await createMessage({
        conversationUuid: responseConversation.data?.uuid || '',
        content,
      })

      if (responseMessage.success) {
        setMessages([
          ...messages,
          {
            conversationUuid: responseConversation.data?.uuid || '',
            content,
            author: 'user',
            createdAt: new Date(),
          },
          {
            ...responseMessage.data,
            author: 'assistant',
            createdAt: new Date(),
          },
        ])

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.AI_CONVERSATION.module],
        })
      }
    }
  }

  const onContinueConversation = async (content: string) => {
    setMessages([
      ...messages,
      {
        conversationUuid,
        content,
        author: 'user',
        createdAt: new Date(),
      },
    ])

    const responseMessage = await createMessage({
      conversationUuid,
      content,
    })

    if (responseMessage.success && responseMessage.data) {
      setMessages([
        ...messages,
        {
          conversationUuid,
          content,
          author: 'user',
          createdAt: new Date(),
        },
        responseMessage.data,
      ])
    }
  }

  const onSendMessage = async (message: string) => {
    if (conversationUuid) {
      onContinueConversation(message)
    } else {
      onInitConversation(message)
    }
  }

  useEffect(() => {
    if (conversationDetail?.success && conversationDetail?.data) {
      setMessages(conversationDetail.data.messages)
      setConversationUuid(conversationDetail.data.uuid)
    }
  }, [conversationDetail, isOpen])

  useEffect(() => {
    if (!isOpen) {
      setMessages([])
      setConversationUuid('')
    }

    return () => {
      setMessages([])
      setConversationUuid('')
    }
  }, [isOpen])

  return (
    <Drawer isOpen={isOpen || false} onHandleOpen={() => onClose?.()}>
      <div className={styles.core}>
        <Header title={t('headerTitle')} description={t('headerDescription')} />

        <Chat
          messages={messages as IAMessageProps[]}
          onSendMessage={onSendMessage}
          isLoading={isCreatingConversation || isCreatingMessage}
          origin={conversationDetail?.data?.origin || ''}
        />
      </div>
    </Drawer>
  )
}
