import { useEffect, useState, useMemo, ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, CloseIcon, Drawer, Text } from '@mercai/clever'

import {
  Header,
  LevelForm,
  OrderCenterForm,
  OrderCenterShow,
  ProfileCredit,
  ProfilePaymentForm,
} from '@/components'
import { convertObjectToSnakeCase, useParamsState } from '@/helpers'
import { ROUTES_ADMIN } from '@/router'

import {
  useProfileDetail,
  useAccountLevelQuestionList,
  useUpdateProfile,
  useOrderCenterSaleOrderList,
} from '@/services'

import { AccountLevelQuestionCategoryProps } from '@/types'

import { ProfileDrawerForm } from '../list/components'

import { ProfileShowSidebar, ProfileShowBuyerUsers } from './components'
import styles from './show.module.css'
import { TableQuotationRequest } from '@/pages/business/quotation-request-v2/list/components'
import { TableQuotation } from '@/pages/business/quotation-v2/list/components'
import { OrderCenterTable } from '@/pages/order-center/components'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

export const ProfileShow = () => {
  const { t } = useTranslation('screenProfileShow')
  const [categorySelected, setCategorySelected] = useState<
    AccountLevelQuestionCategoryProps | undefined
  >(undefined)

  const [paymentPreferencesSelected, setPaymentPreferencesSelected] = useState<
    string | undefined
  >(undefined)

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [answers, setAnswers] = useState<Record<string, string | string[]>>({})
  const [showModule, setShowModule] = useState('quotation-request')

  const { uuid } = useParams()

  const { data, isLoading, refetch } = useProfileDetail(uuid)
  const { data: dataQuestionList, isLoading: isLoadingQuestionList } =
    useAccountLevelQuestionList()

  const { mutateAsync: updateProfile } = useUpdateProfile()

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    taxStatus: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
    ordering: '',
    keyAccountManager: '',
    buyer_profile: uuid || '',
  })

  const { data: dataSaleOrder, isLoading: isLoadginSaleOrder } =
    useOrderCenterSaleOrderList(filter, showModule === 'order')

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    action: '',
  })

  const defaultProps = {
    filter,
    onEditRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'form',
      }),
    onSetFilter: setFilter,
    onShowRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'show',
      }),
    formattedFilter: {},
    onDeleteRegister: () => {},
  }

  const categories = useMemo(() => {
    return (
      dataQuestionList?.data?.results?.reduce((acc, question) => {
        const categoryFinded = acc?.some(
          (category) => category.slug === question?.category?.slug,
        )

        if (categoryFinded || question?.typeForm === 'watch') {
          return acc
        }

        return [
          ...acc,
          {
            ...question?.category,
          },
        ]
      }, [] as AccountLevelQuestionCategoryProps[]) || []
    )
  }, [dataQuestionList?.data?.results])

  const getQuestionsByCategory = () => {
    return (
      dataQuestionList?.data?.results?.filter(
        (question) => question.category.slug === categorySelected?.slug,
      ) || []
    )
  }

  const onSelectCategory = (categorySlug: string) => {
    const category = categories?.find(
      (category) => category?.slug === categorySlug,
    )

    setCategorySelected(category)
  }

  const onUnselectCategory = () => {
    setCategorySelected(undefined)
  }

  const onUnselectPaymentPreferences = () => {
    setPaymentPreferencesSelected(undefined)
  }

  const onHandleAnswers = (key: string, newValue: string | string[]) => {
    const newAttributes = { ...answers, [key]: newValue }

    updateProfile({
      uuid,
      attributes: newAttributes,
    })

    setAnswers(newAttributes)
  }

  const objectsRoute: Record<string, () => ReactNode> = {
    'quotation-request': () => (
      <TableQuotationRequest buyerProfile={uuid} hasAddButton />
    ),
    quotation: () => <TableQuotation buyerProfile={uuid} hasAddButton />,
    order: () => (
      <OrderCenterTable
        {...defaultProps}
        data={dataSaleOrder?.data}
        isLoading={isLoadginSaleOrder}
        type="all"
      />
    ),
    users: () => <ProfileShowBuyerUsers profileUuid={uuid} />,
  }

  useEffect(() => {
    if (data?.data) {
      const newAnswers = convertObjectToSnakeCase(data?.data?.attributes)
      setAnswers(newAnswers as Record<string, string | string[]>)
    }
  }, [data?.data?.attributes])

  useEffect(() => {
    if (uuid && showModule === 'order') {
      return setFilter({ ...filter, buyer_profile: uuid, page: '1' })
    }

    setFilter({ ...filter, page: '1' })
  }, [showModule])

  return (
    <div>
      <Header
        title={data?.data?.companyName || ''}
        description={
          data?.data?.accountLevel?.title || t('headerEmptyDescription')
        }
        link={ROUTES_ADMIN.PROFILE.fullPath()}
        isLoading={isLoading}
      />

      <div className={styles.content}>
        <ProfileShowSidebar
          profile={data?.data}
          isLoading={isLoading || isLoadingQuestionList}
          categories={
            categories?.map((item) => ({
              value: item.slug,
              label: item.title,
            })) || []
          }
          onSelectCategory={onSelectCategory}
          onClickEdit={() => setIsOpenForm(true)}
          onSelectBenefit={setPaymentPreferencesSelected}
        />

        <div className={styles.tables}>
          <div className={styles.buttons}>
            <Button
              variant="secondary"
              model="pill"
              isActive={showModule === 'quotation-request'}
              onClick={() => setShowModule('quotation-request')}
            >
              {t('tablesButtonRFQs')}
            </Button>
            <Button
              variant="secondary"
              model="pill"
              isActive={showModule === 'quotation'}
              onClick={() => setShowModule('quotation')}
            >
              {t('tablesButtonQuotations')}
            </Button>
            <Button
              variant="secondary"
              model="pill"
              isActive={showModule === 'order'}
              onClick={() => setShowModule('order')}
            >
              {t('tablesButtonOrders')}
            </Button>
            <Button
              variant="secondary"
              model="pill"
              isActive={showModule === 'users'}
              onClick={() => setShowModule('users')}
            >
              {t('tablesButtonUsers')}
            </Button>
          </div>

          {objectsRoute?.[showModule as string]()}
        </div>
      </div>

      <Drawer
        isOpen={categorySelected !== undefined}
        onHandleOpen={onUnselectCategory}
        className={styles.drawer}
      >
        {categorySelected && (
          <div className={styles['drawer-wrap']}>
            <div className={styles['drawer-header']}>
              <Text variant="text-large-bold">{categorySelected?.title}</Text>
              <button
                className={styles['drawer-header-button']}
                type="button"
                onClick={onUnselectCategory}
              >
                <CloseIcon />
              </button>
            </div>

            <div className={styles['drawer-form']}>
              <LevelForm
                answers={answers}
                questions={getQuestionsByCategory()}
                onHandleAnswers={onHandleAnswers}
                transtations={{
                  uploadSize: t('sidebarUploadSize'),
                  uploadDrag: t('sidebarUploadDrag'),
                  uploadButton: t('sidebarUploadButton'),
                }}
              />
            </div>

            <div className={styles['drawer-footer']}>
              <Button type="button" onClick={onUnselectCategory}>
                {t('drawerQuestionFooterButtonSubmit')}
              </Button>
            </div>
          </div>
        )}
      </Drawer>

      <ProfileDrawerForm
        isOpen={isOpenForm}
        onHandleOpen={setIsOpenForm}
        profileUuid={uuid}
      />

      <ProfileCredit
        isOpen={paymentPreferencesSelected === 'credit'}
        onHandleOpen={onUnselectPaymentPreferences}
        buyerProfileUuid={uuid || ''}
        buyerProfileEmail={data?.data?.email || ''}
        financeProfileData={data?.data?.financeProfile}
      />

      <ProfilePaymentForm
        isOpen={paymentPreferencesSelected === 'settings'}
        onHandleOpen={onUnselectPaymentPreferences}
        profileUuid={uuid || ''}
        profileName={data?.data?.name || ''}
        financeProfileData={data?.data?.financeProfile}
        financeProfileRefetch={refetch}
      />

      <OrderCenterShow
        isOpen={queryParams.action === 'show'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={'all'}
      />

      <OrderCenterForm
        isOpen={queryParams.action === 'form'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={'all'}
      />
    </div>
  )
}
