import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DragDropFileList, FileListFileProps, Label } from '@/lib'
import { ORDERS_CENTER_SALE_ORDER_URL, api } from '@/services'
import { SaleOrderProps } from '@/types'
import { FinanceDocumentsProps } from './documents.type'

import styles from './documents.module.css'
import { getFileNameAndExtension } from '@/helpers'

export const FinaceDocumentsTab = ({
  values = {} as SaleOrderProps,
  onSuccessUploadFiles,
}: FinanceDocumentsProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const [dataFilesDeduction, setDataFilesDeduction] = useState<
    FileListFileProps[]
  >([])

  useEffect(() => {
    if (values.deduction) {
      const file = getFileNameAndExtension(values.deduction)
      setDataFilesDeduction([
        {
          id: file.fileName,
          name: `${file.fileName}.${file.extension}`,
          url: values.deduction,
          uploaded: true,
        },
      ])
    } else {
      setDataFilesDeduction([])
    }
  }, [])

  return (
    <div className={styles.box}>
      <div className={styles.field}>
        <Label>{t('paymentDeductionLabel')}</Label>
        <DragDropFileList
          translations={{
            clickToUpload: t('uploadClickToUpload'),
            dragFile: t('uploadDragFile'),
            sizeFile: t('uploadSizeFile'),
          }}
          urlAdd={ORDERS_CENTER_SALE_ORDER_URL(values?.uuid || '')}
          configsRequest={{
            fileField: 'deduction',
            method: 'PATCH',
          }}
          axiosInstance={api}
          data={dataFilesDeduction}
          onChangeData={setDataFilesDeduction}
          successedUpload={onSuccessUploadFiles}
        />
      </div>
    </div>
  )
}
