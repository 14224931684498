import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

import {
  QUERIES_ADMIN,
  useCalculateUnitPrice,
  useCreateQuotation,
  useDollarQuotationLatest,
  useQuotationRequestDetail,
  useSplitQuotationRequest,
  useUpdateQuotationRequest,
} from '@/services'
import {
  onConfirmWarningMessage,
  onErrorMessage,
  onSuccessMessage,
  trackEvent,
  useParamsState,
  useValidationErrors,
} from '@/helpers'

import { Button, Text } from '@mercai/clever'

import { RfqStatusSelect, UpdateRfq } from './components'

import styles from './show.module.css'
import {
  AddressProps,
  CalculateUnitPricePayloadProps,
  ProfileProps,
  QuotationProps,
  QuotationRequestItemProps,
  QuotationRequestProps,
  QuotationRequestSplitEnum,
} from '@/types'
import {
  accordionsByStatusObject,
  getQuotationRequestWithOrdenedItems,
  getQuotationSuggestedUnitPricePayload,
} from './show.constants'
import {
  AddressDrawerForm,
  BuyerDrawerForm,
  CatalogProducts,
  GeneralInformation,
  OrderData,
  QuotationDialog,
  QuotationMetrics,
  QuotationMetricsProps,
  QuotationsModal,
} from '../../components'
import {
  checkIsCatalogProductsPrices,
  checkIsCompleteGeneralInformation,
  checkIsCompleteOrderData,
  schemaQuotation,
} from '../../quotation-validation'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'
import { getQuotationMetricsPayload } from '../../helpers'

export const QuotationRequestV2Show = () => {
  const { t } = useTranslation('screenBusinessV2')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const { uuid } = useParams()

  const queryClient = useQueryClient()

  const { mutateAsync: mutateAsyncCreateQuotation } = useCreateQuotation()

  const { data: dataUnitPrice, mutateAsync: mutateAsyncUnitPrice } =
    useCalculateUnitPrice()

  const { data: dataDollarQuotationLatest } = useDollarQuotationLatest()

  const { whoAmI } = useAuth()

  const [quotationValues, setQuotationValues] =
    useState<Partial<QuotationProps>>()

  const { data: quotationRequest, isLoading: isLoadingRequest } =
    useQuotationRequestDetail(uuid)

  const { mutateAsync: mutateAsyncUpdateQuotationRequest, isPending } =
    useUpdateQuotationRequest(uuid || '')

  const [quotationRequestOrdened, setQuotationRequestOrdened] =
    useState<QuotationRequestProps>()

  const setBuyerProfile = (buyerProfile: ProfileProps) => {
    setQuotationRequestOrdened({
      ...quotationRequestOrdened,
      buyerProfile,
      buyerProfileUuid: buyerProfile.uuid,
    } as QuotationRequestProps)
  }

  const setAddress = (address: Partial<AddressProps>) => {
    setQuotationRequestOrdened({
      ...quotationRequestOrdened,
      address,
      addressUuid: address.uuid,
    } as QuotationRequestProps)
  }

  const {
    mutateAsync: mutateAsyncSplitQuotationRequest,
    isPending: isPendingSplitQuotationRequest,
  } = useSplitQuotationRequest(uuid || '')

  const approveForQuotation = async () => {
    const response = await mutateAsyncUpdateQuotationRequest({
      ...quotationRequestOrdened,
      status: 'approved_for_quotation',
    })

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })
    }
  }

  const isCompleteGeneralInformation = checkIsCompleteGeneralInformation({
    address: quotationRequestOrdened?.address,
    buyerProfile: quotationRequestOrdened?.buyerProfile,
    paymentFormOption: quotationValues?.paymentFormOption,
    paymentMethodOption: quotationValues?.paymentMethodOption,
  })

  const isCompleteOrderData = checkIsCompleteOrderData({
    expiresAt: quotationValues?.expiresAt,
    expectedDeliveryDays: quotationValues?.expectedDeliveryDays,
    totalFreightPrice: quotationValues?.totalFreightPrice,
    firstMileFreightCost: quotationValues?.firstMileFreightCost,
    lastMileFreightCost: quotationValues?.lastMileFreightCost,
    customsFreightCost: quotationValues?.customsFreightCost,
    purchaseOnCredit: quotationValues?.purchaseOnCredit,
    financingCost: quotationValues?.financingCost,
  })

  const isCompleteCatalogProductsPrices = checkIsCatalogProductsPrices(
    quotationValues?.products,
  )

  const isApprovedForQuotation =
    quotationRequestOrdened?.status === 'approved_for_quotation'

  const isProcessed = quotationRequestOrdened?.status === 'processed'

  const [activeItems, setActiveItems] = useState<string[]>([
    'general-information',
    'catalog-products',
    'order-data',
  ])

  const [quotationDialogIsOpen, setQuotationDialogIsOpen] = useState(false)

  const [dataForMetrics, setDataForMetrics] =
    useState<QuotationMetricsProps['values']>()

  const [dataForSuggestedUnitPrice, setDataForSuggestedUnitPrice] =
    useState<CalculateUnitPricePayloadProps>()

  const [queryParams, setQueryParams] = useParamsState({
    form: '',
  })

  const [quotationsModalIsOpen, setQuotationsModalIsOpen] = useState(false)

  const onHandleOpenQuotationsModal = () => {
    if (quotationRequestOrdened?.quotations?.length === 0) return

    setQuotationsModalIsOpen(true)
  }

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaQuotation(t),
    quotationValues,
  )

  const onCloseDrawerForm = () => {
    setQueryParams({ ...queryParams, form: '' })
  }

  const getMetrics = (quotation: QuotationProps | undefined = undefined) => {
    const data = quotation || quotationValues
    if (data) {
      setDataForMetrics(
        getQuotationMetricsPayload({
          quotationValues: data as QuotationProps,
        }),
      )
    }
  }

  const getSuggestedUnitPrice = (quotation?: QuotationProps) => {
    const data = quotation || quotationValues
    if (data) {
      setDataForSuggestedUnitPrice(
        getQuotationSuggestedUnitPricePayload({
          quotationValues: data as QuotationProps,
        }),
      )
    }
  }

  const accordionsComponents = {
    'general-information': (position: number) => (
      <GeneralInformation
        position={position}
        key="general-information"
        isComplete={isCompleteGeneralInformation}
        isLoading={isLoadingRequest}
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        buyerProfile={quotationRequestOrdened?.buyerProfile}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        address={quotationRequestOrdened?.address}
        onHandleBuyerDrawerOpen={() =>
          setQueryParams({ ...queryParams, form: 'buyer' })
        }
        onHandleAddressDrawerOpen={() =>
          setQueryParams({ ...queryParams, form: 'address' })
        }
        isEditable={isApprovedForQuotation}
        getMetrics={getMetrics}
        errors={errors}
        quotationRequestStatus={quotationRequestOrdened?.status || 'pending'}
      />
    ),
    'order-data': (position: number) => (
      <OrderData
        position={position}
        key="order-data"
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        isComplete={isCompleteOrderData}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        errors={errors}
        getMetrics={getMetrics}
        getSuggestedUnitPrice={getSuggestedUnitPrice}
      />
    ),
    'catalog-products': (position: number) => (
      <CatalogProducts
        position={position}
        key="catalog-products"
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        validation={isCompleteCatalogProductsPrices}
        isEditable={isApprovedForQuotation}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        catalogProducts={quotationRequestOrdened?.items || []}
        onHandleCatalogProductsDrawerOpen={() => {}}
        getMetrics={() => getMetrics()}
        getSuggestedUnitPrice={getSuggestedUnitPrice}
        suggestedUnitPrice={dataUnitPrice?.data}
        quotationRequest={quotationRequestOrdened}
        errors={errors}
        isRfq
      />
    ),
  }

  const onHandleSubmitComplete = async () => {
    if (!isValid) {
      return onHandleListenErrors(true)
    }

    onHandleListenErrors(false)

    const confirm = await onConfirmWarningMessage(
      t('quotationCreateConfirmTitle'),
      t('quotationCreateConfirmMessage'),
      t('quotationCreateConfirmButtonCreate'),
      t('quotationCreateConfirmButtonCancel'),
    )

    if (!confirm) {
      return
    }

    const response = await mutateAsyncCreateQuotation({
      ...quotationValues,
      transportCompany: undefined,
    } as QuotationProps)

    if (response.success) {
      onSuccessMessage(
        t('quotationFormSuccessCreateTitle'),
        t('quotationFormSuccessCreateMessage'),
      )

      window.location.href = `${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(response?.data?.uuid || '')}`
    } else {
      onErrorMessage(
        t('quotationFormErrorCreateTitle'),
        t('quotationFormErrorCreateMessage'),
      )
    }
  }

  const onHandleSubmit = () => {
    if (
      quotationValues?.products &&
      quotationValues?.products?.filter((product) => product.catalogProductUuid)
        ?.length < quotationValues?.products?.length
    ) {
      return setQuotationDialogIsOpen(true)
    }

    onHandleSubmitComplete()
  }

  const onHandleSubmitUpdate = async ({
    action,
    selectedProducts,
  }: {
    selectedProducts: QuotationRequestItemProps[]
    action: keyof typeof QuotationRequestSplitEnum | ''
  }) => {
    let response: {
      success: boolean
      message: string
    } = {
      success: false,
      message: '',
    }

    if (action === '') {
      response = await mutateAsyncUpdateQuotationRequest({
        ...quotationRequestOrdened,
        items: selectedProducts,
      })
    } else {
      response = await mutateAsyncSplitQuotationRequest({
        action,
        selectedItems: selectedProducts.map((product) => product.uuid),
      })

      setQueryParams({ ...queryParams, form: '' })
    }

    if (response.success) {
      onSuccessMessage(
        t('quotationRequestFormUpdateTitle'),
        t('quotationRequestFormUpdateMessage'),
      )

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })
    } else {
      onErrorMessage(
        t('quotationRequestFormUpdateTitle'),
        t('quotationRequestFormUpdateMessage'),
      )
    }
  }

  useEffect(() => {
    setQuotationValues({
      discount: '0.00',
      ...quotationValues,
      status: 'pending',
      buyerProfileUuid: quotationRequestOrdened?.buyerProfile?.uuid,
      buyerProfile: quotationRequestOrdened?.buyerProfile,
      deliveryAddressUuid: quotationRequestOrdened?.address?.uuid,
      deliveryAddress: quotationRequestOrdened?.address as AddressProps,
      creatorEmail: whoAmI?.email,
      dollarExchangeRate: dataDollarQuotationLatest?.data?.value,
      includeIva: true,
      products: quotationRequestOrdened?.items?.map((item, i) => ({
        ...item,
        ...quotationValues?.products?.[i],
        quantity: item?.quantity || 0,
        catalogProduct: item?.catalogProduct,
        catalogProductUuid: item?.catalogProduct?.uuid,
      })) as QuotationProps['products'],
      quotationRequestUuid: quotationRequestOrdened?.uuid,
    })
  }, [dataDollarQuotationLatest, whoAmI, quotationRequestOrdened])

  useEffect(() => {
    if (dataForSuggestedUnitPrice) {
      mutateAsyncUnitPrice(dataForSuggestedUnitPrice)
    }
  }, [dataForSuggestedUnitPrice])

  useEffect(() => {
    setQuotationRequestOrdened(
      getQuotationRequestWithOrdenedItems(quotationRequest?.data),
    )
  }, [quotationRequest])

  useEffect(() => {
    trackEvent('adminRfqShowPageView', { page })
  }, [])

  if (queryParams.form === 'update-rfq') {
    return (
      <UpdateRfq
        isLoading={isPendingSplitQuotationRequest}
        onHandleSubmit={onHandleSubmitUpdate}
        items={quotationRequestOrdened?.items as QuotationRequestItemProps[]}
        reference={quotationRequestOrdened?.reference}
        onHandleClose={() => setQueryParams({ ...queryParams, form: '' })}
      />
    )
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Text variant="text-small" className={styles.breadcrumb}>
          <Link
            to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}
          >{`${t('quotationRequestTitle')}`}</Link>
          {` / `}
          <span>{quotationRequestOrdened?.reference}</span>
        </Text>
        <div className={styles['header-content']}>
          <div>
            <div className={styles['sub-title']}>
              <Text variant="title-large">
                {t(
                  quotationRequestOrdened?.items?.length === 1
                    ? 'quotationIdentifier'
                    : 'quotationIdentifierPlural',
                  {
                    rfq: quotationRequestOrdened?.reference,
                    quantity: quotationRequestOrdened?.items?.length,
                  },
                )}
              </Text>
              <RfqStatusSelect quotationRequest={quotationRequestOrdened} />
            </div>
            <Text
              variant="text-base"
              className={styles['sub-title-instructions']}
            >
              {t('instructionGenerateProposals')}
            </Text>
          </div>
          <div className={styles['header-actions']}>
            {!isProcessed && (
              <Button
                variant="secondary"
                onClick={() =>
                  setQueryParams({ ...queryParams, form: 'update-rfq' })
                }
              >
                {t('buttonEditRequestQuotation')}
              </Button>
            )}
            {!isProcessed && !isApprovedForQuotation && (
              <Button
                variant="secondary"
                onClick={approveForQuotation}
                disabled={isPending}
              >
                {t('buttonApproveRFQ')}
              </Button>
            )}
            {!isProcessed && isApprovedForQuotation && (
              <Button variant="primary" onClick={onHandleSubmit}>
                {t('buttonCreateQuotation')}
              </Button>
            )}
            {isProcessed && (
              <Button variant="primary" onClick={onHandleOpenQuotationsModal}>
                {t('buttonViewQuotations')}
              </Button>
            )}
          </div>
        </div>
      </header>

      {isApprovedForQuotation && <QuotationMetrics values={dataForMetrics} />}

      {accordionsByStatusObject[
        quotationRequestOrdened?.status || 'pending'
      ].map((key, i) =>
        accordionsComponents[key as keyof typeof accordionsComponents](i + 1),
      )}

      <BuyerDrawerForm
        uuid={quotationRequestOrdened?.buyerProfile?.uuid || ''}
        isOpen={queryParams.form === 'buyer'}
        onHandleOpen={onCloseDrawerForm}
        setBuyerProfile={setBuyerProfile}
      />
      <AddressDrawerForm
        quotationRequest={quotationRequestOrdened as QuotationRequestProps}
        isOpen={queryParams.form === 'address'}
        onHandleOpen={onCloseDrawerForm}
        setAddress={setAddress}
      />

      <QuotationDialog
        onHandleSubmit={onHandleSubmitComplete}
        isOpen={quotationDialogIsOpen}
        onHandleClose={() => setQuotationDialogIsOpen(false)}
      />

      {isProcessed && (
        <QuotationsModal
          quotations={quotationRequestOrdened?.quotations || []}
          isOpen={quotationsModalIsOpen}
          onHandleClose={() => setQuotationsModalIsOpen(false)}
        />
      )}
    </div>
  )
}
