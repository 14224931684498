export const invalidateRFQOptions = [
  { label: 'MOQ', value: 'MOQ' },
  { label: 'Test', value: 'Test' },
  { label: 'Duplicado', value: 'Duplicado' },
  { label: 'Perfil', value: 'Perfil' },
  { label: 'NAN', value: 'NAN' },
  { label: 'Producto terminado', value: 'Producto terminado' },
  { label: 'Proyecto', value: 'Proyecto' },
  { label: 'Licitaciones', value: 'Licitaciones' },
  { label: 'Precio', value: 'Precio' },
  { label: 'Datos de contacto', value: 'Datos de contacto' },
  { label: 'Venta por spot', value: 'Venta por spot' },
  { label: 'Zona fuera de rango', value: 'Zona fuera de rango' },
  { label: 'MOQ CDMX y EDOMEX', value: 'MOQ CDMX y EDOMEX' },
  { label: 'MOQ República', value: 'MOQ República' },
  { label: 'Otro', value: 'Otro' },
]
