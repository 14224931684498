import {
  AddressProps,
  FinanceProfilePaymentFormsEnum,
  FinanceProfileTransactionPaymentMethodsEnum,
  TransportCompanyProps,
  ProfileProps,
} from '@/types'
import { CatalogProductProps } from './catalog-product.type'
import { SupplierProps } from './supplier.type'

export enum QuotationProductPackageEnum {
  '25kg_bags' = '25kg_bags',
  '25kg_boxes' = '25kg_boxes',
  'super_sacks' = 'super_sacks',
  'in_bulk' = 'in_bulk',
  'gaylord_boxes' = 'gaylord_boxes',
  'rolls' = 'rolls',
}

export interface QuotationNonCatalogProductProps {
  description: string
  link: string
}

export interface QuotationProductProps {
  uuid: string
  quantity: number
  cost: number
  price: number
  supplier: SupplierProps
  supplierUuid?: string
  catalogProduct: CatalogProductProps
  nonCatalogProduct: QuotationNonCatalogProductProps
  catalogProductUuid: string
  selected: boolean
  package: keyof typeof QuotationProductPackageEnum
  traderCommission: number
  isQuoted?: boolean
  createdAt: string
  isSupplierWarehouseSelected?: boolean
}

export enum QuotationStatusEnum {
  'pending' = 'pending',
  'active' = 'active',
  'accepted' = 'accepted',
  'expired' = 'expired',
  'rejected' = 'rejected',
  'cancelled' = 'cancelled',
  'pending_approval' = 'pending_approval',
}

export enum QuotationDeliveryEnum {
  'pick_up' = 'pick_up',
  'receive_at_address' = 'receive_at_address',
}

export enum QuotationInvoiceOptionEnum {
  'buyer' = 'buyer',
  'general_public' = 'general_public',
}

export enum QuotationRecurrencyOptionEnum {
  'once' = 'once',
  'quarterly' = 'quarterly',
}

export interface QuotationProps {
  uuid: string
  quotationRequest: {
    uuid: string
    reference: string
  }
  creatorEmail: string
  createdAt: string
  updatedAt: string
  expiresAt: string
  totalPrice: number
  totalFreightPrice: number
  firstMileFreightCost: number
  lastMileFreightCost: number
  customsFreightCost: number
  financingCost: number
  discount: string
  invoiceOption: keyof typeof QuotationInvoiceOptionEnum
  recurrencyOption: keyof typeof QuotationRecurrencyOptionEnum
  status: keyof typeof QuotationStatusEnum
  reference: string
  deliveryOption: keyof typeof QuotationDeliveryEnum
  rejectedReason: string
  creatorUser: number
  products: QuotationProductProps[]
  buyerProfileUuid: string
  deliveryAddressUuid: string
  quotationRequestUuid: string
  deliveryAddress: AddressProps
  buyerProfile: ProfileProps
  orders: {
    uuid: string
    reference: string
  }[]
  includeIva: boolean
  isAutomated: boolean
  expectedDeliveryDays: number
  dollarExchangeRate: number
  purchaseOnCredit: boolean
  paymentFormOption: keyof typeof FinanceProfilePaymentFormsEnum
  paymentMethodOption: keyof typeof FinanceProfileTransactionPaymentMethodsEnum
  logisticTransportCompany?: TransportCompanyProps
  logisticTransportCompanyUuid?: string
}

export interface QuotationsListWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: QuotationProps[]
}

export interface QuotationClassificationProps {
  total: string
  pending: string
  active: string
  pendingApproval: string
  accepted: string
  expired: string
  rejected: string
}

export interface QuotationSLASProps {
  activeToAcceptedConvertion: number
  activeToAcceptedConvertionVariation: number
  activeToAcceptedSla: number
  activeToAcceptedSlaVariation: number
  pendingToActiveConvertion: number
  pendingToActiveConvertionVariation: number
  pendingToActiveSla: number
  pendingToActiveSlaVariation: number
}
