import styles from './sidebar.module.css'

export const getContainerClasses = (isOpen: boolean) => {
  return `${styles.container} ${isOpen ? styles['container-opened'] : ''}`
}
export const getBackdropClasses = (isOpen: boolean) => {
  return `${styles.backdrop} ${!isOpen ? styles['backdrop-closed'] : ''}`
}

export const getMenuContainerClasses = (isOpen: boolean) => {
  return `${styles['menu-container']} ${isOpen ? styles['menu-container-opened'] : ''}`
}

export const getItemTitleClasses = (isOpen: boolean) => {
  return !isOpen ? styles['item-title-closed'] : styles['item-title']
}

export const getItemTitleTooltipClasses = (isOpen: boolean) => {
  return !isOpen
    ? styles['item-title-tooltip']
    : styles['item-title-tooltip-closed']
}

export const getItemContainerTitleClasses = (isOpen: boolean) => {
  return isOpen
    ? styles['item-container-title']
    : styles['item-container-title-closed']
}
