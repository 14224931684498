import styles from './create-select-products.module.css'

export const variants: Record<string, string> = {
  isSelected: styles['button-selected'],
  isUnselected: '',
}

export const generateButtonClasses = (isSelected: boolean) => {
  return `${styles.button} ${isSelected ? variants.isSelected : variants.isUnselected}`
}
