import {
  ArrowDownIcon,
  ArrowUpIcon,
  DropDown,
  SortIcon,
  Tag,
  Text,
} from '@mercai/clever'

import styles from './sort-by.module.css'
import { useTranslation } from 'react-i18next'
import { SortByProps } from './sort-by.types'
import { MouseEvent } from 'react'

export const SortBy = ({ sortBy, sorts, onHandleSortBy }: SortByProps) => {
  const { t } = useTranslation('componentsSortBy')

  const onHandleSortByInternal = (
    e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
    sortByName: string,
  ) => {
    e.stopPropagation()
    if (sortBy === sortByName) {
      return onHandleSortBy('')
    }

    onHandleSortBy(sortByName)
  }

  const toggleSortBy = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    sortByName: string,
  ) => {
    e.stopPropagation()
    const isDesc = sortBy?.[0] === '-'
    const isActive = !!sortBy?.includes(sortByName)

    const index = isActive && !isDesc ? 1 : isActive && isDesc ? 0 : 2

    const sorts = ['', `-${sortByName}`, sortByName]

    onHandleSortBy(sorts[index])
  }

  return (
    <DropDown.Root>
      <DropDown.ButtonTrigger variant="secondary" hasArrow={false}>
        <SortIcon />
        {t('title')}
        {sortBy && (
          <Tag variant="primary" model="light" size="small">
            1
          </Tag>
        )}
      </DropDown.ButtonTrigger>
      <DropDown.Content orientation="bottom-left" className={styles.sortby}>
        {sorts.map(({ title, sortBy: sortByName }) => (
          <div
            className={styles['sortby-row']}
            key={sortByName}
            onClick={(e) => toggleSortBy(e, sortByName)}
          >
            <Text>{title}</Text>
            <div>
              <ArrowUpIcon
                className={sortBy === sortByName ? styles['sortby-active'] : ''}
                onClick={(e) => onHandleSortByInternal(e, sortByName)}
              />
              <ArrowDownIcon
                className={
                  sortBy === `-${sortByName}` ? styles['sortby-active'] : ''
                }
                onClick={(e) => onHandleSortByInternal(e, `-${sortByName}`)}
              />
            </div>
          </div>
        ))}
      </DropDown.Content>
    </DropDown.Root>
  )
}
