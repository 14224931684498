import { SVGProps } from 'react'

export const MercaiOpusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="71"
      height="20"
      viewBox="0 0 71 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2277_22134)">
        <path d="M35.687 16.8424C34.8283 16.8424 34.0301 16.6952 33.2925 16.4007C32.5549 16.0953 31.9054 15.6754 31.3439 15.141C30.7934 14.5956 30.3586 13.9685 30.0393 13.2595C29.731 12.5397 29.5769 11.7653 29.5769 10.9364C29.5769 10.1074 29.731 9.33849 30.0393 8.62954C30.3586 7.90968 30.7934 7.28254 31.3439 6.7481C31.9054 6.20275 32.5549 5.78284 33.2925 5.48835C34.0301 5.18295 34.8283 5.03026 35.687 5.03026C36.5457 5.03026 37.3439 5.18295 38.0815 5.48835C38.8302 5.78284 39.4797 6.20275 40.0302 6.7481C40.5916 7.28254 41.0265 7.90968 41.3348 8.62954C41.643 9.33849 41.7971 10.1074 41.7971 10.9364C41.7971 11.7653 41.643 12.5397 41.3348 13.2595C41.0265 13.9685 40.5916 14.5956 40.0302 15.141C39.4797 15.6754 38.8302 16.0953 38.0815 16.4007C37.3439 16.6952 36.5457 16.8424 35.687 16.8424ZM35.687 14.6665C36.2155 14.6665 36.6999 14.5738 37.1402 14.3884C37.5806 14.1921 37.9659 13.9303 38.2962 13.6031C38.6265 13.265 38.8852 12.8669 39.0724 12.4088C39.2595 11.9507 39.3531 11.4599 39.3531 10.9364C39.3531 10.4019 39.2595 9.9111 39.0724 9.46392C38.8852 9.00583 38.6265 8.61318 38.2962 8.28597C37.9659 7.94786 37.5806 7.68609 37.1402 7.50067C36.6999 7.30435 36.2155 7.20619 35.687 7.20619C35.1696 7.20619 34.6907 7.30435 34.2503 7.50067C33.81 7.68609 33.4246 7.94786 33.0944 8.28597C32.7641 8.61318 32.5054 9.00583 32.3182 9.46392C32.131 9.9111 32.0375 10.3965 32.0375 10.92C32.0375 11.4544 32.131 11.9507 32.3182 12.4088C32.5054 12.8669 32.7641 13.265 33.0944 13.6031C33.4246 13.9303 33.81 14.1921 34.2503 14.3884C34.6907 14.5738 35.1696 14.6665 35.687 14.6665Z" />
        <path d="M43.3078 20V8.13873H45.5371V8.84222C46.2417 8.29688 47.0564 8.02421 47.9812 8.02421C48.7959 8.02421 49.5335 8.22053 50.194 8.61318C50.8546 9.00583 51.3775 9.53481 51.7629 10.2001C52.1592 10.8546 52.3574 11.5908 52.3574 12.4088C52.3574 13.2268 52.1592 13.9685 51.7629 14.6338C51.3665 15.2882 50.8326 15.8117 50.161 16.2044C49.4895 16.5861 48.7408 16.777 47.9151 16.777C47.4858 16.777 47.0729 16.7225 46.6766 16.6134C46.2803 16.4934 45.9115 16.3244 45.5702 16.1062V20H43.3078ZM47.6179 14.8792C48.0913 14.8792 48.5151 14.7701 48.8895 14.552C49.2748 14.3339 49.5775 14.0394 49.7977 13.6685C50.0179 13.2977 50.128 12.8778 50.128 12.4088C50.128 11.9398 50.0179 11.5199 49.7977 11.149C49.5775 10.7673 49.2748 10.4728 48.8895 10.2656C48.5151 10.0474 48.0913 9.93837 47.6179 9.93837C47.1995 9.93837 46.8142 10.0093 46.4619 10.1511C46.1206 10.2819 45.8234 10.4837 45.5702 10.7564V14.0775C45.8124 14.3284 46.1096 14.5247 46.4619 14.6665C46.8252 14.8083 47.2105 14.8792 47.6179 14.8792Z" />
        <path d="M57.0615 16.8261C56.4009 16.8261 55.8119 16.6843 55.2945 16.4007C54.7881 16.1062 54.3862 15.7027 54.089 15.19C53.8028 14.6774 53.6596 14.0885 53.6596 13.4231V8.13873H55.922V13.0959C55.922 13.6413 56.0817 14.0775 56.4009 14.4048C56.7312 14.732 57.1716 14.8956 57.722 14.8956C58.0964 14.8956 58.4321 14.8247 58.7294 14.6829C59.0376 14.5302 59.2909 14.3175 59.489 14.0448V8.13873H61.7514V16.6625H59.489V15.9753C58.8285 16.5425 58.0193 16.8261 57.0615 16.8261Z" />
        <path d="M66.7349 16.8261C65.9422 16.8261 65.2101 16.7279 64.5385 16.5316C63.867 16.3244 63.2835 16.0299 62.788 15.6481L63.911 14.1593C64.4064 14.4866 64.8798 14.732 65.3312 14.8956C65.7936 15.0592 66.2505 15.141 66.7018 15.141C67.2083 15.141 67.6101 15.0592 67.9073 14.8956C68.2156 14.7211 68.3697 14.4975 68.3697 14.2248C68.3697 14.0066 68.2817 13.8321 68.1055 13.7013C67.9404 13.5704 67.6706 13.4777 67.2963 13.4231L65.6449 13.1777C64.7862 13.0468 64.1367 12.7742 63.6963 12.3597C63.2559 11.9343 63.0358 11.3781 63.0358 10.6909C63.0358 10.1347 63.1789 9.66024 63.4651 9.26759C63.7624 8.86404 64.1752 8.55319 64.7037 8.33505C65.2431 8.10601 65.8816 7.99149 66.6193 7.99149C67.2468 7.99149 67.8578 8.07874 68.4523 8.25325C69.0578 8.42776 69.6248 8.69498 70.1532 9.05491L69.0633 10.511C68.5899 10.2165 68.1385 10.0038 67.7092 9.87293C67.2798 9.74204 66.8449 9.6766 66.4046 9.6766C65.9972 9.6766 65.667 9.75295 65.4137 9.90565C65.1715 10.0583 65.0504 10.2601 65.0504 10.511C65.0504 10.74 65.1385 10.92 65.3147 11.0509C65.4908 11.1818 65.7936 11.2745 66.2229 11.329L67.8578 11.5744C68.7165 11.6944 69.3716 11.9671 69.8229 12.3924C70.2743 12.8069 70.5 13.3468 70.5 14.0121C70.5 14.5574 70.3349 15.0428 70.0046 15.4682C69.6743 15.8826 69.2284 16.2153 68.667 16.4662C68.1055 16.7061 67.4615 16.8261 66.7349 16.8261Z" />
        <path d="M0.5 5.61654L6.85944 0.286618C7.08077 0.102083 7.36142 0 7.64994 0C8.32776 0 8.87717 0.545752 8.87717 1.21911V16.3883C8.87717 17.1029 8.2942 17.6839 7.57286 17.6839H5.9919C5.27256 17.6839 4.6876 17.1048 4.6876 16.3883V5.61654H0.5Z" />
        <path d="M8.8772 8.4529L15.2366 3.2176C15.458 3.03491 15.7347 2.93668 16.0232 2.93668C16.703 2.93668 17.2544 3.48477 17.2544 4.16055V16.2892C17.2544 17.0592 16.6259 17.6839 15.8512 17.6839H14.4679C13.6932 17.6839 13.0648 17.0592 13.0648 16.2892V8.4529H8.8772Z" />
        <path d="M25.5955 13.4959V7.13909C25.5955 6.63574 25.3082 6.15793 24.8419 5.96721C24.4051 5.78829 23.9288 5.87283 23.5865 6.15597L17.2544 11.396H21.424V16.1621C21.424 17.0036 22.1068 17.6839 22.9469 17.6839H29.5624L26.2389 14.8821C25.8296 14.538 25.5955 14.0307 25.5955 13.4959Z" />
      </g>
      <defs>
        <clipPath id="clip0_2277_22134">
          <rect
            width="70"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
