import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ArrowRightIcon,
  EditIcon,
  Button,
  Text,
  Tag,
  WarningOutlineIcon,
  TrashIcon,
} from '@mercai/clever'

import { formatLongDate, formatCurrency, isSuperUser } from '@/helpers'

import { ROUTES_ADMIN } from '@/router'
import { useAuth } from '@/providers'

import {
  SaleOrderStatusEnum,
  SaleOrderPaymentCalculatedStatusEnum,
} from '@/types'

import type {
  TableColumnReferenceProps,
  TableColumnBuyerProps,
  TableColumnTaxStatusProps,
  TableColumnTagMoscowProps,
  TableColumnPaymentStatusProps,
  TableColumnInvoiceStatusProps,
  TableColumnLogisticStatusProps,
  TableColumnSalesOrderStatusProps,
  TableColumnExpectedDeliveryDateProps,
  TableColumnLogisticActionsProps,
  TableColumnTotalPriceProps,
} from './table-columns.type'

import styles from './table-columns.module.css'

export const TableReference = ({
  reference,
  saleOrderStatus,
}: TableColumnReferenceProps) => {
  const { t: tEnums } = useTranslation('enums')

  const saleOrderStatusObject: Record<SaleOrderStatusEnum, ReactNode> = {
    cancelled: (
      <Tag model="light" variant="danger">
        {tEnums('sale_order_status_cancelled')}
      </Tag>
    ),
    completed: (
      <Tag model="light" variant="success">
        {tEnums('sale_order_status_completed')}
      </Tag>
    ),
    created: (
      <Tag model="light" variant="warning">
        {tEnums('sale_order_status_created')}
      </Tag>
    ),
    fraudulent: (
      <Tag model="light" variant="danger">
        {tEnums('sale_order_status_fraudulent')}
      </Tag>
    ),
    processing: (
      <Tag model="light" variant="primary">
        {tEnums('sale_order_status_processing')}
      </Tag>
    ),
  }

  return (
    <div className={styles.reference}>
      {saleOrderStatusObject?.[saleOrderStatus]}

      <Text>#{reference}</Text>
    </div>
  )
}

export const TableColumnBuyer = ({
  buyerName,
  companyName,
  uuid,
}: TableColumnBuyerProps) => {
  const getInitials = () => {
    const name = companyName || buyerName

    const splittedName = name.split(' ')

    let initials = ''

    if (splittedName?.[0]) {
      initials = `${splittedName[0][0]}`
    }

    if (splittedName?.[splittedName?.length - 1]) {
      initials = `${initials}${splittedName[splittedName?.length - 1][0]}`
    }

    return initials
  }

  return (
    <Link
      target="_blank"
      to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(uuid)}
      className={styles.buyer}
    >
      <Text variant="title-small" className={styles.initials}>
        {getInitials()}
      </Text>
      <div className={styles.content}>
        <Text variant="title-base">{companyName || '-'}</Text>
        {buyerName && <Text variant="text-small">{buyerName || ''}</Text>}
      </div>
    </Link>
  )
}

export const TableTaxStatus = ({
  taxStatus = 'pending',
}: TableColumnTaxStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('profile_tax_status_pending')}
      </Tag>
    ),
    review: (
      <Tag variant="danger" model="light">
        {t('profile_tax_status_review')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {t('profile_tax_status_completed')}
      </Tag>
    ),
    invalid: (
      <Tag variant="basic" model="light">
        {t('profile_tax_status_invalid')}
      </Tag>
    ),
  }

  return <div>{object[taxStatus]}</div>
}

export const TableTagMoscow = ({
  moscowClassification,
}: TableColumnTagMoscowProps) => {
  const { t } = useTranslation('enums')

  const moscowObject: Record<string, ReactNode> = {
    must: (
      <Tag variant="success" model="light">
        {t('profile_moscow_must')}
      </Tag>
    ),
    should: (
      <Tag variant="primary" model="light">
        {t('profile_moscow_should')}
      </Tag>
    ),
    could: (
      <Tag variant="warning" model="light">
        {t('profile_moscow_could')}
      </Tag>
    ),
    wont: (
      <Tag variant="danger" model="light">
        {t('profile_moscow_wont')}
      </Tag>
    ),
    unknown: (
      <Tag variant="basic" model="light">
        {t('profile_moscow_unknown')}
      </Tag>
    ),
  }

  return moscowObject[moscowClassification || 'unknown']
}

export const TableColumnsPaymentStatus = ({
  calculatedPaymentStatus,
}: TableColumnPaymentStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<SaleOrderPaymentCalculatedStatusEnum, ReactNode> = {
    processing: (
      <Tag variant="warning" model="light">
        {t('order_payment_calculated_status_processing')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {t('order_payment_calculated_status_paid')}
      </Tag>
    ),
    invalid: (
      <Tag variant="basic" model="light">
        {t('order_payment_calculated_status_invalid')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_payment_calculated_status_cancelled')}
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {t('order_payment_calculated_status_expired')}
      </Tag>
    ),
    partially_paid: (
      <Tag variant="warning" model="light">
        {t('order_payment_calculated_status_partially_paid')}
      </Tag>
    ),
  }

  return <div>{object[calculatedPaymentStatus]}</div>
}

export const TableColumnsInvoiceStatus = ({
  invoiceStatus,
}: TableColumnInvoiceStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('order_invoice_status_pending')}
      </Tag>
    ),
    uploaded: (
      <Tag variant="success" model="light">
        {t('order_invoice_status_uploaded')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('order_invoice_status_cancelled')}
      </Tag>
    ),
  }

  return <div>{object[invoiceStatus]}</div>
}

export const TableColumnsLogisticStatus = ({
  logisticStatus,
}: TableColumnLogisticStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_pending')}
      </Tag>
    ),
    delivery_scheduled: (
      <Tag variant="success" model="light">
        {t('logistic_shipment_status_delivery_scheduled')}
      </Tag>
    ),
    in_transit: (
      <Tag variant="primary" model="light">
        {t('logistic_shipment_status_in_transit')}
      </Tag>
    ),
    on_hold: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_on_hold')}
      </Tag>
    ),
    partially_delivered: (
      <Tag variant="warning" model="light">
        {t('logistic_shipment_status_partially_delivered')}
      </Tag>
    ),
    delivered: (
      <Tag variant="success" model="light">
        {t('logistic_shipment_status_delivered')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('logistic_shipment_status_cancelled')}
      </Tag>
    ),
    refused: (
      <Tag variant="danger" model="light">
        {t('logistic_shipment_status_refused')}
      </Tag>
    ),
  }

  return <div>{object[logisticStatus]}</div>
}
export const TableColumnsSalesOrderStatus = ({
  salesOrderStatus,
}: TableColumnSalesOrderStatusProps) => {
  const { t } = useTranslation('enums')

  const object: Record<string, ReactNode> = {
    created: (
      <Tag variant="warning" model="light">
        {t('sale_order_status_created')}
      </Tag>
    ),
    processing: (
      <Tag variant="warning" model="light">
        {t('sale_order_status_processing')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {t('sale_order_status_completed')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {t('sale_order_status_fraudulent')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('sale_order_status_cancelled')}
      </Tag>
    ),
  }

  return <div>{object[salesOrderStatus]}</div>
}

export const TableColumnExpectedDeliveryDate = ({
  expectedDeliveryAt,
  deliveryDate,
}: TableColumnExpectedDeliveryDateProps) => {
  const verifyLate = () => {
    const expectedDate = new Date(expectedDeliveryAt)
    expectedDate.setDate(expectedDate.getDate() + 1)

    let delivery = new Date(deliveryDate)

    if (!deliveryDate) {
      delivery = new Date()
    }

    return expectedDate < delivery
  }

  const isLate = verifyLate()

  return (
    <div className={styles['expected-delivery-date']}>
      <Text className={isLate ? styles['is-late'] : undefined}>
        {formatLongDate(expectedDeliveryAt)}
      </Text>

      {isLate && <WarningOutlineIcon />}
    </div>
  )
}

export const TableColumnTotalPrice = ({
  saleOrder,
}: TableColumnTotalPriceProps) => {
  const currency = saleOrder?.payments?.[0]?.currency
  const currencyMultiplier = +(currency === 'USD'
    ? 1
    : saleOrder?.payments?.[0]?.dollarExchangeRate || 0)

  return (
    <div>
      {formatCurrency(
        +(saleOrder?.totalOrderPrice || 0) * currencyMultiplier,
        'es-MX',
        currency,
      )}
    </div>
  )
}

export const TableColumnActionsButtons = ({
  onClickEdit,
  onClickView,
  onClickDelete,
}: TableColumnLogisticActionsProps) => {
  const { whoAmI } = useAuth()

  return (
    <div className={styles['actions-buttons']}>
      <Button variant="secondary" onClick={onClickEdit} model="square">
        <EditIcon />
      </Button>

      <Button onClick={onClickView} model="square">
        <ArrowRightIcon />
      </Button>

      {isSuperUser(whoAmI?.email) && (
        <Button variant="danger" model="square" onClick={onClickDelete}>
          <TrashIcon />
        </Button>
      )}
    </div>
  )
}
