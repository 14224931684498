import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FilterIcon,
  InputSearch,
  TableColumns,
  Tag,
  Text,
  Pagination,
  PlusIcon,
  TableColumnsColumnProps,
  Select,
} from '@mercai/clever'

import {
  TableColumnPayer,
  TableColumnPaidAt,
  TableColumnPaymentStatus,
  TransactionDrawerFilter,
  TableColumnActions,
  TransactionDrawerForm,
  TableColumnPrices,
  TableColumnPaymentMethod,
} from './components'

import { AppliedFilters, SortBy } from '@/components'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { formatDate } from '@/helpers'

import { TransactionProps } from '@/types'

import { OrderCenterTableTransactionProps } from './table-transaction.type'

import styles from './table-transaction.module.css'

export const OrderCenterTableTransaction = ({
  data,
  isLoading,
  filter,
  onSetFilter,
}: OrderCenterTableTransactionProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [registerUuidEdit, setRegisterUuidEdit] = useState('')
  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const onHandleOpenDrawerEdit = (uuid?: string) => {
    setRegisterUuidEdit(uuid || '')
    setIsOpenDrawerForm(true)
  }

  const onHandleNewRegister = () => {
    setIsOpenDrawerForm(true)

    setRegisterUuidEdit('')
  }

  const [totalPages, setTotalPages] = useState(1)

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      onSetFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      onSetFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  useEffect(() => {
    if (data?.count) {
      setTotalPages(
        Math.ceil(data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  const columns: TableColumnsColumnProps<TransactionProps>[] = [
    {
      title: t('tableTransactionReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ record }) => (
        <div className={`${styles['reference-column']}`}>
          <div>
            <Text>#{record?.reference}</Text>
            <Text variant="text-small" className={styles['gray-400']}>
              {record?.createdAt ? formatDate(record?.createdAt) : ''}
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: t('tableTransactionPayer'),
      key: 'buyer',
      sortKey: 'payer__company_name',
      render: ({ record }) => (
        <TableColumnPayer
          buyerName={
            record?.payer?.usersNames
              ? record?.payer?.usersNames?.join(', ')
              : ''
          }
          companyName={record?.payer?.companyName}
          uuid={record?.payer?.uuid}
        />
      ),
    },
    {
      title: t('tableTransactionPaidAt'),
      key: 'paidAt',
      sortKey: 'paid_at',
      dataKey: 'paidAt',
      width: '12rem',
      render: ({ value }) => <TableColumnPaidAt paidAt={value || ''} />,
    },
    {
      title: t('tableTransactionPaymentMethod'),
      key: 'paymentMethod',
      dataKey: 'paymentMethod',
      width: '10rem',
      render: ({ record }) => (
        <TableColumnPaymentMethod
          paymentMethod={record?.paymentMethod}
          destinationBank={record.destinationBank}
        />
      ),
    },
    {
      title: t('tableTransactionStatePayment'),
      key: 'paymentStatus',
      sortKey: 'status',
      width: '12rem',
      render: ({ record }) => (
        <TableColumnPaymentStatus
          saleOrderReference={record?.order?.reference}
          status={record?.status}
        />
      ),
    },
    {
      title: t('tableTransactionAmount'),
      key: 'invoiceStatus',
      width: '10rem',
      render: ({ record }) => (
        <TableColumnPrices
          currency={record?.currency || 'USD'}
          dollarExchangeRate={+(record?.dollarExchangeRate || 0)}
          dollarValue={+(record?.dollarValue || 0)}
          value={+(record?.value || 0)}
        />
      ),
    },
    {
      key: 'tableLogisticActions',
      dataKey: 'uuid',
      width: '5rem',
      render: ({ record }) => (
        <TableColumnActions
          onClick={() => onHandleOpenDrawerEdit(record.uuid)}
        />
      ),
    },
  ]

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const sorts = [
    {
      title: t('orderingCreatedAtButton'),
      sortBy: 'created_at',
    },
  ]

  return (
    <div className={styles.core}>
      <Text variant="title-large">{t('tableTransactionTitle')}</Text>

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableTransactionSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableTransactionFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <SortBy
            sortBy={filter?.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>

        <div className={styles['filter-group']}>
          <Button onClick={onHandleNewRegister}>
            <PlusIcon />
            {t('tableTransactionButtonNew')}
          </Button>
        </div>
      </div>

      <AppliedFilters
        filtersApplied={filter}
        excludeKeys={['page', 'limit', 'ordering']}
        onRemoveFilter={(key) => handleFilter(key, '')}
        translations={{
          filterAppliedText: t('filterApplied'),
        }}
        keyTranslations={{
          payment_method: t('drawerFilterTransactionStatusTitle'),
          status: t('drawerFilterTransactionStatusTitle'),
          created_at_before: t('filterCreatedAtBefore'),
          created_at_after: t('filterCreatedAtAfter'),
          search: t('filterSearch'),
        }}
        renderValue={(key, value) => {
          if (key === 'created_at_before' || key === 'created_at_after') {
            return formatDate(value)
          }

          const objectsTranslations = {
            payment_method: tEnums(`transaction_payment_method_${value}`),
            status: tEnums(`transaction_status_${value}`),
          }

          return objectsTranslations[key as keyof typeof objectsTranslations]
        }}
      />

      <TableColumns<TransactionProps>
        classNames={{
          bodyColumn: styles['body-column'],
        }}
        isLoading={isLoading}
        columns={columns}
        data={data?.results || []}
        sortBy={filter.ordering}
        onSortBy={onHandleSortBy}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableTransactionShowRegisters', {
              showNumber:
                (data?.count || 0) < +filter.limit
                  ? data?.count
                  : +filter.limit,
              totalNumber: data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <TransactionDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={onSetFilter}
      />

      <TransactionDrawerForm
        isOpen={isOpenDrawerForm}
        onHandleOpen={() => setIsOpenDrawerForm((prev) => !prev)}
        uuid={registerUuidEdit}
        onSetUuid={setRegisterUuidEdit}
      />
    </div>
  )
}
