import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text, PlusIcon } from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { useParamsState, trackEvent } from '@/helpers'
import { useQuotationClassification, useQuotationSlas } from '@/services'

import type { QuotationListProps } from './list.type'

import styles from './list.module.css'
import { useLocation } from 'react-router-dom'
import { TableQuotation } from './components'
import { BusinessMetricCards } from '../../components'

import { Tabs } from '@/components'

export const QuotationV2List = ({ buyerProfile }: QuotationListProps) => {
  const { t } = useTranslation('screenQuotationV2List')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    recurrencyOption: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
    classification_group: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const { data: dataClassification, isLoading: isLoadingClassification } =
    useQuotationClassification()

  const { data: dataSlas, isLoading: isLoadingSlas } = useQuotationSlas()

  const metrics = [
    {
      value: t('quotationMetricHours', {
        value: (dataSlas?.data?.pendingToActiveSla || 0).toFixed(2),
      }),
      label: t('quotationMetricSLAPendingToActive'),
      positiveIsBad: true,
      change: dataSlas?.data?.pendingToActiveSlaVariation || 0,
      changeSuffix: ' h',
      isLoading: isLoadingSlas,
    },
    {
      value: `${(dataSlas?.data?.pendingToActiveConvertion || 0).toFixed(2)}%`,
      label: t('quotationMetricConversionPendingToActive'),
      change: dataSlas?.data?.pendingToActiveConvertionVariation || 0,
      changeSuffix: ' p.p',
      isLoading: isLoadingSlas,
    },
    {
      value: t('quotationMetricHours', {
        value: (dataSlas?.data?.activeToAcceptedSla || 0).toFixed(2),
      }),
      label: t('quotationMetricSLAActiveToAccepted'),
      positiveIsBad: true,
      change: dataSlas?.data?.activeToAcceptedSlaVariation || 0,
      changeSuffix: ' h',
      isLoading: isLoadingSlas,
    },
    {
      value: `${(dataSlas?.data?.activeToAcceptedConvertion || 0).toFixed(2)}%`,
      label: t('quotationMetricConversionActiveToAccepted'),
      change: dataSlas?.data?.activeToAcceptedConvertionVariation || 0,
      changeSuffix: ' p.p',
      isLoading: isLoadingSlas,
    },
  ]

  useEffect(() => {
    trackEvent('adminQuotationListPageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-content']}>
          <div>
            <Text variant="title-large">{t('quotationTitle')}</Text>
            <div>
              <Text variant="text-base" className={styles['sub-title']}>
                {t('quotationSubtitle')}
              </Text>
            </div>
          </div>
          <div className={styles['header-actions']}>
            <Button onClick={onHandleNewRegister}>
              <PlusIcon />
              {t('quotationTableNewQuotationButton')}
            </Button>
          </div>
        </div>
      </header>

      <Tabs
        isLoading={isLoadingClassification}
        hasCount
        items={[
          {
            title: t('quotationTabAll'),
            isActive: !filter.classification_group,
            onSelect: () => handleFilter('classification_group', ''),
            count: dataClassification?.data?.total || 0,
          },
          {
            title: t('quotationTabPending'),
            isActive: filter.classification_group === 'pending',
            onSelect: () => handleFilter('classification_group', 'pending'),
            count: dataClassification?.data?.pending || 0,
          },
          // {
          //   title: t('quotationTabApproval'),
          //   isActive: filter.classification_group === 'pending_approval',
          //   onSelect: () =>
          //     handleFilter('classification_group', 'pending_approval'),
          //   count: dataClassification?.data?.pendingApproval || 0,
          // },
          {
            title: t('quotationTabActive'),
            isActive: filter.classification_group === 'active',
            onSelect: () => handleFilter('classification_group', 'active'),
            count: dataClassification?.data?.active || 0,
          },
          {
            title: t('quotationTabAccepted'),
            isActive: filter.classification_group === 'accepted',
            onSelect: () => handleFilter('classification_group', 'accepted'),
            count: dataClassification?.data?.accepted || 0,
          },
          {
            title: t('quotationTabExpired'),
            isActive: filter.classification_group === 'expired',
            onSelect: () => handleFilter('classification_group', 'expired'),
            count: dataClassification?.data?.expired || 0,
          },
          {
            title: t('quotationTabRejected'),
            isActive: filter.classification_group === 'rejected',
            onSelect: () => handleFilter('classification_group', 'rejected'),
            count: dataClassification?.data?.rejected || 0,
          },
        ]}
      />

      <BusinessMetricCards metrics={metrics} />

      <TableQuotation
        buyerProfile={buyerProfile}
        filter={filter}
        queryParams={queryParams}
      />
    </div>
  )
}
