import { Link } from 'react-router-dom'
import {
  ButtonModelEnum,
  ButtonProps,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from './button.type'

import { generateClasses } from './button.constants'

export const Button = ({
  children,
  onClick,
  className,
  size = ButtonSizeEnum.medium,
  type = 'button',
  variant = ButtonVariantEnum.primary,
  model = ButtonModelEnum.default,
  isActive = false,
  href,
  target,
  ...rest
}: ButtonProps) => {
  const buttonClasses = generateClasses(
    size,
    variant,
    model,
    isActive,
    className,
  )

  if (href) {
    return (
      <Link
        className={buttonClasses}
        to={href}
        target={target}
        onClick={onClick as () => void}
        {...rest}
      >
        {children}
      </Link>
    )
  }

  return (
    <button className={buttonClasses} onClick={onClick} type={type} {...rest}>
      {children}
    </button>
  )
}
