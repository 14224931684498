import { useTranslation } from 'react-i18next'

import styles from './table-supplier-transaction.module.css'
import {
  Button,
  CloseIcon,
  EditIcon,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
  Tooltip,
} from '@mercai/clever'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import { EmptyState, SortBy } from '@/components'
import { formatCurrency, formatLongDate, useParamsState } from '@/helpers'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { SupplierTransactionProps } from '@/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSupplierList, useSupplierTransactionList } from '@/services'
import { SupplierOrderDrawerForm } from './components'

import {
  getExpectedPaidClasses,
  getBuyerName,
} from './table-supplier-transaction.constants'

export const TableSupplierTransaction = () => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    ordering: '',
  })

  const [params, setParams] = useParamsState({
    uuid: '',
  })

  const { data: suppliersData } = useSupplierList({ params: { limit: 100 } })

  const supplierFiltered = useMemo(() => {
    return suppliersData?.data?.results?.find(
      (supplier) => supplier.email === filter?.supplier__email,
    )
  }, [suppliersData, filter.supplier__email])

  const { data, isLoading } = useSupplierTransactionList(filter, true)

  const [totalPages, setTotalPages] = useState(1)

  const handleOpenDrawerForm = (uuid: string) => {
    setParams({ uuid })
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'supplier__email') {
      return `${t('tableSupplierOrderDrawerFilterSupplier')}: ${supplierFiltered?.name || ''}`
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) =>
        !['limit', 'page', 'ordering', 'uuid'].includes(item) && !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('filterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  const statuses: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('supplier_transaction_status_pending')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light">
        {tEnums('supplier_transaction_status_paid')}
      </Tag>
    ),
  }

  const columns: TableColumnsColumnProps<SupplierTransactionProps>[] = [
    {
      title: t('tableSupplierTransactionColumnSupplier'),
      key: 'shipments.supplier',
      render: ({ record }) => (
        <div className={styles['column-supplier']}>
          <Tooltip text={record?.supplier?.name || '-'} orientation="top">
            <Link
              target="_blank"
              to={ROUTES_ADMIN.SUPPLY.SUPPLIERS.SHOW.fullPath(
                record?.supplier?.uuid || '',
              )}
              className={styles.link}
            >
              {record?.supplier?.name || '-'}
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('tableSupplierTransactionColumnBuyerName'),
      key: 'buyer.companyName',
      width: '14rem',
      render: ({ record }) => {
        const buyerName = getBuyerName(record?.supplyOrders || [])
        return (
          <div>
            <Tooltip
              text={buyerName}
              orientation="top"
              className={styles.tooltip}
            >
              <div className={styles['company-name']}>{buyerName}</div>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: t('tableSupplierTransactionColumnReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
    },
    {
      title: t('tableSupplierTransactionColumnExpectedPaid'),
      key: 'expectedPaymentDate',
      width: '10rem',
      render: ({ record }) => (
        <Text
          className={getExpectedPaidClasses(
            record?.expectedPaymentDate || '',
            record?.paymentAt,
          )}
        >
          {record?.expectedPaymentDate
            ? formatLongDate(record?.expectedPaymentDate)
            : '-'}
        </Text>
      ),
    },
    {
      title: t('tableSupplierTransactionColumnTotal'),
      key: 'total',
      width: '11rem',
      render: ({ record }) => {
        return (
          <div>{formatCurrency(record?.value, 'es-MX', record?.currency)}</div>
        )
      },
    },
    {
      title: t('tableSupplierTransactionColumnCountry'),
      key: 'currency',
      dataKey: 'currency',
      width: '5.6rem',
    },
    {
      title: t('tableSupplierTransactionColumnBank'),
      key: 'destinationBank',
      dataKey: 'destinationBank',
      width: '7rem',
    },
    {
      title: t('tableSupplierTransactionColumnOrdersToPaid'),
      key: 'ordersToPaid',
      width: '7rem',
      render: ({ record }) => record?.supplyOrders?.length || '-',
    },

    {
      title: t('tableSupplierTransactionColumnPaidStatus'),
      key: 'status',
      width: '10rem',
      render: ({ record }) => statuses[record.status],
    },
    {
      title: '',
      key: 'actions',
      width: '4.5rem',
      render: ({ record }) => (
        <div className={styles['column-actions']}>
          <Button
            variant="secondary"
            model="square"
            size="small"
            onClick={() => handleOpenDrawerForm(record?.uuid || '')}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
  ]

  const sorts = [
    {
      title: t('orderingCreatedAtButton'),
      sortBy: 'created_at',
    },
  ]

  const ExpandedComponent = ({
    record,
  }: {
    record: SupplierTransactionProps
  }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>
              {t('tableSupplierTransactionExpandedComponentSupplierOrders')}
            </strong>
          </Text>

          {record?.supplyOrders?.map((item) => (
            <Text key={item?.uuid}>
              <Link
                to={`${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.PURCHASE_ORDER.fullPath()}?search=${item.reference}`}
                target="_blank"
                className={styles.link}
              >
                {item.reference || '-'}
              </Link>
            </Text>
          ))}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(
        Math.ceil(data?.data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <SortBy
            sortBy={filter?.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>
      </div>

      {getContentFilter()}

      <TableColumns
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
        onSortBy={onHandleSortBy}
        sortBy={filter?.ordering}
        expandableComponent={ExpandedComponent}
      />

      {!isLoading && (!data?.data || data?.data?.results?.length === 0) && (
        <EmptyState
          title={t('tableSupplierTransactionEmptyTitle')}
          description={t('tableSupplierTransactionEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('tableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <SupplierOrderDrawerForm
        uuid={params.uuid}
        onHandleOpen={() => setParams({ uuid: '' })}
      />
    </div>
  )
}
