import { ROUTES_ADMIN } from '@/router'

export enum TabsPathsEnum {
  'generate-purchase-order' = 'generate-purchase-order',
  'purchase-order' = 'purchase-order',
  'supplier-transaction' = 'supplier-transaction',
}

export const tabPath = (key: keyof typeof TabsPathsEnum) =>
  (
    ({
      'generate-purchase-order':
        ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.GENERATE_PURCHASE_ORDER.path,
      'purchase-order':
        ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.PURCHASE_ORDER.path,
      'supplier-transaction':
        ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.SUPPLIER_TRANSACTION.path,
    }) as Record<TabsPathsEnum, string>
  )?.[key] || ''

export const getActualTab = (path: string) => {
  return {
    [`/${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.GENERATE_PURCHASE_ORDER.path}`]:
      'generate-purchase-order',
    [`/${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.PURCHASE_ORDER.path}`]:
      'purchase-order',
    [`/${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.SUPPLIER_TRANSACTION.path}`]:
      'supplier-transaction',
  }[`${path}`]
}
