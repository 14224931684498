import { useTranslation } from 'react-i18next'
import {
  AlertHighlightIcon,
  AlertIcon,
  ArrowRightIcon,
  Button,
  FilterIcon,
  InputSearch,
  Pagination,
  PlusIcon,
  Select,
  Skeleton,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
  Tooltip,
  TrashIcon,
} from '@mercai/clever'

import styles from './table.module.css'
import { QuotationRequestProps, QuotationRequestStatusEnum } from '@/types'
import { EmptyState, SortBy } from '@/components'
import { QuotationRequestDrawerFilter } from '../drawer-filter'
import {
  formatDate,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
  useParamsState,
} from '@/helpers'
import { TableQuotationRequestProps } from './table.types'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { ReactNode, useEffect, useState } from 'react'
import { useDeleteQuotationRequest, useQuotationRequestList } from '@/services'
import { CatalogProductsCarousel } from '@/pages/business/components'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import { TagMoscow } from '@/pages/profile/components'
import {
  getReferenceColumnStatus,
  getStatusByTab,
  ReferenceColumnStatusEnum,
} from './table.constants'
import { useAuth } from '@/providers'

export const TableQuotationRequest = ({
  filter: externalFilter,
  buyerProfile,
  hasAddButton = false,
}: TableQuotationRequestProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationRequestListV2')

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [totalPages, setTotalPages] = useState(1)

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    ordering: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
    ...externalFilter,
  })

  const { data, isLoading, refetch } = useQuotationRequestList(
    buyerProfile
      ? {
          ...filter,
          buyerProfile,
          status: filter?.tab
            ? `${getStatusByTab(filter.tab)}${filter.status}`
            : filter.status || '',
          tab: '',
        }
      : {
          ...filter,
          status: filter?.tab
            ? `${getStatusByTab(filter.tab)}${filter.status}`
            : filter.status || '',
          tab: '',
        },
  )

  const { isPending, mutateAsync: mutateDelete } = useDeleteQuotationRequest()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationRequestTableDeleteConfirmTitle'),
      t('quotationRequestTableDeleteConfirmMessage'),
      t('quotationRequestTableDeleteConfirmButtonDelete'),
      t('quotationRequestTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('quotationRequestTableDeleteSuccessTitle'),
          t('quotationRequestTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('quotationRequestTableDeleteErrorTitle'),
          t('quotationRequestTableDeleteErrorMessage'),
        )
      }
    }
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else if (changedKey === 'tab') {
      setFilter({
        ...filter,
        [changedKey]: `${newValue}`,
        page: '1',
        classification_group: '',
      })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'tab' &&
        key !== 'classification_group' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const refereceColumn = ({ record }: { record: QuotationRequestProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    const referenceStatusObject: Record<ReferenceColumnStatusEnum, ReactNode> =
      {
        default: (
          <div className={`${styles['reference-column']}`}>
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
          </div>
        ),
        success: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-success']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipSuccess')}
              orientation="right"
            >
              <AlertHighlightIcon />
            </Tooltip>
          </div>
        ),
        warning: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-warning']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipWarning')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
        danger: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-danger']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipDanger')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
      }

    return referenceStatusObject[referenceStatus]
  }

  const bodyColumn = ({ record }: { record: QuotationRequestProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    if (record?.status === 'pending' && referenceStatus === 'danger') {
      return styles['column-danger']
    }

    return ''
  }

  const stateObject: Record<QuotationRequestStatusEnum, ReactNode> = {
    approved_for_quotation: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_approved_for_quotation')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('quotation_request_status_completed')}
      </Tag>
    ),
    disqualified: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_disqualified')}
      </Tag>
    ),
    qualified: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_qualified')}
      </Tag>
    ),
    expired: (
      <Tag variant="basic" model="light">
        {tEnums('quotation_request_status_expired')}
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_invalid')}
      </Tag>
    ),
    processed: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_request_status_processed')}
      </Tag>
    ),
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_request_status_pending')}
      </Tag>
    ),
  }

  const columns: TableColumnsColumnProps<QuotationRequestProps>[] = [
    {
      title: t('quotationRequestTableReference'),
      key: 'reference',
      dataKey: 'reference',
      sortKey: 'reference',
      width: '9.6rem',
      render: ({ record }) => refereceColumn({ record }),
    },
    {
      title: t('quotationRequestTableBuyerName'),
      key: 'buyerProfile.companyName',
      sortKey: 'buyer_profile__company_name',
      dataKey: 'buyerProfile.companyName',
      render: ({ record }) => (
        <div className={styles['buyer-profile']}>
          <Tooltip
            text={record?.buyerProfile?.companyName || '-'}
            orientation="top"
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(
                record?.buyerProfile?.uuid,
              )}
              className={styles.link}
            >
              {record?.buyerProfile?.companyName || '-'}
            </Link>
          </Tooltip>
          <Tooltip text={record?.buyerProfile?.email || '-'}>
            <div>
              <Text variant="text-small" className={styles.email}>
                {record?.buyerProfile?.email || '-'}
              </Text>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('quotationRequestTableState'),
      key: 'status',
      sortKey: 'status',
      dataKey: 'status',
      render: ({ record }) =>
        stateObject[
          (record?.status || 'pending') as QuotationRequestStatusEnum
        ] || '-',
      width: '15.4rem',
    },
    {
      title: t('quotationRequestTablePhone'),
      key: 'buyerProfile.phone',
      dataKey: 'buyerProfile.phone',
      width: '10rem',
    },
    {
      title: t('quotationRequestTableType'),
      key: 'buyerProfile.type',
      sortKey: 'buyer_profile__type',
      dataKey: 'buyerProfile.type',
      width: '6.5rem',
      render: ({ record }) =>
        record?.buyerProfile?.type
          ? tEnums(`profile_type_${record?.buyerProfile?.type}`)
          : '-',
    },
    {
      title: t('quotationRequestTableMoscow'),
      key: 'buyerProfile.moscowClassification',
      sortKey: 'buyer_profile__moscow_classification',
      dataKey: 'buyerProfile.moscowClassification',
      width: '10.6rem',
      render: ({ record }) => (
        <TagMoscow
          moscowClassification={record?.buyerProfile?.moscowClassification}
        />
      ),
    },
    {
      title: isSuperUser ? t('quotationRequestTableActions') : '',
      key: 'uuid',
      dataKey: 'uuid',
      width: isSuperUser ? '8rem' : '4.375rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            model="square"
            variant="primary"
            href={ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(record.uuid)}
          >
            <ArrowRightIcon />
          </Button>
          {isSuperUser && (
            <Button
              model="square"
              variant="danger"
              onClick={() => onHandleDelete(record.uuid)}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      ),
      renderLoading: (
        <div className={styles['action-buttons']}>
          <Skeleton height="2rem" width="2rem" />
          {isSuperUser && <Skeleton height="2rem" width="2rem" />}
        </div>
      ),
    },
  ]

  const ExpandedComponent = ({ record }: { record: QuotationRequestProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('quotationRequestTableSellerName')}</strong>
            {': '}
            {(record?.buyerProfile?.keyAccountManager as unknown as string) ||
              '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableCustomerLevel')}</strong>
            {': '}
            {record?.buyerProfile?.customerLevel
              ? tEnums(
                  `profile_customer_${record.buyerProfile.customerLevel}`.toLocaleLowerCase(),
                )
              : '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableOrigin')}</strong>
            {': '}
            {record?.origin
              ? tEnums(`quotation_request_origin_${record.origin}`)
              : '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableCreatorName')}</strong>
            {': '}
            {record?.creatorUser?.email || '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableQuotations')}</strong>
            {': '}
            {record?.quotations && record?.quotations?.length > 0 ? (
              <div className={styles['column-quotation']}>
                {record?.quotations?.map((quotation) => (
                  <Link
                    key={quotation?.uuid}
                    to={`${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(
                      quotation?.uuid,
                    )}&search=${quotation.reference}`}
                    className={styles.link}
                    target="_blank"
                  >
                    {`#${quotation?.reference}`}
                  </Link>
                ))}
              </div>
            ) : (
              <Text>-</Text>
            )}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableRefusedByEmail')}</strong>
            {': '}
            {record?.refusedBy?.email || '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableRefusedReason')}</strong>
            {': '}
            {record?.refusedReason ? (
              <Tooltip
                text={record?.refusedReason}
              >{`${record?.refusedReason.substring(0, 50)}...`}</Tooltip>
            ) : (
              '-'
            )}
          </Text>

          <Text>
            <strong>{t('quotationRequestTableTrafficSource')}</strong>
            {': '}
            {record?.trafficSource || '-'}
          </Text>
        </div>
        <div className={styles['expanded-content-products']}>
          <CatalogProductsCarousel products={record?.items || []} />
        </div>
      </div>
    )
  }

  const sorts = [
    {
      title: t('quotationRequestTableOrderingCreatedAtButton'),
      sortBy: 'created_at',
    },
  ]

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    setFilter({
      ...filter,
      ...externalFilter,
    })
  }, [externalFilter])

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('quotationRequestTableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <SortBy
            sortBy={filter.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>

        <div className={styles['filter-group']}>
          <Button
            variant="ghost"
            href={ROUTES_ADMIN.BUSINESS.RFQ_OLD.fullPath()}
          >
            Versión antigua
          </Button>
        </div>

        {hasAddButton && (
          <div className={styles['filter-group']}>
            <Button href={ROUTES_ADMIN.BUSINESS.RFQ.CREATE.fullPath()}>
              <PlusIcon />
              {t('quotationRequestTableNewRFQButton')}
            </Button>
          </div>
        )}
      </div>

      <TableColumns<QuotationRequestProps>
        isLoading={isLoading || isPending}
        columns={columns}
        expandableComponent={ExpandedComponent}
        classNames={{
          bodyColumn,
        }}
        data={data?.data?.results || []}
        sortBy={filter.ordering}
        onSortBy={onHandleSortBy}
      />
      {!isLoading && data?.data?.results?.length === 0 && (
        <EmptyState
          title={t('quotationRequestTableEmptyTitle')}
          description={t('quotationRequestTableEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('quotationRequestTableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <QuotationRequestDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />
    </div>
  )
}
