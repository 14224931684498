import styles from './circle-rating.module.css'

const variants = {
  danger: styles['variant-danger'],
  warning: styles['variant-warning'],
  success: styles['variant-success'],
}

export const getVariantByRating = (rating: number): string => {
  if (rating <= 2) {
    return 'danger'
  } else if (rating <= 4) {
    return 'warning'
  }
  return 'success'
}

export const getVariantClassess = (rating: number): string => {
  const variant = getVariantByRating(rating)
  return `${styles.foreground} ${variants[variant as keyof typeof variants]}`
}
