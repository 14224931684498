import { Text } from '../text'

import { SelectProps, SelectSizeEnum } from './select.type'

import { generateClasses } from './select.constants'

import styles from './select.module.css'

const Select = ({
  className,
  disabled,
  onChange,
  size = SelectSizeEnum.medium,
  value = '',
  iconLeft,
  iconRight,
  placeholder,
  id,
  options,
  error,
}: SelectProps) => {
  const classes = generateClasses(value === '', size, !!error)
  const selectValue = value !== null ? value : ''

  return (
    <div className={className}>
      <div className={classes}>
        {!iconLeft || <div className={styles['left-icon']}>{iconLeft} </div>}
        <select
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onChange && onChange(e.target.value)
          }
          value={selectValue}
          id={id}
        >
          <option value="" disabled>
            {placeholder}
          </option>

          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {!iconRight || <div className={styles['right-icon']}>{iconRight}</div>}
      </div>

      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}

export default Select
