import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  FilterIcon,
  InputSearch,
  TableColumns,
  Tag,
  Tooltip,
  Text,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  Select,
} from '@mercai/clever'

import {
  TableReference,
  TableColumnBuyer,
  TableTaxStatus,
  TableTagMoscow,
  TableColumnsPaymentStatus,
  TableColumnsInvoiceStatus,
  TableColumnsLogisticStatus,
  OrderDrawerFilter,
  TableColumnsSalesOrderStatus,
  TableColumnExpectedDeliveryDate,
  TableColumnActionsButtons,
  OrderCenterExpandedColumn,
  TableColumnTotalPrice,
} from './components'

import { AppliedFilters, SortBy } from '@/components'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import {
  trimString,
  useCookieState,
  formatCurrency,
  formatNumber,
  getOrderItemDescription,
  distanceToNow,
  formatDate,
} from '@/helpers'

import { SaleOrderProps, SaleOrderItemProps } from '@/types'

import { columnsForType } from './table.constants'

import { OrderCenterTableProps } from './table.type'

import styles from './table.module.css'

export const OrderCenterTable = ({
  type,
  data,
  isLoading,
  filter,
  onSetFilter,
  onEditRegister,
  onShowRegister,
  onDeleteRegister,
  formattedFilter,
}: OrderCenterTableProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenOrderCenterPage')

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    `@mercai/order-center-${type}-columns-v2`,
    columnsForType[type],
  )

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) =>
      !['logistic', 'finance'].includes(type)
        ? columnsKeys.includes(key)
        : true,
    [columnsKeys, type],
  )

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      onSetFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      onSetFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  useEffect(() => {
    if (data?.count) {
      setTotalPages(
        Math.ceil(data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  const getContentProduct = useCallback(
    (saleOrdersItems?: SaleOrderProps['items'], index = 0) => {
      const concatItems = saleOrdersItems
        ?.map((item) => getOrderItemDescription(item as SaleOrderItemProps))
        .join(', ')

      return (
        <Tooltip
          text={concatItems || ''}
          widthText="18.75rem"
          orientation={index >= 8 ? 'top' : 'bottom'}
        >
          {trimString(concatItems || '', 52, '...')}
        </Tooltip>
      )
    },
    [data],
  )

  const getQuantityProducts = useCallback(
    (saleOrdersItems: SaleOrderProps['items'] = []) => {
      return saleOrdersItems?.reduce((acc, current) => {
        return (acc += current?.quantity)
      }, 0)
    },
    [data],
  )

  const columns: TableColumnsColumnProps<SaleOrderProps>[] = [
    {
      title: t('tableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '10rem',
      render: ({ value, record }) => (
        <TableReference
          reference={value || ''}
          saleOrderStatus={record.status}
        />
      ),
    },
    {
      title: t('tableBuyer'),
      key: 'buyer',
      width: '20rem',
      sortKey: 'buyer_profile__company_name',
      dataKey: 'buyer',
      render: ({ record }) => (
        <TableColumnBuyer
          buyerName={
            record?.buyer?.usersNames
              ? record?.buyer?.usersNames?.join(', ')
              : ''
          }
          companyName={record?.buyer?.companyName}
          uuid={record?.buyer?.uuid}
        />
      ),
    },
    {
      title: t('tableTaxStatus'),
      key: 'taxStatus',
      sortKey: 'buyer_profile__tax_status',
      width: '12rem',
      render: ({ record }) => (
        <TableTaxStatus taxStatus={record?.buyer?.taxStatus} />
      ),
    },
    {
      title: t('tableTime'),
      key: 'createdAtDistanceToNow',
      dataKey: 'buyer.createdAt',
      width: '16rem',
      render: ({ value }) => (value ? distanceToNow(value) : '-'),
    },
    {
      title: t('tableProducts'),
      key: 'items',
      width: '20rem',
      render: ({ record, index }) => getContentProduct(record?.items, index),
    },
    {
      title: t('tableAmount'),
      key: 'totalPrice',
      sortKey: 'total_order_price_ordering',
      dataKey: 'items',
      render: ({ record }) => <TableColumnTotalPrice saleOrder={record} />,
    },
    {
      title: t('tableQuantity'),
      key: 'totalQuantity',
      dataKey: 'items',
      render: ({ record }) =>
        formatNumber(getQuantityProducts(record.items) || 0, 'es-MX'),
    },
    {
      title: t('tableMoscow'),
      key: 'moscowClassification',
      dataKey: 'buyer.moscowClassification',
      render: ({ value }) => <TableTagMoscow moscowClassification={value} />,
    },
    {
      title: t('tableSellerName'),
      key: 'keyAccountManager',
      dataKey: 'keyAccountManager.email',
      width: '18.75rem',
    },
    {
      title: t('tableStatePayment'),
      key: 'paymentStatus',
      sortKey: 'payments__status',
      width: '12rem',
      render: ({ record }) => (
        <TableColumnsPaymentStatus
          calculatedPaymentStatus={record?.paymentsStatus || 'processing'}
        />
      ),
    },
    {
      title: t('tableStateInvoice'),
      key: 'invoiceStatus',
      sortKey: 'finance_order',
      width: '13rem',
      render: ({ record }) => (
        <TableColumnsInvoiceStatus
          invoiceStatus={record?.invoices?.[0]?.status}
        />
      ),
    },
    {
      title: t('tablePaidAt'),
      key: 'paidAt',
      render: ({ record }) =>
        record?.payments?.[0]?.paidAt
          ? formatDate(record?.payments?.[0]?.paidAt)
          : '-',
    },
    {
      title: t('tableCogs'),
      key: 'cogs',
      render: ({ record }) =>
        record?.totalOrderCost
          ? formatCurrency(record?.totalOrderCost, 'es-MX', 'USD')
          : '-',
    },
    {
      title: t('tableLogisticStatus'),
      key: 'logisticStatus',
      sortKey: 'shipments__status',
      width: '14rem',
      render: ({ record }) => (
        <TableColumnsLogisticStatus
          logisticStatus={record?.shipments?.[0]?.status}
        />
      ),
    },
    {
      title: t('tableSalesOrderStatus'),
      key: 'salesOrderStatus',
      render: ({ record }) => (
        <TableColumnsSalesOrderStatus salesOrderStatus={record?.status} />
      ),
    },
    {
      title: t('tableExpectedDeliveryDate'),
      key: 'expectedDeliveryAt',
      sortKey: 'shipments__expected_delivery_at',
      width: '14rem',
      render: ({ record }) => (
        <TableColumnExpectedDeliveryDate
          expectedDeliveryAt={record?.shipments?.[0]?.expectedDeliveryAt}
          deliveryDate={record?.shipments?.[0]?.deliveredAt}
        />
      ),
    },
    {
      title: t('tableTransportPrice'),
      key: 'transportPrice',
      render: ({ record }) =>
        formatCurrency(record?.totalFreightPrice || 0, 'es-MX', 'USD'),
    },
    {
      title: t('tableDeliveryOption'),
      key: 'deliveryOption',
      width: '11.6rem',
      render: ({ record }) => (
        <Text>
          {record?.shipments?.[0]?.deliveryOption
            ? tEnums(
                `logistic_shipment_delivery_option_${record.shipments[0].deliveryOption}`,
              )
            : '-'}
        </Text>
      ),
    },
    {
      key: 'tableActionsButtons',
      dataKey: 'uuid',
      render: ({ record }) => (
        <TableColumnActionsButtons
          onClickEdit={() => onEditRegister(record.uuid)}
          onClickView={() => onShowRegister(record.uuid)}
          onClickDelete={() => onDeleteRegister(record.uuid)}
        />
      ),
    },
  ]

  const memoizedColumns = useMemo(() => {
    const columnsFiltered = columnsForType?.[type]?.reduce((acc, current) => {
      const column = columns?.find((column) => column.key === current)

      if (column && getColumnsShow(column.key)) {
        return [...acc, column]
      }

      return acc
    }, [] as TableColumnsColumnProps<SaleOrderProps>[])

    return columnsFiltered
  }, [columns, getColumnsShow])

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const sorts = [
    {
      title: t('orderingCreatedAtButton'),
      sortBy: 'created_at',
    },
    ...(['finance'].includes(type)
      ? [
          {
            title: t('orderingExpectedDeliveryDate'),
            sortBy: 'shipments__expected_delivery_date',
          },
          {
            title: t('orderingExpectedDeliveryAt'),
            sortBy: 'shipments__expected_delivery_at',
          },
          {
            title: t('orderingDeliveryAt'),
            sortBy: 'shipments__delivery_at',
          },
          {
            title: t('orderingPaymentsPaidAt'),
            sortBy: 'payments__paid_at',
          },
        ]
      : []),
  ]

  return (
    <div className={styles.core}>
      <Text variant="title-large">{t('tableTitle')}</Text>

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <SortBy
            sortBy={filter?.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>

        <div className={styles['filter-group']}>
          {!['logistic', 'finance'].includes(type) && (
            <DropDown.Root>
              <DropDown.ButtonTrigger
                variant="secondary"
                model="square"
                hasArrow={false}
              >
                <SettingIcon />
              </DropDown.ButtonTrigger>

              <DropDown.Content orientation="bottom-right">
                {columns?.map((column) => (
                  <DropDown.Item key={column.key}>
                    <Checkbox
                      onChange={() => onHandleColumnsShow(column.key)}
                      value={getColumnsShow(column.key)}
                    >
                      {column.title}
                    </Checkbox>
                  </DropDown.Item>
                ))}
              </DropDown.Content>
            </DropDown.Root>
          )}
        </div>
      </div>

      <AppliedFilters
        filtersApplied={filter}
        excludeKeys={[
          'page',
          'limit',
          'ordering',
          'shipmentExpectedDeliveryAtAfter',
          'shipmentExpectedDeliveryAtBefore',
        ]}
        onRemoveFilter={(key) => handleFilter(key, '')}
        translations={{
          filterAppliedText: t('filterApplied'),
        }}
        keyTranslations={{
          order_status: t('filterOrderStatus'),
          payment_status: t('filterPaymentStatus'),
          invoice_status: t('filterInvoiceStatus'),
          shipment_status: t('filterShipmentStatus'),
          tax_status: t('drawerFilterTaxStatusTitle'),
          moscow: t('filterMoscowClassification'),
          root_category: t('filterRootCategory'),
          key_account_manager: t('filterKeyAccountManager'),
          created_at_before: t('filterCreatedAtBefore'),
          created_at_after: t('filterCreatedAtAfter'),
          is_sample: t('filterIsSample'),
          classification_group: t('filterClassificationGroup'),
          search: t('filterSearch'),
        }}
        renderValue={(key, value) => {
          if (key === 'created_at_before' || key === 'created_at_after') {
            return formatDate(value)
          }

          if (key === 'key_account_manager') {
            return formattedFilter?.key_account_manager || ''
          }

          if (key === 'is_sample') {
            return value === 'true'
              ? t('filterIsSampleYesLabel')
              : t('filterIsSampleNoLabel')
          }

          const objectsTranslations = {
            order_status: tEnums(`sale_order_status_${value}`),
            payment_status: tEnums(`order_payment_status_${value}`),
            invoice_status: tEnums(`order_invoice_status_${value}`),
            shipment_status: tEnums(`logistic_shipment_status_${value}`),
            tax_status: tEnums(`profile_tax_status_${value}`),
            moscow: tEnums(`profile_moscow_${value}`),
            root_category: tEnums(`root_category_${value}`),
            classification_group: tEnums(`classification_group_${value}`),
          }

          return objectsTranslations[key as keyof typeof objectsTranslations]
        }}
      />

      <TableColumns<SaleOrderProps>
        classNames={{
          bodyColumn: styles['body-column'],
        }}
        isLoading={isLoading}
        columns={memoizedColumns}
        fixedColumns={
          !['logistic', 'finance'].includes(type)
            ? [
                {
                  title: t('tableActions'),
                  key: 'tableActions',
                  dataKey: 'uuid',
                  render: ({ record }) => (
                    <div className={styles['action-buttons']}>
                      <Button
                        variant="secondary"
                        onClick={() => onEditRegister(record.uuid)}
                      >
                        {t('tableActionsEdit')}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => onShowRegister(record.uuid)}
                      >
                        {t('tableActionsView')}
                      </Button>
                    </div>
                  ),
                  renderLoading: (
                    <div className={styles['action-buttons']}>
                      <Skeleton height="2rem" width="5.15rem" />
                      <Skeleton height="2rem" width="5.7rem" />
                    </div>
                  ),
                },
              ]
            : undefined
        }
        data={data?.results || []}
        expandableComponent={
          ['logistic', 'finance'].includes(type)
            ? ({ record }) => (
                <OrderCenterExpandedColumn
                  record={record}
                  type={type as 'finance' | 'logistic'}
                />
              )
            : undefined
        }
        sortBy={filter.ordering}
        onSortBy={onHandleSortBy}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableShowRegisters', {
              showNumber:
                (data?.count || 0) < +filter.limit
                  ? data?.count
                  : +filter.limit,
              totalNumber: data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <OrderDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={onSetFilter}
        type={type}
      />
    </div>
  )
}
