import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CloseIcon,
  FilterIcon,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
} from '@mercai/clever'
import { TransportCompanyRouteResponseProps } from '@/types'
import { formatCurrency, useParamsState } from '@/helpers'
import { useRouteList, useTransporCompanyRoutesList } from '@/services'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { TransportCompanyRoutesTableProps } from './transport-company-routes-table.types'

import styles from './transport-company-routes-table.module.css'
import { TransportCompanyRoutesDrawerFilter } from './components'

export const TransportCompanyRoutesTable = ({
  uuid,
  inDetail = true,
}: TransportCompanyRoutesTableProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const [filter, setFilter] = useParamsState({
    transport_company__uuid: uuid,
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    logistic_route__uuid: 'all',
    search: '',
  })

  const [totalPages, setTotalPages] = useState(1)

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useTransporCompanyRoutesList({
    params: {
      ...filter,
      logistic_route__uuid:
        filter.logistic_route__uuid !== 'all'
          ? filter.logistic_route__uuid
          : '',
    },
  })
  const { data: dataRoutes } = useRouteList({})

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'logistic_route__origin_location') {
      return `${t('transportCompaniesRoutesFilterOriginLabel')}: ${filterString}`
    }

    if (filterKey === 'logistic_route__destination_location') {
      return `${t('transportCompaniesRoutesFilterDestinationLabel')}: ${filterString}`
    }

    if (filterKey === 'vehicle__name') {
      return `${t('transportCompaniesRoutesFilterVehicleLabel')}: ${filterString}`
    }

    if (!filterString || typeof filterString === 'number') return ''

    if (filterString?.[0] === ',') {
      return filterString?.slice(1)?.replace(/,/g, ', ')
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) =>
        ![
          'limit',
          'page',
          'ordering',
          'tab',
          'logistic_route__uuid',
          'transport_company__uuid',
        ].includes(item) && !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('transportCompaniesRoutesFilterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'tab' &&
        key !== 'transport_company__uuid' &&
        key !== 'logistic_route__uuid',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const columns: TableColumnsColumnProps<TransportCompanyRouteResponseProps>[] =
    [
      ...(!inDetail
        ? [
            {
              title: t('transportCompaniesRoutesTableTransportCompanyName'),
              key: 'transportCompany.name',
              render: ({
                record,
              }: {
                record: TransportCompanyRouteResponseProps
              }) => record?.transportCompany?.name,
            },
          ]
        : []),
      {
        title: t('transportCompaniesRoutesTableName'),
        key: 'name',
        dataKey: 'logisticRoute',
        render: ({ record }) => record?.logisticRoute?.name,
      },
      {
        title: t('transportCompaniesRoutesTableVehicle'),
        key: 'vehicle.name',
        dataKey: 'vehicle.name',
        width: '12rem',
        render: ({ record }) => record?.vehicle?.name,
      },
      {
        title: t('transportCompaniesRoutesTableCapacity'),
        key: 'vehicle.capacity',
        dataKey: 'vehicle.capacity',
        width: '12rem',
        render: ({ record }) => record?.vehicle?.capacity,
      },
      {
        title: t('transportCompaniesRoutesTablePrice'),
        key: 'price',
        dataKey: 'price',
        width: '12rem',
        render: ({ record }) =>
          formatCurrency(record?.price || '', 'es-MX', 'MXN'),
      },
    ]

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  return (
    <div>
      {inDetail && (
        <Text className={styles.title} variant="title-large">
          {t('transportCompaniesRoutesTitle')}
        </Text>
      )}
      <div className={styles.filters}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('searchFilterPlaceholder'),
            }}
          />
        </div>

        <Select
          options={[
            {
              label: t('transportCompaniesRoutesFilterRoutes'),
              value: 'all',
            },
            ...(dataRoutes?.data?.results?.map((route) => ({
              label: route.name,
              value: route.uuid,
            })) || []),
          ]}
          value={filter.logistic_route__uuid}
          onChange={(value) => handleFilter('logistic_route__uuid', value)}
        />

        <Button variant="secondary" onClick={() => setIsOpenDrawerFilter(true)}>
          <FilterIcon />
          {t('transportCompaniesRoutesFilterTitle')}
          {getContentTotalFiltered()}
        </Button>
      </div>

      {getContentFilter()}

      <TableColumns<TransportCompanyRouteResponseProps>
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
      />
      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('tableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>

      <TransportCompanyRoutesDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />
    </div>
  )
}
