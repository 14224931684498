import { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Label,
  PlusIcon,
  Select,
  SelectSearch,
  Text,
} from '@mercai/clever'

import { SupplierProductFormProps } from './supplier-product-form.types'
import { useTranslation } from 'react-i18next'

import styles from './supplier-product-form.module.css'
import { SupplierProductPackageEnum, SupplierProps } from '@/types'
import { useSupplierWarehouseList, useSupplierList } from '@/services'

export const SupplierProductForm = ({
  values,
  productItem,
  supplierUuid,
  errors,
  onChangeValues,
  openNewWarehouse,
}: SupplierProductFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('componentsSupplierProductForm')

  const [supplierSearch, setSupplierSearch] = useState('')
  const [supplier, setSupplier] = useState<Partial<SupplierProps>>({
    uuid: supplierUuid,
  })

  const product = values?.catalogProduct || productItem

  const onHandleValues = (
    key: string,
    val: string | number | boolean | null,
  ) => {
    onChangeValues({ ...values, [key]: val })
  }

  const { data: dataSuppliers, isLoading: isLoadingSuppliers } =
    useSupplierList({
      isEnable: true,
      params: {
        search: supplierSearch,
      },
    })

  const { data: warehouses } = useSupplierWarehouseList({
    params: {
      supplier__uuid: supplier?.uuid || '',
    },
  })

  const onSelectSupplier = (supplier: Partial<SupplierProps>) => {
    setSupplier({
      ...supplier,
    })

    onChangeValues({
      ...values,
      supplierUuid: supplier?.uuid || '',
    })
  }

  useEffect(() => {
    if (product) {
      onHandleValues('catalogProductUuid', product?.uuid || '')
    }
  }, [])

  return (
    <div>
      {product && (
        <div className={styles.product}>
          <div>
            <Text variant="text-base">
              <strong>{product?.attributes?.title}</strong>
            </Text>
          </div>
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormCategoryLabel')}</strong>{' '}
              {product?.category?.name}
            </Text>
          </div>

          {product?.reference && (
            <div className={styles.row}>
              <Text>
                <strong>{t('newSupplierProductsFormReferenceLabel')}</strong>{' '}
                {product.reference}
              </Text>
            </div>
          )}

          {product?.attributes?.fluidity && (
            <div className={styles.row}>
              <Text>
                <strong>{t('newSupplierProductsFormFluidityLabel')}</strong>{' '}
                {product.attributes.fluidity} g/10m
              </Text>
            </div>
          )}

          {product?.attributes?.density && (
            <div className={styles.row}>
              <Text>
                <strong>{t('newSupplierProductsFormDensityLabel')}</strong>{' '}
                {product.attributes.density} g/cm³
              </Text>
            </div>
          )}
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.title}>
          <Text variant="text-large-bold">
            {t('supplierProductsFormTitle')}
          </Text>
          <Text>{t('supplierProductsFormDescription')}</Text>
        </div>
        <div className={styles['content-form']}>
          {!supplierUuid && (
            <SelectSearch<SupplierProps>
              dataSearch={dataSuppliers?.data?.results || []}
              isLoadingSearch={isLoadingSuppliers}
              onSearch={setSupplierSearch}
              onSelectItem={onSelectSupplier}
              selectedItem={supplier as SupplierProps}
              keyLabel="name"
              keyValue="uuid"
              translations={{
                inputSearchLabel: t('supplierProductFormSupplierLabel'),
                inputSearchPlaceholder: t(
                  'supplierProductFormSupplierPlaceholder',
                ),
                noResults: t('supplierProductFormSupplierNoResults'),
              }}
              error={(errors?.supplier?.uuid || errors?.supplier) as string}
            />
          )}

          <div>
            <Label>{t('supplierProductsFormMinimumDeliveryTimeLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t(
                'supplierProductsFormMinimumDeliveryTimePlaceholder',
              )}
              value={values?.minDeliveryDays}
              onChange={(newValue) =>
                onHandleValues('minDeliveryDays', newValue)
              }
              error={errors?.minDeliveryDays as string}
              suffix={t('supplierProductsFormMinimumDeliveryTimeSuffix')}
              decimalPrecision={0}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormAvailableLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormAvailablePlaceholder')}
              value={values?.quantity}
              onChange={(newValue) => onHandleValues('quantity', newValue)}
              error={errors?.quantity as string}
              suffix=" Kg"
              decimalPrecision={0}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormPriceLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormPricePlaceholder')}
              value={values?.price}
              onChange={(newValue) => onHandleValues('price', newValue)}
              error={errors?.price as string}
              suffix=" USD"
              decimalPrecision={3}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormMinLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormMinPlaceholder')}
              value={values?.minimumPurchaseQuantity}
              onChange={(newValue) =>
                onHandleValues('minimumPurchaseQuantity', newValue)
              }
              error={errors?.minimumPurchaseQuantity as string}
              suffix=" Kg"
              decimalPrecision={0}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormWarehouseLabel')}</Label>
            <div className={styles['warehouse-select']}>
              <Select
                options={
                  warehouses?.data?.results?.map?.((warehouse) => ({
                    label: `${warehouse.name}`,
                    value: warehouse?.uuid || '',
                  })) || []
                }
                placeholder={t('supplierProductsFormWarehousePlaceholder')}
                value={values?.supplierWarehouseUuid}
                onChange={(newValue) =>
                  onHandleValues('supplierWarehouseUuid', newValue)
                }
                error={errors?.supplierWarehouseUuid as string}
              />
              {openNewWarehouse && (
                <Button
                  variant="secondary"
                  model="square"
                  onClick={openNewWarehouse}
                >
                  <PlusIcon />
                </Button>
              )}
            </div>
          </div>
          <div>
            <Label>{t('supplierProductsFormPackingLabel')}</Label>
            <Select
              options={Object.keys(SupplierProductPackageEnum).map((key) => ({
                label: tEnums(`supplier_product_package_${key}`),
                value: key,
              }))}
              placeholder={t('supplierProductsFormPackingPlaceholder')}
              value={values?.package}
              onChange={(newValue) => onHandleValues('package', newValue)}
              error={errors?.package as string}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormInternalCodeLabel')}</Label>
            <Input
              placeholder={t('supplierProductsFormInternalCodePlaceholder')}
              value={values?.internalCode}
              onChange={(newValue) => onHandleValues('internalCode', newValue)}
              error={errors?.internalCode as string}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormBatchCodeLabel')}</Label>
            <Input
              placeholder={t('supplierProductsFormBatchCodePlaceholder')}
              value={values?.batchCode}
              onChange={(newValue) => onHandleValues('batchCode', newValue)}
              error={errors?.batchCode as string}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormEntryDateLabel')}</Label>
            <DatePicker
              placeholder={t('supplierProductsFormEntryDatePlaceholder')}
              value={values?.entryDate}
              onChange={(newValue) =>
                onHandleValues('entryDate', !newValue ? null : newValue)
              }
              error={errors?.entryDate as string}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
