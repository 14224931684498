import { ReactNode, useRef, useState, useEffect } from 'react'
import {
  Button,
  ExpandCircleDownIcon,
  Input,
  Label,
  Modal,
  Select,
  Tag,
} from '@mercai/clever'

import { QuotationRequestStatusEnum } from '@/types'
import { useTranslation } from 'react-i18next'
import { RfqStatusSelectProps } from './rfq-status-select.type'

import styles from './rfq-status-select.module.css'
import { QUERIES_ADMIN, useUpdateQuotationRequest } from '@/services'
import { onErrorMessage } from '@/helpers'
import { useQueryClient } from '@tanstack/react-query'
import { invalidateRFQOptions } from '../../../constants'

export const RfqStatusSelect = ({ quotationRequest }: RfqStatusSelectProps) => {
  const { t } = useTranslation('screenBusinessV2')
  const { t: tEnums } = useTranslation('enums')

  const queryClient = useQueryClient()

  const [invalidateStatus, setInvalidateStatus] = useState<
    'invalid' | 'disqualified' | ''
  >('')
  const [invalidateReason, setInvalidateReason] = useState('')
  const [invalidateReasonShowError, setInvalidateReasonShowError] =
    useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const popperRef = useRef<HTMLDivElement | null>(null)

  const isProcessed = quotationRequest?.status === 'processed'

  const stateObject: Record<QuotationRequestStatusEnum, ReactNode> = {
    approved_for_quotation: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_approved_for_quotation')}
        <ExpandCircleDownIcon style={{ fill: 'var(--info-800)' }} />
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('quotation_request_status_completed')}
        <ExpandCircleDownIcon style={{ fill: 'var(--success-800)' }} />
      </Tag>
    ),
    disqualified: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_disqualified')}
        <ExpandCircleDownIcon style={{ fill: 'var(--danger-800)' }} />
      </Tag>
    ),
    qualified: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_qualified')}
        <ExpandCircleDownIcon style={{ fill: 'var(--info-800)' }} />
      </Tag>
    ),
    expired: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_expired')}
        <ExpandCircleDownIcon style={{ fill: 'var(--danger-800)' }} />
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_invalid')}
        <ExpandCircleDownIcon style={{ fill: 'var(--danger-800)' }} />
      </Tag>
    ),
    processed: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_request_status_processed')}
        <ExpandCircleDownIcon style={{ fill: 'var(--primary-800)' }} />
      </Tag>
    ),
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_request_status_pending')}
        <ExpandCircleDownIcon style={{ fill: 'var(--warning-800)' }} />
      </Tag>
    ),
  }

  const { mutateAsync: mutateAsyncUpdateQuotationRequest, isPending } =
    useUpdateQuotationRequest(quotationRequest?.uuid || '')

  const togglePopper = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  const onHandleCloseInvalidateModal = () => {
    setInvalidateStatus('')
    setInvalidateReason('')
    setInvalidateReasonShowError(false)
  }

  const onHandleOpenInvalidateModal = (val: 'invalid' | 'disqualified') => {
    setIsOpen(false)
    setInvalidateStatus(val)
    setInvalidateReason('')
  }

  const onSubmit = async (val: keyof typeof QuotationRequestStatusEnum) => {
    setIsOpen(false)

    if ((val === 'invalid' || val === 'disqualified') && !invalidateReason) {
      return setInvalidateReasonShowError(true)
    }

    const response = await mutateAsyncUpdateQuotationRequest({
      ...quotationRequest,
      status: val,
      ...(invalidateReason ? { refusedReason: invalidateReason } : {}),
    })

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })
      onHandleCloseInvalidateModal()
    } else {
      onErrorMessage(
        t('rfqStatusSelectErrorTitle'),
        t('rfqStatusSelectErrorMessage'),
      )
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.core}>
      <div
        ref={buttonRef}
        onClick={togglePopper}
        className={styles['status-button']}
      >
        {stateObject[quotationRequest?.status || 'pending']}
      </div>
      {isOpen && (
        <div ref={popperRef} className={styles.popper}>
          <ul>
            {!isProcessed &&
              Object.keys(QuotationRequestStatusEnum).map((key) => (
                <li
                  key={key}
                  className={
                    key === quotationRequest?.status ? styles.active : ''
                  }
                  onClick={() =>
                    key === 'invalid' || key === 'disqualified'
                      ? onHandleOpenInvalidateModal(key)
                      : onSubmit(key as keyof typeof QuotationRequestStatusEnum)
                  }
                >
                  {tEnums(`quotation_request_status_${key}`)}
                </li>
              ))}
            {isProcessed && (
              <>
                <li
                  className={
                    quotationRequest?.status === 'processed'
                      ? styles.active
                      : ''
                  }
                  onClick={() => onSubmit('processed')}
                >
                  {tEnums(`quotation_request_status_processed`)}
                </li>
                <li
                  className={
                    quotationRequest?.status === 'invalid' ? styles.active : ''
                  }
                  onClick={() => onHandleOpenInvalidateModal('invalid')}
                >
                  {tEnums(`quotation_request_status_invalid`)}
                </li>
                <li
                  className={
                    quotationRequest?.status === 'disqualified'
                      ? styles.active
                      : ''
                  }
                  onClick={() => onHandleOpenInvalidateModal('disqualified')}
                >
                  {tEnums(`quotation_request_status_disqualified`)}
                </li>
              </>
            )}
          </ul>
        </div>
      )}
      <Modal.Root
        isOpen={!!invalidateStatus}
        onHandleOpen={onHandleCloseInvalidateModal}
        size="medium"
      >
        <Modal.Header title={t('rfqStatusSelectInvalidateModalTitle')} />
        <Modal.Body className={styles['modal-body']}>
          <div>
            <Label>{t('rfqStatusSelectInvalidateModalStatusLabel')}</Label>
            <Input
              className={styles['status-input']}
              value={
                invalidateStatus
                  ? tEnums(`quotation_request_status_${invalidateStatus}`)
                  : ''
              }
            />
          </div>
          <div>
            <Label>{t('rfqStatusSelectInvalidateModalReasonLabel')}</Label>
            <Select
              options={invalidateRFQOptions}
              value={invalidateReason}
              onChange={(value) => setInvalidateReason(value)}
              error={invalidateReasonShowError ? t('requiredMessage') : ''}
              placeholder={t('rfqStatusSelectInvalidateModalReasonPlaceholder')}
            />
          </div>
          <div className={styles['modal-footer']}>
            <Button variant="secondary" onClick={onHandleCloseInvalidateModal}>
              {t('rfqStatusSelectInvalidateModalCancelButton')}
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                onSubmit(invalidateStatus as 'invalid' | 'disqualified')
              }
              disabled={isPending}
            >
              {t('rfqStatusSelectInvalidateModalConfirmButton')}
            </Button>
          </div>
        </Modal.Body>
      </Modal.Root>
    </div>
  )
}
