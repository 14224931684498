import { SVGProps } from 'react'

export const StoreIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21.025 11.05V19C21.025 19.55 20.8292 20.0208 20.4375 20.4125C20.0458 20.8042 19.575 21 19.025 21H5.025C4.475 21 4.00416 20.8042 3.6125 20.4125C3.22083 20.0208 3.025 19.55 3.025 19V11.05C2.64166 10.7 2.34583 10.25 2.1375 9.7C1.92916 9.15 1.925 8.55 2.125 7.9L3.175 4.5C3.30833 4.06667 3.54583 3.70833 3.8875 3.425C4.22916 3.14167 4.625 3 5.075 3H18.975C19.425 3 19.8167 3.1375 20.15 3.4125C20.4833 3.6875 20.725 4.05 20.875 4.5L21.925 7.9C22.125 8.55 22.1208 9.14167 21.9125 9.675C21.7042 10.2083 21.4083 10.6667 21.025 11.05ZM14.225 10C14.675 10 15.0167 9.84583 15.25 9.5375C15.4833 9.22917 15.575 8.88333 15.525 8.5L14.975 5H13.025V8.7C13.025 9.05 13.1417 9.35417 13.375 9.6125C13.6083 9.87083 13.8917 10 14.225 10ZM9.725 10C10.1083 10 10.4208 9.87083 10.6625 9.6125C10.9042 9.35417 11.025 9.05 11.025 8.7V5H9.075L8.525 8.5C8.45833 8.9 8.54583 9.25 8.7875 9.55C9.02916 9.85 9.34166 10 9.725 10ZM5.275 10C5.575 10 5.8375 9.89167 6.0625 9.675C6.2875 9.45833 6.425 9.18333 6.475 8.85L7.025 5H5.075L4.075 8.35C3.975 8.68333 4.02916 9.04167 4.2375 9.425C4.44583 9.80833 4.79166 10 5.275 10ZM18.775 10C19.2583 10 19.6083 9.80833 19.825 9.425C20.0417 9.04167 20.0917 8.68333 19.975 8.35L18.925 5H17.025L17.575 8.85C17.625 9.18333 17.7625 9.45833 17.9875 9.675C18.2125 9.89167 18.475 10 18.775 10ZM5.025 19H19.025V11.95C18.9417 11.9833 18.8875 12 18.8625 12H18.775C18.325 12 17.9292 11.925 17.5875 11.775C17.2458 11.625 16.9083 11.3833 16.575 11.05C16.275 11.35 15.9333 11.5833 15.55 11.75C15.1667 11.9167 14.7583 12 14.325 12C13.875 12 13.4542 11.9167 13.0625 11.75C12.6708 11.5833 12.325 11.35 12.025 11.05C11.7417 11.35 11.4125 11.5833 11.0375 11.75C10.6625 11.9167 10.2583 12 9.825 12C9.34166 12 8.90416 11.9167 8.5125 11.75C8.12083 11.5833 7.775 11.35 7.475 11.05C7.125 11.4 6.77916 11.6458 6.4375 11.7875C6.09583 11.9292 5.70833 12 5.275 12H5.1625C5.12083 12 5.075 11.9833 5.025 11.95V19Z" />
    </svg>
  )
}
