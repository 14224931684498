import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { AiWrapper } from '@/components'

import styles from './admin-panel.module.css'
import { Sidebar } from './components/sidebar'
import { ROUTES_ADMIN } from '@/router'
import { BurguerIcon, Button, CloseIcon, MercaiOpusIcon } from '@mercai/clever'

export const AdminPanelLayout = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  return (
    <AiWrapper>
      <div
        className={`${styles.layout} ${isOpenSidebar ? styles['layout-opened'] : ''}`}
      >
        <header className={styles['aside-header']}>
          <Link to={ROUTES_ADMIN.BUSINESS.RFQ_OLD.fullPath()}>
            <MercaiOpusIcon className={styles.logo} />
          </Link>

          <Button
            variant="ghost"
            model="round"
            className={styles['aside-header-burguer']}
            type="button"
            onClick={toggleSidebar}
          >
            {isOpenSidebar ? <CloseIcon /> : <BurguerIcon />}
          </Button>
        </header>

        <Sidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />

        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
    </AiWrapper>
  )
}
