import { useEffect, useState } from 'react'
import { Button, DrawerStepperProgress } from '@mercai/clever'
import { ProfileForm, ProfileList } from './components'
import { useTranslation } from 'react-i18next'
import { QUERIES_ADMIN, useCreateProfile, useProfileList } from '@/services'
import { DrawerBuyerProfileProps } from './drawer-buyer-profile.type'
import { ProfileProps } from '@/types'
import { useValidationErrors } from '@/helpers'
import { schemaProfileItem } from './drawer-buyer-profile.constants'
import { useQueryClient } from '@tanstack/react-query'

export const DrawerBuyerProfile = ({
  isOpen,
  onHandleOpen,
  buyerProfile,
  setBuyerProfile,
}: DrawerBuyerProfileProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const { mutateAsync: mutateAsyncCreateProfile } = useCreateProfile()

  const queryClient = useQueryClient()

  const [internalBuyerProfile, setInternalBuyerProfile] = useState<
    Partial<ProfileProps>
  >(buyerProfile || {})

  const [profileFilters, setProfileFilters] = useState({
    currentPage: 1,
    search: '',
  })

  const { data: profiles, isLoading: isLoadingProfiles } = useProfileList({
    params: {
      pagination: profileFilters.currentPage,
      search: profileFilters.search,
    },
  })

  const {
    isValid: isValidProfileItem,
    errors: profileItemErrors,
    onHandleListenErrors: onHandleListenErrorsProfile,
  } = useValidationErrors(schemaProfileItem(t), internalBuyerProfile)

  const onHandleNewProfile = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    setInternalBuyerProfile({})
    onNavigateToNextStep('profileForm')
  }

  const onSubmitProfile = () => {
    if (internalBuyerProfile) {
      setBuyerProfile(internalBuyerProfile)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ADDRESS.module, buyerProfile?.uuid],
      })
    }

    onHandleOpen()
  }

  const onSubmitNewProfile = async () => {
    if (isValidProfileItem && internalBuyerProfile) {
      const response = await mutateAsyncCreateProfile(internalBuyerProfile)

      if (response.success) {
        onHandleListenErrorsProfile(false)

        setProfileFilters({
          search: response.data?.email || '',
          currentPage: 1,
        })
        setBuyerProfile(response?.data || {})

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.ADDRESS.module, buyerProfile?.uuid],
        })

        onHandleOpen()
      }
    } else {
      onHandleListenErrorsProfile(true)
    }
  }

  useEffect(() => {
    if (buyerProfile) setInternalBuyerProfile(buyerProfile)
  }, [buyerProfile])

  return (
    <DrawerStepperProgress
      initialStep="root"
      totalSteps={1}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      translations={{
        optional: '',
      }}
      title={t('drawerBuyerProfileTitle')}
      steps={{
        root: {
          stepNumber: 1,
          title: t('profileListSearchProfileTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <ProfileList
              onSelectedProfile={
                setInternalBuyerProfile as (
                  profile: Partial<ProfileProps>,
                ) => void
              }
              onClickNewProfile={() =>
                onHandleNewProfile({ onNavigateToNextStep })
              }
              profileSelected={internalBuyerProfile}
              profiles={profiles?.data?.results || []}
              onChangeSearch={(search) =>
                setProfileFilters({ ...profileFilters, search })
              }
              search={profileFilters.search}
              isLoading={isLoadingProfiles}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonCancel')}
              </Button>

              <Button onClick={() => onSubmitProfile()}>
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
        profileForm: {
          stepNumber: 1,
          title: t('searchProfileFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <ProfileForm
              errors={profileItemErrors as Record<string, string>}
              onChangeValues={setInternalBuyerProfile}
              values={internalBuyerProfile}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonCancel')}
              </Button>

              <Button onClick={() => onSubmitNewProfile()}>
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
