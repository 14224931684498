import { formatDistance, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

export const formatDate = (
  inputDate: string | Date,
  region = 'es-MX',
): string => {
  let dateObj: Date
  if (typeof inputDate === 'string') {
    dateObj = new Date(inputDate)
    if (isNaN(dateObj.getTime())) {
      return 'Invalid date'
    }
  } else {
    dateObj = inputDate
  }

  if (!(dateObj instanceof Date)) {
    return 'Invalid date'
  }

  let formattedDate: string
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getUTCFullYear())

  switch (region) {
    case 'es-MX':
      formattedDate = `${day}/${month}/${year}`
      break
    case 'en-US':
      formattedDate = `${month}/${day}/${year}`
      break
    case 'CN':
      formattedDate = `${year}年${month}月${day}日`
      break
    default:
      return 'Not supported region.'
  }

  return formattedDate
}

export const formatDateWithTime = (
  inputDate: string | Date,
  region = 'es-MX',
  includeSeconds = false,
): string => {
  let dateObj: Date
  if (typeof inputDate === 'string') {
    dateObj = new Date(inputDate)
    if (isNaN(dateObj.getTime())) {
      return 'Invalid date'
    }
  } else {
    dateObj = inputDate
  }

  if (!(dateObj instanceof Date)) {
    return 'Invalid date'
  }

  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getUTCFullYear())
  const hours = String(dateObj.getUTCHours()).padStart(2, '0')
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0')
  const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0')
  let formattedDateTime = ''

  switch (region) {
    case 'es-MX':
      formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''}`
      break
    case 'en-US':
      formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''}`
      break
    case 'CN':
      formattedDateTime = `${year}年${month}月${day}日 ${hours}时${minutes}分${includeSeconds ? `${seconds}秒` : ''}`
      break
    default:
      return 'Not supported region.'
  }

  return formattedDateTime
}

export const formatLongDate = (inputDate: string | Date) => {
  let dateObj: Date
  if (typeof inputDate === 'string') {
    dateObj = new Date(inputDate)
    if (isNaN(dateObj.getTime())) {
      return 'Invalid date'
    }
  } else {
    dateObj = inputDate
  }

  if (!(dateObj instanceof Date)) {
    return 'Invalid date'
  }

  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = dateObj.getUTCMonth()
  const year = dateObj.getUTCFullYear()

  const monthNames = [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ]

  return `${day} de ${monthNames[month]}. ${year}`
}

export const formatLongTime = (inputDate: string | Date) => {
  let dateObj: Date
  if (typeof inputDate === 'string') {
    dateObj = new Date(inputDate)
    if (isNaN(dateObj.getTime())) {
      return 'Invalid date'
    }
  } else {
    dateObj = inputDate
  }

  if (!(dateObj instanceof Date)) {
    return 'Invalid date'
  }

  const hours = dateObj.getUTCHours()
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12

  return `${String(formattedHours).padStart(2, '0')}:${minutes} ${ampm}`
}

export const parseDateTimeToISO = (
  inputDateTime?: string,
  region = 'es-MX',
): string => {
  let dateObj: Date

  if (!inputDateTime) {
    return ''
  }

  switch (region) {
    case 'es-MX': {
      let datePart = ''
      let timePart = '00:00:00'

      if (inputDateTime?.includes(' ')) {
        const spplitedDateTime = inputDateTime.split(' ')
        datePart = spplitedDateTime[0]
        timePart = spplitedDateTime[1]
      } else {
        datePart = inputDateTime
      }
      const [day, month, year] = datePart.split('/')
      const [hour = '00', minute = '00', second = '00'] = timePart?.split(':')
      dateObj = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`)
      break
    }
    case 'en-US': {
      let datePart2 = ''
      let timePart2 = '00:00:00'

      if (inputDateTime?.includes(' ')) {
        const spplitedDateTime = inputDateTime.split(' ')
        datePart2 = spplitedDateTime[0]
        timePart2 = spplitedDateTime[1]
      } else {
        datePart2 = inputDateTime
      }

      const [month2, day2, year2] = datePart2.split('/')
      const [hour2 = '00', minute2 = '00', second2 = '00'] =
        timePart2.split(':')
      dateObj = new Date(
        `${year2}-${month2}-${day2}T${hour2}:${minute2}:${second2}`,
      )
      break
    }
    default:
      return 'Not supported region.'
  }

  return dateObj.toISOString()
}

export const parseFormattedDateToDate = (
  inputDate: string,
  region = 'es-MX',
): string => {
  let dateObj: string

  switch (region) {
    case 'es-MX': {
      const [day, month, year] = inputDate.split('/')
      dateObj = `${year}-${month}-${day}`
      break
    }
    case 'en-US': {
      const [month, day, year] = inputDate.split('/')
      dateObj = `${year}-${month}-${day}`
      break
    }
    default:
      dateObj = inputDate
  }

  return dateObj
}

export const getDateUnities = (date: Date) => {
  function addZero(number: number) {
    return number < 10 ? '0' + number : number
  }

  const year = addZero(date.getFullYear())
  const month = addZero(date.getMonth() + 1)
  const day = addZero(date.getDate())
  const hour = addZero(date.getHours())
  const minute = addZero(date.getMinutes())
  const second = addZero(date.getSeconds())

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
  }
}

export const isDateGreaterThan = (
  date1: Date | string,
  date2: Date | string,
): boolean => {
  const parsedDate1 = typeof date1 === 'string' ? new Date(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? new Date(date2) : date2

  return parsedDate1.getTime() > parsedDate2.getTime()
}

export const distanceToNow = (value: string): string => {
  if (!value) {
    return 'Invalid date'
  }

  return formatDistance(parseISO(value), new Date(), {
    addSuffix: false,
    locale: es,
  })
}

export const getDifferenceInDays = (
  date1?: Date | string,
  date2?: Date | string,
): number => {
  if (!date1 || !date2) {
    return 0
  }

  const parsedDate1 = typeof date1 === 'string' ? new Date(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? new Date(date2) : date2

  if (!parsedDate1 || !parsedDate2) {
    return 0
  }

  const differenceInTime = parsedDate2.getTime() - parsedDate1.getTime()
  return Math.floor(differenceInTime / (1000 * 3600 * 24))
}

export const calculateDelayDays = (date: Date, now: Date): number => {
  const day = new Date(date.getFullYear(), date.getMonth(), date.getDate())

  day.setDate(day.getDate() + 1)

  day.setHours(0, 0, 0, 0)
  now.setHours(0, 0, 0, 0)

  const time = day.getTime()
  const timeNow = now.getTime()

  const differenceInMilliseconds = timeNow - time

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return differenceInDays
}
