import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CloseIcon,
  DatePicker,
  DragDropFileList,
  Drawer,
  Input,
  Label,
  Text,
} from '@mercai/clever'

import { SupplierOrderDrawerFormProps } from './drawer-form.types'

import styles from './drawer-form.module.css'
import {
  QUERIES_ADMIN,
  SUPPLY_ORDER_INVOICE_URL,
  useSupplierOrderDetail,
  api,
  useSupplierPaymentEdit,
  useSupplierOrderEdit,
} from '@/services'
import {
  getFileNameAndExtension,
  onErrorMessage,
  onSuccessMessage,
} from '@/helpers'
import { useQueryClient } from '@tanstack/react-query'

export const SupplierOrderDrawerForm = ({
  isOpen,
  onHandleOpen,
  uuid,
}: SupplierOrderDrawerFormProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [expectedPaymentDate, setExpectedPaymentDate] = useState<
    string | undefined
  >()
  const [observation, setObservation] = useState<string>('')

  const { data } = useSupplierOrderDetail(uuid || '')

  const getFileValue = (file: string | undefined) => {
    if (!file) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(file)

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: file as string,
        uploaded: true,
      },
    ]
  }

  const queryClient = useQueryClient()

  const onSuccessUpload = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
    })
  }

  const { mutateAsync: mutateAsyncPayment, isPending: isPendingPayment } =
    useSupplierPaymentEdit(data?.data?.payment?.uuid || '')

  const { mutateAsync: mutateAsyncOrder, isPending: isPendingOrder } =
    useSupplierOrderEdit(uuid || '')

  const onSubmit = async () => {
    const responsePayment = await mutateAsyncPayment({
      expectedPaymentDate,
    })

    const responsOrder = await mutateAsyncOrder({
      observation,
    })

    if (responsePayment.success && responsOrder.success) {
      onHandleOpen()
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
      })
      onSuccessMessage(
        t('drawerSupplierOrderSuccessTitle'),
        t('drawerSupplierOrderSuccessDescription'),
      )
      return null
    }

    onErrorMessage(
      t('drawerSupplierOrderErrorTitle'),
      t('drawerSupplierOrderErrorDescription'),
    )
  }

  useEffect(() => {
    if (data) {
      setObservation(data?.data?.observation || '')

      setExpectedPaymentDate(
        data?.data?.payment?.expectedPaymentDate || undefined,
      )
    }
  }, [data])

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('tableSupplierOrderDrawerEditTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div>
          <Label>{t('tableSupplierOrderDrawerEditExpectedPaidLabel')}</Label>
          <DatePicker
            value={expectedPaymentDate}
            onChange={(date) => setExpectedPaymentDate(date)}
            placeholder="dd/mm/yyyy"
          />
        </div>

        <div>
          <Label>{t('tableSupplierOrderDrawerEditInvoicePDFLabel')}</Label>
          <DragDropFileList
            translations={{
              dragFile: t('uploadDragFile'),
              clickToUpload: t('uploadClickToUpload'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={SUPPLY_ORDER_INVOICE_URL(data?.data?.invoice?.uuid || '')}
            initialData={getFileValue(data?.data?.invoice?.pdfFile)}
            onClickPreview={(url) => window.open(url, '_blank')}
            configsRequest={{
              fileField: 'pdf_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            successedUpload={onSuccessUpload}
          />
        </div>

        <div>
          <Label>{t('tableSupplierOrderDrawerEditInvoiceXMLLabel')}</Label>
          <DragDropFileList
            translations={{
              dragFile: t('uploadDragFile'),
              clickToUpload: t('uploadClickToUpload'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={SUPPLY_ORDER_INVOICE_URL(data?.data?.invoice?.uuid || '')}
            initialData={getFileValue(data?.data?.invoice?.xmlFile)}
            onClickPreview={(url) => window.open(url, '_blank')}
            configsRequest={{
              fileField: 'xml_file',
              method: 'PATCH',
            }}
            axiosInstance={api}
            successedUpload={onSuccessUpload}
          />
        </div>

        <div>
          <Label>{t('tableSupplierOrderDrawerEditObservationLabel')}</Label>
          <Input
            isTextArea
            value={observation}
            onChange={(value) => setObservation(value)}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('tableSupplierOrderDrawerEditCancelButton')}
        </Button>
        <Button
          onClick={onSubmit}
          isActive={!isPendingOrder && !isPendingPayment}
          disabled={isPendingOrder || isPendingPayment}
        >
          {t('tableSupplierOrderDrawerEditConfirmButton')}
        </Button>
      </div>
    </Drawer>
  )
}
