import { SVGProps } from 'react'

export const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -960 960 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M440-240v-368L296-464l-56-56 240-240 240 240-56 56-144-144v368h-80Z"
      />
    </svg>
  )
}
