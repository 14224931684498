import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, Text, SummaryValues } from '@mercai/clever'

import { formatCurrency, formatNumber, formatLongDate } from '@/helpers'

import { Progress } from './components'
import { OrderPaymentProps } from './order-payment.type'
import { getPaymentPercentage } from './order-payment.constants'

import styles from './order-payment.module.css'
import {
  OrderPaymentProps as OrderPaymentType,
  OrderPaymentStatusEnum,
} from '@/types'

export const OrderPayment = ({ saleOrder }: OrderPaymentProps) => {
  const { t } = useTranslation('componentOrderCenterShow')
  const { t: tEnums } = useTranslation('enums')

  const currencySaleOrder = saleOrder?.payments?.[0]?.currency
  const dollarExchangeRate = saleOrder?.payments?.[0]?.dollarExchangeRate // verifify

  const getCurrencyValue = (value: string = '0') => {
    if (currencySaleOrder === 'USD') {
      return +value
    }

    if (currencySaleOrder === 'MXN') {
      return Number(value) * Number(dollarExchangeRate || 0)
    }

    return 0
  }

  const totalOrderPrice = getCurrencyValue(saleOrder?.totalOrderPrice)
  const totalPaid = getCurrencyValue(saleOrder?.totalPaidValue)

  const getValues = () => {
    const exchangeRateMultiplier =
      saleOrder?.payments?.[0]?.currency === 'USD'
        ? 1
        : +(saleOrder?.payments?.[0]?.dollarExchangeRate || 0)

    const total = +(saleOrder?.totalOrderPrice || 0) * exchangeRateMultiplier
    const subTotal =
      +(saleOrder?.totalProductPrice || 0) * exchangeRateMultiplier
    const iva = +(saleOrder?.taxPrice || 0) * exchangeRateMultiplier
    const discount = +(saleOrder?.discount || 0) * exchangeRateMultiplier
    const totalFreight =
      +(saleOrder?.totalFreightPrice || 0) * exchangeRateMultiplier

    return {
      total,
      subTotal,
      iva,
      discount,
      totalFreight,
    }
  }

  const { discount, iva, subTotal, total, totalFreight } = getValues()

  const calculatePercentagePaid = () => {
    const calculated = (+(totalPaid || 0) / +(totalOrderPrice || 0)) * 100

    return isNaN(calculated) ? 0 : calculated
  }

  const percentagePaid = calculatePercentagePaid()

  const statusesPaymentsBadges: Record<OrderPaymentStatusEnum, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light" size="small">
        {tEnums('order_payment_status_pending')}
      </Tag>
    ),
    waiting_approval: (
      <Tag variant="primary" model="light" size="small">
        {tEnums('order_payment_status_waiting_approval')}
      </Tag>
    ),
    paid: (
      <Tag variant="success" model="light" size="small">
        {tEnums('order_payment_status_paid')}
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light" size="small">
        {tEnums('order_payment_status_invalid')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light" size="small">
        {tEnums('order_payment_status_cancelled')}
      </Tag>
    ),
  }

  const getPaymentConditionComponent = (payment: OrderPaymentType) => {
    const isSinglePayment = saleOrder?.payments?.length === 1
    const label = isSinglePayment
      ? t('orderPaymentSinglePayment')
      : payment?.instalment === 1
        ? t('orderPaymentUpfrontPayment')
        : t('orderPaymentCashOnDeliveryPayment')

    const value = +(payment?.value || 0)

    const percentage = getPaymentPercentage(+(totalOrderPrice || 0), value)

    const formattedLabel = `${label}${isSinglePayment ? ': ' : ` (${percentage}%): `}`

    return (
      <div key={payment.uuid} className={styles['payment-condition']}>
        <Text>
          <strong>{formattedLabel}</strong>
        </Text>
        {statusesPaymentsBadges[payment.status]}
        <Text className={styles['payment-condition-value']}>
          {formatCurrency(value, 'es-MX', currencySaleOrder)}{' '}
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.core}>
      <div className={styles['payment-container']}>
        <div className={styles.row}>
          <div className={styles['progress-row']}>
            <Text variant="text-large-bold" className={styles['primary-1100']}>
              {t('orderPaymentProgressLabel')}
            </Text>

            <Text>{formatNumber(percentagePaid)}%</Text>
          </div>
          <Progress percentage={percentagePaid} />
          <div className={styles['value-box']}>
            <div className={styles['paid-box']}></div>
            <Text>
              <strong>{t('orderPaymentProgressPaidLabel')}</strong>
            </Text>
            <Text>{formatCurrency(totalPaid, 'es-MX', currencySaleOrder)}</Text>
          </div>
          <div className={styles['value-box']}>
            <div className={styles['to-paid-box']}></div>
            <Text>
              <strong>{t('orderPaymentProgressToPaidLabel')}</strong>
            </Text>
            <Text>
              {formatCurrency(
                totalOrderPrice - totalPaid,
                'es-MX',
                currencySaleOrder,
              )}{' '}
            </Text>
          </div>
        </div>
        <div className={styles.row}>
          <Text variant="text-large-bold" className={styles['primary-1100']}>
            {t('orderPaymentTransactionsLabel')}
          </Text>

          {saleOrder?.transactions && saleOrder?.transactions?.length > 0 ? (
            <ul className={styles['transactions-container']}>
              {saleOrder?.transactions?.map(item => (
                <li key={item.uuid} className={styles['transaction-item']}>
                  <Text
                    variant="title-base"
                    className={styles['transaction-item-description']}
                  >
                    {tEnums(
                      `transaction_payment_method_${item?.paymentMethod}`,
                    )}
                  </Text>

                  <Text>{formatLongDate(item?.paidAt)}</Text>

                  <Text>
                    {formatCurrency(item?.value, 'es-MX', item?.currency)}
                  </Text>
                </li>
              ))}
            </ul>
          ) : (
            <Text className={styles['empty-transactions']}>
              {t('orderPaymentNoTransactions')}
            </Text>
          )}
        </div>
        <div className={styles.row}>
          <Text variant="text-large-bold" className={styles['primary-1100']}>
            {t('orderPaymentConditionsLabel')}
          </Text>

          <div className={styles['payments-condition-box']}>
            {saleOrder?.payments &&
              saleOrder?.payments.map(payment =>
                getPaymentConditionComponent(payment),
              )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles['summary-values-wrap']}>
            <SummaryValues.Root>
              <SummaryValues.Row>
                <SummaryValues.Column type="value">
                  {t('orderPaymentShowSummaryValuesSubTotal')}
                </SummaryValues.Column>
                <SummaryValues.Column type="value" direction="right">
                  {formatCurrency(subTotal, 'es-MX', currencySaleOrder)}
                </SummaryValues.Column>
              </SummaryValues.Row>

              <SummaryValues.Row>
                <SummaryValues.Column type="value">
                  {t('orderPaymentShowSummaryValuesDiscount')}
                </SummaryValues.Column>
                <SummaryValues.Column type="value" direction="right">
                  - {formatCurrency(discount, 'es-MX', currencySaleOrder)}
                </SummaryValues.Column>
              </SummaryValues.Row>

              <SummaryValues.Row>
                <SummaryValues.Column type="value">
                  {t('orderPaymentShowSummaryValuesIVA')}
                </SummaryValues.Column>
                <SummaryValues.Column type="value" direction="right">
                  {formatCurrency(iva, 'es-MX', currencySaleOrder)}
                </SummaryValues.Column>
              </SummaryValues.Row>

              <SummaryValues.Row>
                <SummaryValues.Column type="value">
                  {t('orderPaymentShowSummaryValuesDelivery')}
                </SummaryValues.Column>
                <SummaryValues.Column type="value" direction="right">
                  {formatCurrency(totalFreight, 'es-MX', currencySaleOrder)}
                </SummaryValues.Column>
              </SummaryValues.Row>

              <SummaryValues.Row
                type="strip"
                className={styles['summary-total']}
              >
                <SummaryValues.Column type="bookend">
                  {t('orderPaymentShowSummaryValuesAmount')}
                </SummaryValues.Column>
                <SummaryValues.Column type="bookend" direction="right">
                  {formatCurrency(total, 'es-MX', currencySaleOrder)}
                </SummaryValues.Column>
              </SummaryValues.Row>
            </SummaryValues.Root>
          </div>
        </div>
      </div>
    </div>
  )
}
