import { TabsProps } from './tabs.types'

import styles from './tabs.module.css'
import { generateButtonClasses } from './tabs.constants'
import { Skeleton } from '@mercai/clever'

export const Tabs = ({ items, hasCount = false, isLoading }: TabsProps) => {
  return (
    <div className={styles.menus}>
      {items.map((item, index) => (
        <div
          key={index}
          className={generateButtonClasses(item.isActive || false)}
          onClick={item.onSelect}
        >
          {item.title}{' '}
          {hasCount &&
            (!isLoading ? (
              `- ${item.count || 0}`
            ) : (
              <Skeleton width={'20px'} height={'12px'} />
            ))}
        </div>
      ))}
    </div>
  )
}
