import { useMemo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Header, Tabs } from '@/components'
import { ROUTES_ADMIN } from '@/router'
import { useAuth } from '@/providers'

import { isSuperUser } from '@/helpers'

import { AgentsList } from './agents'
import { ConversationsList } from './conversations'

import styles from './ai-center.module.css'

export const AiCenter = () => {
  const { t } = useTranslation('screenAiCenter')

  const { whoAmI } = useAuth()

  const isSuperUsers = isSuperUser(whoAmI?.email)

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const navigate = useNavigate()

  const object: Record<string, ReactNode> = {
    agent: <AgentsList />,
    conversation: <ConversationsList />,
  }

  const tabs = useMemo(() => {
    if (isSuperUsers) {
      return [
        {
          title: t('buttonAgentTabs'),
          isActive: page === 'agent',
          onSelect: () => navigate(ROUTES_ADMIN.AI_CENTER.AGENT.fullPath()),
        },
        {
          title: t('buttonConversationTabs'),
          isActive: page === 'conversation',
          onSelect: () =>
            navigate(ROUTES_ADMIN.AI_CENTER.CONVERSATION.fullPath()),
        },
      ]
    }

    return [
      {
        title: t('buttonConversationTabs'),
        isActive: page === 'conversation',
        onSelect: () =>
          navigate(ROUTES_ADMIN.AI_CENTER.CONVERSATION.fullPath()),
      },
    ]
  }, [location.pathname, isSuperUsers])

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <Tabs items={tabs} />

        {object?.[page as keyof typeof object]}
      </div>
    </div>
  )
}
