import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from '@mercai/clever'

import { Message } from '../message'

import { isSuperUser } from '@/helpers'
import { useAuth } from '@/providers'

import { useTranslation } from 'react-i18next'

import type { ChatProps } from './chat.type'

import styles from './chat.module.css'

export const Chat = ({
  messages,
  onSendMessage,
  isLoading,
  origin,
}: ChatProps) => {
  const { t } = useTranslation('componentsDrawerFormAI')

  const [message, setMessage] = useState('')
  const contentRef = useRef<HTMLDivElement>(null)

  const { whoAmI } = useAuth()

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onSendMessage(message)

    setMessage('')
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [messages])

  return (
    <div className={styles.core}>
      <div className={styles.content} ref={contentRef}>
        {messages?.map((message, index) => (
          <Message {...message} isLoading={false} key={index} />
        ))}

        {isLoading && (
          <Message author="assistant" content="Loading..." isLoading={true} />
        )}
      </div>

      {(origin !== 'whatsapp' || isSuperUser(whoAmI?.email)) && (
        <form className={styles.send} onSubmit={handleSendMessage}>
          <Input
            value={message}
            onChange={setMessage}
            placeholder={t('inputPlaceholder')}
          />
          <Button type="submit">{t('buttonSend')}</Button>
        </form>
      )}
    </div>
  )
}
