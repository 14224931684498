import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

import {
  useCalculateUnitPrice,
  useDollarQuotationLatest,
  useQuotationDetail,
  useUpdateQuotation,
} from '@/services'
import {
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
  trackEvent,
  useParamsState,
  useValidationErrors,
} from '@/helpers'

import { Button, Text } from '@mercai/clever'

import styles from './update.module.css'
import {
  AddressProps,
  CalculateUnitPricePayloadProps,
  ProfileProps,
  QuotationProps,
} from '@/types'
import {
  accordionsByStatusObject,
  getQuotationSuggestedUnitPricePayload,
  getQuotationWithOrdenedProducts,
} from './update.constants'
import {
  AddressDrawerForm,
  BuyerDrawerForm,
  CatalogProducts,
  GeneralInformation,
  OrderData,
  QuotationDialog,
  QuotationMetrics,
  QuotationMetricsProps,
  QuotationStatusSelect,
} from '../../components'
import {
  checkIsCatalogProductsPrices,
  checkIsCompleteGeneralInformation,
  checkIsCompleteOrderData,
  schemaQuotation,
} from '../../quotation-validation'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'
import { getQuotationMetricsPayload } from '../../helpers'

export const QuotationV2Update = () => {
  const { t } = useTranslation('screenBusinessV2')

  const { uuid } = useParams()

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const { mutateAsync: mutateAsyncUpdateQuotation } = useUpdateQuotation(uuid)

  const { data: dataUnitPrice, mutateAsync: mutateAsyncUnitPrice } =
    useCalculateUnitPrice()

  const { data: dataDollarQuotationLatest } = useDollarQuotationLatest()

  const { whoAmI } = useAuth()

  const [quotationValues, setQuotationValues] =
    useState<Partial<QuotationProps>>()

  const { data: quotation, isLoading: isLoadingQuotation } =
    useQuotationDetail(uuid)

  const [quotationOrdened, setQuotationOrdened] = useState<QuotationProps>()

  const setBuyerProfile = (buyerProfile: ProfileProps) => {
    setQuotationOrdened({
      ...quotationOrdened,
      buyerProfile,
      buyerProfileUuid: buyerProfile.uuid,
    } as QuotationProps)
  }

  const setAddress = (address: Partial<AddressProps>) => {
    setQuotationOrdened({
      ...quotationOrdened,
      address,
      addressUuid: address.uuid,
    } as QuotationProps)
  }

  const isCompleteGeneralInformation = checkIsCompleteGeneralInformation({
    address: quotationOrdened?.deliveryAddress,
    buyerProfile: quotationOrdened?.buyerProfile,
    paymentFormOption: quotationValues?.paymentFormOption,
    paymentMethodOption: quotationValues?.paymentMethodOption,
  })
  const isCompleteOrderData = checkIsCompleteOrderData({
    expiresAt: quotationValues?.expiresAt,
    expectedDeliveryDays: quotationValues?.expectedDeliveryDays,
    totalFreightPrice: quotationValues?.totalFreightPrice,
    firstMileFreightCost: quotationValues?.firstMileFreightCost,
    lastMileFreightCost: quotationValues?.lastMileFreightCost,
    customsFreightCost: quotationValues?.customsFreightCost,
    purchaseOnCredit: quotationValues?.purchaseOnCredit,
    financingCost: quotationValues?.financingCost,
    logisticTransportCompanyUuid: quotationValues?.logisticTransportCompanyUuid,
  })

  const isCompleteCatalogProductsPrices = checkIsCatalogProductsPrices(
    quotationValues?.products,
  )

  const [activeItems, setActiveItems] = useState<string[]>([
    'general-information',
    'catalog-products',
    'order-data',
  ])

  const [quotationDialogIsOpen, setQuotationDialogIsOpen] = useState(false)

  const [dataForMetrics, setDataForMetrics] =
    useState<QuotationMetricsProps['values']>()

  const [dataForSuggestedUnitPrice, setDataForSuggestedUnitPrice] =
    useState<CalculateUnitPricePayloadProps>()

  const [queryParams, setQueryParams] = useParamsState({
    form: '',
  })

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaQuotation(t),
    quotationValues,
  )

  const onCloseDrawerForm = () => {
    setQueryParams({ ...queryParams, form: '' })
  }

  const getMetrics = (quotation: QuotationProps | undefined = undefined) => {
    const data = quotation || quotationValues
    if (data) {
      setDataForMetrics(
        getQuotationMetricsPayload({
          quotationValues: data as QuotationProps,
        }),
      )
    }
  }

  const getSuggestedUnitPrice = (quotation?: QuotationProps) => {
    const data = quotation || quotationValues
    if (data) {
      setDataForSuggestedUnitPrice(
        getQuotationSuggestedUnitPricePayload({
          quotationValues: data as QuotationProps,
        }),
      )
    }
  }

  const accordionsComponents = {
    'general-information': (position: number) => (
      <GeneralInformation
        position={position}
        key="general-information"
        isComplete={isCompleteGeneralInformation}
        isLoading={isLoadingQuotation}
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        buyerProfile={quotationOrdened?.buyerProfile}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        address={quotationOrdened?.deliveryAddress}
        onHandleBuyerDrawerOpen={() =>
          setQueryParams({ ...queryParams, form: 'buyer' })
        }
        onHandleAddressDrawerOpen={() =>
          setQueryParams({ ...queryParams, form: 'address' })
        }
        isEditable={true}
        getMetrics={getMetrics}
        errors={errors}
      />
    ),
    'order-data': (position: number) => (
      <OrderData
        position={position}
        key="order-data"
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        isComplete={isCompleteOrderData}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        errors={errors}
        getMetrics={getMetrics}
        getSuggestedUnitPrice={getSuggestedUnitPrice}
      />
    ),
    'catalog-products': (position: number) => (
      <CatalogProducts
        position={position}
        key="catalog-products"
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        validation={isCompleteCatalogProductsPrices}
        isEditable={true}
        quotationValues={quotationValues}
        setQuotationValues={setQuotationValues}
        catalogProducts={quotationOrdened?.products || []}
        onHandleCatalogProductsDrawerOpen={() => {}}
        getMetrics={getMetrics}
        getSuggestedUnitPrice={getSuggestedUnitPrice}
        suggestedUnitPrice={dataUnitPrice?.data}
        errors={errors}
      />
    ),
  }

  const onHandleSubmitComplete = async () => {
    if (!isValid) {
      return onHandleListenErrors(true)
    }

    onHandleListenErrors(false)

    const confirm = await onConfirmDangerMessage(
      t('quotationCreateConfirmTitle'),
      t('quotationUpdateConfirmMessage'),
      t('quotationCreateConfirmButtonCreate'),
      t('quotationCreateConfirmButtonCancel'),
    )

    if (!confirm) {
      return
    }

    const response = await mutateAsyncUpdateQuotation({
      ...quotationValues,
      transportCompany: undefined,
    } as QuotationProps)

    if (response.success) {
      onSuccessMessage(
        t('quotationFormSuccessCreateTitle'),
        t('quotationFormSuccessUpdateMessage'),
      )

      window.location.href = `${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(uuid || '')}`
    } else {
      onErrorMessage(
        t('quotationFormErrorCreateTitle'),
        t('quotationFormErrorUpdateMessage'),
      )
    }
  }

  const onHandleSubmit = () => {
    if (
      quotationValues?.products &&
      quotationValues?.products?.filter((product) => product.catalogProductUuid)
        ?.length < quotationValues?.products?.length
    ) {
      return setQuotationDialogIsOpen(true)
    }

    onHandleSubmitComplete()
  }

  useEffect(() => {
    setQuotationValues({
      ...quotationOrdened,
      discount: quotationOrdened?.discount || '0.00',
      ...quotationValues,
      logisticTransportCompanyUuid:
        quotationOrdened?.logisticTransportCompany?.uuid,
      status: 'pending',
      dollarExchangeRate: dataDollarQuotationLatest?.data?.value,
      buyerProfileUuid: quotationOrdened?.buyerProfile?.uuid,
      buyerProfile: quotationOrdened?.buyerProfile,
      deliveryAddressUuid: quotationOrdened?.deliveryAddress?.uuid,
      deliveryAddress: quotationOrdened?.deliveryAddress,
      products:
        quotationOrdened?.products?.map((product, i) => ({
          ...product,
          ...quotationValues?.products?.[i],
          catalogProductUuid: product.catalogProduct?.uuid,
          supplierUuid: product.supplier?.uuid,
        })) || [],
    })
  }, [dataDollarQuotationLatest, whoAmI, quotationOrdened])

  useEffect(() => {
    if (dataForSuggestedUnitPrice) {
      mutateAsyncUnitPrice(dataForSuggestedUnitPrice)
    }
  }, [dataForSuggestedUnitPrice, quotation])

  useEffect(() => {
    getMetrics(quotation?.data)
    getSuggestedUnitPrice(quotation?.data)

    setQuotationOrdened(getQuotationWithOrdenedProducts(quotation?.data))
  }, [quotation])

  useEffect(() => {
    trackEvent('adminQuotationUpdatePageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Text variant="text-small" className={styles.breadcrumb}>
          <Link
            to={ROUTES_ADMIN.BUSINESS.QUOTATION.fullPath()}
          >{`${t('quotationTitle')}`}</Link>
          {` / `}
          <span>{quotationOrdened?.reference}</span>
        </Text>
        <div className={styles['header-content']}>
          <div>
            <div className={styles['sub-title']}>
              <Text variant="title-large">
                {t(
                  quotationOrdened?.products?.length === 1
                    ? 'quotationIdentifier'
                    : 'quotationIdentifierPlural',
                  {
                    rfq: quotationOrdened?.reference,
                    quantity: quotationOrdened?.products?.length,
                  },
                )}
              </Text>
              <QuotationStatusSelect quotation={quotationOrdened} />
            </div>
            <Text
              variant="text-base"
              className={styles['sub-title-instructions']}
            >
              {t('instructionGenerateProposals')}
            </Text>
          </div>
          <div className={styles['header-actions']}>
            <Button
              variant="primary"
              onClick={onHandleSubmit}
              disabled={quotationOrdened?.status !== 'pending'}
            >
              {t('buttonEditQuotation')}
            </Button>
          </div>
        </div>
      </header>

      <QuotationMetrics values={dataForMetrics} />

      {accordionsByStatusObject[quotationOrdened?.status || 'pending'].map(
        (key, i) =>
          accordionsComponents[key as keyof typeof accordionsComponents](i + 1),
      )}

      <BuyerDrawerForm
        uuid={quotationOrdened?.buyerProfile?.uuid || ''}
        isOpen={queryParams.form === 'buyer'}
        onHandleOpen={onCloseDrawerForm}
        setBuyerProfile={setBuyerProfile}
      />
      <AddressDrawerForm
        quotation={quotationOrdened as QuotationProps}
        isOpen={queryParams.form === 'address'}
        onHandleOpen={onCloseDrawerForm}
        setAddress={setAddress}
      />

      <QuotationDialog
        onHandleSubmit={onHandleSubmitComplete}
        isOpen={quotationDialogIsOpen}
        onHandleClose={() => setQuotationDialogIsOpen(false)}
      />
    </div>
  )
}
