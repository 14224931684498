import { SupplierOrderProps, SupplierQuotationItemProps } from '@/types'
import { SupplierOrderVisualizationProps } from '../../../supplier-order-visualization/supplier-order-visualization.types'

export const getSupplierOrderVisualizationProps = (
  data?: SupplierOrderProps,
): SupplierOrderVisualizationProps => {
  const itemsBySaleOrders: Record<string, SupplierQuotationItemProps[]> = {}

  data?.supplyQuotation?.supplyQuotationItems?.forEach((item) => {
    if (!itemsBySaleOrders[item?.saleOrderItem?.order || '']) {
      itemsBySaleOrders[item?.saleOrderItem?.order || ''] = []
    }
    itemsBySaleOrders[item?.saleOrderItem?.order || ''].push(item)
  })

  const items: SupplierOrderVisualizationProps['items'] = Object.keys(
    itemsBySaleOrders,
  )?.map((key) => ({
    buyerCompanyName: itemsBySaleOrders[key]?.[0]?.buyer?.companyName || '-',
    saleOrderReference: key,
    products: itemsBySaleOrders[key]?.map((item) => ({
      productTitle: item?.catalogProduct?.attributes?.title || '-',
      productCost: item?.cost,
      productQuantity: item?.quantity,
      productTotal: item?.total,
    })),
  }))

  return {
    total: data?.supplyQuotation?.value || 0,
    currency: data?.supplyQuotation?.currency || 'MXN',
    items,
    poFile: data?.supplyQuotation?.purchaseOrderFile,
  }
}
