import { TrashIcon, Text, FilterIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  checkArrowDirection,
  generateClassesMetricCard,
  generateClassesChangeMetricCard,
} from './metric-card.constants'

import { formatNumber } from '@/helpers'

import styles from './metric-card.module.css'

import { MetricCardProps, MetricCardsProps } from './metric-card.type'

export const MetricCard = ({
  value,
  change,
  changeSuffix = '',
  label,
  isActiveFilter,
  onClickFilter,
  isLoading,
  positiveIsBad = false,
}: MetricCardProps) => {
  const { t } = useTranslation('componentsMetricCard')

  return (
    <li className={generateClassesMetricCard(isActiveFilter)}>
      <Text className={styles['metrics-item-texts']}>{label}</Text>

      <div className={styles['metrics-item-row']}>
        <Text variant="title-large">
          {!isLoading ? value : <Skeleton height="2rem" width="4rem" />}
        </Text>

        {(change || change === 0) && (
          <Text
            variant="title-small"
            className={generateClassesChangeMetricCard(
              positiveIsBad ? change <= 0 : change >= 0,
            )}
          >
            {!isLoading ? (
              <>
                ( {checkArrowDirection(positiveIsBad, change) ? '↑' : '↓'}{' '}
                {formatNumber(change)}
                {changeSuffix} )
              </>
            ) : (
              <Skeleton height="1.2rem" width="2rem" />
            )}
          </Text>
        )}
      </div>

      {onClickFilter && (
        <button
          onClick={() => onClickFilter()}
          className={styles['metrics-item-button']}
        >
          <Text variant="text-small" className={styles['metrics-item-text']}>
            {isActiveFilter ? t('filterTabRemove') : t('filterTabApply')}
          </Text>

          {isActiveFilter ? <TrashIcon /> : <FilterIcon />}
        </button>
      )}
    </li>
  )
}

export const MetricCards = ({ metrics }: MetricCardsProps) => {
  return (
    <ul className={styles.metrics}>
      {metrics.map((metric, i) => (
        <MetricCard {...metric} key={i + '-metric'} />
      ))}
    </ul>
  )
}
