import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Tag,
  Button,
  Checkbox,
  CircleRating,
  DropDown,
  TableColumns,
  Text,
  Select,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  InputSearch,
  ArrowRightIcon,
  PlusIcon,
  ChartIcon,
  SparksIcon,
  CloseIcon,
  Modal,
  Input,
  Label,
  CheckIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { DrawerFormAIChat, MetricCards } from '@/components'

import {
  formatDate,
  useParamsState,
  useCookieState,
  trackEvent,
  isSuperUser,
  formatNumber,
} from '@/helpers'

import { useAuth } from '@/providers'

import {
  useAIConversationList,
  useAIEvaluationConversation,
  useAIRegenerateConversation,
  useAICreateWhatsappMessage,
  useAIUpdateConversation,
  useAIConversationMetrics,
} from '@/services'
import { IAConversationProps } from '@/types'

import styles from './list.module.css'

export const ConversationsList = () => {
  const { t } = useTranslation('screenAiCenter')

  const [totalPages, setTotalPages] = useState(1)

  const { whoAmI } = useAuth()

  const [isOpenRegenerateConversation, setIsOpenRegenerateConversation] =
    useState(false)
  const [uuidRegenerateConversation, setUuidRegenerateConversation] =
    useState('')
  const [responseRegenerateConversation, setResponseRegenerateConversation] =
    useState('')

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/conversations-list-columns-2',
    [
      'createdBy',
      'status',
      'messages',
      'createdAt',
      'actions',
      'attendedAt',
      'origin',
      'evaluation',
      'actions',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading, refetch } = useAIConversationList({
    params: filter,
  })

  const {
    mutateAsync: evaluateConversation,
    isPending: isPendingEvaluateConversation,
  } = useAIEvaluationConversation()

  const {
    mutateAsync: regenerateConversation,
    isPending: isPendingRegenerateConversation,
  } = useAIRegenerateConversation()

  const {
    mutateAsync: createWhatsappMessage,
    isPending: isPendingCreateWhatsappMessage,
  } = useAICreateWhatsappMessage()

  const {
    mutateAsync: updateWhatsappConversation,
    isPending: isPendingUpdateWhatsappConversation,
  } = useAIUpdateConversation()

  const { data: metricsData, isLoading: isLoadingMetrics } =
    useAIConversationMetrics()

  const onHandleEvaluateConversation = async (uuid: string) => {
    await evaluateConversation(uuid)

    await refetch()
  }

  const onHandleRegenerateConversation = async (uuid: string) => {
    setIsOpenRegenerateConversation(true)
    setUuidRegenerateConversation(uuid)
  }

  const onRequestRegenerateConversation = async () => {
    const response = await regenerateConversation(uuidRegenerateConversation)

    setResponseRegenerateConversation(response?.message || '')

    if (response?.success) {
      refetch()
    }
  }

  const onRequestCreateWhatsappMessage = async () => {
    const response = await createWhatsappMessage({
      content: responseRegenerateConversation,
      conversationUuid: uuidRegenerateConversation,
    })

    setResponseRegenerateConversation('')

    if (response?.success) {
      refetch()
    }
  }

  const onHandleUpdateWhatsappConversation = async (uuid: string) => {
    const response = await updateWhatsappConversation({
      uuid,
      attendedAt: new Date().toISOString(),
    })

    if (response?.success) {
      refetch()
    }
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    setResponseRegenerateConversation('')
  }, [uuidRegenerateConversation])

  useEffect(() => {
    trackEvent('adminAiCenterConversationsListPageView')
  }, [])

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const columns: TableColumnsColumnProps<IAConversationProps>[] = [
    {
      title: t('conversationTableCreatedBy'),
      key: 'createdBy',
      dataKey: 'createdBy',
    },
    {
      title: t('conversationTableStatus'),
      width: '8rem',
      key: 'status',
      dataKey: 'status',
      render: ({ value }) => (
        <Tag variant={value === 'active' ? 'success' : 'danger'} model="light">
          {value}
        </Tag>
      ),
    },

    {
      title: t('conversationTableattendedByAcquisition'),
      key: 'attendedAt',
      dataKey: 'attendedAt',
      render: ({ value, record }) => {
        if (value) {
          return (
            <Tag variant="info" model="light">
              {formatDate(value)}
            </Tag>
          )
        }

        return (
          <div className={styles['column-attended-by-acquisition']}>
            <Tag variant="danger" model="light">
              {t('conversationTableattendedByAcquisitionNo')}
            </Tag>
            <Button
              variant="secondary"
              model="square"
              size="small"
              onClick={() =>
                onHandleUpdateWhatsappConversation(record.uuid || '')
              }
              disabled={isPendingUpdateWhatsappConversation}
            >
              <CheckIcon />
            </Button>
          </div>
        )
      },
    },
    {
      title: t('conversationTableMessages'),
      key: 'messages',
      dataKey: 'messages',
      render: ({ value }) => `${value?.length} messages`,
    },
    {
      title: t('conversationTableOrigin'),
      key: 'origin',
      width: '8rem',
      dataKey: 'origin',
    },
    {
      title: t('conversationTableJtbdCompleted'),
      key: 'jtbdCompleted',
      width: '8rem',
      dataKey: 'extraData',
      render: ({ record }) => {
        const jtbdCompleted = (
          record?.extraData as unknown as {
            evaluation: { is_jtbd_completed: string }
          }
        )?.evaluation?.is_jtbd_completed as string

        return (
          <div className={styles.icon}>
            {jtbdCompleted ? (
              <CheckIcon className={styles.check} />
            ) : (
              <CloseIcon className={styles.close} />
            )}
          </div>
        )
      },
    },
    {
      title: t('conversationTableEvaluation'),
      key: 'evaluation',
      width: '8rem',
      dataKey: 'extraData',
      render: ({ record }) => {
        const evaluation = (
          record?.extraData as unknown as {
            evaluation: { general_note: string }
          }
        )?.evaluation?.general_note as string

        return <CircleRating rating={+(evaluation || 0)} />
      },
    },
    {
      title: t('conversationTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('conversationTableActions'),
      key: 'actions',
      dataKey: 'uuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            variant="secondary"
            model="square"
            onClick={() => onHandleRegenerateConversation(record.uuid || '')}
          >
            <SparksIcon />
          </Button>
          <Button
            variant="secondary"
            model="square"
            onClick={() => onHandleEvaluateConversation(record.uuid || '')}
            disabled={isPendingEvaluateConversation}
          >
            <ChartIcon />
          </Button>
          <Button
            variant="primary"
            onClick={() => onHandleEditRegister(record.uuid || '')}
            model="square"
          >
            <ArrowRightIcon />
          </Button>
        </div>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <>
      <MetricCards
        metrics={[
          {
            label: t('conversationTableAverageRating'),
            value: `${formatNumber(metricsData?.data?.generalNoteMetricsReport?.currentPeriodAvg || 0)}/5.00`,
            change:
              metricsData?.data?.generalNoteMetricsReport?.deltaPercentage,
            changeSuffix: '%',
            isLoading: isLoadingMetrics,
          },
          {
            label: t('conversationTableJtbdCompletedConversion'),
            value: `${formatNumber(metricsData?.data?.jtbdCompletedMetricsReport?.currentPeriodPercentage || 0)}%`,
            change:
              metricsData?.data?.jtbdCompletedMetricsReport?.deltaVariation,
            changeSuffix: '%',
            isLoading: isLoadingMetrics,
          },
          {
            label: t('conversationTableJtbdCompletedTotal'),
            value: `${metricsData?.data?.jtbdCompletedMetricsReport?.currentPeriodCount || 0}/${metricsData?.data?.jtbdCompletedMetricsReport?.totalCurrentPeriod || 0}`,
            change:
              metricsData?.data?.jtbdCompletedMetricsReport?.deltaPercentage,
            changeSuffix: '%',
            isLoading: isLoadingMetrics,
          },
        ]}
      />

      <div>
        <div className={styles.filter}>
          <div className={styles['filter-group']}>
            <InputSearch
              value={filter.search as string}
              onChange={(value) => handleFilter('search', value)}
              className={styles['search-input']}
              translations={{
                inputPlaceholder: t('searchFilterPlaceholder'),
              }}
            />
          </div>

          <div className={styles['filter-group']}>
            <Button onClick={onHandleNewRegister}>
              <PlusIcon />
              {t('conversationTableNewRegister')}
            </Button>

            <DropDown.Root>
              <DropDown.ButtonTrigger
                variant="secondary"
                model="square"
                hasArrow={false}
              >
                <SettingIcon />
              </DropDown.ButtonTrigger>

              <DropDown.Content orientation="bottom-right">
                {columns?.map((column) => (
                  <DropDown.Item key={column.dataKey}>
                    <Checkbox
                      onChange={() => onHandleColumnsShow(column.key)}
                      value={getColumnsShow(column.key)}
                    >
                      {column.title}
                    </Checkbox>
                  </DropDown.Item>
                ))}
              </DropDown.Content>
            </DropDown.Root>
          </div>
        </div>

        <TableColumns<IAConversationProps>
          isLoading={isLoading}
          columns={memoizedColumns}
          data={data?.data?.results || []}
        />

        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('agentTableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', `${page}`)}
            currentPage={+filter.page as number}
          />
        </div>

        <DrawerFormAIChat
          isOpen={queryParams.form === 'true'}
          onClose={() => setQueryParams({ ...queryParams, form: '' })}
          uuid={queryParams.uuid}
        />

        <Modal.Root
          isOpen={isOpenRegenerateConversation}
          onHandleOpen={() => setIsOpenRegenerateConversation(false)}
          size="small"
        >
          <Modal.Body className={styles['modal-body']}>
            <Text variant="title-large">
              {t('conversationTableRegenerateConversation')}
            </Text>
            <Text>
              {t('conversationTableRegenerateConversationDescription')}
            </Text>

            <div>
              <Label>
                {t('conversationTableRegenerateConversationResponseLabel')}
              </Label>
              <Input
                value={responseRegenerateConversation}
                isTextArea
                onChange={setResponseRegenerateConversation}
              />
            </div>

            <Button
              onClick={() => onRequestRegenerateConversation()}
              disabled={isPendingRegenerateConversation}
            >
              {t('conversationTableRegenerateConversationButton')}
            </Button>

            {isSuperUser(whoAmI?.email) && (
              <Button
                variant="secondary"
                onClick={() => onRequestCreateWhatsappMessage()}
                disabled={
                  isPendingCreateWhatsappMessage ||
                  !responseRegenerateConversation
                }
              >
                {t('conversationTableCreateWhatsappMessageButton')}
              </Button>
            )}
          </Modal.Body>
        </Modal.Root>
      </div>
    </>
  )
}
