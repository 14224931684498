import { ReactNode, useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  DropDown,
  FilterIcon,
  TableColumns,
  Tag,
  Tooltip,
  Text,
  Select,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  PlusIcon,
  TrashIcon,
  InputSearch,
  EditIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE, appQuotationLink } from '@/constants'
import {
  formatDate,
  trimString,
  useParamsState,
  useCookieState,
  formatCurrency,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
} from '@/helpers'
import { useQuotationList, useDeleteQuotation } from '@/services'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'
import { QuotationProps } from '@/types'

import {
  QuotationDrawerFilter,
  QuotationDrawerForm,
  QuotationDrawerShow,
} from './components'

import { TagMoscow } from '../../../profile/components'

import type { QuotationListProps } from './list.type'

import styles from './list.module.css'

export const QuotationList = ({ buyerProfile }: QuotationListProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationList')

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/quotation-list-columns-v2',
    [
      'reference',
      'buyerProfile.name',
      'buyerProfile.companyName',
      'buyerProfile.email',
      'buyerProfile.phone',
      'status',
      'rejectedReason',
      'buyerProfile.moscowClassification',
      'totalPrice',
      'products',
      'recurrencyOption',
      'creatorEmail',
      'expiresAt',
      'createdAt',
      'buyerProfile.keyAccountManager',
      'quotationRequest',
      'orders',
      'uuid',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    recurrencyOption: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) => !!filter[key] && key !== 'page' && key !== 'limit',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: newValue })
    } else {
      setFilter({ ...filter, [changedKey]: newValue, page: '1' })
    }
  }

  const { data, isLoading, refetch } = useQuotationList(
    buyerProfile ? { ...filter, buyerProfile } : filter,
  )

  const { isPending, mutateAsync: mutateDelete } = useDeleteQuotation()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationTableDeleteConfirmTitle'),
      t('quotationTableDeleteConfirmMessage'),
      t('quotationTableDeleteConfirmButtonDelete'),
      t('quotationTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('quotationTableDeleteSuccessTitle'),
          t('quotationTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('quotationTableDeleteErrorTitle'),
          t('quotationTableDeleteErrorMessage'),
        )
      }
    }
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  const onHandleShowRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: '', show: 'true', uuid })
  }

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const stateObject: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {t('quotationTableStatusPending')}
      </Tag>
    ),
    active: (
      <Tag variant="primary" model="light">
        {t('quotationTableStatusActive')}
      </Tag>
    ),
    accepted: (
      <Tag variant="success" model="light">
        {t('quotationTableStatusAccepted')}
      </Tag>
    ),
    expired: (
      <Tag variant="basic" model="light">
        {t('quotationTableStatusExpired')}
      </Tag>
    ),
    rejected: (
      <Tag variant="danger" model="light">
        {t('quotationTableStatusRejected')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {t('quotationTableStatusCancelled')}
      </Tag>
    ),
  }

  const getContentProduct = useCallback(
    (items: QuotationProps['products'], index: number) => {
      const concatItems = items
        ?.map(
          (item) =>
            item?.catalogProduct?.attributes?.title ||
            item?.nonCatalogProduct?.description,
        )
        .join(', ')

      return (
        <Tooltip
          text={concatItems}
          widthText="18.75rem"
          orientation={index >= 8 ? 'top' : 'bottom'}
        >
          {trimString(concatItems, 52, '...')}
        </Tooltip>
      )
    },
    [data],
  )

  const columns: TableColumnsColumnProps<QuotationProps>[] = [
    {
      title: t('quotationTableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ value }) => `#${value}`,
    },
    {
      title: t('quotationTableBuyerName'),
      key: 'buyerProfile.name',
      dataKey: 'buyerProfile.usersNames',
      render: ({ record }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {record?.buyerProfile?.usersNames?.join(', ') || '-'}
        </Link>
      ),
    },
    {
      title: t('quotationTableBusinessName'),
      key: 'buyerProfile.companyName',
      dataKey: 'buyerProfile.companyName',
      width: '18rem',
      render: ({ record, value }) =>
        record?.buyerProfile?.companyName ? (
          <Link
            target="_blank"
            to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
            className={styles.link}
          >
            {value}
          </Link>
        ) : (
          <Text variant="title-base">
            {record?.buyerProfile?.companyName || '-'}
          </Text>
        ),
    },
    {
      title: t('quotationTableEmail'),
      key: 'buyerProfile.email',
      dataKey: 'buyerProfile.email',
      width: '22rem',
      render: ({ record, value }) =>
        record?.buyerProfile?.email ? (
          <Link
            target="_blank"
            to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
            className={styles.link}
          >
            {value}
          </Link>
        ) : (
          <Text variant="title-base">{record?.buyerProfile?.email || '-'}</Text>
        ),
    },
    {
      title: t('quotationTablePhone'),
      key: 'buyerProfile.phone',
      dataKey: 'buyerProfile.phone',
      render: ({ record }) =>
        record?.buyerProfile?.phone || (
          <Text variant="title-base">{record?.buyerProfile?.phone || '-'}</Text>
        ),
    },
    {
      title: t('quotationTableState'),
      key: 'status',
      dataKey: 'status',
      render: ({ record }) =>
        stateObject[(record?.status || 'pending') as string] || '-',
    },
    {
      title: t('quotationTableRejectedReason'),
      key: 'rejectedReason',
      dataKey: 'rejectedReason',
      width: '18rem',
    },
    {
      title: t('quotationTableMoscow'),
      key: 'buyerProfile.moscowClassification',
      dataKey: 'buyerProfile.moscowClassification',
      render: ({ record }) => (
        <TagMoscow
          moscowClassification={record?.buyerProfile?.moscowClassification}
        />
      ),
    },
    {
      title: t('quotationTableAmount'),
      key: 'totalPrice',
      dataKey: 'totalPrice',
      render: ({ value }) =>
        value ? formatCurrency(value, 'es-MX', 'USD') : '-',
    },
    {
      title: t('quotationTableProducts'),
      key: 'products',
      dataKey: 'products',
      width: '20rem',
      render: ({ record, index }) => getContentProduct(record.products, index),
    },
    {
      title: t('quotationTableRecurrencyOption'),
      key: 'recurrencyOption',
      dataKey: 'recurrencyOption',
      render: ({ record }) =>
        !record?.recurrencyOption
          ? '-'
          : tEnums(`quotation_recurrency_option_${record?.recurrencyOption}`),
    },
    {
      title: t('quotationTableCreatedBy'),
      key: 'creatorEmail',
      dataKey: 'creatorEmail',
    },
    {
      title: t('quotationTableExpiredAt'),
      key: 'expiresAt',
      dataKey: 'expiresAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('quotationTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('quotationTableSellerName'),
      key: 'buyerProfile.keyAccountManager',
      dataKey: 'buyerProfile.keyAccountManager',
      width: '18.75rem',
    },
    {
      title: t('quotationTableRFQ'),
      key: 'quotationRequest',
      dataKey: 'quotationRequest',
      render: ({ record }) =>
        record?.quotationRequest ? (
          <Link
            to={ROUTES_ADMIN.BUSINESS.RFQ_OLD.SHOW.fullPath(
              record?.quotationRequest.uuid,
            )}
            className={styles.link}
            target="_blank"
          >
            {`#${record?.quotationRequest.reference}`}
          </Link>
        ) : (
          <Text>-</Text>
        ),
    },
    {
      title: t('quotationTableOrder'),
      key: 'orders',
      dataKey: 'orders',
      render: ({ record }) =>
        record?.orders?.length > 0 ? (
          <div className={styles['column-order']}>
            {record?.orders?.map((order) => (
              <Link
                to={`${ROUTES_ADMIN.ORDER_CENTER.LOGISTIC.fullPath()}?search=${order.reference}`}
                className={styles.link}
                target="_blank"
                key={order?.uuid}
              >
                {`#${order?.reference}`}
              </Link>
            ))}
          </div>
        ) : (
          <Text>-</Text>
        ),
    },
    {
      title: t('quotationTableAppLink'),
      key: 'uuid',
      dataKey: 'uuid',
      render: ({ value }) => (
        <Link
          to={appQuotationLink(value || '')}
          className={styles.link}
          target="_blank"
        >
          {t('quotationTableAppLink')}
        </Link>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('quotationTableFilterButton')}

            {getContentTotalFiltered()}
          </Button>
        </div>

        <div className={styles['filter-group']}>
          <Button onClick={onHandleNewRegister}>
            <PlusIcon />
            {t('quotationTableNewRegister')}
          </Button>

          <DropDown.Root>
            <DropDown.ButtonTrigger
              variant="secondary"
              model="square"
              hasArrow={false}
            >
              <SettingIcon />
            </DropDown.ButtonTrigger>

            <DropDown.Content orientation="bottom-right">
              {columns?.map((column) => (
                <DropDown.Item key={column.key}>
                  <Checkbox
                    onChange={() => onHandleColumnsShow(column.key)}
                    value={getColumnsShow(column.key)}
                  >
                    {column.title}
                  </Checkbox>
                </DropDown.Item>
              ))}
            </DropDown.Content>
          </DropDown.Root>
        </div>
      </div>

      <TableColumns<QuotationProps>
        isLoading={isLoading || isPending}
        columns={memoizedColumns}
        fixedColumns={[
          {
            title: t('quotationTableActions'),
            key: 'uuid',
            dataKey: 'uuid',
            render: ({ record }) => (
              <div className={styles['action-buttons']}>
                <Button
                  variant="secondary"
                  onClick={() => onHandleEditRegister(record.uuid)}
                >
                  {t('quotationTableActionsEdit')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => onHandleShowRegister(record.uuid)}
                >
                  {t('quotationTableActionsView')}
                </Button>
                {isSuperUser && (
                  <>
                    <Button
                      variant="secondary"
                      model="square"
                      href={ROUTES_ADMIN.BUSINESS.QUOTATION.UPDATE.fullPath(
                        record.uuid,
                      )}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      model="square"
                      variant="danger"
                      onClick={() => onHandleDelete(record.uuid)}
                    >
                      <TrashIcon />
                    </Button>
                  </>
                )}
              </div>
            ),
            renderLoading: (
              <div className={styles['action-buttons']}>
                <Skeleton height="2rem" width="5.15rem" />
                <Skeleton height="2rem" width="5.7rem" />
                {isSuperUser && <Skeleton height="2rem" width="2rem" />}
              </div>
            ),
          },
        ]}
        data={data?.data?.results || []}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('quotationTableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>

      <QuotationDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <QuotationDrawerShow
        isOpen={!!queryParams.show}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, show: '', uuid: '' })
        }
        quotationUuid={queryParams.uuid}
      />

      <QuotationDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        quotationUuid={queryParams.uuid}
      />
    </div>
  )
}
