import React, { useState } from 'react'
import styles from './catalog-products-carousel.module.css'
import { CatalogProductsCarouselProps } from './catalog-products-carousel.types'
import {
  Card,
  ChevronLeftIcon,
  ChevronRightIcon,
  Radio,
  Text,
  Tooltip,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

export const CatalogProductsCarousel: React.FC<
  CatalogProductsCarouselProps
> = ({ products }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const { t } = useTranslation('componentsCatalogProductsCarousel')

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length)
  }

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + products.length) % products.length,
    )
  }

  const handleSelect = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <Card className={styles['carousel-container']}>
      <div className={styles['carousel-row']}>
        <Text variant="text-large-bold">{t('title')}</Text>
        <div className={styles['carousel-actions']}>
          <div onClick={handlePrevious} className={styles['arrow-button']}>
            <ChevronLeftIcon />
          </div>
          {products?.map((_, index) => (
            <Radio
              key={index}
              value={index === currentIndex}
              onChange={() => handleSelect(index)}
            />
          ))}
          <button onClick={handleNext} className={styles['arrow-button']}>
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div className={styles['product-card']}>
        <div className={styles['product-image']}>
          <img
            src={
              products?.[currentIndex]?.catalogProduct?.attributes?.mainImage ||
              '/images/product-placeholder.webp'
            }
            alt={'img'}
          />
        </div>
        <div className={styles['product-info']}>
          <Tooltip
            orientation="left"
            className={styles['product-name-tooltip']}
            text={
              products?.[currentIndex]?.catalogProduct?.attributes?.title ||
              products?.[currentIndex]?.product?.title ||
              '-'
            }
          >
            <Text className={styles['product-name']}>
              {products?.[currentIndex]?.catalogProduct?.attributes?.title ||
                products?.[currentIndex]?.product?.title ||
                '-'}
            </Text>
          </Tooltip>
          {products?.[currentIndex]?.quantity && (
            <Text className={styles['product-details']}>
              <strong>{t('quantity')}</strong> {products[currentIndex].quantity}
              {' kg'}
            </Text>
          )}
          {(products?.[currentIndex]?.catalogProduct?.attributes?.density ||
            products?.[currentIndex]?.product?.density) && (
            <Text className={styles['product-details']}>
              <strong>{t('density')}</strong>{' '}
              {products?.[currentIndex]?.catalogProduct?.attributes?.density ||
                products?.[currentIndex]?.product?.density}
              {' g/cm³'}
            </Text>
          )}
          {(products?.[currentIndex]?.catalogProduct?.attributes?.fluidity ||
            products?.[currentIndex]?.product?.fluidity) && (
            <Text className={styles['product-details']}>
              <strong>{t('fluidity')}</strong>{' '}
              {products?.[currentIndex].catalogProduct?.attributes?.fluidity ||
                products?.[currentIndex]?.product?.fluidity}
              {' g/10min'}
            </Text>
          )}
          {products?.[currentIndex]?.catalogProduct?.reference && (
            <Text className={styles['product-details']}>
              <strong>{t('reference')}</strong>{' '}
              {products?.[currentIndex]?.catalogProduct?.reference}
            </Text>
          )}

          <Text className={styles['product-details']}>
            <strong>{t('sample')}</strong>{' '}
            {+(products?.[currentIndex]?.quantity || 0) < 1000
              ? t('yes')
              : t('no')}
          </Text>
        </div>
      </div>
    </Card>
  )
}
