import {
  Button,
  CloseIcon,
  DatePicker,
  Drawer,
  InputDebounce,
  Radio,
  Select,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const SupplierOrderDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
  suppliersData,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const onChangeBooleanOfSingleValue = (key: string, value: string) => {
    onHandleFilter({ ...filter, [key]: value })
  }

  const getBooleanOfSingleValue = (key: string, value: string): boolean => {
    return filter[key] === value
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('drawerFilterTaxStatusTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('tableSupplierOrderDrawerFilterHasTransaction')}
          </Text>
          <Radio
            value={getBooleanOfSingleValue('has_transaction', 'true')}
            onChange={() =>
              onChangeBooleanOfSingleValue('has_transaction', 'true')
            }
          >
            {t('tableSupplierOrderDrawerFilterHasTransactionYes')}
          </Radio>
          <Radio
            value={getBooleanOfSingleValue('has_transaction', 'false')}
            onChange={() =>
              onChangeBooleanOfSingleValue('has_transaction', 'false')
            }
          >
            {t('tableSupplierOrderDrawerFilterHasTransactionNo')}
          </Radio>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('tableSupplierOrderDrawerFilterSupplier')}
          </Text>
          <Select
            value={filter.supplier__email || ''}
            options={[
              { label: t('tableSupplierOrderDrawerFilterNoFilled'), value: '' },
              ...(suppliersData?.map((supplier) => ({
                label: supplier.name,
                value: `${supplier?.email}`,
              })) || []),
            ]}
            onChange={(newValue) =>
              onHandleFilter({ ...filter, supplier__email: newValue })
            }
            placeholder={t('tableSupplierOrderDrawerFilterSupplierPlaceholder')}
          />
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('tableSupplierOrderDrawerFilterDestinationAddress')}
          </Text>
          <InputDebounce
            value={filter.destination_address || ''}
            onChange={(newValue) =>
              onHandleFilter({ ...filter, destination_address: newValue })
            }
            placeholder={t(
              'tableSupplierOrderDrawerFilterDestinationAddressPlaceholder',
            )}
          />
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('tableSupplierOrderDrawerFilterExpectedPaid')}
          </Text>
          <div className={styles['content-filter-date']}>
            <DatePicker
              value={filter.expected_payment_date_after || ''}
              onChange={(newValue) =>
                onHandleFilter({
                  ...filter,
                  expected_payment_date_after: newValue || '',
                })
              }
              placeholder={t(
                'tableSupplierOrderDrawerFilterExpectedPaidStartPlaceholder',
              )}
            />
            <Text>-</Text>
            <DatePicker
              value={filter.expected_payment_date_before || ''}
              onChange={(newValue) =>
                onHandleFilter({
                  ...filter,
                  expected_payment_date_before: newValue || '',
                })
              }
              placeholder={t(
                'tableSupplierOrderDrawerFilterExpectedPaidEndPlaceholder',
              )}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('drawerFilterTaxStatusButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
