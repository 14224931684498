import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Text } from '@mercai/clever'

import { QuotationRequestProps, QuotationRequestOriginEnum } from '@/types'
import { ROUTES_ADMIN } from '@/router'

import { CreateGeneralInformation } from './components'

import {
  checkIsCompleteCreateGeneralInformation,
  checkIsCompleteCreateSelectProducts,
  shemaQuotationRequest,
} from './create.constants'

import { CreateSelectProducts } from './components/create-select-products'

import styles from './create.module.css'
import {
  onErrorMessage,
  onSuccessMessage,
  useValidationErrors,
} from '@/helpers'
import { useCreateQuotationRequest } from '@/services'

export const QuotationRequestCreate = () => {
  const { t } = useTranslation('screenBusinessV2')

  const { mutateAsync: mutateAsyncCreateQuotationRequest } =
    useCreateQuotationRequest()

  const [values, setValues] = useState<Partial<QuotationRequestProps>>({
    status: 'qualified',
    origin: QuotationRequestOriginEnum.backoffice_quotation_request,
  })

  const [activeItems, setActiveItems] = useState([
    'general-information',
    'catalog-products',
  ])

  const isCompleteCreateGeneralInformation =
    checkIsCompleteCreateGeneralInformation({
      address: values?.address,
      buyerProfile: values?.buyerProfile,
    })

  const isCompleteCreateSelectProducts = checkIsCompleteCreateSelectProducts({
    items: values?.items,
    file: values?.file,
  })

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    shemaQuotationRequest(t),
    values,
  )

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)

    const response = await mutateAsyncCreateQuotationRequest(values)

    if (response.success) {
      onSuccessMessage(t('successCreateTitle'), t('successCreateMessage'))

      return setTimeout(() => {
        window.location.href = ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(
          response?.data?.uuid || '',
        )
      }, 1500)
    } else {
      onErrorMessage(t('errorCreateTitle'), t('errorCreateMessage'))
    }
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Text variant="text-small" className={styles.breadcrumb}>
          <Link
            to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}
          >{`${t('quotationRequestTitle')}`}</Link>
          {` / `}
          <span>{t('quotationRequestCreateBreadcrumb')}</span>
        </Text>
        <div className={styles['header-content']}>
          <div>
            <div className={styles['sub-title']}>
              <Text variant="title-large">
                {t('quotationRequestCreateTitle')}
              </Text>
            </div>
            <Text
              variant="text-base"
              className={styles['sub-title-instructions']}
            >
              {t('quotationRequestCreateInstructions')}
            </Text>
          </div>
          <div className={styles['header-actions']}>
            <Button
              variant="secondary"
              href={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}
            >
              {t('quotationRequestCreateCancelButton')}
            </Button>
            <Button onClick={onHandleSubmit}>
              {t('quotationRequestCreateConfirmButton')}
            </Button>
          </div>
        </div>
      </header>

      <CreateGeneralInformation
        setValues={setValues}
        address={values?.address}
        buyerProfile={values?.buyerProfile}
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        isComplete={isCompleteCreateGeneralInformation}
        errors={errors}
      />

      <CreateSelectProducts
        setValues={setValues}
        values={values}
        activeItems={activeItems}
        setActiveItems={setActiveItems}
        isComplete={isCompleteCreateSelectProducts}
        errors={errors}
      />
    </div>
  )
}
