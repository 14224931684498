import styles from './metric-card.module.css'

export const selectedClassesMetricCards: Record<
  'selected' | 'unselected',
  string
> = {
  selected: `${styles['metrics-item']} ${styles.selected}`,
  unselected: `${styles['metrics-item']} ${styles.unselected}`,
}

export const generateClassesMetricCard = (
  isSelected = false,
  className?: string,
) => {
  return `${selectedClassesMetricCards[isSelected ? 'selected' : 'unselected']} ${className || ''}`
}

export const generateClassesChangeMetricCard = (isIncrease = true) => {
  return `${isIncrease ? styles['metrics-item-change-increase'] : styles['metrics-item-change-decrease']}`
}

export const checkArrowDirection = (
  positiveIsBad: boolean,
  change: number,
): boolean => {
  if (positiveIsBad) {
    return change <= 0
  }
  return change >= 0
}
