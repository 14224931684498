import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Text } from '@mercai/clever'

import {
  useValidationErrors,
  cleanObject,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'

import {
  useTransactionDetail,
  useTransactionUpdate,
  useOrderCenterFinanceList,
} from '@/services'

import { SelectOrderItem, SelectOrderItemSkeleton } from '../select-order-item'
import { CurrencyTabValues } from '@/components'
import { schema } from './order.constants'

import { TransactionProps, SaleOrderFinanceProps } from '@/types'
import type { TransactionFormStepProps } from './order.type'

import styles from './order.module.css'

export const TransactionOrderStep = ({
  uuid,
  onHandleBack,
  onSuccessSubmit,
}: TransactionFormStepProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  const isNewRecord = !uuid

  const [values, setValues] = useState<TransactionProps>({} as TransactionProps)
  const [editedForm, setEditedForm] = useState(false)
  const [filterText, setFilterText] = useState('')

  const { data: transactionDetail } = useTransactionDetail(uuid)
  const { mutateAsync, isPending: isPendingUpdate } = useTransactionUpdate(uuid)

  const { data: orderFinanceList, isLoading } = useOrderCenterFinanceList(
    {
      buyer_profile__uuid: transactionDetail?.data?.payer?.uuid || '',
      search: filterText,
    },
    !!transactionDetail?.data?.payer?.uuid,
  )

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schema(t),
    values,
  )

  const onSelectOrder = (order: Partial<SaleOrderFinanceProps>) => {
    if (!editedForm) {
      setEditedForm(true)
    }

    setValues({
      ...values,
      order: order as SaleOrderFinanceProps,
      orderUuid: order?.uuid,
    })
  }

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    if (!editedForm && !isNewRecord) {
      onSuccessSubmit?.()

      return
    }

    const payload: Partial<TransactionProps> = {
      orderUuid: values?.orderUuid,
      status: 'processed',
    }

    const result = await mutateAsync(cleanObject(payload))

    if (result.success) {
      onSuccessMessage(
        t('formTransactionSubmitSuccessTitle'),
        t('formTransactionSubmitSuccessMessage'),
      )

      onSuccessSubmit?.()
    } else {
      onErrorMessage(
        t('formTransactionSubmitErrorTitle'),
        t('formTransactionSubmitErrorMessage'),
      )
    }
  }

  useEffect(() => {
    if (transactionDetail?.data) {
      if (!editedForm) {
        setValues({
          ...transactionDetail.data,
          orderUuid: transactionDetail?.data?.order?.uuid,
        })
      }
    } else {
      setValues({} as TransactionProps)
    }

    return () => {
      setEditedForm(false)
    }
  }, [transactionDetail])

  const getOrders = () => {
    if (
      transactionDetail?.data?.order?.reference &&
      orderFinanceList?.data?.results &&
      orderFinanceList?.data?.results?.length > 0
    ) {
      const order = orderFinanceList?.data?.results?.find(
        (order) =>
          order?.reference === transactionDetail?.data?.order?.reference,
      )

      const orders = orderFinanceList?.data?.results?.filter(
        (item) => item?.reference !== transactionDetail?.data?.order?.reference,
      )

      return [order, ...(orders || [])]
    }

    return orderFinanceList?.data?.results || []
  }

  const orders = getOrders()

  return (
    <>
      <div className={styles.content}>
        <CurrencyTabValues
          mxnValue={
            +(values?.currency === 'MXN'
              ? +(values?.value || 0)
              : +(values?.dollarValue || 0) *
                +(values?.dollarExchangeRate || 0))
          }
          usdValue={+(values?.dollarValue || 0)}
          dollarExchangeRate={+(values?.dollarExchangeRate || 0)}
          initialCurrency={transactionDetail?.data?.currency || 'MXN'}
        />

        <Text variant="title-base">
          {t('formTransactionStepSelectOrderTitle')}
        </Text>

        <Input
          value={filterText}
          onChange={setFilterText}
          placeholder={t('formTransactionStepSelectOrderPlaceholder')}
        />

        {!isLoading &&
          orders &&
          orders?.length > 0 &&
          orders?.map((saleOrder, index) => (
            <SelectOrderItem
              key={saleOrder?.uuid || index}
              saleOrder={saleOrder || ({} as SaleOrderFinanceProps)}
              isActive={saleOrder?.uuid === values?.order?.uuid}
              onSelect={() => onSelectOrder(saleOrder as SaleOrderFinanceProps)}
            />
          ))}

        {isLoading &&
          [0, 1, 2, 3].map((item) => <SelectOrderItemSkeleton key={item} />)}

        {!isLoading && (!orders || orders?.length === 0) && (
          <div className={styles.empty}>
            <img src="/images/empty-transaction-list.svg" alt="empty state" />
            <Text>{t('formTransactionStepSelectOrderEmpty')}</Text>
          </div>
        )}

        {errors?.orderUuid && (
          <Text variant="text-small" className={styles.error}>
            {t('requiredField')}
          </Text>
        )}
      </div>

      <div className={styles.footer}>
        <Button onClick={onHandleBack} variant="secondary">
          {t('formTransactionStepFormBack')}
        </Button>
        <Button onClick={onHandleSubmit} disabled={isPendingUpdate}>
          {t('formTransactionStepFormNext')}
        </Button>
      </div>
    </>
  )
}
