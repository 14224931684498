import {
  InvalidateQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import {
  TransportCompanyProps,
  TransportCompanyListWithPagesProps,
  RouteListWithPagesProps,
  TransporCompanyRoutesListWithPagesProps,
  VehicleListWithPagesProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import {
  TRANSPORT_COMPANY_ROUTES_URL,
  LOGISTIC_ROUTES_URL,
  TRANSPORT_COMPANY_URL,
  LOGISTIC_VEHICLES_URL,
} from './api.urls'

export const useTransportCompanyList = (
  {
    params,
  }: {
    params?: Record<string, string | number>
  },
  isEnabled = true,
) => {
  const getTransportCompanyList = async (): Promise<{
    success: boolean
    message: string
    data?: TransportCompanyListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(TRANSPORT_COMPANY_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Transport Companies fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as TransportCompanyListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transport companies!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.TRANSPORT_COMPANY.module, params],
    queryFn: () => getTransportCompanyList(),
    enabled: isEnabled,
  })
}

export const useTransportCompanyDetail = (uuid?: string) => {
  const getTransportCompanyDetail = async (): Promise<{
    success: boolean
    message: string
    data?: TransportCompanyProps
  }> => {
    try {
      const { data } = await api.get(`${TRANSPORT_COMPANY_URL}${uuid}/`)

      return {
        success: true,
        message: 'Transport Company fetched successfully!',
        data: convertObjectToCamelCase(data) as TransportCompanyProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transport company!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.TRANSPORT_COMPANY.module, uuid],
    queryFn: () => getTransportCompanyDetail(),
    enabled: !!uuid,
  })
}

export const useCreateTransportCompany = () => {
  const queryClient = useQueryClient()
  const createTransportCompany = async (
    payload: Partial<TransportCompanyProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: TransportCompanyProps
  }> => {
    try {
      const { data } = await api.post(
        TRANSPORT_COMPANY_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Transport Company created successfully!',
        data: convertObjectToCamelCase(data) as TransportCompanyProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create transport company!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.TRANSPORT_COMPANY.module],
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERIES_ADMIN.TRANSPORT_COMPANY.module,
      ] as InvalidateQueryFilters)
    },
    mutationFn: (payload: Partial<TransportCompanyProps>) =>
      createTransportCompany(payload),
  })
}

export const useUpdateTransportCompany = () => {
  const queryClient = useQueryClient()
  const updateTransportCompany = async (
    payload: Partial<TransportCompanyProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: TransportCompanyProps
  }> => {
    try {
      const { data } = await api.patch(
        `${TRANSPORT_COMPANY_URL}${payload.uuid}/`,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Transport Company updated successfully!',
        data: convertObjectToCamelCase(data) as TransportCompanyProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update transport company!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.TRANSPORT_COMPANY.module],
    onSuccess: () => {
      queryClient.invalidateQueries([
        QUERIES_ADMIN.TRANSPORT_COMPANY.module,
      ] as InvalidateQueryFilters)
    },
    mutationFn: (payload: Partial<TransportCompanyProps>) =>
      updateTransportCompany(payload),
  })
}

export const useRouteList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getRouteList = async (): Promise<{
    success: boolean
    message: string
    data?: RouteListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(LOGISTIC_ROUTES_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Routes fetched successfully!',
        data: convertObjectToCamelCase(data) as RouteListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transport routes!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.ROUTE.module, params],
    queryFn: () => getRouteList(),
  })
}

export const useVehiclesList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getVehiclesList = async (): Promise<{
    success: boolean
    message: string
    data?: VehicleListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(LOGISTIC_VEHICLES_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Vehicles fetched successfully!',
        data: convertObjectToCamelCase(data) as VehicleListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transport vehicle!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.VEHICLES.module, params],
    queryFn: () => getVehiclesList(),
  })
}

export const useTransporCompanyRoutesList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getTransporCompanyRoutesList = async (): Promise<{
    success: boolean
    message: string
    data?: TransporCompanyRoutesListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(TRANSPORT_COMPANY_ROUTES_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Transport Companies Routes fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as TransporCompanyRoutesListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transport companies routes!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.TRANSPORT_COMPANY_ROUTE.module, params],
    queryFn: () => getTransporCompanyRoutesList(),
  })
}
