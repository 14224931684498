const superUsersEmails = (import.meta.env.VITE_SUPER_USERS_EMAILS || '')?.split(
  ',',
)

export const isSuperUser = (email: string = '') =>
  superUsersEmails.includes(email)

const editOrderTrackerEmails = (
  import.meta.env.VITE_EDIT_ORDER_TRACKER_EMAILS || ''
)?.split(',')

export const isEditOrderTracker = (email: string = '') =>
  editOrderTrackerEmails.includes(email) || isSuperUser(email)

const finisherOrdersEmails = (
  import.meta.env.VITE_FINISHER_ORDERS_EMAILS || ''
)?.split(',')

export const isFinisherOrders = (email: string = '') =>
  finisherOrdersEmails.includes(email) || isSuperUser(email)

const enabledCreditOrderTracker = (
  import.meta.env.VITE_ENABLED_CREDIT_ORDER_TRACKER || ''
)?.split(',')

export const isEnabledCreditOrderTracker = (email: string = '') =>
  enabledCreditOrderTracker.includes(email) || isSuperUser(email)

export const isEnabledPaymentPreferences = (email: string = '') =>
  enabledCreditOrderTracker.includes(email) || isSuperUser(email)

const pendingApprovalQuotationApprover = (
  import.meta.env.VITE_PENDING_APPROVAL_QUOTATION_APPROVER_EMAILS || ''
)?.split(',')

export const isPendingApprovalQuotationApprover = (email: string = '') =>
  pendingApprovalQuotationApprover.includes(email) || isSuperUser(email)
