import { useQuery, useMutation } from '@tanstack/react-query'

import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type { TransactionWithPagesProps, TransactionProps } from '@/types'

import { TRANSACTION_URL, TRANSACTIONS_URL } from './api.urls'

export const useTransactionList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderTransactionList = async (): Promise<{
    success: boolean
    message: string
    data?: TransactionWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(TRANSACTIONS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Transactions fetched successfully!',
        data: convertObjectToCamelCase(data) as TransactionWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get order transactions!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.TRANSACTION.module, params],
    queryFn: () => getOrderTransactionList(),
    enabled: isEnabled,
  })
}

export const useTransactionDetail = (uuid?: string) => {
  const getTransactionDetail = async (): Promise<{
    success: boolean
    message: string
    data?: TransactionProps
  }> => {
    try {
      const { data } = await api.get(TRANSACTION_URL(uuid || ''))

      return {
        success: true,
        message: 'Transaction fetched successfully!',
        data: convertObjectToCamelCase(data) as TransactionProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get transaction!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.TRANSACTION.module, uuid],
    queryFn: () => getTransactionDetail(),
    enabled: !!uuid,
  })
}

export const useTransactionCreate = () => {
  const createTransaction = async (
    payload: Partial<TransactionProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: TransactionProps
  }> => {
    try {
      const { data } = await api.post(
        TRANSACTIONS_URL,
        convertObjectToSnakeCase({ paymentSource: 'internal', ...payload }),
      )

      return {
        success: true,
        message: 'Transaction updated successfully!',
        data: convertObjectToCamelCase(data) as TransactionProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update transaction!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.TRANSACTION.module],
    mutationFn: (payload: Partial<TransactionProps>) =>
      createTransaction(payload),
  })
}

export const useTransactionUpdate = (uuid?: string) => {
  const updateTransaction = async (
    payload: Partial<TransactionProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: TransactionProps
  }> => {
    try {
      const { data } = await api.patch(
        TRANSACTION_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Transaction updated successfully!',
        data: convertObjectToCamelCase(data) as TransactionProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update transaction!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.TRANSACTION.module, uuid],
    mutationFn: (payload: Partial<TransactionProps>) =>
      updateTransaction(payload),
  })
}
