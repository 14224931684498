import { SVGProps } from 'react'

export const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -960 960 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M480-240 240-480l56-56 144 144v-368h80v368l144-144 56 56-240 240Z"
      />
    </svg>
  )
}
