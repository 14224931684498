import { useTranslation } from 'react-i18next'

import styles from './table-generate-purchase-order.module.css'
import {
  Button,
  Checkbox,
  CloseIcon,
  FilterIcon,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
  Tooltip,
} from '@mercai/clever'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import { CarouselProducts, EmptyState, SortBy } from '@/components'
import {
  formatCurrency,
  formatDate,
  formatLongDate,
  useParamsState,
} from '@/helpers'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { SaleOrderProps } from '@/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useOrderCenterSaleOrderList, useUserList } from '@/services'
import {
  GeneratePurchaseOrderButton,
  GeneratePurchaseOrderDrawerFilter,
} from './components'
import { useAuth } from '@/providers'
import { DrawerPreviewPurchaseOrder } from '../drawer-preview-purchase-order'
import { checkIfCheckedSaleOrdersIsValid } from './table-generate-purchase-order.constants'

export const TableGeneratePurchaseOrder = () => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    ordering: 'shipments__supplier__name',
  })

  const { whoAmI } = useAuth()

  const { data: dataUserList } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const userFiltered = useMemo(() => {
    if (filter.keyAccountManager && filter.keyAccountManager === 'undefined') {
      return dataUserList?.data?.results?.find(
        (user) => user.email === whoAmI?.email,
      )
    }

    return dataUserList?.data?.results?.find(
      (user) => user.id === +filter?.keyAccountManager,
    )
  }, [dataUserList, filter.keyAccountManager, whoAmI])

  const { data, isLoading } = useOrderCenterSaleOrderList(
    { ...filter, has_supply_order: 'false' },
    true,
  )

  const [totalPages, setTotalPages] = useState(1)

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [checkedSaleOrders, setcheckedSaleOrders] = useState<
    Record<string, SaleOrderProps>
  >({})

  const [
    isOpenDrawerPreviewPurchaseOrder,
    setIsOpenDrawerPreviewPurchaseOrder,
  ] = useState(false)

  const isValidCheckedSaleOrders = useMemo(
    () => checkIfCheckedSaleOrdersIsValid(checkedSaleOrders),
    [checkedSaleOrders],
  )

  const onHandleToggleSaleOrder = (record: SaleOrderProps) => {
    if (checkedSaleOrders[record.uuid]) {
      setcheckedSaleOrders((oldCheckedSaleOrders) => {
        const newCheckedSaleOrders = { ...oldCheckedSaleOrders }
        delete newCheckedSaleOrders[record.uuid]
        return newCheckedSaleOrders
      })
    } else {
      setcheckedSaleOrders({
        ...checkedSaleOrders,
        [record.uuid]: record,
      })
    }
  }

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'keyAccountManager') {
      return userFiltered?.email || ''
    }

    if (filterKey === 'createdAtBefore') {
      return `${t('filterCreatedAtBefore')} ${formatDate(filterString)}`
    }

    if (filterKey === 'createdAtAfter') {
      return `${t('filterCreatedAtAfter')} ${formatDate(filterString)}`
    }

    if (filterKey === 'destination_address') {
      return `${t('filterDestinationAddress')}: ${filterString?.replace(/,/g, ', ')}`
    }

    if (!filterString || typeof filterString === 'number') return ''

    if (filterString?.[0] === ',') {
      return filterString?.slice(1)?.replace(/,/g, ', ')
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) => !['limit', 'page', 'ordering'].includes(item) && !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('filterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  const statuses: Record<string, ReactNode> = {
    created: (
      <Tag variant="warning" model="light">
        {tEnums('sale_order_status_created')}
      </Tag>
    ),
    processing: (
      <Tag variant="warning" model="light">
        {tEnums('sale_order_status_processing')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('sale_order_status_completed')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {tEnums('sale_order_status_fraudulent')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {tEnums('sale_order_status_cancelled')}
      </Tag>
    ),
  }

  const columns: TableColumnsColumnProps<SaleOrderProps>[] = [
    {
      title: t('tableGeneratePurchaseOrderColumnSupplier'),
      key: 'shipments.supplier',
      sortKey: 'shipments__supplier__name',
      render: ({ record }) => (
        <div className={styles['column-supplier']}>
          <Checkbox
            value={!!checkedSaleOrders?.[record.uuid] || false}
            onChange={() => onHandleToggleSaleOrder(record)}
          />
          <Tooltip
            text={record?.shipments?.[0]?.supplier?.name || '-'}
            orientation="top"
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.SUPPLY.SUPPLIERS.SHOW.fullPath(
                record?.shipments?.[0]?.supplier?.uuid || '',
              )}
              className={styles.link}
            >
              {record?.shipments?.[0]?.supplier?.name || '-'}
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('tableGeneratePurchaseOrderColumnBuyerName'),
      key: 'buyer.companyName',
      sortKey: 'buyer_profile__company_name',
      width: '14rem',
      render: ({ record }) => (
        <div>
          <Tooltip
            text={record?.buyer?.companyName || '-'}
            orientation="top"
            className={styles.tooltip}
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyer?.uuid)}
              className={styles.link}
            >
              <div className={styles['company-name']}>
                {record?.buyer?.companyName || '-'}
              </div>
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('tableGeneratePurchaseOrderColumnReference'),
      key: 'reference',
      width: '10rem',
      render: ({ record }) => (
        <Link
          to={`${ROUTES_ADMIN.ORDER_CENTER.ALL.fullPath()}?search=${record.reference}&uuid=${record.uuid}&action=show`}
          target="_blank"
          className={styles.link}
        >
          {record.reference || '-'}
        </Link>
      ),
    },
    {
      title: t('tableGeneratePurchaseOrderColumnPrice'),
      key: 'totalPrice',
      sortKey: 'total_order_price_ordering',
      width: '12rem',
      render: ({ record }) => {
        const currency = record?.payments?.[0]?.currency
        const currencyMultiplier = +(currency === 'USD'
          ? 1
          : record?.payments?.[0]?.dollarExchangeRate || 0)

        return (
          <div>
            {formatCurrency(
              +(record?.totalOrderPrice || 0) * currencyMultiplier,
              'es-MX',
              currency,
            )}
          </div>
        )
      },
    },
    {
      title: t('tableGeneratePurchaseOrderColumnDestinationAddress'),
      key: 'shipment.destinationAddress',
      width: '14rem',
      render: ({ record }) => {
        return (
          <Tooltip
            text={record.shipments?.[0]?.destinationAddress || '-'}
            orientation="top"
            className={styles.tooltip}
          >
            <div className={styles['destination-address']}>
              {record.shipments?.[0]?.destinationAddress || '-'}
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: t('tableGeneratePurchaseOrderColumnExpectedDeliveryAt'),
      key: 'expectedDeliveryAt',
      sortKey: 'shipments__expected_delivery_at',
      width: '12rem',
      render: ({ record }) =>
        formatLongDate(record?.shipments?.[0]?.expectedDeliveryAt),
    },
    {
      title: t('tableGeneratePurchaseOrderColumnStatus'),
      key: 'status',
      width: '10rem',
      render: ({ record }) => statuses[record.status],
    },
  ]

  const sorts = [
    {
      title: t('orderingCreatedAtButton'),
      sortBy: 'created_at',
    },
  ]

  const ExpandedComponent = ({ record }: { record: SaleOrderProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <div>
            <Text>
              <strong>
                {t('tableExpandedComponentGeneratePurchaseOrderTotalOrderCost')}
              </strong>
              {formatCurrency(record?.totalOrderCost, 'es-MX', 'USD')}
            </Text>
          </div>
          <div>
            <Text>
              <strong>
                {t('tableExpandedComponentGeneratePurchaseOrderDeliveryOption')}
              </strong>
              {tEnums(
                `logistic_shipment_delivery_option_${record?.shipments?.[0]?.deliveryOption || 'receive_at_address'}`,
              )}
            </Text>
          </div>
        </div>
        <div></div>
        <div className={styles['expanded-content-products']}>
          <CarouselProducts
            items={record?.items}
            dollarExchangeRate={
              +(record?.payments?.[0]?.dollarExchangeRate || 0)
            }
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(
        Math.ceil(data?.data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <SortBy
            sortBy={filter?.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>
      </div>

      {getContentFilter()}

      <TableColumns
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
        onSortBy={onHandleSortBy}
        sortBy={filter?.ordering}
        expandableComponent={ExpandedComponent}
      />

      {!isLoading && (!data?.data || data?.data?.results?.length === 0) && (
        <EmptyState
          title={t('tableGeneratePurchaseOrderEmptyTitle')}
          description={t('tableGeneratePurchaseOrderEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('tableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <GeneratePurchaseOrderDrawerFilter
        filter={filter}
        onHandleFilter={setFilter}
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter(false)}
      />

      <GeneratePurchaseOrderButton
        isValid={isValidCheckedSaleOrders}
        isOpen={Object.keys(checkedSaleOrders).length > 0}
        onGeneratePreview={() => setIsOpenDrawerPreviewPurchaseOrder(true)}
      />

      <DrawerPreviewPurchaseOrder
        values={checkedSaleOrders}
        setValues={setcheckedSaleOrders}
        isOpen={isOpenDrawerPreviewPurchaseOrder}
        setIsOpen={setIsOpenDrawerPreviewPurchaseOrder}
      />
    </div>
  )
}
