import { useMutation, useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { ProfileProps, ProfileListWithPagesProps, UserProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import {
  PROFILES_URL,
  PROFILE_ADD_USER_URL,
  PROFILE_UPDATE_USER_URL,
  PROFILES_MERGE_URL,
} from './api.urls'

export const useProfileList = ({
  params,
}: {
  params: Record<string, string | number>
}) => {
  const getProfileList = async (): Promise<{
    success: boolean
    message: string
    data?: ProfileListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      if (copyParams.customerLevel === 'ALL') {
        delete copyParams.customerLevel
      }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(PROFILES_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Profiles fetched successfully!',
        data: convertObjectToCamelCase(data) as ProfileListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get profiles!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.PROFILE.module, params],
    queryFn: () => getProfileList(),
  })
}

export const useProfileDetail = (uuid?: string) => {
  const getProfileDetail = async (): Promise<{
    success: boolean
    message: string
    data?: ProfileProps
  }> => {
    try {
      const { data } = await api.get(`${PROFILES_URL}${uuid}/`)

      return {
        success: true,
        message: 'Profile fetched successfully!',
        data: convertObjectToCamelCase(data) as ProfileProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get profile!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.PROFILE.module, uuid],
    queryFn: () => getProfileDetail(),
    enabled: !!uuid,
  })
}

export const useCreateProfile = () => {
  const createProfile = async (
    payload: Partial<ProfileProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: ProfileProps
  }> => {
    try {
      const { data } = await api.post(
        PROFILES_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Profile created successfully!',
        data: convertObjectToCamelCase(data) as ProfileProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create profile!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.PROFILE.module],
    mutationFn: (payload: Partial<ProfileProps>) => createProfile(payload),
  })
}

export const useUpdateProfile = () => {
  const updateProfile = async (
    payload: Partial<ProfileProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: ProfileProps
  }> => {
    try {
      const { data } = await api.patch(
        `${PROFILES_URL}${payload.uuid}/`,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Profile updated successfully!',
        data: convertObjectToCamelCase(data) as ProfileProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update profile!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.PROFILE.module],
    mutationFn: (payload: Partial<ProfileProps>) => updateProfile(payload),
  })
}

export const useCreateUserInProfile = ({ uuid }: { uuid: string }) => {
  const createUserInProfile = async (
    payload: UserProps,
  ): Promise<{
    success: boolean
    message: string
    data?: UserProps
  }> => {
    try {
      const { data } = await api.post(
        PROFILE_ADD_USER_URL(uuid),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'User created successfully!',
        data: convertObjectToCamelCase(data) as UserProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed create user!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.PROFILE_USER.module],
    mutationFn: (payload: UserProps) => createUserInProfile(payload),
  })
}

export const useUpdateUserInProfile = ({ uuid }: { uuid: string }) => {
  const updateUserInProfile = async (
    payload: Partial<UserProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: Partial<UserProps>
  }> => {
    try {
      const { data } = await api.patch(
        PROFILE_UPDATE_USER_URL(uuid),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'User updated successfully!',
        data: convertObjectToCamelCase(data) as Partial<UserProps>,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed update user!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.PROFILE_USER.module, uuid],
    mutationFn: (payload: Partial<UserProps>) => updateUserInProfile(payload),
  })
}

export const useMergeProfiles = () => {
  const mergeProfiles = async (payload: {
    mainProfile: string
    secondaryProfile: string
  }): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      await api.post(PROFILES_MERGE_URL, convertObjectToSnakeCase(payload))

      return {
        success: true,
        message: 'Profiles merged successfully!',
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to merge profiles!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.PROFILE.module],
    mutationFn: (payload: { mainProfile: string; secondaryProfile: string }) =>
      mergeProfiles(payload),
  })
}
