import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PersonAddOutlineIcon,
  Radio,
  TableColumns,
  TableColumnsColumnProps,
  Text,
} from '@mercai/clever'

import { SupplierProductProps } from '@/types'

import { SupplierProductsTableProps } from './supplier-products-table.type'

import styles from './supplier-products-table.module.css'

import { formatCurrency, formatDate, formatNumber } from '@/helpers'
import { EmptyState } from '@/components'

export const SupplierProductsTable = ({
  isLoading,
  data,
  selectedSupplierProduct,
  onSelectedSupplierProduct,
  onClick,
  onOpenSupplierProductDrawer,
}: SupplierProductsTableProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const buttonRef = useRef<HTMLDivElement | null>(null)

  const handleShowNewSupplier = () => {
    onOpenSupplierProductDrawer?.()
  }

  const columns: TableColumnsColumnProps<SupplierProductProps>[] = [
    {
      title: t('catalogProductsTableName'),
      dataKey: 'supplierWarehouse.supplier.name',
      key: 'supplierWarehouse.supplier.name',
      render: ({ value, index, record }) => (
        <div className={styles['table-supplier-column']}>
          <Radio
            value={selectedSupplierProduct === index}
            onChange={() => onSelectedSupplierProduct?.(index)}
          />
          <span>
            <Text className={styles['table-supplier-name']}>
              {value || '-'}
            </Text>
            <Text
              variant="text-small"
              className={styles['table-supplier-email']}
            >
              {record?.supplierWarehouse?.supplier?.email || '-'}
            </Text>
          </span>
        </div>
      ),
      renderHeader: ({ title }) => (
        <div className={styles['table-supplier-header']}>
          <Text className={styles['table-supplier-header-text']}>
            <strong>{title}</strong>
          </Text>
          <div ref={buttonRef} onClick={handleShowNewSupplier}>
            <PersonAddOutlineIcon />
          </div>
        </div>
      ),
    },
    {
      title: t('catalogProductsTableMin'),
      dataKey: 'minimumPurchaseQuantity',
      key: 'minimumPurchaseQuantity',
      width: '12rem',
      render: ({ record }) =>
        record?.minimumPurchaseQuantity
          ? `${formatNumber(record.minimumPurchaseQuantity)} Kg`
          : '-',
    },
    {
      title: t('catalogProductsTablePrice'),
      key: 'priceWithoutIVA',
      dataKey: 'price',
      width: '12rem',
      render: ({ record }) =>
        record?.price ? formatCurrency(record.price, 'es-MX', 'USD') : '-',
    },
    {
      title: t('catalogProductsTableLocation'),
      key: 'supplierWarehouseUuid',
      dataKey: 'supplierWarehouseUuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['warehouse-column']}>
          {record?.supplierWarehouse?.city ? (
            <>
              <img
                src={
                  record?.supplierWarehouse?.country === 'US'
                    ? '/images/flag-us.svg'
                    : '/images/flag-mx.svg'
                }
                alt={'flag'}
              />
              {record?.supplierWarehouse?.city}
            </>
          ) : (
            '-'
          )}
        </div>
      ),
    },
  ]

  const ExpandedComponent = ({ record }: { record: SupplierProductProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('catalogProductsTableQuantity')}</strong>
            {': '}
            {record?.quantity ? formatNumber(record.quantity) : '-'}
            {' Kg'}
          </Text>
          <Text>
            <strong>{t('catalogProductsTableUpdatedAt')}</strong>
            {': '}
            {record?.updatedAt ? formatDate(record.updatedAt) : '-'}
          </Text>
          <Text>
            <strong>{t('catalogProductsTableCountry')}</strong>
            {': '}
            {record?.supplierWarehouse?.country || '-'}
          </Text>
        </div>
        <div>
          <Text>
            <strong>{t('catalogProductsTableInternalCode')}</strong>
            {': '}
            {record?.internalCode || '-'}
          </Text>
          <Text>
            <strong>{t('catalogProductsTableBatchCode')}</strong>
            {': '}
            {record?.batchCode || '-'}
          </Text>
          <Text>
            <strong>{t('catalogProductsTableEntryDate')}</strong>
            {': '}
            {record?.entryDate ? formatDate(record.entryDate) : '-'}
          </Text>
        </div>
      </div>
    )
  }

  return (
    <div className={styles['table-container']} onClick={onClick}>
      <TableColumns
        columns={columns}
        data={data || []}
        selectedRow={selectedSupplierProduct}
        onSelectRow={onSelectedSupplierProduct}
        isLoading={isLoading}
        expandableComponent={ExpandedComponent}
      />

      {!isLoading && data?.length === 0 && (
        <EmptyState
          title={t('supplierProductsTableEmptyTitle')}
          description={t('supplierProductsTableEmptyDescription')}
          buttonText={t('supplierProductsTableEmptyButton')}
          onClickButton={handleShowNewSupplier}
        />
      )}
    </div>
  )
}
