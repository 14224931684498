import { SVGProps } from 'react'

export const BarChartIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 9H4V20H8V9Z" />
      <path d="M20 13H16V20H20V13Z" />
      <path d="M14 4H10V20H14V4Z" />
    </svg>
  )
}
