import { Checkbox, DatePicker, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { getMultiValue, onChangeMultiValue } from './cards.helpers'
import type { DrawerFilterCardProps } from './cards.type'

import { TransactionPaymentMethodEnum, TransactionStatusEnum } from '@/types'

import styles from './cards.module.css'

export const DrawerFilterStatusCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">
        {t('drawerFilterTransactionStatusTitle')}
      </Text>
      {Object.keys(TransactionStatusEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'status', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'status', status)
          }
        >
          {tEnums(`transaction_status_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterPaymentMethodCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">
        {t('drawerFilterTransactionPaymentMethodTitle')}
      </Text>
      {Object.keys(TransactionPaymentMethodEnum)?.map((status) => (
        <Checkbox
          key={status}
          value={getMultiValue(filter, 'payment_method', status)}
          onChange={() =>
            onChangeMultiValue(filter, onChangeFilter, 'payment_method', status)
          }
        >
          {tEnums(`transaction_payment_method_${status}`)}
        </Checkbox>
      ))}
    </div>
  )
}

export const DrawerFilterCreatedAtIntervalCard = ({
  filter,
  onChangeFilter,
}: DrawerFilterCardProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles['content-filter']}>
      <Text variant="title-base">
        {t('drawerFilterTransactionCreatedAtTitle')}
      </Text>

      <div className={styles['content-filter-row']}>
        <DatePicker
          value={filter.created_at_after}
          onChange={(value) =>
            onChangeFilter({
              ...filter,
              created_at_after: value || '',
            })
          }
        />

        <Text>-</Text>

        <DatePicker
          value={filter.created_at_before}
          onChange={(value) =>
            onChangeFilter({
              ...filter,
              created_at_before: value || '',
            })
          }
        />
      </div>
    </div>
  )
}
