import { SVGProps } from 'react'

export const MercaiOpusShortIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="20"
      viewBox="0 0 50 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 6.71687L16.5646 1.29174C16.7931 1.10391 17.0828 1 17.3806 1C18.0803 1 18.6475 1.5555 18.6475 2.24088V17.681C18.6475 18.4084 18.0457 18.9998 17.3011 18.9998H15.6691C14.9265 18.9998 14.3227 18.4104 14.3227 17.681V6.71687H10Z" />
      <path d="M18.6475 9.60374L25.2121 4.27492C25.4406 4.08896 25.7262 3.98898 26.024 3.98898C26.7257 3.98898 27.2949 4.54686 27.2949 5.2347V17.58C27.2949 18.3638 26.6462 18.9997 25.8465 18.9997H24.4186C23.6189 18.9997 22.9702 18.3638 22.9702 17.58V9.60374H18.6475Z" />
      <path d="M35.9052 14.7371V8.26679C35.9052 7.75444 35.6086 7.26811 35.1272 7.07397C34.6763 6.89186 34.1847 6.97791 33.8313 7.26611L27.2949 12.5997H31.599V17.451C31.599 18.3076 32.3038 19 33.1711 19H40L36.5693 16.1481C36.1468 15.7978 35.9052 15.2815 35.9052 14.7371Z" />
    </svg>
  )
}
