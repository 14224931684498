import { useTranslation } from 'react-i18next'

import styles from './table-supplier-order.module.css'
import {
  Button,
  Checkbox,
  CloseIcon,
  EditIcon,
  EyeIcon,
  FilterIcon,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Tag,
  Text,
  Tooltip,
} from '@mercai/clever'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import { CarouselProducts, EmptyState, SortBy } from '@/components'
import {
  formatCurrency,
  formatDate,
  formatLongDate,
  onErrorMessage,
  useParamsState,
} from '@/helpers'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { SaleOrderItemProps, SupplierOrderProps } from '@/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  QUERIES_ADMIN,
  useGenerateSupplierTransaction,
  useSupplierList,
  useSupplierOrderList,
} from '@/services'
import {
  GenerateSupplierOrderButton,
  SupplierOrderDrawerFilter,
  SupplierOrderDrawerForm,
  SupplierOrderDrawerShow,
} from './components'

import {
  checkIfCheckedSupplierOrdersIsValid,
  getBuyerName,
} from './table-supplier-order.constants'
import { useQueryClient } from '@tanstack/react-query'

export const TableSupplierOrder = () => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [checkedSupplierOrders, setcheckedSupplierOrders] = useState<
    Record<string, SupplierOrderProps>
  >({})

  const queryClient = useQueryClient()

  const { mutateAsync, isPending } = useGenerateSupplierTransaction()

  const onGenerateSupplierTransaction = async () => {
    const supplyOrders = Object.keys(checkedSupplierOrders).map(
      (key) => checkedSupplierOrders[key].reference || '',
    )

    const response = await mutateAsync({ supplyOrders })

    if (response.success) {
      setcheckedSupplierOrders({})
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
      })
      window.open(
        `${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.SUPPLIER_TRANSACTION.fullPath()}?search=${response.data?.reference}&uuid=${response.data?.uuid}`,
        '_self',
      )
      return null
    }

    onErrorMessage(
      t('tableSupplierOrderDrawerEditErrorTitle'),
      t('tableSupplierOrderDrawerEditErrorDescription'),
    )
  }

  const onHandleToggleSupplierOrder = (record: SupplierOrderProps) => {
    if (checkedSupplierOrders[record.uuid || '']) {
      setcheckedSupplierOrders((oldCheckedSaleOrders) => {
        const newCheckedSaleOrders = { ...oldCheckedSaleOrders }
        delete newCheckedSaleOrders[record.uuid || '']
        return newCheckedSaleOrders
      })
    } else {
      setcheckedSupplierOrders({
        ...checkedSupplierOrders,
        [record.uuid || '']: record,
      })
    }
  }

  const isValidCheckedSupplierOrders = useMemo(
    () => checkIfCheckedSupplierOrdersIsValid(checkedSupplierOrders),
    [checkedSupplierOrders],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    ordering: '-payment__expected_payment_date',
    has_transaction: 'false',
  })

  const [params, setParams] = useParamsState({
    showUuid: '',
  })

  const { data: suppliersData } = useSupplierList({ params: { limit: 100 } })

  const supplierFiltered = useMemo(() => {
    return suppliersData?.data?.results?.find(
      (supplier) => supplier.email === filter?.supplier__email,
    )
  }, [suppliersData, filter.supplier__email])

  const { data, isLoading } = useSupplierOrderList({ ...filter }, true)

  const [totalPages, setTotalPages] = useState(1)

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [drawerFormUuid, setdrawerFormUuid] = useState('')

  const handleOpenDrawerForm = (uuid: string) => {
    setdrawerFormUuid(uuid)
    setIsOpenDrawerForm(true)
  }

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'showUuid' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleSortBy = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const getFormattedFilter = (filterKey: string | number) => {
    const filterString = filter?.[filterKey]

    if (filterKey === 'has_transaction') {
      return `${t('tableSupplierOrderDrawerFilterHasTransaction')}: ${filterString === 'true' ? t('tableSupplierOrderDrawerFilterHasTransactionYes') : t('tableSupplierOrderDrawerFilterHasTransactionNo')}`
    }

    if (filterKey === 'supplier__email') {
      return `${t('tableSupplierOrderDrawerFilterSupplier')}: ${supplierFiltered?.name || ''}`
    }

    if (filterKey === 'expected_payment_date_before') {
      return `${t('tableSupplierOrderDrawerFilterCreatedAtBefore')} ${formatDate(filterString)}`
    }

    if (filterKey === 'expected_payment_date_after') {
      return `${t('tableSupplierOrderDrawerFilterCreatedAtAfter')} ${formatDate(filterString)}`
    }

    if (filterKey === 'destination_address') {
      return `${t('tableSupplierOrderDrawerFilterDestinationAddress')}: ${filterString?.replace(/,/g, ', ')}`
    }

    return filterString?.replace(/,/g, ', ')
  }

  const getContentFilter = () => {
    const filtersKeys = Object.keys(filter).filter(
      (item) =>
        !['limit', 'page', 'ordering', 'showUuid'].includes(item) &&
        !!filter[item],
    )

    if (filtersKeys && filtersKeys.length === 0) {
      return null
    }

    return (
      <div className={styles['filtered-options']}>
        <Text>{t('filterApplied')}:</Text>

        {filtersKeys?.map((item) => (
          <Button
            onClick={() => handleFilter(item, '')}
            variant="light"
            size="small"
            key={item}
          >
            {getFormattedFilter(item)}

            <CloseIcon />
          </Button>
        ))}
      </div>
    )
  }

  const statuses: Record<string, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('supplier_order_status_pending')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('supplier_order_status_completed')}
      </Tag>
    ),
  }

  const columns: TableColumnsColumnProps<SupplierOrderProps>[] = [
    {
      title: t('tableSupplierOrderColumnSupplier'),
      key: 'shipments.supplier',
      sortKey: 'supplier__name',
      render: ({ record }) => (
        <div className={styles['column-supplier']}>
          <Checkbox
            value={!!checkedSupplierOrders?.[record.uuid || ''] || false}
            onChange={() => onHandleToggleSupplierOrder(record)}
          />
          <Tooltip
            text={record?.supplyQuotation?.supplier?.name || '-'}
            orientation="top"
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.SUPPLY.SUPPLIERS.SHOW.fullPath(
                record?.supplyQuotation?.supplier?.uuid || '',
              )}
              className={styles.link}
            >
              {record?.supplyQuotation?.supplier?.name || '-'}
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('tableSupplierOrderColumnBuyerName'),
      key: 'buyer.companyName',
      width: '14rem',
      render: ({ record }) => (
        <div>
          <Tooltip
            text={getBuyerName(
              record?.supplyQuotation?.supplyQuotationItems || [],
            )}
            orientation="top"
            className={styles.tooltip}
          >
            <div className={styles['company-name']}>
              {getBuyerName(
                record?.supplyQuotation?.supplyQuotationItems || [],
              )}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('tableSupplierOrderColumnPrice'),
      key: 'value',
      width: '12rem',
      sortKey: 'payment__value',
      render: ({ record }) => {
        return (
          <div>
            {record?.payment?.value
              ? formatCurrency(
                  `${record.payment.value}`,
                  'es-MX',
                  record.payment?.currency,
                )
              : '-'}
          </div>
        )
      },
    },
    {
      title: t('tableSupplierOrderColumnReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
    },
    {
      title: t('tableSupplierOrderColumnDestinationAddress'),
      key: 'shipment.destinationAddress',
      width: '14rem',
      render: ({ record }) => {
        return (
          <Tooltip
            text={record?.supplyQuotation?.destinationAddress || '-'}
            orientation="top"
            className={styles.tooltip}
          >
            <div className={styles['destination-address']}>
              {record?.supplyQuotation?.destinationAddress || '-'}
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: t('tableSupplierOrderColumnExpectedPaid'),
      key: 'expectedPaymentDate',
      sortKey: 'payment__expected_payment_date',
      width: '12rem',
      render: ({ record }) =>
        record?.payment?.expectedPaymentDate ? (
          formatLongDate(record?.payment?.expectedPaymentDate)
        ) : (
          <Button
            variant="ghost"
            className={styles['button-expected-paid']}
            size="small"
            onClick={() => handleOpenDrawerForm(record?.uuid || '')}
          >
            {t('tableSupplierOrderColumnExpectedPaidButton')}
          </Button>
        ),
    },
    {
      title: t('tableSupplierOrderColumnStatus'),
      key: 'status',
      sortKey: 'status',
      width: '10rem',
      render: ({ record }) => statuses[record.status],
    },
    {
      title: '',
      key: 'actions',
      width: '6.5rem',
      render: ({ record }) => (
        <div className={styles['column-actions']}>
          <Button
            variant="secondary"
            model="square"
            size="small"
            onClick={() => handleOpenDrawerForm(record?.uuid || '')}
          >
            <EditIcon />
          </Button>
          <Button
            variant="primary"
            model="square"
            size="small"
            onClick={() =>
              setParams({ ...params, showUuid: record?.uuid || '' })
            }
          >
            <EyeIcon />
          </Button>
        </div>
      ),
    },
  ]

  const sorts = [
    {
      title: t('orderingCreatedAtButton'),
      sortBy: 'created_at',
    },
  ]

  const ExpandedComponent = ({ record }: { record: SupplierOrderProps }) => {
    const getSupplierOrders = () => {
      return record?.supplyQuotation?.supplyQuotationItems?.reduce(
        (acc, item) => {
          if (
            item?.saleOrderItem &&
            !acc.find(
              (saleOrder) => saleOrder.order === item.saleOrderItem?.order,
            )
          ) {
            acc.push({
              ...item.saleOrderItem,
              companyName: item.buyer?.companyName,
            })
          }
          return acc
        },
        [] as {
          companyName: string
          uuid: string
          order: string
        }[],
      )
    }

    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>
              {t('tableExpandedComponentSupplierOrderAssociatedOrders')}
            </strong>
          </Text>

          {getSupplierOrders()?.map((item) => (
            <Text key={item?.uuid}>
              <strong>
                {item?.companyName || '-'}
                {' · '}
              </strong>
              <Link
                to={`${ROUTES_ADMIN.ORDER_CENTER.ALL.fullPath()}?search=${item?.order}`}
                target="_blank"
                className={styles.link}
              >
                {item?.order || '-'}
              </Link>
            </Text>
          ))}
        </div>
        <div>
          <Text>
            <strong>
              {t('tableExpandedComponentSupplierOrderObservations')}
            </strong>
          </Text>

          <Text variant="text-small">{record?.observation || '-'}</Text>
        </div>
        <div className={styles['expanded-content-products']}>
          <CarouselProducts
            items={
              (record?.supplyQuotation
                ?.supplyQuotationItems as unknown as SaleOrderItemProps[]) || []
            }
            dollarExchangeRate={
              +(record?.supplyQuotation?.dollarExchangeRate || 0)
            }
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(
        Math.ceil(data?.data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('tableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <SortBy
            sortBy={filter?.ordering}
            sorts={sorts}
            onHandleSortBy={onHandleSortBy}
          />
        </div>
      </div>

      {getContentFilter()}

      <TableColumns
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
        onSortBy={onHandleSortBy}
        sortBy={filter?.ordering}
        expandableComponent={ExpandedComponent}
      />

      {!isLoading && (!data?.data || data?.data?.results?.length === 0) && (
        <EmptyState
          title={t('tableSupplierOrderEmptyTitle')}
          description={t('tableSupplierOrderEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('tableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <SupplierOrderDrawerFilter
        filter={filter}
        onHandleFilter={setFilter}
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter(false)}
        suppliersData={suppliersData?.data?.results || []}
      />

      <SupplierOrderDrawerForm
        isOpen={isOpenDrawerForm}
        onHandleOpen={() => setIsOpenDrawerForm(false)}
        uuid={drawerFormUuid}
      />

      <SupplierOrderDrawerShow
        uuid={params.showUuid}
        onHandleOpen={() => setParams({ ...params, showUuid: '' })}
      />

      <GenerateSupplierOrderButton
        isValid={isValidCheckedSupplierOrders}
        isOpen={Object.keys(checkedSupplierOrders).length > 0}
        onGenerateSupplierTransaction={onGenerateSupplierTransaction}
        isLoading={isPending}
      />
    </div>
  )
}
