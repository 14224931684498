import {
  OrderCenterExpandedRowExpectedDeliveryDate,
  OrderCenterExpandedRowOrigin,
  OrderCenterExpandedRowAmount,
  OrderCenterExpandedRowDestination,
  OrderCenterExpandedRowKam,
  OrderCenterExpandedRowPaymentCondition,
  OrderCenterExpandedRowPaymentMethod,
  OrderCenterExpandedRowSubtotal,
  OrderCenterExpandedRowTaxPrice,
  OrderCenterExpandedRowTotalFreight,
  OrderCenterExpandedRowTransportPrice,
  OrderCenterExpandedRowDeliveryAt,
  OrderCenterExpandedRowInvoiceId,
} from './components'

import { expandedColumns } from './expanded-column.constants'
import { OrderCenterExpandedColumnProps } from './expanded-column.type'

import styles from './expanded-column.module.css'
import { CarouselProducts } from '@/components'

export const OrderCenterExpandedColumn = ({
  record,
  type,
}: OrderCenterExpandedColumnProps) => {
  const allColumns = {
    'expected-delivery-date': (
      <OrderCenterExpandedRowExpectedDeliveryDate record={record} />
    ),
    origin: <OrderCenterExpandedRowOrigin record={record} />,
    destination: <OrderCenterExpandedRowDestination record={record} />,
    'total-freight-price': (
      <OrderCenterExpandedRowTotalFreight record={record} />
    ),
    amount: <OrderCenterExpandedRowAmount record={record} />,
    'key-account-manager': <OrderCenterExpandedRowKam record={record} />,
    'payment-method': <OrderCenterExpandedRowPaymentMethod record={record} />,
    'payment-condition': (
      <OrderCenterExpandedRowPaymentCondition record={record} />
    ),
    'total-product-price': <OrderCenterExpandedRowSubtotal record={record} />,
    'tax-price': <OrderCenterExpandedRowTaxPrice record={record} />,
    'transport-price': <OrderCenterExpandedRowTransportPrice record={record} />,
    'delivery-at': <OrderCenterExpandedRowDeliveryAt record={record} />,
    'invoice-id': <OrderCenterExpandedRowInvoiceId record={record} />,
  }

  const selectedRows = expandedColumns?.[type] || []

  const contents = selectedRows.map(
    (column) => allColumns?.[column as keyof typeof allColumns] || null,
  )

  return (
    <div className={styles.core}>
      <div className={styles.content}>{contents}</div>

      <div className={styles.content}>
        <CarouselProducts
          items={record?.items}
          dollarExchangeRate={+(record?.payments?.[0]?.dollarExchangeRate || 0)}
        />
      </div>
    </div>
  )
}
