import { Button, GeneratingFileLottie, Modal } from '@mercai/clever'
import { ModalGeneratePurchaseOrderProps } from './modal-generate-purchase-order.types'

import styles from './modal-generate-purchase-order.module.css'
import { useTranslation } from 'react-i18next'
import { useGeneratePurchaseOrderPDFPreview } from '@/services'
import { useEffect, useState } from 'react'
import { useLottie } from 'lottie-react'

export const ModalGeneratePurchaseOrder = ({
  isOpen,
  setIsOpen,
  values,
  onSubmit,
  isLoading: submitIsLoading,
  fileId,
}: ModalGeneratePurchaseOrderProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  const { data, isLoading } = useGeneratePurchaseOrderPDFPreview(
    {
      saleOrders: Object.values(values).map(
        (saleOrder) => saleOrder?.reference,
      ),
      fileId,
    },
    isOpen,
  )

  const { View: LoadingView } = useLottie({
    animationData: GeneratingFileLottie,
    loop: true,
    autoplay: true,
    hidden: !isLoading,
  })

  useEffect(() => {
    if (data?.data) {
      const url = window.URL.createObjectURL(data.data)
      setPdfUrl(url)
    }

    return () => {
      window.URL.revokeObjectURL(pdfUrl || '')
      setPdfUrl(null)
    }
  }, [data])

  return (
    <Modal.Root
      isOpen={isOpen}
      onHandleOpen={setIsOpen}
      mainClassName={styles.modal}
    >
      <Modal.Header
        title={t('drawerPreviewPurchaseOrderModalTitle')}
        description={t('drawerPreviewPurchaseOrderModalDescription')}
      />
      <div className={styles.content}>
        <div>{LoadingView}</div>
        {!isLoading && <iframe src={pdfUrl || ''} />}
      </div>

      <div className={styles.footer}>
        <div>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            {t('drawerPreviewPurchaseOrderModalCancel')}
          </Button>
        </div>
        <Button
          onClick={() => onSubmit(false)}
          disabled={submitIsLoading}
          isActive={!submitIsLoading}
        >
          {t('drawerPreviewPurchaseOrderModalConfirm')}
        </Button>
        <Button
          onClick={() => onSubmit(true)}
          disabled={submitIsLoading}
          isActive={!submitIsLoading}
        >
          {t('drawerPreviewPurchaseOrderModalConfirmAndSend')}
        </Button>
      </div>
    </Modal.Root>
  )
}
