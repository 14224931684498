import { SaleOrderProps } from '@/types'
import { SupplierOrderVisualizationProps } from '../supplier-order-visualization/supplier-order-visualization.types'

interface ItemsProps {
  uuid: string
  catalogProduct: {
    attributes: {
      title: string
    }
  }
  quantity: number
  cost: string
  total: string
}

export const getSupplierOrderVisualizationProps = (
  saleOrders: SaleOrderProps[],
  total: number,
  currency: 'MXN' | 'USD',
): SupplierOrderVisualizationProps => {
  return {
    total,
    currency,
    items: saleOrders.map((saleOrder) => {
      return {
        buyerCompanyName: saleOrder.buyer?.companyName || '-',
        saleOrderReference: saleOrder.reference || '-',
        products: (saleOrder?.items as unknown as ItemsProps[])?.map(
          (product) => {
            return {
              productTitle: product?.catalogProduct?.attributes?.title || '-',
              productCost: +product?.cost,
              productQuantity: product?.quantity,
              productTotal: +product?.total,
            }
          },
        ),
      }
    }),
  }
}
