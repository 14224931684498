export const metricsByTabs: Record<string, string[]> = {
  all: [
    'sla-rfq-pending-to-approved-for-quotation',
    'sla-rfq-approved-for-quotation-to-processed',
    'sla-rfq-created-to-processed',
  ],
  'pending-acquisition': [
    'sla-rfq-pending-to-approved-for-quotation',
    'conversion-rfq-pending-to-approved-for-quotation',
    'number-of-customers-acquired-in-month',
  ],
  'pending-supply': [
    'sla-rfq-qualified-to-processed',
    'conversion-rfq-qualified-to-processed',
  ],
  'automating-rfqs': [
    'sla-rfq-created-to-automatic-processed',
    'conversion-rfq-qualified-to-automatic-processed',
  ],
  invalid: [],
  expired: [],
}
