import { Button, CloseIcon, Text } from '@mercai/clever'

import { AppliedFiltersProps } from './applied-filters.type'

import styles from './applied-filters.module.css'

export const AppliedFilters = ({
  filtersApplied,
  excludeKeys,
  translations,
  onRemoveFilter,
  keyTranslations,
  renderValue,
}: AppliedFiltersProps) => {
  const filteredArray = Object.keys(filtersApplied).reduce<
    Record<string, string>
  >((acc, key) => {
    if (!excludeKeys?.includes(key) && filtersApplied[key]) {
      acc[key] = filtersApplied[key]
    }

    return acc
  }, {})

  const getTranslatedValue = (key: string, value: string): string => {
    const splitValue = value.split(',')

    if (splitValue.length > 1) {
      return splitValue
        .map((value) => {
          return renderValue?.(key, value) || value
        })
        .join(', ')
    }

    return renderValue?.(key, value) || value
  }

  if (Object.keys(filteredArray).length === 0) {
    return null
  }

  return (
    <div className={styles.core}>
      <Text>{translations?.filterAppliedText}</Text>

      {Object.keys(filteredArray).map((key) => {
        const value = filteredArray[key]
        const translatedValue = getTranslatedValue(key, value)

        return (
          <Button
            onClick={() => onRemoveFilter?.(key)}
            variant="light"
            size="small"
            key={value}
          >
            {keyTranslations?.[key] || key}: {translatedValue}
            <CloseIcon />
          </Button>
        )
      })}
    </div>
  )
}
