import { TrashIcon, Text, FilterIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  checkArrowDirection,
  generateClassesBusinessMetricCard,
  generateClassesChangeBusinessMetricCard,
} from './business-metric-card.constants'

import styles from './business-metric-card.module.css'

import {
  BusinessMetricCardProps,
  BusinessMetricCardsProps,
} from './business-metric-card.type'

export const BusinessMetricCard = ({
  value,
  change,
  changeSuffix = '',
  label,
  isActiveFilter,
  onClickFilter,
  isLoading,
  positiveIsBad = false,
}: BusinessMetricCardProps) => {
  const { t } = useTranslation('componentsBusinessMetricCard')

  return (
    <li className={generateClassesBusinessMetricCard(isActiveFilter)}>
      <Text className={styles['metrics-item-texts']}>{label}</Text>

      <div className={styles['metrics-item-row']}>
        <Text variant="title-large">
          {!isLoading ? value : <Skeleton height="2rem" width="4rem" />}
        </Text>

        {(change || change === 0) && (
          <Text
            variant="title-small"
            className={generateClassesChangeBusinessMetricCard(
              positiveIsBad ? change <= 0 : change >= 0,
            )}
          >
            {!isLoading ? (
              <>
                ( {checkArrowDirection(positiveIsBad, change) ? '↑' : '↓'}{' '}
                {change}
                {changeSuffix} )
              </>
            ) : (
              <Skeleton height="1.2rem" width="2rem" />
            )}
          </Text>
        )}
      </div>

      {onClickFilter && (
        <button
          onClick={() => onClickFilter()}
          className={styles['metrics-item-button']}
        >
          <Text variant="text-small" className={styles['metrics-item-text']}>
            {isActiveFilter ? t('filterTabRemove') : t('filterTabApply')}
          </Text>

          {isActiveFilter ? <TrashIcon /> : <FilterIcon />}
        </button>
      )}
    </li>
  )
}

export const BusinessMetricCards = ({ metrics }: BusinessMetricCardsProps) => {
  return (
    <ul className={styles.metrics}>
      {metrics.map((metric, i) => (
        <BusinessMetricCard {...metric} key={i + '-metric'} />
      ))}
    </ul>
  )
}
