import { useMutation } from '@tanstack/react-query'

import { IAMessageProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { AI_MESSAGES_URL, AI_WHATSAPP_MESSAGES_URL } from './api.urls'

export const useAICreateMessage = () => {
  const createMessage = async (
    payload: Partial<IAMessageProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: IAMessageProps
  }> => {
    try {
      const { data } = await api.post(AI_MESSAGES_URL, payload)

      return {
        success: true,
        message: 'Message created successfully!',
        data: data as IAMessageProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create message!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_MESSAGE.module],
    mutationFn: (payload: Partial<IAMessageProps>) => createMessage(payload),
  })
}

export const useAICreateWhatsappMessage = () => {
  const createMessage = async (
    payload: Partial<IAMessageProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: IAMessageProps
  }> => {
    try {
      const { data } = await api.post(AI_WHATSAPP_MESSAGES_URL, payload)

      return {
        success: true,
        message: 'Message whatsapp created successfully!',
        data: data as IAMessageProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create whatsapp message!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_MESSAGE.module],
    mutationFn: (payload: Partial<IAMessageProps>) => createMessage(payload),
  })
}

export const useAIRemoveMessage = () => {
  const removeMessage = async (id: string) => {
    try {
      const { data } = await api.delete(`${AI_MESSAGES_URL}/${id}`)

      return {
        success: true,
        message: 'Message removed successfully!',
        data: data as IAMessageProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to remove message!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_MESSAGE.module],
    mutationFn: (id: string) => removeMessage(id),
  })
}
