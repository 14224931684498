import { useEffect, useState } from 'react'
import styles from './circle-rating.module.css'
import { getVariantClassess } from './circle-rating.constants'
import { CircleRatingProps } from './circle-rating.type'

export const CircleRating = ({
  rating,
  maxRating = 5,
  className,
}: CircleRatingProps) => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    setProgress(rating)
  }, [rating])

  const radius = 20
  const strokeWidth = 6
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (progress / maxRating) * circumference

  const variantClass = getVariantClassess(rating)

  return (
    <div className={`${styles.core} ${className || ''}`}>
      <div className={styles['circle-wrapper']}>
        <svg viewBox="0 0 50 50">
          <circle
            className={styles.background}
            cx="25"
            cy="25"
            r={radius}
            strokeWidth={strokeWidth}
          />
          <circle
            className={variantClass}
            cx="25"
            cy="25"
            r={radius}
            strokeWidth={strokeWidth}
            transform="rotate(-90 25 25)"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
        </svg>
        <span className={styles.label}>{rating}</span>
      </div>
    </div>
  )
}
