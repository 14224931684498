import { useTranslation } from 'react-i18next'
import { Button, CloseIcon, Drawer, Text } from '@mercai/clever'

import { SupplierOrderVisualization } from '../../../supplier-order-visualization'

import { SupplierOrderDrawerShowProps } from './drawer-show.types'

import styles from './drawer-show.module.css'
import { getSupplierOrderVisualizationProps } from './drawer-show.constants'
import { useSupplierOrderDetail } from '@/services'

export const SupplierOrderDrawerShow = ({
  uuid,
  onHandleOpen,
}: SupplierOrderDrawerShowProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const { data } = useSupplierOrderDetail(uuid)

  return (
    <Drawer isOpen={!!uuid} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('tableSupplierOrderDrawerShowTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <SupplierOrderVisualization
          {...getSupplierOrderVisualizationProps(data?.data)}
        />
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('tableSupplierOrderDrawerShowCancelButton')}
        </Button>
      </div>
    </Drawer>
  )
}
