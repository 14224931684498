import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  TableColumns,
  Text,
  Select,
  Skeleton,
  Pagination,
  TableColumnsColumnProps,
  EditIcon,
  ArrowRightIcon,
  InputSearch,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { Header, Tabs } from '@/components'
import { useParamsState, trackEvent } from '@/helpers'
import { useTransportCompanyList } from '@/services'
import { TransportCompanyProps } from '@/types'

import {
  TransportCompanyDrawerForm,
  TransportCompanyRoutesTable,
} from '../components'

import styles from './list.module.css'
import { useNavigate } from 'react-router-dom'

export const SupplyTransportCompaniesList = () => {
  const { t } = useTranslation('screenTransportCompanies')

  const [totalPages, setTotalPages] = useState(1)

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    logistic_route__uuid: 'all',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
    tab: '',
  })

  const navigate = useNavigate()

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useTransportCompanyList(
    { params: filter },
    !queryParams?.tab || queryParams.tab === 'suppliers',
  )

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminTransportCompaniesListPageView')
  }, [])

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const onHandleView = (uuid: string) => {
    navigate(uuid)
  }

  const onHandleTab = (tab: string) => {
    setFilter({
      ...filter,
      page: '1',
      limit: `${DEFAULT_LIMIT_PAGE}`,
      logistic_route__uuid: 'all',
    })
    setQueryParams({ ...queryParams, ...filter, tab })
  }

  const columns: TableColumnsColumnProps<TransportCompanyProps>[] = [
    {
      title: t('transportCompaniesTableName'),
      key: 'name',
      dataKey: 'name',
    },
    {
      title: t('transportCompaniesTablePhone'),
      key: 'phone',
      dataKey: 'phone',
      width: '12rem',
    },
    {
      title: t('transportCompaniesTableEmail'),
      key: 'email',
      dataKey: 'email',
      width: '21rem',
    },
    {
      title: t('transportCompaniesTableRoute'),
      key: 'routes',
      dataKey: 'transportCompanyRoutes',
      width: '10rem',
      render: ({ record }) => record.transportCompanyRoutes?.length || 0,
    },
    {
      title: t('transportCompaniesTableActions'),
      key: 'uuid',
      dataKey: 'uuid',
      width: '8rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            variant="secondary"
            onClick={() => onHandleEditRegister(record.uuid)}
            model="square"
          >
            <EditIcon />
          </Button>
          <Button
            variant="primary"
            onClick={() => onHandleView(record.uuid)}
            model="square"
          >
            <ArrowRightIcon />
          </Button>
        </div>
      ),
      renderLoading: (
        <div className={styles['action-buttons']}>
          <Skeleton height="2.8rem" width="2.8rem" />
          <Skeleton height="2.8rem" width="2.8rem" />
        </div>
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <section className={styles['header-section']}>
        <Header
          title={t('title')}
          description={t('description')}
          className={styles.header}
        />
        <Button onClick={onHandleNewRegister}>
          {t('transportCompaniesTableNewRegister')}
        </Button>
      </section>

      <div className={styles.content}>
        <Tabs
          items={[
            {
              title: t('transportCompaniesTableTabSuppliers'),
              isActive: queryParams.tab === 'suppliers' || !queryParams.tab,
              onSelect: () => onHandleTab('suppliers'),
            },
            {
              title: t('transportCompaniesTableTabRoutes'),
              isActive: queryParams.tab === 'routes',
              onSelect: () => onHandleTab('routes'),
            },
          ]}
        />
        {queryParams.tab === 'suppliers' || !queryParams.tab ? (
          <div>
            <div className={styles.filter}>
              <div className={styles['filter-group']}>
                <InputSearch
                  value={filter.search as string}
                  onChange={(value) => handleFilter('search', value)}
                  className={styles['search-input']}
                  translations={{
                    inputPlaceholder: t('searchFilterPlaceholder'),
                  }}
                />
              </div>
            </div>

            <TableColumns<TransportCompanyProps>
              isLoading={isLoading}
              columns={columns}
              classNames={{
                expandedContent: styles['expanded-content'],
              }}
              data={data?.data?.results || []}
            />

            <div className={styles.pagination}>
              <div className={styles['pagination-show-registers']}>
                <Text>
                  {t('tableShowRegisters', {
                    showNumber:
                      (data?.data?.count || 0) < +filter.limit
                        ? data?.data?.count
                        : +filter.limit,
                    totalNumber: data?.data?.count || 0,
                  })}
                </Text>

                <Select
                  value={filter.limit}
                  onChange={(value) => handleFilter('limit', value)}
                  options={[
                    { label: '10', value: '10' },
                    { label: '20', value: '20' },
                    { label: '50', value: '50' },
                    { label: '100', value: '100' },
                  ]}
                />
              </div>

              <Pagination
                totalPages={totalPages}
                onChange={(page) => handleFilter('page', `${page}`)}
                currentPage={+filter.page as number}
              />
            </div>
          </div>
        ) : (
          <div>
            <TransportCompanyRoutesTable uuid="" inDetail={false} />
          </div>
        )}
      </div>

      <TransportCompanyDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        transportCompany={data?.data?.results?.find(
          ({ uuid }) => uuid === queryParams.uuid,
        )}
      />
    </div>
  )
}
