import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input, Label, Modal, Select } from '@mercai/clever'
import { onErrorMessage } from '@/helpers'
import {
  CalculateMarginPayloadProps,
  QuotationProps,
  QuotationStatusEnum,
} from '@/types'
import { QUERIES_ADMIN, useUpdateQuotation } from '@/services'
import { useQueryClient } from '@tanstack/react-query'

import { PendingApprovalModalProps } from './pending-approval-modal.types'

import styles from './pending-approval-modal.module.css'
import { QuotationMetrics } from '@/pages/business/components'
import { getQuotationMetricsPayload } from '@/pages/business/helpers'

export const PendingApprovalModal = ({
  quotation,
  isOpen,
  onClose,
}: PendingApprovalModalProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  const [value, setValue] =
    useState<keyof typeof QuotationStatusEnum>('accepted')

  const [invalidateReason, setInvalidateReason] = useState<string>('')

  const [invalidateReasonShowError, setInvalidateReasonShowError] =
    useState(false)

  const queryClient = useQueryClient()

  const { mutateAsync: mutateAsyncUpdateQuotation, isPending } =
    useUpdateQuotation(quotation?.uuid || '')

  const onSubmit = async (val: keyof typeof QuotationStatusEnum) => {
    if (val === 'rejected' && !invalidateReason) {
      return setInvalidateReasonShowError(true)
    }

    const response = await mutateAsyncUpdateQuotation({
      ...quotation,
      status: val,
      logisticTransportCompanyUuid: quotation?.logisticTransportCompany?.uuid,
      buyerProfileUuid: quotation?.buyerProfile?.uuid,
      products:
        quotation?.products?.map((product) => ({
          ...product,
          catalogProductUuid: product.catalogProduct?.uuid,
          supplierUuid: product.supplier?.uuid,
        })) || [],
      ...(invalidateReason ? { rejectedReason: invalidateReason } : {}),
    })

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION.module],
      })
    } else {
      onErrorMessage(
        t('pendingApprovalModalErrorTitle'),
        t('pendingApprovalModalErrorMessage'),
      )
    }

    onClose()
  }

  const dataForMetrics = useMemo(() => {
    if (quotation) {
      return getQuotationMetricsPayload({
        quotationValues: quotation as QuotationProps,
      })
    }
    return {}
  }, [quotation])

  return (
    <Modal.Root isOpen={isOpen} onHandleOpen={onClose} size="small">
      <Modal.Header
        title={t('pendingApprovalModalTitle')}
        description={t('pendingApprovalModalDescription')}
      />
      <Modal.Body className={styles['modal-body']}>
        <div>
          <QuotationMetrics
            values={dataForMetrics as CalculateMarginPayloadProps}
          />

          <Label className={styles.label}>
            {t('pendingApprovalModalStatusLabel')}
            <span>*</span>
          </Label>
          <Select
            value={value}
            onChange={(value) =>
              setValue(value as keyof typeof QuotationStatusEnum)
            }
            options={[
              { value: 'active', label: tEnums('quotation_status_active') },
              {
                value: 'rejected',
                label: tEnums('quotation_status_rejected'),
              },
            ]}
          />
        </div>
        {value === 'rejected' && (
          <div>
            <Label className={styles.label}>
              {t('pendingApprovalModalReasonLabel')}
              <span>*</span>
            </Label>
            <Input
              placeholder={t('pendingApprovalModalReasonPlaceholder')}
              value={invalidateReason}
              onChange={(value) => setInvalidateReason(value)}
              error={invalidateReasonShowError ? t('requiredMessage') : ''}
              isTextArea
            />
          </div>
        )}
        <div className={styles['modal-footer']}>
          <Button variant="secondary" onClick={onClose}>
            {t('pendingApprovalModalCancelButton')}
          </Button>
          <Button
            variant="primary"
            onClick={() => onSubmit(value)}
            disabled={isPending}
          >
            {t('pendingApprovalModalConfirmButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}
