import type { ProfileProps } from './profiles.type'
import type { CatalogProductProps } from './catalog-product.type'

import type { OrderPaymentProps, OrderInvoiceProps } from './order-finance.type'
import type { LogisticShipmentProps } from './logistic-shipment.type'
import type { TransactionProps } from './finance-transaction.type'

export enum SaleOrderStatusEnum {
  'created' = 'created',
  'processing' = 'processing',
  'completed' = 'completed',
  'cancelled' = 'cancelled',
  'fraudulent' = 'fraudulent',
}

export enum SaleOrderItemPackageEnum {
  '25kg_bags' = '25kg_bags',
  'super_sacks' = 'super_sacks',
  'in_bulk' = 'in_bulk',
  'gaylord_boxes' = 'gaylord_boxes',
  'rolls' = 'rolls',
}

export interface SaleOrderListItemProps {
  uuid: string
  orderItemUuid: string
  nonCatalogProduct: Record<string, string>
  catalogProduct?: CatalogProductProps
  quantity: number
  price: number
  cost?: number
  traderCommission?: number
  package: keyof typeof SaleOrderItemPackageEnum

  orderId: string
}

export interface SaleOrderListProps {
  uuid: string
  reference: string
  orderReference: string
  buyer: ProfileProps
  keyAccountManager: string
  status: keyof typeof SaleOrderStatusEnum
  totalOrderPrice: string
  totalOrderCost: string
  totalFreightPrice: string
  items: SaleOrderListItemProps[]
  invoices: OrderInvoiceProps[]
  payments: OrderPaymentProps[]
  shipments: LogisticShipmentProps[]
  createdAt: string
}

export interface SaleOrderFinanceProps extends SaleOrderListProps {
  invoices: OrderInvoiceProps[]
  payments: OrderPaymentProps[]
  transactions: TransactionProps[]
  totalPaidValue: string
}

export interface SaleOrderFinanceWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: SaleOrderFinanceProps[]
}

export interface SaleOrderLogisticProps extends SaleOrderListProps {
  shipments: LogisticShipmentProps[]
}

export interface SaleOrderLogisticWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: SaleOrderLogisticProps[]
}

export interface SaleOrderItemNonCatalogProductProps {
  description: string
  link: string
}

export interface SaleOrderItemProps {
  uuid: string
  orderItemUuid: string
  catalogProduct: CatalogProductProps
  catalogProductUuid: string
  nonCatalogProduct: SaleOrderItemNonCatalogProductProps
  quantity: number
  price: string
  cost: number
  traderCommission?: number
  package: keyof typeof SaleOrderItemPackageEnum
  order: string
  createdAt: string
}

export interface SaleOrderQuotationProps {
  uuid: string
  reference: string
  creatorUser: string
  createdAt: string
}

interface ProfileLocalProps extends Omit<ProfileProps, 'customerLevel'> {
  customerLevel: string
}

export interface SaleOrderKeyAccountManagerProps {
  id: string
  name: string
}

export enum SaleOrderPaymentCalculatedStatusEnum {
  'paid' = 'paid',
  'partially_paid' = 'partially_paid',
  'cancelled' = 'cancelled',
  'invalid' = 'invalid',
  'expired' = 'expired',
  'processing' = 'processing',
}

export interface SaleOrderProps {
  uuid: string
  reference: string
  orderReference: string
  orderUuid: string
  buyer: ProfileLocalProps
  buyerUuid: string
  keyAccountManagerId: string
  keyAccountManager: SaleOrderKeyAccountManagerProps
  items: SaleOrderItemProps[]
  totalProductPrice: string
  totalProductCost: string
  totalFreightPrice: string
  totalFreightCost: string
  taxPrice: string
  totalOrderPrice: string
  totalPaidValue: string
  dollarExchangeRate: string
  totalOrderCost: string
  financingCost: string
  discount: string
  includeIva: boolean
  status: keyof typeof SaleOrderStatusEnum
  statusHistory: Record<string, string>
  statusReason?: string
  quotation: SaleOrderQuotationProps
  invoices: OrderInvoiceProps[]
  payments: OrderPaymentProps[]
  shipments: LogisticShipmentProps[]
  expiresAt: string
  updatedAt: string
  createdAt: string
  purchaseOnCredit: boolean
  transactions: TransactionProps[]
  paymentsStatus: keyof typeof SaleOrderPaymentCalculatedStatusEnum
  warehouseCost: string | null
  deduction: string
}

export interface SaleOrderWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: SaleOrderProps[]
}
