import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, EditIcon, PinIcon, ProfileIcon, Text } from '@mercai/clever'

import { CreateGeneralInformationProps } from './create-general-information.types'

import styles from './create-general-information.module.css'
import { Accordion } from '@/pages/business/components'
import { DrawerBuyerProfile } from '../drawer-buyer-profile'
import { AddressProps, ProfileProps } from '@/types'
import { DrawerAddress } from '../drawer-address'

export const CreateGeneralInformation = ({
  errors,
  setValues,
  address,
  buyerProfile,
  activeItems,
  setActiveItems,
  isComplete,
}: CreateGeneralInformationProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  const [isOpenDrawerBuyerProfile, setIsOpenDrawerBuyerProfile] =
    useState(false)
  const [isOpenDrawerAddress, setIsOpenDrawerAddress] = useState(false)

  const setBuyerProfile = (data?: ProfileProps) => {
    setValues((prev) => ({
      ...prev,
      buyerProfile: data,
      buyerProfileUuid: data?.uuid,
      ...(prev.buyerProfileUuid !== data?.uuid && {
        address: undefined,
        addressUuid: undefined,
      }),
    }))
  }

  const setAddress = (data?: AddressProps) => {
    setValues((prev) => ({
      ...prev,
      address: data,
      addressUuid: data?.uuid,
    }))
  }

  return (
    <Accordion
      activeItems={activeItems}
      setActiveItems={setActiveItems}
      title={`1. ${t('sectionGeneralInformationTitle')}`}
      id="general-information"
      key="general-information"
      isComplete={isComplete}
    >
      <div>
        <div className={styles['info-block']}>
          <div className={styles.title}>
            <ProfileIcon className={styles.icon} />
            <Text variant="title-medium">{t('buyerLabel')}</Text>
          </div>
          <div className={styles['info-row']}>
            <div className={styles['info-row-content']}>
              <Text className={styles['info-item']}>
                <strong>{t('companyLabel')}:</strong>{' '}
                {buyerProfile?.companyName || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('emailLabel')}:</strong> {buyerProfile?.email || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('customerLevelLabel')}:</strong>{' '}
                {buyerProfile?.customerLevel?.slug
                  ? tEnums(
                      `profile_customer_${buyerProfile?.customerLevel?.slug.toLocaleLowerCase()}`,
                    )
                  : '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('phoneLabel')}:</strong> {buyerProfile?.phone || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('originLabel')}:</strong>{' '}
                {buyerProfile?.type
                  ? tEnums(`profile_type_${buyerProfile?.type}`)
                  : '-'}
              </Text>
            </div>
            {buyerProfile ? (
              <Button
                variant="secondary"
                model="square"
                size="small"
                onClick={() => setIsOpenDrawerBuyerProfile(true)}
              >
                <EditIcon />
              </Button>
            ) : (
              <Button
                variant="success-light"
                onClick={() => setIsOpenDrawerBuyerProfile(true)}
              >
                {t('buyerAddLabel')}
              </Button>
            )}
          </div>
          {!!errors?.buyerProfileUuid && (
            <Text variant="text-base" className={styles.error}>
              {errors.buyerProfileUuid as string}
            </Text>
          )}
        </div>

        <div
          className={`${styles['info-block']} ${!buyerProfile ? styles.disabled : ''}`}
        >
          <div className={styles.title}>
            <PinIcon className={styles.icon} />
            <Text variant="title-medium">{t('deliveryLabel')}</Text>
          </div>
          <div className={styles['info-row']}>
            <div className={styles['info-row-content']}>
              <Text className={styles['info-item']}>
                <strong>{t('postalCodeLabel')}:</strong>{' '}
                {address?.postalCode || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('countryLabel')}:</strong> {address?.country || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('stateLabel')}:</strong> {address?.state || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('cityLabel')}:</strong> {address?.city || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('neighborhoodLabel')}:</strong>{' '}
                {address?.neighborhood || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('streetLabel')}:</strong> {address?.street || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('referencePointLabel')}:</strong>{' '}
                {address?.reference || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('needsUnloadingSupportLabel')}:</strong>{' '}
                {address?.needsUnloadingSupport ? t('yesLabel') : t('noLabel')}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('restrictedVehiclesLabel')}:</strong>{' '}
                {address?.restrictedVehicles &&
                address?.restrictedVehicles?.length > 0
                  ? address?.restrictedVehicles?.map((vehicle, i) => {
                      return `${i > 0 ? ',' : ''} ${tEnums(
                        `logistic_shipment_type_${vehicle}`,
                      )}`
                    })
                  : '-'}
              </Text>
            </div>
            {address ? (
              <Button
                variant="secondary"
                model="square"
                size="small"
                onClick={() => setIsOpenDrawerAddress(true)}
              >
                <EditIcon />
              </Button>
            ) : (
              <Button
                variant="success-light"
                onClick={() => setIsOpenDrawerAddress(true)}
              >
                {t('deliveryAddLabel')}
              </Button>
            )}
          </div>
          {!!errors?.addressUuid && (
            <Text variant="text-base" className={styles.error}>
              {errors.addressUuid as string}
            </Text>
          )}
        </div>

        <DrawerBuyerProfile
          isOpen={isOpenDrawerBuyerProfile}
          onHandleOpen={() => setIsOpenDrawerBuyerProfile(false)}
          buyerProfile={buyerProfile}
          setBuyerProfile={
            setBuyerProfile as (data?: Partial<ProfileProps>) => void
          }
        />

        <DrawerAddress
          isOpen={isOpenDrawerAddress}
          onHandleOpen={() => setIsOpenDrawerAddress(false)}
          address={address}
          setAddress={setAddress as (data?: Partial<AddressProps>) => void}
          buyerProfileUuid={buyerProfile?.uuid || ''}
        />
      </div>
    </Accordion>
  )
}
