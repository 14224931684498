import { useState } from 'react'
import {
  Button,
  Checkbox,
  CloseIcon,
  Drawer,
  InputMask,
  Radio,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { parseDateTimeToISO } from '@/helpers'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

import { ProfileTypeEnum, ProfileTrafficTypeEnum } from '@/types'

export const ProfileDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenProfileList')
  const { t: tEnums } = useTranslation('enums')

  const [createdAtBefore, setCreatedAtBefore] = useState('')
  const [createdAtAfter, setCreatedAtAfter] = useState('')

  const getBooleanOfMultiValue = (key: string, value: string): boolean => {
    const values = (filter[key] as string)?.split(',') || []
    return values.includes(value)
  }

  const onChangeBooleanOfMultiValue = (key: string, value: string) => {
    let values = (filter[key] as string)?.split(',') || []
    values = values.filter((item) => !!item)

    let newValues = ''

    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value).join(',')
    } else {
      newValues = [...values, value].join(',')
    }

    onHandleFilter({ ...filter, [key]: newValues })
  }

  const getBooleanOfRadioValue = (key: string, value: string): boolean => {
    return filter[key] === value
  }

  const onChangeRadioValue = (key: string, value: string) => {
    onHandleFilter({ ...filter, [key]: value })
  }

  const onApplyDate = (key: string) => {
    const value = key === 'createdAtBefore' ? createdAtBefore : createdAtAfter

    onHandleFilter({
      ...filter,
      [key]: value === '__/__/____' ? '' : parseDateTimeToISO(value),
    })
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('profileTableFilterTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">{t('profileTableFilterMoscowLabel')}</Text>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'must')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'must')}
          >
            {t('profileTableFilterMoscowMW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'should')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'should')}
          >
            {t('profileTableFilterMoscowSW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'could')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'could')}
          >
            {t('profileTableFilterMoscowCW')}
          </Checkbox>

          <Checkbox
            value={getBooleanOfMultiValue('moscow', 'wont')}
            onChange={() => onChangeBooleanOfMultiValue('moscow', 'wont')}
          >
            {t('profileTableFilterMoscowWW')}
          </Checkbox>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">{t('profileTableFilterTypeLabel')}</Text>

          {Object.values(ProfileTypeEnum).map((type) => (
            <Checkbox
              key={type}
              value={getBooleanOfMultiValue('type', type)}
              onChange={() => onChangeBooleanOfMultiValue('type', type)}
            >
              {tEnums(`profile_type_${type}`)}
            </Checkbox>
          ))}
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('profileTableFilterTrafficSourceLabel')}
          </Text>

          <Radio
            value={getBooleanOfRadioValue('traffic_source', '')}
            onChange={() => onChangeRadioValue('traffic_source', '')}
          >
            {t('profileTableFilterTrafficSourceAll')}
          </Radio>

          {Object.values(ProfileTrafficTypeEnum).map((type) => (
            <Radio
              key={type}
              value={getBooleanOfRadioValue('traffic_source', type)}
              onChange={() => onChangeRadioValue('traffic_source', type)}
            >
              {tEnums(`profile_traffic_source_${type}`)}
            </Radio>
          ))}
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('profileTableFilterCraetedAtLabel')}
          </Text>

          <div className={styles['content-filter-row']}>
            <InputMask
              value={createdAtAfter}
              onChange={setCreatedAtAfter}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtAfter')}
            />

            <Text>-</Text>

            <InputMask
              value={createdAtBefore}
              onChange={setCreatedAtBefore}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtBefore')}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('profileTableFilterButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
