import { useState } from 'react'
import * as Yup from 'yup'
import {
  Alert,
  Button,
  DrawerStepper,
  SelectSearch,
  Text,
  InfoIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'
import { useProfileList, useMergeProfiles } from '@/services'
import { ProfileProps } from '@/types'

import styles from './drawer-form.module.css'

import { DrawerFormMergeProps } from './drawer-form-merge.type'

export const DrawerFormMerge = ({ isOpen, onClose }: DrawerFormMergeProps) => {
  const { t } = useTranslation('screenProfileList')

  const [profilePrimarySearch, setProfilePrimarySearch] = useState<string>('')
  const [profileSecondarySearch, setProfileSecondarySearch] =
    useState<string>('')

  const [mainProfile, setMainProfile] = useState<ProfileProps | undefined>(
    undefined,
  )
  const [secondaryProfile, setSecondaryProfile] = useState<
    ProfileProps | undefined
  >(undefined)

  const { mutateAsync: mergeProfiles, isPending: isLoadingMergeProfiles } =
    useMergeProfiles()

  const { data: dataPrimarySearch, isLoading: isLoadingPrimarySearch } =
    useProfileList({
      params: { search: profilePrimarySearch },
    })
  const { data: dataSecondarySearch, isLoading: isLoadingSecondarySearch } =
    useProfileList({
      params: { search: profileSecondarySearch },
    })

  const onSelectProfile = (isMain: boolean, profile?: ProfileProps) => {
    if (isMain) {
      setMainProfile(profile)
    } else {
      setSecondaryProfile(profile)
    }
  }

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    {
      mainProfile: Yup.object().shape({
        email: Yup.string().required(
          'profileTableMergeAccountsRequiredMessage',
        ),
      }),
      secondaryProfile: Yup.object().shape({
        email: Yup.string().required(
          'profileTableMergeAccountsRequiredMessage',
        ),
      }),
    },
    {
      mainProfile,
      secondaryProfile,
    },
  )

  const formatedErrors: Record<
    string,
    Record<string, string>
  > = errors as Record<string, Record<string, string>>

  const onSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    const response = await mergeProfiles({
      mainProfile: mainProfile?.email || '',
      secondaryProfile: secondaryProfile?.email || '',
    })

    if (response.success) {
      onClose()
      onSuccessMessage(t('profileTableMergeAccountsSuccess'))
    } else {
      onErrorMessage(t('profileTableMergeAccountsError'))
    }
  }

  return (
    <DrawerStepper
      initialStep="root"
      isOpen={isOpen}
      onHandleOpen={onClose}
      translations={{
        optional: t('profileTableMergeAccountsOptional'),
      }}
      title={t('profileTableMergeAccountsTitle')}
      steps={{
        root: {
          backNavigate: 'root',
          component: (
            <div className={styles.container}>
              <Alert
                title={t('profileTableMergeStepTitle')}
                variant="primary"
                leftIcon={<InfoIcon />}
              >
                {t('profileTableMergeAccountsDescription')}
              </Alert>
              <div className={styles.field}>
                <SelectSearch<ProfileProps>
                  dataSearch={dataPrimarySearch?.data?.results || []}
                  isLoadingSearch={isLoadingPrimarySearch}
                  onSearch={setProfilePrimarySearch}
                  onSelectItem={(value) =>
                    onSelectProfile(true, value as ProfileProps)
                  }
                  selectedItem={mainProfile}
                  keyLabel="email"
                  keyValue="email"
                  translations={{
                    inputSearchLabel: t(
                      'profileTableMergeAccountsPrimaryProfile',
                    ),
                    inputSearchPlaceholder: t(
                      'profileTableMergeAccountsProfilePlaceholder',
                    ),
                    noResults: t('profileTableMergeAccountsProfileNoResults'),
                  }}
                  error={formatedErrors?.mainProfile?.email as string}
                  renderContentItem={(item) => (
                    <div className={styles['select-item']}>
                      <Text variant="title-base">
                        {item?.companyName ||
                          t('profileTableMergeAccountsProfileNoHasCompany')}
                      </Text>
                      <Text variant="text-small">
                        {item?.email ||
                          t('profileTableMergeAccountsProfileNoHasEmail')}
                      </Text>
                    </div>
                  )}
                />
              </div>

              <div className={styles.field}>
                <SelectSearch<ProfileProps>
                  dataSearch={dataSecondarySearch?.data?.results || []}
                  isLoadingSearch={isLoadingSecondarySearch}
                  onSearch={setProfileSecondarySearch}
                  onSelectItem={(value) =>
                    onSelectProfile(false, value as ProfileProps)
                  }
                  selectedItem={secondaryProfile}
                  keyLabel="email"
                  keyValue="email"
                  translations={{
                    inputSearchLabel: t(
                      'profileTableMergeAccountsSecondaryProfile',
                    ),
                    inputSearchPlaceholder: t(
                      'profileTableMergeAccountsProfilePlaceholder',
                    ),
                    noResults: t('profileTableMergeAccountsProfileNoResults'),
                  }}
                  error={formatedErrors?.secondaryProfile?.email as string}
                  renderContentItem={(item) => (
                    <div className={styles['select-item']}>
                      <Text variant="title-base">
                        {item?.companyName ||
                          t('profileTableMergeAccountsProfileNoHasCompany')}
                      </Text>
                      <Text variant="text-small">
                        {item?.email ||
                          t('profileTableMergeAccountsProfileNoHasEmail')}
                      </Text>
                    </div>
                  )}
                />
              </div>
            </div>
          ),
          isOptional: false,
          footer: (
            <Button onClick={onSubmit} disabled={isLoadingMergeProfiles}>
              {t('profileTableMergeStepButton')}
            </Button>
          ),
        },
      }}
    />
  )
}
