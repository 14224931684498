import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Button,
  Card,
  InputSearch,
  Pagination,
  Select,
  TableColumns,
  TableColumnsColumnProps,
  Text,
  Tooltip,
  TrashIcon,
} from '@mercai/clever'
import { SupplierProductProps } from '@/types'
import {
  formatCurrency,
  formatDate,
  onConfirmDangerMessage,
  trimString,
  useParamsState,
} from '@/helpers'
import {
  QUERIES_ADMIN,
  useDeleteSupplierProduct,
  useSupplierProductsList,
} from '@/services'
import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { SupplierProductsTableProps } from './supplier-products-table.types'

import styles from './supplier-products-table.module.css'
import { Metrics } from '../metrics'
import { ROUTES_ADMIN } from '@/router'
import { SupplierDrawerForm } from '../../../components'
import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from '@/providers'

export const SupplierProductsTable = ({
  uuid,
  values,
}: SupplierProductsTableProps) => {
  const { t: tEmuns } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierShow')

  const { isSuperUser } = useAuth()

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    supplierUuid: uuid,
    productTitle: '',
    catalogProduct_Uuid: '',
    search: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    supplierProductform: '',
  })

  const onHandleNewRegister = () => {
    setQueryParams({
      ...queryParams,
      supplierProductform: 'true',
    })
  }

  const [totalPages, setTotalPages] = useState(1)

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useSupplierProductsList({
    params: {
      ...filter,
    },
  })

  const queryClient = useQueryClient()

  const { mutateAsync: mutateAsyncDeleteSupplierProduct } =
    useDeleteSupplierProduct()

  const onDeleteSupplierProduct = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('supplierProductsDeleteConfirmTitle'),
      t('supplierProductsDeleteConfirmDescription'),
      t('supplierProductsDeleteConfirmConfirmButton'),
      t('supplierProductsDeleteConfirmCancelButton'),
    )

    if (!confirm) {
      return
    }

    const response = await mutateAsyncDeleteSupplierProduct(uuid)

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module],
      })
    }
  }

  const columns: TableColumnsColumnProps<SupplierProductProps>[] = [
    {
      title: t('supplierProductsTableName'),
      key: 'name',
      dataKey: 'catalogProduct',
      render: ({ record }) => (
        <Tooltip
          text={record?.catalogProduct?.attributes?.title || ''}
          orientation="top"
          className={styles['product-column']}
        >
          <Link
            to={`/${ROUTES_ADMIN.CATALOG.PRODUCT.path}/${record?.catalogProduct?.uuid}`}
          >
            {trimString(
              record?.catalogProduct?.attributes?.title || '',
              22,
              '...',
            )}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: t('supplierProductsTablePacking'),
      key: 'package',
      dataKey: 'package',
      width: '12rem',
      render: ({ record }) =>
        record?.package
          ? tEmuns(`supplier_product_package_${record.package}`)
          : '-',
    },
    {
      title: t('supplierProductsTableLocation'),
      key: 'supplierWarehouseUuid',
      dataKey: 'supplierWarehouseUuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['warehouse-column']}>
          <img
            src={
              record?.supplierWarehouse?.country === 'US'
                ? '/images/flag-us.svg'
                : '/images/flag-mx.svg'
            }
            alt={'flag'}
          />
          {record?.supplierWarehouse?.name}
        </div>
      ),
    },
    {
      title: t('supplierProductsTableCost'),
      key: 'priceWithoutIVA',
      dataKey: 'price',
      width: '12rem',
      render: ({ record }) =>
        record?.price ? formatCurrency(record.price, 'es-MX', 'USD') : '-',
    },
    {
      title: t('supplierProductsTableMin'),
      key: 'minimumPurchaseQuantity',
      dataKey: 'minimumPurchaseQuantity',
      width: '12rem',
      render: ({ record }) =>
        record?.minimumPurchaseQuantity
          ? `${record?.minimumPurchaseQuantity} Kg`
          : '-',
    },
    {
      title: t('supplierProductsTableAvailable'),
      key: 'quantity',
      dataKey: 'quantity',
      width: '12rem',
      render: ({ record }) =>
        record?.quantity ? `${record?.quantity} Kg` : '-',
    },
    {
      title: '',
      key: 'actions',
      width: '4.6rem',
      render: ({ record }) =>
        isSuperUser ? (
          <Button
            variant="danger"
            model="square"
            onClick={() =>
              record?.uuid ? onDeleteSupplierProduct(record.uuid) : null
            }
          >
            <TrashIcon />
          </Button>
        ) : null,
    },
  ]

  const ExpandedComponent = ({ record }: { record: SupplierProductProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('supplierProductsTableInternalCode')}</strong>
            {': '}
            {record?.internalCode || '-'}
          </Text>
          <Text>
            <strong>{t('supplierProductsTableBatchCode')}</strong>
            {': '}
            {record?.batchCode || '-'}
          </Text>
          <Text>
            <strong>{t('supplierProductsTableEntryDate')}</strong>
            {': '}
            {record?.entryDate ? formatDate(record.entryDate) : '-'}
          </Text>
        </div>
        <Card className={styles.product}>
          <div>
            <Text variant="text-base">
              <strong>{record?.catalogProduct?.attributes?.title}</strong>
            </Text>
          </div>
          <div className={styles.row}>
            <Text>
              <strong>{t('supplierProductsTableProductCategoryLabel')}</strong>{' '}
              {record?.catalogProduct?.category?.name}
            </Text>
          </div>

          {record?.catalogProduct?.reference && (
            <div className={styles.row}>
              <Text>
                <strong>
                  {t('supplierProductsTableProductReferenceLabel')}
                </strong>{' '}
                {record?.catalogProduct.reference}
              </Text>
            </div>
          )}

          {record?.catalogProduct?.attributes?.fluidity && (
            <div className={styles.row}>
              <Text>
                <strong>
                  {t('supplierProductsTableProductFluidityLabel')}
                </strong>{' '}
                {record?.catalogProduct.attributes.fluidity} g/10m
              </Text>
            </div>
          )}

          {record?.catalogProduct?.attributes?.density && (
            <div className={styles.row}>
              <Text>
                <strong>{t('supplierProductsTableProductDensityLabel')}</strong>{' '}
                {record?.catalogProduct.attributes.density} g/cm³
              </Text>
            </div>
          )}
        </Card>
      </div>
    )
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  return (
    <div>
      <div className={styles.metrics}>
        <Metrics values={values} />
      </div>
      <Text className={styles.title} variant="title-large">
        {t('supplierProductsTitle')}
      </Text>
      <div className={styles['table-header']}>
        <div className={styles.filters}>
          <InputSearch
            value={filter.search}
            onChange={(value) => handleFilter('search', value)}
            translations={{
              inputPlaceholder: t('supplierProductsTableSearchPlaceholder'),
            }}
          />
        </div>
        <Button variant="light" onClick={onHandleNewRegister}>
          {t('supplierProductsAddButton')}
        </Button>
      </div>
      <TableColumns<SupplierProductProps>
        isLoading={isLoading}
        columns={columns}
        data={data?.data?.results || []}
        expandableComponent={ExpandedComponent}
      />
      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('supplierPaginationShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>
      <SupplierDrawerForm
        noSupplierForm={true}
        supplierUuid={uuid}
        isOpen={!!queryParams.supplierProductform}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, supplierProductform: '' })
        }
      />
    </div>
  )
}
