import {
  Button,
  CloseIcon,
  Drawer,
  InputDebounce,
  Select,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'
import { useVehiclesList } from '@/services'

export const TransportCompanyRoutesDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenTransportCompanies')

  const { data: vehiclesData } = useVehiclesList({})

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('transportCompaniesRoutesFilterTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('transportCompaniesRoutesFilterOriginLabel')}
          </Text>

          <InputDebounce
            value={filter.logistic_route__origin_location}
            onChange={(value) =>
              onHandleFilter({
                ...filter,
                logistic_route__origin_location: value,
              })
            }
            placeholder={t('searchFilterPlaceholder')}
          />
        </div>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('transportCompaniesRoutesFilterDestinationLabel')}
          </Text>

          <InputDebounce
            value={filter.logistic_route__destination_location}
            onChange={(value) =>
              onHandleFilter({
                ...filter,
                logistic_route__destination_location: value,
              })
            }
            placeholder={t('searchFilterPlaceholder')}
          />
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('transportCompaniesRoutesFilterVehicleLabel')}
          </Text>

          <Select
            value={filter.vehicle__name}
            onChange={(value) =>
              onHandleFilter({ ...filter, vehicle__name: value })
            }
            options={
              vehiclesData?.data?.results?.map((vehicle) => ({
                label: vehicle.name,
                value: vehicle.name,
              })) || []
            }
            placeholder={t('transportCompaniesRoutesFilterVehiclePlaceholder')}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('transportCompaniesRoutesButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
