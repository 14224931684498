import { SVGProps } from 'react'

export const PlagiarismIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" />
      <path d="M9.03 11.03C7.66 12.4 7.66 14.61 9.03 15.98C10.15 17.1 11.83 17.29 13.16 16.57L15.04 18.45L16.45 17.04L14.57 15.16C15.28 13.83 15.1 12.15 13.98 11.03C12.61 9.66 10.39 9.66 9.03 11.03ZM12.56 14.56C11.97 15.15 11.02 15.15 10.44 14.56C9.85 13.97 9.85 13.02 10.44 12.44C11.03 11.85 11.98 11.85 12.56 12.44C13.15 13.03 13.15 13.97 12.56 14.56Z" />
    </svg>
  )
}
