import { Button, Text } from '@mercai/clever'
import {
  generateContainerClasses,
  generateMessageClasses,
} from './generate-purchase-order-button.constants'
import { GeneratePurchaseOrderButtonProps } from './generate-purchase-order-button.types'
import { useTranslation } from 'react-i18next'

export const GeneratePurchaseOrderButton = ({
  isOpen,
  isValid,
  onGeneratePreview,
}: GeneratePurchaseOrderButtonProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  return (
    <div className={generateContainerClasses(isOpen, isValid)}>
      <div className={generateMessageClasses(isValid)}>
        <Text>{t('generatePurchaseOrderButtonMessage')}</Text>
        <Button variant="primary" onClick={onGeneratePreview}>
          {t('generatePurchaseOrderButtonButton')}
        </Button>
      </div>
      <div className={generateMessageClasses(!isValid)}>
        <Text>{t('generatePurchaseOrderButtonInvalidMessage')}</Text>
      </div>
    </div>
  )
}
