import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Label,
  Text,
  Select,
  DatePicker,
  DragDropFileList,
  DragDropFileFileProps,
} from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import { ORDERS_CENTER_PAYMENT_URL, api } from '@/services'

import { OrderPayment } from '@/components/order-center-show/components/order-payment'

import type { FinancePaymentsFormProps } from './payment.type'
import type { OrderPaymentProps, SaleOrderProps } from '@/types'

import styles from './payment.module.css'

export const FinancePaymentTab = ({
  onChange,
  values = {} as SaleOrderProps,
  onSuccessUploadFiles,
}: FinancePaymentsFormProps) => {
  const { t } = useTranslation('componentOrderCenterForm')
  const { t: tEnums } = useTranslation('enums')

  const [dataFilesProofMap, setDataFilesProofMap] = useState<
    Record<string, DragDropFileFileProps[]>
  >({})

  useEffect(() => {
    const newDataFilesProofMap: Record<string, DragDropFileFileProps[]> = {}

    values?.payments?.forEach(payment => {
      if (payment.proof) {
        const file = getFileNameAndExtension(payment.proof)
        newDataFilesProofMap[payment.uuid] = [
          {
            id: file.fileName,
            name: `${file.fileName}.${file.extension}`,
            url: payment.proof,
            uploaded: true,
          },
        ]
      } else {
        newDataFilesProofMap[payment.uuid] = []
      }
    })

    setDataFilesProofMap(newDataFilesProofMap)
  }, [values?.payments])

  const onChangePaymentValues = (
    keyIndex: number,
    keyParam: keyof OrderPaymentProps,
    value: string,
  ) => {
    const payments = [...values?.payments]

    payments[keyIndex] = {
      ...payments[keyIndex],
      [keyParam]: value,
    }

    onChange({
      ...values,
      payments,
    })
  }

  const getPaymentTitle = (form: string, installment: number) => {
    if (form !== 'partial') {
      return `${t(`paymentTitle`)} - ${tEnums(`finance_payment_form_${form}`)}`
    }

    if (values?.payments?.length > 2) {
      return `${t('paymentPartialTitle')} - ${installment} de ${values?.payments?.length}`
    }
    return `${installment === 1 ? t('paymentUpfrontTitle') : t('paymentOnDeliveryTitle')}`
  }

  return (
    <div className={styles.core}>
      {values?.payments?.map((payment, index) => (
        <div className={styles.content} key={payment?.uuid}>
          <div className={styles['content-title']}>
            <Text variant="text-large-bold">
              {getPaymentTitle(payment?.form, payment?.instalment)}
            </Text>
          </div>

          <div className={styles.field}>
            <Label>{t('paymentStatusLabel')}</Label>
            <Select
              options={[
                {
                  label: tEnums('order_payment_status_pending'),
                  value: 'pending',
                },
                {
                  label: tEnums('order_payment_status_waiting_approval'),
                  value: 'waiting_approval',
                },
                {
                  label: tEnums('order_payment_status_paid'),
                  value: 'paid',
                },
                {
                  label: tEnums('order_payment_status_invalid'),
                  value: 'invalid',
                },
                {
                  label: tEnums('order_payment_status_cancelled'),
                  value: 'cancelled',
                },
              ]}
              value={payment?.status}
              onChange={newValue =>
                onChangePaymentValues(index, 'status', newValue)
              }
            />
          </div>

          <div className={styles.field}>
            <Label>{t('paymentDateLabel')}</Label>
            <DatePicker
              value={values?.payments?.[index]?.paidAt}
              onChange={newValue =>
                onChangePaymentValues(index, 'paidAt', newValue || '')
              }
            />
          </div>

          <div className={styles.field}>
            <Label>{t('paymentProofLabel')}</Label>
            <DragDropFileList
              translations={{
                clickToUpload: t('uploadClickToUpload'),
                dragFile: t('uploadDragFile'),
                sizeFile: t('uploadSizeFile'),
              }}
              urlAdd={ORDERS_CENTER_PAYMENT_URL(payment?.uuid || '')}
              configsRequest={{
                fileField: 'proof',
                method: 'PATCH',
              }}
              axiosInstance={api}
              data={dataFilesProofMap[payment?.uuid] || []}
              onChangeData={(
                newFiles:
                  | DragDropFileFileProps[]
                  | ((
                      prevFiles: DragDropFileFileProps[],
                    ) => DragDropFileFileProps[]),
              ) => {
                setDataFilesProofMap(prevMap => ({
                  ...prevMap,
                  [payment?.uuid || '']: Array.isArray(newFiles)
                    ? newFiles
                    : newFiles(dataFilesProofMap[payment?.uuid] || []),
                }))
              }}
              successedUpload={onSuccessUploadFiles}
            />
          </div>
        </div>
      ))}

      <OrderPayment isLoading={false} saleOrder={values} />
    </div>
  )
}
