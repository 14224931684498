import { SupplierOrderProps, SupplierQuotationItemProps } from '@/types'

export const getBuyerName = (saleOrders: SupplierQuotationItemProps[]) => {
  const buyers: string[] = []

  saleOrders.forEach((saleOrder) => {
    if (!buyers.includes(saleOrder.buyer.companyName)) {
      buyers.push(saleOrder.buyer.companyName)
    }
  })

  return buyers.join(', ') || '-'
}

export const checkIfCheckedSupplierOrdersIsValid = (
  values: Record<string, SupplierOrderProps>,
) => {
  const arrayValues = Object.values(values)

  if (arrayValues.length === 0) return true

  const firstSupplierUuid = arrayValues?.[0].supplyQuotation?.supplier.uuid

  return arrayValues.every(
    (saleOrder) =>
      saleOrder.supplyQuotation?.supplier.uuid === firstSupplierUuid,
  )
}
