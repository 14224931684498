import styles from './business-metric-card.module.css'

export const selectedClassesBusinessMetricCards: Record<
  'selected' | 'unselected',
  string
> = {
  selected: `${styles['metrics-item']} ${styles.selected}`,
  unselected: `${styles['metrics-item']} ${styles.unselected}`,
}

export const generateClassesBusinessMetricCard = (
  isSelected = false,
  className?: string,
) => {
  return `${selectedClassesBusinessMetricCards[isSelected ? 'selected' : 'unselected']} ${className || ''}`
}

export const generateClassesChangeBusinessMetricCard = (isIncrease = true) => {
  return `${isIncrease ? styles['metrics-item-change-increase'] : styles['metrics-item-change-decrease']}`
}

export const checkArrowDirection = (
  positiveIsBad: boolean,
  change: number,
): boolean => {
  if (positiveIsBad) {
    return change <= 0
  }
  return change >= 0
}
