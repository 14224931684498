import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, TabsItemProps } from '@/components'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  getActualTab,
  tabPath,
  TabsPathsEnum,
} from './supplier-order-management.constants'
import styles from './supplier-order-management.module.css'
import { Text } from '@mercai/clever'
import {
  TableGeneratePurchaseOrder,
  TableSupplierOrder,
  TableSupplierTransaction,
} from './components'
export const SupplierOrderManagementPage = () => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const location = useLocation()
  const navigate = useNavigate()

  const actualTab = useMemo(
    () => getActualTab(location.pathname),
    [location.pathname],
  )

  const checkActive = (path: string) => location.pathname === `/${path}`

  const onSelectTab = (path: string) => {
    navigate(`/${path}`)
  }

  const tabsItems: TabsItemProps[] = [
    {
      title: t('tabTitleGeneratePurchaseOrder'),
      onSelect: () => onSelectTab(tabPath('generate-purchase-order')),
      isActive: checkActive(tabPath('generate-purchase-order')),
    },
    {
      title: t('tabTitlePurchaseOrder'),
      onSelect: () => onSelectTab(tabPath('purchase-order')),
      isActive: checkActive(tabPath('purchase-order')),
    },
    {
      title: t('tabTitleSupplierTransaction'),
      onSelect: () => onSelectTab(tabPath('supplier-transaction')),
      isActive: checkActive(tabPath('supplier-transaction')),
    },
  ]

  const tabTables: Record<TabsPathsEnum, React.ReactNode> = {
    'generate-purchase-order': <TableGeneratePurchaseOrder />,
    'purchase-order': <TableSupplierOrder />,
    'supplier-transaction': <TableSupplierTransaction />,
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-content']}>
          <div>
            <Text variant="title-large">{t('title')}</Text>
            <div>
              <Text variant="text-base" className={styles['sub-title']}>
                {t('description')}
              </Text>
            </div>
          </div>
        </div>
      </header>

      <Tabs items={tabsItems} />

      {tabTables[actualTab as TabsPathsEnum]}
    </div>
  )
}
