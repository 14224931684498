import { useEffect, useState } from 'react'
import { Button, DrawerStepperProgress } from '@mercai/clever'
import { ProductList, SearchMethod, UploadFile } from './components'
import { useTranslation } from 'react-i18next'
import { DrawerSelectProductsProps } from './drawer-select-products.type'
import { useValidationErrors } from '@/helpers'
import { schemaProductList } from './drawer-select-products.constants'
import { ProductForm } from '@/pages/business/components'
import { QuotationRequestItemProps } from '@/types'
import { useCatalogCategoryList } from '@/services'

export const DrawerSelectProducts = ({
  isOpen,
  onHandleOpen,
  values,
  setValues,
}: DrawerSelectProductsProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const { data: catalogCategoryList } = useCatalogCategoryList()

  const [internalValues, setInternalValues] = useState(values)

  const [missingFile, setMissingFile] = useState(false)

  const [method, setMethod] = useState('file')

  const [productItem, setProductItem] = useState<
    Partial<QuotationRequestItemProps>
  >({})

  const [productIndex, setProductIndex] = useState<number | undefined>()

  const {
    isValid: isValidProductList,
    errors: productListErrorsList,
    onHandleListenErrors: onHandleListenErrorsProductList,
  } = useValidationErrors(schemaProductList(t), internalValues)

  const onSubmitMethod = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    const methodRoute: Record<string, string> = {
      file: 'file',
      manual: 'manualProductList',
    }

    onNavigateToNextStep(methodRoute?.[method])
  }

  const onUploadFile = (filePath: string) => {
    setInternalValues({
      ...internalValues,
      filePath,
      file: filePath,
      items: undefined,
    })

    setMissingFile(false)
  }

  const onSubmitFile = () => {
    if (!internalValues?.file) {
      setMissingFile(true)
      return
    }

    setMissingFile(false)

    setValues(internalValues)

    onHandleOpen()
  }

  const onSubmitProducts = async () => {
    if (isValidProductList) {
      onHandleListenErrorsProductList(false)

      setValues(internalValues)

      onHandleOpen()
    } else {
      onHandleListenErrorsProductList(true)
    }
  }

  const onHandleNewProduct = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    setProductIndex(undefined)
    setProductItem({})
    onNavigateToNextStep('manualNewProduct')
  }

  const onHandleClickRemove = (productIndex: number) => {
    const newItems = internalValues.items?.filter(
      (_, index) => index !== productIndex,
    )

    setInternalValues({
      ...internalValues,
      items: newItems,
    })
  }

  const onHandleEditProduct = ({
    productIndex: productIndexParam,
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
    productIndex: number
  }) => {
    const product = internalValues.items?.[productIndexParam]

    setProductIndex(productIndexParam)
    setProductItem(product as Partial<QuotationRequestItemProps>)
    onNavigateToNextStep('manualNewProduct')
  }

  const onHandleSubmitProduct = async () => {
    if (productIndex || productIndex === 0) {
      const copyItems = [...(internalValues.items || [])]
      copyItems[productIndex] = productItem

      setInternalValues({
        ...internalValues,
        file: undefined,
        filePath: undefined,
        items: copyItems,
      })
    } else {
      setInternalValues({
        ...internalValues,
        file: undefined,
        filePath: undefined,
        items: [...(internalValues?.items || []), productItem],
      })
    }

    setProductItem({})
    setProductIndex(undefined)
  }

  useEffect(() => {
    if (values) setInternalValues(values)
  }, [values])

  return (
    <DrawerStepperProgress
      initialStep={internalValues?.items?.length ? 'manualProductList' : 'root'}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      translations={{
        optional: '',
      }}
      totalSteps={2}
      title={t('selectProductsTitle')}
      steps={{
        root: {
          title: t('searchMethodTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <SearchMethod method={method} onChangeMethod={setMethod} />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitMethod({ onNavigateToNextStep })}>
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        file: {
          title: t('uploadFileTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <UploadFile
              onSetFile={onUploadFile}
              errorMissingFile={missingFile}
              file={internalValues?.file}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitFile()}>
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
        manualProductList: {
          title: t('productListTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <ProductList
              onClickNewProduct={() =>
                onHandleNewProduct({ onNavigateToNextStep })
              }
              onClickRemoveProduct={onHandleClickRemove}
              onSelectedProduct={(productIndex) =>
                onHandleEditProduct({ onNavigateToNextStep, productIndex })
              }
              productItems={internalValues?.items || []}
              errors={productListErrorsList as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitProducts()}>
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
        manualNewProduct: {
          title: t('productFormTitle'),
          isOptional: false,
          backNavigate: 'manualProductList',
          component: ({ onNavigateToPreviousStep }) => (
            <ProductForm
              values={productItem}
              onChangeValues={(newData) => setProductItem(newData)}
              catalogCategoryList={catalogCategoryList?.data?.results || []}
              onHandleSubmit={onHandleSubmitProduct}
              onNavigateToPreviousStep={onNavigateToPreviousStep}
            />
          ),
        },
      }}
    />
  )
}
