import { AddressProps, ProfileProps, QuotationRequestItemProps } from '@/types'
import * as Yup from 'yup'

export const checkIsCompleteCreateGeneralInformation = ({
  address,
  buyerProfile,
}: {
  buyerProfile?: Partial<ProfileProps>
  address?: Partial<AddressProps>
}) => {
  return !!address?.uuid && !!buyerProfile?.uuid
}

export const checkIsCompleteCreateSelectProducts = ({
  items,
  file,
}: {
  items:
    | Partial<QuotationRequestItemProps>[]
    | QuotationRequestItemProps[]
    | undefined
  file: string | undefined
}) => {
  return (!!items && items?.length > 0) || !!file
}

export const shemaQuotationRequest = (t: (key: string) => string) => ({
  buyerProfileUuid: Yup.string().required(t('requiredMessage')),
  addressUuid: Yup.string().required(t('requiredMessage')),
  status: Yup.string().required(t('requiredMessage')),
  items: Yup.array().notRequired(),
  file: Yup.string().notRequired(),

  atLeastOne: Yup.mixed().when(['items', 'file'], ([items, file], schema) => {
    if ((!items || items.length === 0) && !file) {
      return schema.required(t('requiredMessage'))
    }
    return schema.notRequired()
  }),
})
