import { useState } from 'react'
import {
  Button,
  Checkbox,
  CloseIcon,
  Drawer,
  Radio,
  InputMask,
  Select,
  Text,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { parseDateTimeToISO } from '@/helpers'

import { useUserList } from '@/services'

import styles from './drawer-filter.module.css'

import { QuotationRequestOriginEnum } from '@/types'
import { DrawerFilterProps } from './drawer-filter.type'

export const QuotationRequestDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationRequestListV2')

  const [createdAtBefore, setCreatedAtBefore] = useState('')
  const [createdAtAfter, setCreatedAtAfter] = useState('')

  const getBooleanOfMultiValue = (key: string, value: string): boolean => {
    const values = (filter[key] as string)?.split(',') || []
    return values.includes(value)
  }

  const onChangeBooleanOfMultiValue = (key: string, value: string) => {
    let values = (filter[key] as string)?.split(',') || []
    values = values.filter((item) => !!item)

    let newValues = ''

    if (values.includes(value)) {
      newValues = values.filter((item) => item !== value).join(',')
    } else {
      newValues = [...values, value].join(',')
    }

    onHandleFilter({ ...filter, [key]: newValues })
  }

  const getBooleanOfSingleValue = (key: string, value: string): boolean => {
    return filter[key] === value
  }

  const onChangeBooleanOfSingleValue = (key: string, value: string) => {
    onHandleFilter({ ...filter, [key]: value })
  }

  const onApplyDate = (key: string) => {
    const value = key === 'createdAtBefore' ? createdAtBefore : createdAtAfter

    onHandleFilter({
      ...filter,
      [key]: value === '__/__/____' ? '' : parseDateTimeToISO(value),
    })
  }

  const { data: dataUsers } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const dataUsersList = dataUsers?.data?.results?.filter((user) => user?.name)

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('quotationRequestTableFilterTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterCreatedAtLabel')}
          </Text>

          <div className={styles['content-filter-row']}>
            <InputMask
              value={createdAtAfter}
              onChange={setCreatedAtAfter}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtAfter')}
            />

            <Text>-</Text>

            <InputMask
              value={createdAtBefore}
              onChange={setCreatedAtBefore}
              placeholder="dd/mm/aaaa"
              mask="99/99/9999"
              onBlur={() => onApplyDate('createdAtBefore')}
            />
          </div>
        </div>
        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterKAMLabel')}
          </Text>
          <Select
            value={filter.kam || ''}
            options={[
              {
                label: t('quotationRequestTableFilterKAMNoFilledLabel'),
                value: '',
              },
              ...(dataUsersList?.map((user) => ({
                label: user.name,
                value: `${user?.id}`,
              })) || []),
            ]}
            onChange={(newValue) =>
              onHandleFilter({ ...filter, kam: newValue })
            }
          />{' '}
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterStateLabel')}
          </Text>
          <div className={styles['content-filter-column']}>
            <Checkbox
              value={getBooleanOfMultiValue('status', 'pending')}
              onChange={() => onChangeBooleanOfMultiValue('status', 'pending')}
            >
              {tEnums('quotation_request_status_pending')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'approved_for_quotation')}
              onChange={() =>
                onChangeBooleanOfMultiValue('status', 'approved_for_quotation')
              }
            >
              {tEnums('quotation_request_status_approved_for_quotation')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'processed')}
              onChange={() =>
                onChangeBooleanOfMultiValue('status', 'processed')
              }
            >
              {tEnums('quotation_request_status_processed')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'completed')}
              onChange={() =>
                onChangeBooleanOfMultiValue('status', 'completed')
              }
            >
              {tEnums('quotation_request_status_completed')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'expired')}
              onChange={() => onChangeBooleanOfMultiValue('status', 'expired')}
            >
              {tEnums('quotation_request_status_expired')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'invalid')}
              onChange={() => onChangeBooleanOfMultiValue('status', 'invalid')}
            >
              {tEnums('quotation_request_status_invalid')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('status', 'disqualified')}
              onChange={() =>
                onChangeBooleanOfMultiValue('status', 'disqualified')
              }
            >
              {tEnums('quotation_request_status_disqualified')}
            </Checkbox>
          </div>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterOriginLabel')}
          </Text>

          <div className={styles['content-filter-column']}>
            {Object.keys(QuotationRequestOriginEnum)?.map((origin) => (
              <Checkbox
                value={getBooleanOfMultiValue('origin', origin)}
                onChange={() => onChangeBooleanOfMultiValue('origin', origin)}
              >
                {tEnums(`quotation_request_origin_${origin}`)}
              </Checkbox>
            ))}
          </div>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterMoscowLabel')}
          </Text>

          <div className={styles['content-filter-column']}>
            <Checkbox
              value={getBooleanOfMultiValue('moscow', 'must')}
              onChange={() => onChangeBooleanOfMultiValue('moscow', 'must')}
            >
              {t('quotationRequestTableFilterMoscowMW')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('moscow', 'should')}
              onChange={() => onChangeBooleanOfMultiValue('moscow', 'should')}
            >
              {t('quotationRequestTableFilterMoscowSW')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('moscow', 'could')}
              onChange={() => onChangeBooleanOfMultiValue('moscow', 'could')}
            >
              {t('quotationRequestTableFilterMoscowCW')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('moscow', 'wont')}
              onChange={() => onChangeBooleanOfMultiValue('moscow', 'wont')}
            >
              {t('quotationRequestTableFilterMoscowWW')}
            </Checkbox>
          </div>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterRootCategoryLabel')}
          </Text>

          <div className={styles['content-filter-column']}>
            <Radio
              value={getBooleanOfSingleValue('rootCategory', 'Metais')}
              onChange={() =>
                onChangeBooleanOfSingleValue('rootCategory', 'Metais')
              }
            >
              {t('quotationRequestTableFilterRootCategoryMetales')}
            </Radio>

            <Radio
              value={getBooleanOfSingleValue('rootCategory', 'ResPlas')}
              onChange={() =>
                onChangeBooleanOfSingleValue('rootCategory', 'ResPlas')
              }
            >
              {t('quotationRequestTableFilterRootCategoryResPlas')}
            </Radio>

            <Radio
              value={getBooleanOfSingleValue('rootCategory', '')}
              onChange={() => onChangeBooleanOfSingleValue('rootCategory', '')}
            >
              {t('quotationRequestTableFilterRootCategoryUndefined')}
            </Radio>
          </div>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterTrafficSource')}
          </Text>

          <div className={styles['content-filter-column']}>
            <Checkbox
              value={getBooleanOfMultiValue('traffic_source', 'paid')}
              onChange={() =>
                onChangeBooleanOfMultiValue('traffic_source', 'paid')
              }
            >
              {t('quotationRequestTableFilterTrafficSourcePaid')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('traffic_source', 'organic')}
              onChange={() =>
                onChangeBooleanOfMultiValue('traffic_source', 'organic')
              }
            >
              {t('quotationRequestTableFilterTrafficSourceOrganic')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('traffic_source', 'referral')}
              onChange={() =>
                onChangeBooleanOfMultiValue('traffic_source', 'referral')
              }
            >
              {t('quotationRequestTableFilterTrafficSourceReferral')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('traffic_source', 'direct')}
              onChange={() =>
                onChangeBooleanOfMultiValue('traffic_source', 'direct')
              }
            >
              {t('quotationRequestTableFilterTrafficSourceDirect')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('traffic_source', 'unknown')}
              onChange={() =>
                onChangeBooleanOfMultiValue('traffic_source', 'unknown')
              }
            >
              {t('quotationRequestTableFilterTrafficSourceUnknown')}
            </Checkbox>
          </div>
        </div>

        <div className={styles['content-filter']}>
          <Text variant="title-base">
            {t('quotationRequestTableFilterType')}
          </Text>

          <div className={styles['content-filter-column']}>
            <Checkbox
              value={getBooleanOfMultiValue('buyer_profile__type', 'inbound')}
              onChange={() =>
                onChangeBooleanOfMultiValue('buyer_profile__type', 'inbound')
              }
            >
              {t('quotationRequestTableFilterTypeInbound')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue('buyer_profile__type', 'outbound')}
              onChange={() =>
                onChangeBooleanOfMultiValue('buyer_profile__type', 'outbound')
              }
            >
              {t('quotationRequestTableFilterTypeOutbound')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue(
                'buyer_profile__type',
                'inbound_reactivation',
              )}
              onChange={() =>
                onChangeBooleanOfMultiValue(
                  'buyer_profile__type',
                  'inbound_reactivation',
                )
              }
            >
              {t('quotationRequestTableFilterTypeInboundReactivation')}
            </Checkbox>

            <Checkbox
              value={getBooleanOfMultiValue(
                'buyer_profile__type',
                'outbound_reactivation',
              )}
              onChange={() =>
                onChangeBooleanOfMultiValue(
                  'buyer_profile__type',
                  'outbound_reactivation',
                )
              }
            >
              {t('quotationRequestTableFilterTypeOutboundReactivation')}
            </Checkbox>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('quotationRequestTableFilterButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
