import { useTranslation } from 'react-i18next'
import { DragDropFileList, Label, Text } from '@mercai/clever'

import { getFileNameAndExtension } from '@/helpers'
import { api, ORDER_CENTER_SHIPMENT_URL } from '@/services'

import type { LogisticFormDocumentsProps } from './documents.type'
import { LogisticShipmentProps, SaleOrderProps } from '@/types'

import styles from './documents.module.css'

export const LogisticFormDocuments = ({
  onChange,
  values = {} as SaleOrderProps,
  documentsList,
}: LogisticFormDocumentsProps) => {
  const { t } = useTranslation('componentOrderCenterForm')

  const onChangeShipmentValues = (
    keyParam: keyof LogisticShipmentProps,
    value?: string | number,
  ) => {
    const shipment = { ...values?.shipments?.[0] }
    const updatedShipment = { ...shipment, [keyParam]: value }

    onChange({
      ...values,
      shipments: [updatedShipment, ...values?.shipments.slice(1)],
    })
  }

  const getFileValue = (keyParam: keyof LogisticShipmentProps) => {
    if (!values?.shipments?.[0]?.[keyParam]) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(
      values?.shipments?.[0]?.[keyParam] as string,
    )

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: values?.shipments?.[0]?.[keyParam] as string,
        uploaded: true,
        preview: values?.shipments?.[0]?.[keyParam] as string,
      },
    ]
  }

  return (
    <div className={styles.content}>
      <Text variant="text-large-bold">{t('logisticDocumentsTitle')}</Text>

      {documentsList.map(
        ({ file, configRequest, fileTranslationId, translationsConfig }) => (
          <div className={styles.field}>
            <Label>{t(fileTranslationId)}</Label>

            <DragDropFileList
              axiosInstance={api}
              urlAdd={ORDER_CENTER_SHIPMENT_URL(values?.shipments?.[0]?.uuid)}
              configsRequest={configRequest}
              initialData={getFileValue(file)}
              successedUpload={(value) =>
                onChangeShipmentValues(
                  file,
                  value?.[configRequest.fileField]
                    ? `${value?.[configRequest.fileField]}`
                    : '',
                )
              }
              translations={
                translationsConfig || {
                  clickToUpload: t('logisticDocumentsUploadButton'),
                  dragFile: t('logisticDocumentsUploadDrag'),
                  sizeFile: t('logisticDocumentsUploadSize'),
                }
              }
            />
          </div>
        ),
      )}
    </div>
  )
}
