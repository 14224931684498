import { useTranslation } from 'react-i18next'

import { Button, CloseIcon, Drawer, Input, Label, Text } from '@mercai/clever'

import { DrawerPreviewPurchaseOrderProps } from './drawer-preview-purchase-order.type'
import styles from './drawer-preview-purchase-order.module.css'
import {
  QUERIES_ADMIN,
  useGeneratePurchaseOrder,
  useGeneratePurchaseOrderPreview,
} from '@/services'
import { onErrorMessage, onSuccessMessage } from '@/helpers'
import { ModalGeneratePurchaseOrder } from '../modal-generate-purchase-order'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { SupplierOrderVisualization } from '../supplier-order-visualization'
import { getSupplierOrderVisualizationProps } from './drawer-preview-purchase-order.constants'

export const DrawerPreviewPurchaseOrder = ({
  values = {},
  isOpen,
  setIsOpen,
  setValues,
}: DrawerPreviewPurchaseOrderProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [isOpenModal, setIsOpenModal] = useState(false)

  const [fileId, setFileId] = useState('')

  const queryClient = useQueryClient()

  const saleOrders = Object.values(values).map(
    (saleOrder) => saleOrder?.reference,
  )

  const { mutateAsync, isPending } = useGeneratePurchaseOrder()

  const { data, isLoading } = useGeneratePurchaseOrderPreview(
    {
      saleOrders,
    },
    isOpen,
  )

  const onHandleOpen = () => {
    setIsOpen(!isOpen)
  }

  const onHandleOpenModal = () => {
    setIsOpenModal(true)
  }

  const onHandleGeneratePurchaseOrder = async (sendPo: boolean) => {
    const response = await mutateAsync({
      saleOrders,
      sendPo,
      fileId,
      status: 'accepted',
    })

    if (response.success) {
      setIsOpenModal(false)
      setIsOpen(false)
      setValues({})
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ORDER_CENTER.module],
      })
      onSuccessMessage(
        t('drawerPreviewPurchaseOrderSuccessGenerateTitle'),
        t('drawerPreviewPurchaseOrderSuccessGenerateDescription'),
      )
      return null
    }

    onErrorMessage(
      t('drawerPreviewPurchaseOrderErrorGenerateTitle'),
      t('drawerPreviewPurchaseOrderErrorGenerateDescription'),
    )
  }

  useEffect(() => {
    if (!isOpen) {
      setFileId('')
    }
  }, [isOpen])

  return (
    <>
      <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
        <div className={styles.header}>
          <div>
            <Text variant="title-large" className={styles['header-title']}>
              {t('drawerPreviewPurchaseOrderTitle')}
            </Text>
            <Text variant="text-small" className={styles['header-description']}>
              {t('drawerPreviewPurchaseOrderDescription')}
            </Text>
          </div>

          <button
            className={styles['header-button']}
            type="button"
            onClick={onHandleOpen}
          >
            <CloseIcon />
          </button>
        </div>

        <div className={styles.content}>
          <div>
            <Label>
              <Text variant="text-large-bold">
                {t('drawerPreviewPurchaseOrderPONumberLabel')}
              </Text>
            </Label>
            <Input
              value={fileId}
              onChange={setFileId}
              placeholder={t('drawerPreviewPurchaseOrderPONumberPlaceholder')}
            />
          </div>

          <SupplierOrderVisualization
            {...getSupplierOrderVisualizationProps(
              data?.data?.saleOrders || [],
              data?.data?.total || 0,
              data?.data?.currency || 'MXN',
            )}
          />
        </div>

        <div className={styles.footer}>
          <Button variant="secondary" onClick={onHandleOpen}>
            {t('drawerPreviewPurchaseOrderCancel')}
          </Button>
          <Button
            variant="primary"
            onClick={onHandleOpenModal}
            disabled={isLoading}
            isActive={!isLoading}
          >
            {t('drawerPreviewPurchaseOrderConfirm')}
          </Button>
        </div>
      </Drawer>
      <ModalGeneratePurchaseOrder
        onSubmit={onHandleGeneratePurchaseOrder}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        values={values}
        fileId={fileId}
        isLoading={isPending}
      />
    </>
  )
}
