import { MouseEvent, useState } from 'react'
import styles from './sidebar.module.css'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import {
  BarChartIcon,
  DescriptionIcon,
  InventoryIcon,
  KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRightIcon,
  MercaiOpusIcon,
  MercaiOpusShortIcon,
  PeopleAltIcon,
  PieChartIcon,
  PlagiarismIcon,
  RequestQuoteIcon,
  StoreIcon,
  Text,
  TruckIcon,
  UpcomingIcon,
  WorkIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import {
  getBackdropClasses,
  getContainerClasses,
  getItemContainerTitleClasses,
  getItemTitleClasses,
  getItemTitleTooltipClasses,
  getMenuContainerClasses,
} from './sidebar.constants'
import { SidebarProps } from './sidebar.types'
import { FormatListBulletedIcon } from '@/lib/icons/format-list-bulleted-icon'
import { trackEvent } from '@/helpers'

const Item = ({
  title = '',
  icon,
  path,
  fullPath,
  isOpen,
  setIsOpen,
  isGrouped = false,
}: {
  title: string
  icon?: React.ReactNode
  path: string
  fullPath: () => string
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  isGrouped?: boolean
}) => {
  const location = useLocation()

  const [tooltipPositionTop, setTooltipPositionTop] = useState(0)

  const getActiveClass = (link: string) => {
    return location?.pathname === `/${link}` ||
      (isGrouped && location?.pathname.includes(link))
      ? styles['item-active']
      : styles.item
  }

  const onMouseEnter = (event: MouseEvent<HTMLAnchorElement>) => {
    const rect = event.currentTarget.getBoundingClientRect()
    setTooltipPositionTop(rect.top)
  }

  return (
    <Link
      className={getActiveClass(path)}
      to={fullPath()}
      onMouseEnter={onMouseEnter}
      onClick={() => setIsOpen(false)}
    >
      {icon}
      <div className={getItemTitleClasses(isOpen)}>{title}</div>
      <div
        className={getItemTitleTooltipClasses(isOpen)}
        style={{ top: tooltipPositionTop }}
      >
        {title}
      </div>
    </Link>
  )
}

export const Sidebar = ({ isOpen, setIsOpen }: SidebarProps) => {
  const { t } = useTranslation('layoutPanelAdmin')

  const toggleSidebar = () => {
    if (!isOpen) {
      trackEvent('adminSidebarOpenClick')
    }

    setIsOpen(!isOpen)
  }

  return (
    <>
      <aside className={getContainerClasses(isOpen)}>
        <div className={styles['toggle-button']} onClick={toggleSidebar}>
          {isOpen ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </div>
        <div className={styles['logo-container']}>
          <Link
            onClick={() => setIsOpen(false)}
            to={ROUTES_ADMIN.BUSINESS.RFQ_OLD.fullPath()}
            className={styles.logo}
          >
            {isOpen ? <MercaiOpusIcon /> : <MercaiOpusShortIcon />}
          </Link>
        </div>

        <div className={getMenuContainerClasses(isOpen)}>
          <div className={styles['item-container']}>
            <Text
              variant="title-base"
              className={getItemContainerTitleClasses(isOpen)}
            >
              {t('performanceMenus')}
            </Text>

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemDashboard')}
              path={ROUTES_ADMIN.ANALYTICS.DASHBOARD.path}
              fullPath={ROUTES_ADMIN.ANALYTICS.DASHBOARD.fullPath}
              icon={<BarChartIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemSaleBoard')}
              path={ROUTES_ADMIN.BOARD.SALE.path}
              fullPath={ROUTES_ADMIN.BOARD.SALE.fullPath}
              icon={<PieChartIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemOrderTracker')}
              path={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.path}
              fullPath={ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.fullPath}
              icon={<FormatListBulletedIcon />}
            />
          </div>

          <div className={styles['item-container']}>
            <Text
              variant="title-base"
              className={getItemContainerTitleClasses(isOpen)}
            >
              {t('operationMenus')}
            </Text>

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemProducts')}
              path={ROUTES_ADMIN.CATALOG.PRODUCT.path}
              fullPath={ROUTES_ADMIN.CATALOG.PRODUCT.fullPath}
              icon={<InventoryIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemProcessor')}
              path={ROUTES_ADMIN.CATALOG.PROCESSOR.path}
              fullPath={ROUTES_ADMIN.CATALOG.PROCESSOR.fullPath}
              icon={<UpcomingIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemSuppliers')}
              path={ROUTES_ADMIN.SUPPLY.SUPPLIERS.path}
              fullPath={ROUTES_ADMIN.SUPPLY.SUPPLIERS.fullPath}
              icon={<StoreIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemTransportCompanies')}
              path={ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.path}
              fullPath={ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.fullPath}
              icon={<TruckIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemLeads')}
              path={ROUTES_ADMIN.PROFILE.path}
              fullPath={ROUTES_ADMIN.PROFILE.fullPath}
              icon={<PeopleAltIcon />}
            />
          </div>

          <div className={styles['item-container']}>
            <Text
              variant="title-base"
              className={getItemContainerTitleClasses(isOpen)}
            >
              {t('sellMenus')}
            </Text>

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemRFQs')}
              path={ROUTES_ADMIN.BUSINESS.RFQ.path}
              fullPath={ROUTES_ADMIN.BUSINESS.RFQ.fullPath}
              icon={<PlagiarismIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemQuotations')}
              path={ROUTES_ADMIN.BUSINESS.QUOTATION.path}
              fullPath={ROUTES_ADMIN.BUSINESS.QUOTATION.fullPath}
              icon={<DescriptionIcon />}
            />

            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemOrderCenter')}
              path={ROUTES_ADMIN.ORDER_CENTER.path.split('/:')[0]}
              fullPath={ROUTES_ADMIN.ORDER_CENTER.ALL.fullPath}
              icon={<RequestQuoteIcon />}
              isGrouped
            />
          </div>

          <div className={styles['item-container']}>
            <Item
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('listItemSupplierOrders')}
              path={
                ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.path.split('/:page')[0]
              }
              fullPath={
                ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.GENERATE_PURCHASE_ORDER
                  .fullPath
              }
              icon={<WorkIcon />}
              isGrouped
            />
          </div>
        </div>
      </aside>

      <div
        className={getBackdropClasses(isOpen)}
        onClick={() => setIsOpen(false)}
      ></div>
    </>
  )
}
