import { useTranslation } from 'react-i18next'

import { Button, Modal, Text, Tooltip } from '@mercai/clever'

import { SupplierOrderVisualizationProps } from './supplier-order-visualization.types'

import styles from './supplier-order-visualization.module.css'
import { formatCurrency } from '@/helpers'
import { useState } from 'react'

export const SupplierOrderVisualization = ({
  currency,
  items = [],
  total,
  poFile,
}: SupplierOrderVisualizationProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [isOpenFile, setIsOpenFile] = useState(false)

  return (
    <>
      <Text variant="text-large-bold">
        {t('supplierOrderVisualizationProductsDescription')}
      </Text>
      <div className={styles['products-card']}>
        {items.map(({ buyerCompanyName, saleOrderReference, products }) => (
          <div key={saleOrderReference}>
            <div className={styles['products-card-header']}>
              <Tooltip
                text={buyerCompanyName || '-'}
                className={styles.tooltip}
              >
                <Text
                  variant="title-medium"
                  className={styles['company-name-title']}
                >
                  {buyerCompanyName || '-'}
                </Text>
              </Tooltip>

              <Text variant="title-medium">{saleOrderReference || '-'}</Text>
            </div>
            <div className={styles['products-card-body']}>
              {products?.map(
                ({
                  productCost,
                  productQuantity,
                  productTitle,
                  productTotal,
                }) => (
                  <div
                    key={productTitle}
                    className={styles['products-card-body-item']}
                  >
                    <div>
                      <Tooltip text={productTitle} className={styles.tooltip}>
                        <Text className={styles['product-title']}>
                          {productTitle}
                        </Text>
                      </Tooltip>

                      <div className={styles['product-quantity']}>
                        <Text variant="text-small">{productQuantity} kg</Text>
                        <Text variant="text-small">x</Text>
                        <Text variant="text-small">
                          {formatCurrency(
                            +productCost,
                            'es-MX',
                            currency || 'MXN',
                          )}
                        </Text>
                      </div>
                    </div>
                    <Text variant="title-base">
                      {formatCurrency(
                        +productTotal,
                        'es-MX',
                        currency || 'MXN',
                      )}
                    </Text>
                  </div>
                ),
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles['total-card']}>
        <Text variant="title-large">TOTAL</Text>
        <Text variant="title-large">
          {formatCurrency(+total, 'es-MX', currency || 'MXN')}
        </Text>
      </div>

      {poFile && (
        <>
          <Button
            variant="ghost"
            onClick={() => setIsOpenFile(true)}
            className={styles['po-file-button']}
          >
            {t('supplierOrderVisualizationPOFileButton')}
          </Button>

          <Modal.Root
            isOpen={isOpenFile}
            onHandleOpen={setIsOpenFile}
            mainClassName={styles.modal}
          >
            <Modal.Header title={t('supplierOrderVisualizationPOFileTitle')} />
            <div className={styles['content-modal']}>
              <iframe src={poFile} />
            </div>
          </Modal.Root>
        </>
      )}
    </>
  )
}
