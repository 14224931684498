import * as Yup from 'yup'

export const shemaAddressItem = (t: (key: string) => string) => ({
  postalCode: Yup.string().required(t('requiredMessage')),
  state: Yup.string().required(t('requiredMessage')),
  city: Yup.string().required(t('requiredMessage')),
  neighborhood: Yup.string().required(t('requiredMessage')),
  street: Yup.string().required(t('requiredMessage')),
  number: Yup.string().required(t('requiredMessage')),
})
