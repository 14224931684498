import { SupplierOrderProps } from '@/types'
import { calculateDelayDays } from '@/helpers'

import styles from './table-supplier-transaction.module.css'

export const getBuyerName = (supplyOrders: SupplierOrderProps[]) => {
  const buyers: string[] = []

  const saleOrders = supplyOrders.flatMap(
    (item) => item.supplyQuotation.supplyQuotationItems,
  )

  saleOrders.forEach((saleOrder) => {
    if (!buyers.includes(saleOrder.buyer.companyName)) {
      buyers.push(saleOrder.buyer.companyName)
    }
  })

  return buyers.join(', ') || '-'
}

export const getExpectedPaidClasses = (
  date?: string,
  paymentAt?: string,
): string => {
  if (!date) {
    return ''
  }

  const delayDays = calculateDelayDays(
    new Date(date),
    paymentAt ? new Date(paymentAt) : new Date(),
  )

  if (delayDays > 0 && delayDays <= 3) {
    return styles['text-warning']
  }

  if (delayDays > 3) {
    return styles['text-danger']
  }

  return ''
}
