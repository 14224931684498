import type { SaleOrderProps } from '@/types'

export interface LogisticFormTransportProps {
  values?: SaleOrderProps
  onChange: (newValue: SaleOrderProps) => void
}

export enum ExtraCostReasons {
  waitingTime = 'waiting_time',
  dwelling = 'dwelling',
  maneuvering = 'maneuvering',
  locationChanges = 'location_changes',
  falseFreight = 'false_freight',
  other = 'other',
}
