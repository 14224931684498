import { useMutation, useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type {
  QuotationsListWithPagesProps,
  QuotationProps,
  QuotationClassificationProps,
  QuotationSLASProps,
} from '@/types'

import {
  QUOTATIONS_URL,
  QUOTATION_CLASSIFICATION_URL,
  QUOTATION_SLAS_URL,
  QUOTATION_URL,
} from './api.urls'

export const useQuotationList = (params: Record<string, string | number>) => {
  const getQuotationList = async (): Promise<{
    success: boolean
    message: string
    data?: QuotationsListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(QUOTATIONS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Quotation requests fetched successfully!',
        data: convertObjectToCamelCase(data) as QuotationsListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get quotation requests!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION.module, params],
    queryFn: () => getQuotationList(),
  })
}

export const useCreateQuotation = () => {
  const createQuotation = async (
    payload: Partial<QuotationProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: QuotationProps
  }> => {
    try {
      const { data } = await api.post(
        QUOTATIONS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Quotation request created successfully!',
        data: convertObjectToCamelCase(data) as QuotationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to quotation request!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION.module],
    mutationFn: (payload: Partial<QuotationProps>) => createQuotation(payload),
  })
}

export const useQuotationDetail = (uuid?: string) => {
  const getQuotationDetail = async (): Promise<{
    success: boolean
    message: string
    data?: QuotationProps
  }> => {
    try {
      const { data } = await api.get(QUOTATION_URL(`${uuid}`))

      return {
        success: true,
        message: 'Quotation request fetched successfully!',
        data: convertObjectToCamelCase(data) as QuotationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get quotation request!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION.module, uuid],
    queryFn: () => getQuotationDetail(),
    enabled: !!uuid,
    refetchOnWindowFocus: false,
  })
}

export const useUpdateQuotation = (uuid?: string) => {
  const updateQuotation = async (
    payload: Partial<QuotationProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: QuotationProps
  }> => {
    try {
      const { data } = await api.patch(
        QUOTATION_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Quotation request created successfully!',
        data: convertObjectToCamelCase(data) as QuotationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to quotation request!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION.module, uuid],
    mutationFn: (payload: Partial<QuotationProps>) => updateQuotation(payload),
  })
}

export const useDeleteQuotation = () => {
  const deleteQuotation = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      await api.delete(QUOTATION_URL(`${uuid}`))

      return {
        success: true,
        message: 'Quotation deleted successfully!',
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete quotation!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.QUOTATION.module],
    mutationFn: (uuid: string) => deleteQuotation(uuid),
  })
}

export const useQuotationClassification = () => {
  const getQuotationClassification = async (): Promise<{
    success: boolean
    message: string
    data?: QuotationClassificationProps
  }> => {
    try {
      const { data } = await api.get(QUOTATION_CLASSIFICATION_URL)

      return {
        success: true,
        message: '',
        data: convertObjectToCamelCase(data) as QuotationClassificationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: '',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION_CLASSIFICATION.module],
    queryFn: () => getQuotationClassification(),
  })
}

export const useQuotationSlas = () => {
  const getQuotationSlas = async (): Promise<{
    success: boolean
    message: string
    data?: QuotationSLASProps
  }> => {
    try {
      const { data } = await api.get(QUOTATION_SLAS_URL)

      return {
        success: true,
        message: '',
        data: convertObjectToCamelCase(data) as QuotationSLASProps,
      }
    } catch (err) {
      return {
        success: false,
        message: '',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.QUOTATION_SLAS.module],
    queryFn: () => getQuotationSlas(),
  })
}
