import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type {
  SupplierTransactionCreatePayload,
  SupplierTransactionProps,
  SupplierTransactionWithPagesProps,
} from '@/types'

import { SUPPLY_TRANSACTION_URL, SUPPLY_TRANSACTIONS_URL } from './api.urls'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useGenerateSupplierTransaction = () => {
  const generateSupplierTransaction = async (
    payload: SupplierTransactionCreatePayload,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierTransactionProps
  }> => {
    try {
      const { data } = await api.post(
        SUPPLY_TRANSACTIONS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_TRANSACTION.module],
    mutationFn: (payload: SupplierTransactionCreatePayload) =>
      generateSupplierTransaction(payload),
  })
}

export const useSupplierTransactionList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getSupplierTransactionList = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierTransactionWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SUPPLY_TRANSACTIONS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Supplier Order fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as SupplierTransactionWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_TRANSACTION.module, params],
    queryFn: () => getSupplierTransactionList(),
    enabled: isEnabled,
  })
}

export const useSupplierTransactionDetail = (uuid?: string) => {
  const getSupplierTransactionDetail = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierTransactionProps
  }> => {
    try {
      const { data } = await api.get(SUPPLY_TRANSACTION_URL(`${uuid}`))

      return {
        success: true,
        message: 'Supplier Order fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierTransactionProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Supplier Order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_TRANSACTION.module, uuid],
    queryFn: () => getSupplierTransactionDetail(),
    enabled: !!uuid,
  })
}

export const useSupplierTransactionEdit = (uuid: string) => {
  const supplierTransactionEdit = async (
    payload: Partial<SupplierTransactionProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierTransactionProps
  }> => {
    try {
      const { data } = await api.patch(
        SUPPLY_TRANSACTION_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data: convertObjectToCamelCase(data) as SupplierTransactionProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
    mutationFn: (payload: Partial<SupplierTransactionProps>) =>
      supplierTransactionEdit(payload),
  })
}
