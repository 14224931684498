import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ArrowRightIcon,
  Button,
  CloseIcon,
  DatePicker,
  DragDropFileList,
  Drawer,
  Label,
  Select,
  Text,
} from '@mercai/clever'

import { SupplierOrderDrawerFormProps } from './drawer-form.types'

import styles from './drawer-form.module.css'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'
import {
  formatCurrency,
  formatDate,
  getFileNameAndExtension,
  onErrorMessage,
  onSuccessMessage,
} from '@/helpers'
import {
  SupplierTransactionProps,
  SupplierTransactionStatusProps,
} from '@/types'
import {
  QUERIES_ADMIN,
  SUPPLY_TRANSACTION_URL,
  useSupplierTransactionDetail,
  useSupplierTransactionEdit,
  api,
} from '@/services'
import { useQueryClient } from '@tanstack/react-query'

export const SupplierOrderDrawerForm = ({
  onHandleOpen,
  uuid,
}: SupplierOrderDrawerFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const [values, setValues] = useState<Partial<SupplierTransactionProps>>({})

  const { mutateAsync, isPending } = useSupplierTransactionEdit(uuid)

  const { data } = useSupplierTransactionDetail(uuid)

  const queryClient = useQueryClient()

  const getFileValue = (file: string | undefined) => {
    if (!file) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(file)

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: file as string,
        uploaded: true,
      },
    ]
  }

  const onHandleValues = (key: string, value?: string | number | null) => {
    setValues({ ...values, [key]: value })
  }

  const onSubmit = async () => {
    const response = await mutateAsync(values)

    if (response.success) {
      onHandleOpen()
      setValues({})
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER_TRANSACTION.module],
      })
      onSuccessMessage(
        t('tableSupplierTransactionDrawerEditSuccessTitle'),
        t('tableSupplierTransactionDrawerEditSuccessDescription'),
      )
      return null
    }

    onErrorMessage(
      t('tableSupplierTransactionDrawerEditErrorTitle'),
      t('tableSupplierTransactionDrawerEditErrorDescription'),
    )
  }

  const SupplierOrder = ({
    invoice,
    reference,
    updatedAt,
    value,
    currency,
  }: {
    invoice?: string
    reference: string
    updatedAt: string
    value: string
    currency: string
  }) => {
    return (
      <div className={styles['supplier-order']}>
        <div className={styles['supplier-order-info']}>
          <Link
            target="_blank"
            to={`${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.PURCHASE_ORDER.fullPath()}?search=${reference}`}
          >
            <Text
              variant="title-base"
              className={styles['supplier-order-link']}
            >
              {t('tableSupplierTransactionDrawerEditSupplierOrder')}
              {reference}
            </Text>
          </Link>
          <Text variant="text-small" className={styles['supplier-order-date']}>
            {t('tableSupplierTransactionDrawerEditSupplierOrderUpdatedAt')}
            {formatDate(updatedAt)}
          </Text>
          <Text variant="title-large">
            {formatCurrency(value, 'ex-MX', currency)}
          </Text>
        </div>
        <div className={styles['supplier-order-actions']}>
          {invoice && (
            <Button variant="success-light" href={invoice} target="_blank">
              {t('tableSupplierTransactionDrawerEditSupplierInvoiceButton')}
            </Button>
          )}

          <Button
            variant="secondary"
            model="square"
            href={`${ROUTES_ADMIN.SUPPLY.ORDER_MANAGEMENT.PURCHASE_ORDER.fullPath()}?search=${reference}`}
            target="_blank"
          >
            <ArrowRightIcon />
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setValues({
      status: data?.data?.status,
      destinationBank: data?.data?.destinationBank,
      paymentAt: data?.data?.paymentAt,
    })
  }, [data])

  useEffect(() => {
    if (!uuid) {
      setValues({})
    }
  }, [uuid])

  return (
    <Drawer isOpen={!!uuid} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <div>
          <Text variant="title-large" className={styles['header-title']}>
            {t('tableSupplierTransactionDrawerEditTitle')}
          </Text>

          <Text variant="text-small" className={styles['header-description']}>
            {t('tableSupplierTransactionDrawerEditDescription')}
          </Text>
        </div>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <div className={styles['supplier-info']}>
          <Text>{t('tableSupplierTransactionDrawerEditPaidToLabel')}</Text>
          <Text>
            <strong>{data?.data?.supplier?.name}</strong>
          </Text>
        </div>

        <div>
          <Label>
            {t('tableSupplierTransactionDrawerEditPaidStatusLabel')}
          </Label>
          <Select
            options={Object.keys(SupplierTransactionStatusProps).map((key) => ({
              label: tEnums(`supplier_transaction_status_${key}`),
              value: key,
            }))}
            value={values?.status}
            onChange={(newValue) => onHandleValues('status', newValue)}
          />
        </div>

        {values?.status === 'paid' && (
          <div>
            <Label>{t('tableSupplierTransactionDrawerEditBankLabel')}</Label>
            <Select
              value={values?.destinationBank}
              onChange={(newValue) =>
                onHandleValues('destinationBank', newValue)
              }
              options={[
                {
                  label: 'Santander',
                  value: 'Santander',
                },
                {
                  label: 'SVB',
                  value: 'SVB',
                },
                {
                  label: 'BBVA',
                  value: 'BBVA',
                },
              ]}
            />
          </div>
        )}

        {values?.status === 'paid' && (
          <div>
            <Label>
              {t('tableSupplierTransactionDrawerEditPaymentAtLabel')}
            </Label>
            <DatePicker
              value={values?.paymentAt}
              onChange={(newValue) => onHandleValues('paymentAt', newValue)}
              placeholder="dd/mm/yyyy"
            />
          </div>
        )}

        <div>
          <Label>{t('tableSupplierTransactionDrawerEditProofPaidLabel')}</Label>
          <DragDropFileList
            translations={{
              dragFile: t('uploadDragFile'),
              clickToUpload: t('uploadClickToUpload'),
              sizeFile: t('uploadSizeFile'),
            }}
            urlAdd={SUPPLY_TRANSACTION_URL(`${uuid}`)}
            configsRequest={{
              fileField: 'proof_file',
              method: 'PATCH',
            }}
            onClickPreview={(file) => {
              window.open(file, '_blank')
            }}
            initialData={getFileValue(data?.data?.proofFile)}
            axiosInstance={api}
          />
        </div>

        {data?.data?.supplyOrders?.map((order) => (
          <SupplierOrder
            key={order.uuid}
            invoice={order.invoice?.pdfFile}
            reference={order.reference || ''}
            updatedAt={order.updatedAt || ''}
            value={order.payment?.value || '0'}
            currency={order.payment?.currency || 'USD'}
          />
        ))}

        <div className={styles['total-card']}>
          <Text variant="title-large">TOTAL</Text>
          <Text variant="title-large">
            {formatCurrency(
              data?.data?.value || '0',
              'es-MX',
              data?.data?.currency || 'MXN',
            )}
          </Text>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('tableSupplierTransactionDrawerEditCancelButton')}
        </Button>
        <Button onClick={onSubmit} disabled={isPending} isActive={!isPending}>
          {t('tableSupplierTransactionDrawerEditConfirmButton')}
        </Button>
      </div>
    </Drawer>
  )
}
