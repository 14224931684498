import { useEffect, useState } from 'react'
import {
  AddressProps,
  CatalogProductProps,
  SupplierProductProps,
  SupplierWarehouseProps,
} from '@/types'
import {
  onErrorMessage,
  onSuccessMessage,
  useValidationErrors,
} from '@/helpers'

import { useQueryClient } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'

import { Button, DrawerStepperProgress } from '@mercai/clever'

import {
  QUERIES_ADMIN,
  useCatalogProductDetail,
  useCreateSupplierProduct,
  useCreateSupplierWarehouse,
} from '@/services'

import { SupplierProductDrawerFormProps } from './supplier-product-drawer-form.type'

import { schemaSupplierProducts, SupplierProductForm } from '@/components'

import { AddressForm } from '../address-form'
import { addressSchema } from '../address-drawer-form/address-drawer-form.constants'

export const SupplierProductDrawerForm = ({
  isOpen,
  onHandleOpen,
  catalogProductUuid,
  onCompleteCreateSupplierProduct,
}: SupplierProductDrawerFormProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const queryClient = useQueryClient()

  const [values, setValues] = useState<SupplierProductProps>({
    catalogProductUuid,
  } as SupplierProductProps)

  const { data } = useCatalogProductDetail(
    !isOpen ? undefined : catalogProductUuid,
  )

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaSupplierProducts(t),
    values,
  )

  const [newWarehouseValues, setNewWarehouseValues] =
    useState<Partial<AddressProps>>()

  const {
    isValid: isValidWarehouse,
    errors: errorsWarehouse,
    onHandleListenErrors: onHandleListenErrorsWarehouse,
  } = useValidationErrors(addressSchema(t), newWarehouseValues)

  const { mutateAsync: mutateAsyncCreateSupplierProduct } =
    useCreateSupplierProduct()

  const { mutateAsync: mutateAsyncCreateSupplierWarehouse } =
    useCreateSupplierWarehouse()

  const onSuccessUpdated = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.SUPPLIER_PRODUCTS.module],
    })
  }
  const onSuccessUpdatedNewWarehouse = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.SUPPLIER_WAREHOUSE.module],
    })
  }

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)

    const response = await mutateAsyncCreateSupplierProduct({
      ...values,
      catalogProductUuid: data?.data?.uuid,
    } as Partial<SupplierProductProps>)

    if (response.success) {
      onSuccessMessage(
        t('supplierProductDrawerFormSuccessCreateTitle'),
        t('supplierProductDrawerFormSuccessCreateMessage'),
      )

      onHandleOpen()

      onSuccessUpdated()
      onCompleteCreateSupplierProduct(response?.data as SupplierProductProps)
    } else {
      onErrorMessage(
        t('supplierProductDrawerFormErrorCreateTitle'),
        t('supplierProductDrawerFormErrorCreateMessage'),
      )
    }
  }

  const onHandleSubmitNewWarehouse = async (
    onNavigateToPreviousStep: () => void,
  ) => {
    if (!isValidWarehouse) {
      onHandleListenErrorsWarehouse(true)
      return
    }

    onHandleListenErrorsWarehouse(false)

    const response = await mutateAsyncCreateSupplierWarehouse({
      ...newWarehouseValues,
      supplierUuid: values?.supplierUuid,
    } as Partial<SupplierWarehouseProps>)

    if (response.success) {
      onNavigateToPreviousStep()

      onSuccessUpdatedNewWarehouse()
    } else {
      onErrorMessage(
        t('supplierProductDrawerFormWarehouseErrorCreateTitle'),
        t('supplierProductDrawerFormWarehouseErrorCreateMessage'),
      )
    }
  }

  const openNewWarehouse = (
    onNavigateToNextStep: (nextRoute: string) => void,
  ) => {
    onNavigateToNextStep('warehouse')
  }

  const onClose = () => {
    setValues({})
    setNewWarehouseValues({})
    onHandleOpen()
  }

  useEffect(() => {
    setValues({ catalogProductUuid } as SupplierProductProps)
  }, [catalogProductUuid, isOpen])

  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      onHandleOpen={onClose}
      initialStep="root"
      translations={{ optional: '' }}
      title={t('supplierProductDrawerFormTitle')}
      description={t('supplierProductDrawerFormTitle')}
      totalSteps={1}
      steps={{
        root: {
          stepNumber: 1,
          isOptional: false,
          backNavigate: '',
          component: ({ onNavigateToNextStep }) => (
            <SupplierProductForm
              errors={errors}
              onChangeValues={setValues}
              values={values}
              productItem={data?.data as CatalogProductProps}
              openNewWarehouse={() => openNewWarehouse(onNavigateToNextStep)}
            />
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('drawerFormBack')}
              </Button>
              <Button onClick={onHandleSubmit}>{t('drawerFormSubmit')}</Button>
            </>
          ),
        },
        warehouse: {
          stepNumber: 1,
          isOptional: false,
          backNavigate: 'root',
          component: () => (
            <AddressForm
              errors={errorsWarehouse}
              onChangeValues={setNewWarehouseValues}
              values={newWarehouseValues}
              isWarehouse
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  onNavigateToPreviousStep()
                  setNewWarehouseValues({})
                }}
              >
                {t('drawerFormBack')}
              </Button>
              <Button
                onClick={() =>
                  onHandleSubmitNewWarehouse(onNavigateToPreviousStep)
                }
              >
                {t('drawerFormSubmit')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
