import { useEffect, useState } from 'react'
import { Button, DrawerStepperProgress } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { QUERIES_ADMIN, useAddressList, useCreateAddress } from '@/services'
import { DrawerAddressProps } from './drawer-address.type'
import { AddressProps } from '@/types'
import { useValidationErrors } from '@/helpers'
import { shemaAddressItem } from './drawer-address.constants'
import { AddressForm, AddressList } from '@/pages/business/components'
import { useQueryClient } from '@tanstack/react-query'

export const DrawerAddress = ({
  isOpen,
  onHandleOpen,
  address,
  buyerProfileUuid,
  setAddress,
}: DrawerAddressProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const [internalAddress, setInternalAddress] = useState<Partial<AddressProps>>(
    address || {},
  )

  const queryClient = useQueryClient()

  const {
    isValid: isValidAddressItem,
    errors: addressItemErrors,
    onHandleListenErrors: onHandleListenErrorsAddressItem,
  } = useValidationErrors(shemaAddressItem(t), internalAddress)

  const { data: dataAddresses } = useAddressList(buyerProfileUuid)

  const { mutateAsync } = useCreateAddress(buyerProfileUuid)

  const onHandleNewAddress = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('addressForm')
  }

  const onSubmitAddress = () => {
    if (internalAddress) {
      setAddress(internalAddress)
    }

    queryClient.invalidateQueries({
      queryKey: [QUERIES_ADMIN.ADDRESS.module, buyerProfileUuid],
    })

    onHandleOpen()
  }

  const onSubmitNewAddress = async () => {
    if (isValidAddressItem) {
      const response = await mutateAsync(internalAddress)

      if (response.success) {
        onHandleListenErrorsAddressItem(false)

        setAddress(response?.data || {})

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.ADDRESS.module, buyerProfileUuid],
        })

        onHandleOpen()
      }
    } else {
      onHandleListenErrorsAddressItem(true)
    }
  }

  useEffect(() => {
    if (address) setInternalAddress(address)
  }, [address])

  return (
    <DrawerStepperProgress
      initialStep="root"
      totalSteps={1}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      translations={{
        optional: '',
      }}
      title={t('addressListTitle')}
      steps={{
        root: {
          stepNumber: 1,
          title: t('addressListTitle'),
          isOptional: true,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <AddressList
              onClickNewAddress={() =>
                onHandleNewAddress({ onNavigateToNextStep })
              }
              onChangeAddressSelected={(address) => setInternalAddress(address)}
              addressSelected={internalAddress as AddressProps}
              addresses={dataAddresses?.data?.addresses || []}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonCancel')}
              </Button>

              <Button onClick={() => onSubmitAddress()}>
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
        addressForm: {
          stepNumber: 1,
          title: t('addressFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <AddressForm
              onChangeValues={setInternalAddress}
              values={internalAddress}
              errors={addressItemErrors as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitNewAddress()}>
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
