import { useEffect, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@mercai/clever'

import {
  Header,
  OrderCenterShow,
  ProfileTaxSituationForm,
  OrderCenterForm,
} from '@/components'
import { DEFAULT_LIMIT_PAGE } from '@/constants'
import {
  trackEvent,
  useParamsState,
  onConfirmDangerMessage,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'

import {
  OrderCenterTable,
  OrderCenterTableTransaction,
  OrderCenterMetrics,
} from './components'

import {
  useOrderCenterProfileList,
  useOrderCenterFinanceList,
  useOrderCenterLogisticList,
  useOrderCenterSaleOrderList,
  useOrderCenterSaleOrderRemove,
  useTransactionList,
  useUserList,
} from '@/services'
import { SaleOrderWithPagesProps } from '@/types'

import styles from './order-center.module.css'

import { OrderCenterPageEnum } from './order-center.type'

const defaultFilter = {
  page: '1',
  limit: `${DEFAULT_LIMIT_PAGE}`,
  search: '',
  taxStatus: '',
  moscow: '',
  created_at_before: '',
  created_at_after: '',
  ordering: '',
  key_account_manager: '',
}

export const OrderCenterPage = () => {
  const { t } = useTranslation('screenOrderCenterPage')
  const navigate = useNavigate()

  const { whoAmI } = useAuth()
  const { data: dataUserList } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const [filter, setFilter] = useParamsState({
    ...JSON.parse(JSON.stringify(defaultFilter)),
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    action: '',
  })

  const {
    data: dataProfile,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
  } = useOrderCenterProfileList(filter, page === 'profile')

  const {
    data: dataFinance,
    isLoading: isLoadingFinance,
    refetch: refetchFinance,
  } = useOrderCenterFinanceList(filter, page === 'finance')

  const {
    data: dataLogistic,
    isLoading: isLoadingLogistic,
    refetch: refetchLogistic,
  } = useOrderCenterLogisticList(filter, page === 'logistic')

  const {
    data: dataSaleOrder,
    isLoading: isLoadginSaleOrder,
    refetch: refetchSaleOrder,
  } = useOrderCenterSaleOrderList(filter, page === 'sale' || page === 'all')

  const {
    data: dataTransaction,
    isLoading: isLoadingTransaction,
    refetch: refetchTransaction,
  } = useTransactionList(filter, page === 'transaction')

  const refetch = () => {
    const objectRefetch: Record<string, () => void> = {
      profile: refetchProfile,
      finance: refetchFinance,
      logistic: refetchLogistic,
      sale: refetchSaleOrder,
      transaction: refetchTransaction,
    }

    objectRefetch[page as string]()
  }

  const whoAmIUser = useMemo(() => {
    if (whoAmI?.email) {
      return dataUserList?.data?.results?.find(
        (user) => user.email === whoAmI?.email,
      )
    }
  }, [dataUserList, whoAmI])

  const userFiltered = useMemo(() => {
    if (
      filter.key_account_manager &&
      filter.key_account_manager === 'undefined'
    ) {
      return dataUserList?.data?.results?.find(
        (user) => user.email === whoAmI?.email,
      )
    }

    return dataUserList?.data?.results?.find(
      (user) => user.id === +filter?.key_account_manager,
    )
  }, [dataUserList, filter.key_account_manager, whoAmI])

  const { mutateAsync: mutateDelete } = useOrderCenterSaleOrderRemove()

  const onHandleNavigate = (page: string) => {
    setFilter({
      ...JSON.parse(JSON.stringify(defaultFilter)),
    })

    navigate(ROUTES_ADMIN.ORDER_CENTER.fullPath(page), { replace: true })
  }

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('orderCenterTableDeleteConfirmTitle'),
      t('orderCenterTableDeleteConfirmMessage'),
      t('orderCenterTableDeleteConfirmButtonDelete'),
      t('orderCenterTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('orderCenterTableDeleteSuccessTitle'),
          t('orderCenterTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('orderCenterTableDeleteErrorTitle'),
          t('orderCenterTableDeleteErrorMessage'),
        )
      }
    }
  }

  const defaultProps = {
    filter,
    onEditRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'form',
      }),
    onSetFilter: setFilter,
    onShowRegister: (uuid: string) =>
      setQueryParams({
        uuid,
        action: 'show',
      }),
    formattedFilter: {
      key_account_manager: userFiltered?.email || '',
    },
    onDeleteRegister: onHandleDelete,
  }

  const objectsRoute: Record<string, ReactNode> = {
    all: (
      <OrderCenterTable
        {...defaultProps}
        data={dataSaleOrder?.data}
        isLoading={isLoadginSaleOrder}
        type="all"
      />
    ),
    sale: (
      <OrderCenterTable
        {...defaultProps}
        data={dataSaleOrder?.data}
        isLoading={isLoadginSaleOrder}
        type="sale"
      />
    ),
    profile: (
      <OrderCenterTable
        {...defaultProps}
        data={dataProfile?.data}
        isLoading={isLoadingProfile}
        type="profile"
      />
    ),
    finance: (
      <OrderCenterTable
        {...defaultProps}
        data={dataFinance?.data as SaleOrderWithPagesProps}
        isLoading={isLoadingFinance}
        type="finance"
      />
    ),
    transaction: (
      <OrderCenterTableTransaction
        {...defaultProps}
        data={dataTransaction?.data}
        isLoading={isLoadingTransaction}
      />
    ),
    logistic: (
      <OrderCenterTable
        {...defaultProps}
        data={dataLogistic?.data as SaleOrderWithPagesProps}
        isLoading={isLoadingLogistic}
        type="logistic"
      />
    ),
  }

  useEffect(() => {
    trackEvent('adminOrderCenterPageView', { page })

    if (page === 'sale' && whoAmI?.email) {
      setFilter({
        ...filter,
        page: '1',
        key_account_manager: `${whoAmIUser?.id}`,
      })
    }

    if (page !== 'sale') {
      setFilter({
        ...filter,
        page: '1',
        key_account_manager: '',
      })
    }
  }, [page])

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'all'}
            onClick={() => onHandleNavigate('all')}
          >
            {t('buttonNavAll')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'sale'}
            onClick={() => onHandleNavigate('sale')}
          >
            {t('buttonNavSale')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'profile'}
            onClick={() => onHandleNavigate('profile')}
          >
            {t('buttonNavProfile')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'finance'}
            onClick={() => onHandleNavigate('finance')}
          >
            {t('buttonNavFinance')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'transaction'}
            onClick={() => onHandleNavigate('transaction')}
          >
            {t('buttonNavTransaction')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            isActive={page === 'logistic'}
            onClick={() => onHandleNavigate('logistic')}
          >
            {t('buttonNavLogistic')}
          </Button>
        </div>

        <OrderCenterMetrics
          type={page as OrderCenterPageEnum}
          filter={filter}
          onFilterChange={setFilter}
        />

        {objectsRoute?.[page as string]}
      </div>

      <OrderCenterShow
        isOpen={queryParams.action === 'show'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={page}
      />

      <ProfileTaxSituationForm
        isOpen={queryParams.action === 'form' && page === 'profile'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        onSuccessUpdated={() => refetchProfile()}
      />

      <OrderCenterForm
        isOpen={queryParams.action === 'form' && page !== 'profile'}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, action: '', uuid: '' })
        }
        saleOrderUuid={queryParams.uuid}
        type={page}
      />
    </div>
  )
}
