import { Button, Text } from '@mercai/clever'
import {
  generateContainerClasses,
  generateMessageClasses,
} from './generate-supplier-transaction-button.constants'
import { GenerateSupplierOrderButtonProps } from './generate-supplier-transaction-button.types'
import { useTranslation } from 'react-i18next'

export const GenerateSupplierOrderButton = ({
  isOpen,
  isValid,
  onGenerateSupplierTransaction,
  isLoading,
}: GenerateSupplierOrderButtonProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  return (
    <div className={generateContainerClasses(isOpen, isValid)}>
      <div className={generateMessageClasses(isValid)}>
        <Text>{t('generateSupplierOrderButtonMessage')}</Text>
        <Button
          variant="primary"
          onClick={onGenerateSupplierTransaction}
          disabled={isLoading}
          isActive={!isLoading}
        >
          {t('generateSupplierOrderButtonButton')}
        </Button>
      </div>
      <div className={generateMessageClasses(!isValid)}>
        <Text>{t('generateSupplierOrderButtonInvalidMessage')}</Text>
      </div>
    </div>
  )
}
