import { Button, CloseIcon, Drawer, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  DrawerFilterCreatedAtIntervalCard,
  DrawerFilterInvoiceStatusCard,
  DrawerFilterKamCard,
  DrawerFilterMoscowCard,
  DrawerFilterPaymentStatusCard,
  DrawerFilterTaxStatusCard,
  DrawerFilterLogisticStatusCard,
  DrawerFilterSalesOrderStatusCard,
  DrawerFilterRootCategoryCard,
  DrawerFilterIsSampleCard,
  DrawerFilterDestinationAddressCard,
} from './components'

import styles from './drawer-filter.module.css'

import { DrawerFilterProps } from './drawer-filter.type'

export const GeneratePurchaseOrderDrawerFilter = ({
  isOpen,
  onHandleOpen,
  filter,
  onHandleFilter,
}: DrawerFilterProps) => {
  const { t } = useTranslation('screenSupplierOrderManagementPage')

  const commonProps = {
    filter,
    onChangeFilter: onHandleFilter,
  }

  return (
    <Drawer isOpen={isOpen} onHandleOpen={onHandleOpen}>
      <div className={styles.header}>
        <Text variant="title-large" className={styles['header-title']}>
          {t('drawerFilterTaxStatusTitle')}
        </Text>

        <button
          className={styles['header-button']}
          type="button"
          onClick={onHandleOpen}
        >
          <CloseIcon />
        </button>
      </div>

      <div className={styles.content}>
        <DrawerFilterDestinationAddressCard
          key="destination_address"
          {...commonProps}
        />
        <DrawerFilterSalesOrderStatusCard
          key="sales_order_status"
          {...commonProps}
        />
        <DrawerFilterTaxStatusCard key="tax_status" {...commonProps} />
        <DrawerFilterInvoiceStatusCard key="invoice_status" {...commonProps} />
        <DrawerFilterPaymentStatusCard key="payment_status" {...commonProps} />
        <DrawerFilterLogisticStatusCard
          key="shipment_status"
          {...commonProps}
        />
        <DrawerFilterMoscowCard key="moscow" {...commonProps} />
        <DrawerFilterKamCard key="key_account_manager" {...commonProps} />
        <DrawerFilterRootCategoryCard key="root_category" {...commonProps} />
        <DrawerFilterCreatedAtIntervalCard
          key="created_at_interval"
          {...commonProps}
        />
        <DrawerFilterIsSampleCard key="is_sample" {...commonProps} />
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onHandleOpen}>
          {t('drawerFilterTaxStatusButtonBack')}
        </Button>
      </div>
    </Drawer>
  )
}
