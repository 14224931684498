import { SaleOrderProps } from '@/types'

export const checkIfCheckedSaleOrdersIsValid = (
  values: Record<string, SaleOrderProps>,
) => {
  const arrayValues = Object.values(values)

  if (arrayValues.length === 0) return true

  const firstSupplierUuid = arrayValues?.[0].shipments?.[0].supplier.uuid

  return arrayValues.every(
    (saleOrder) => saleOrder.shipments?.[0].supplier.uuid === firstSupplierUuid,
  )
}
