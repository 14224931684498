import { useMutation } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import {
  CalculateMarginPayloadProps,
  CalculateMarginProps,
  CalculateUnitPricePayloadProps,
  CalculateUnitPriceProps,
  GetAddressPayloadProps,
  BaseAddressProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import {
  ADDRESS_URL,
  CALCULATE_MARGIN_URL,
  CALCULATE_UNIT_PRICE_URL,
} from './api.urls'

export const useCalculateMargin = () => {
  const calculateMargin = async (
    payload: CalculateMarginPayloadProps,
  ): Promise<{
    success: boolean
    message: string
    data?: CalculateMarginProps
  }> => {
    try {
      const { data } = await api.post(
        CALCULATE_MARGIN_URL,
        convertObjectToSnakeCase(
          !payload?.deliveryOption
            ? { ...payload, deliveryOption: 'receive_at_address' }
            : payload,
        ),
      )

      return {
        success: true,
        message: '',
        data: convertObjectToCamelCase(data) as CalculateMarginProps,
      }
    } catch (err) {
      return {
        success: false,
        message: '',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CALCULATE_MARGIN.module],
    mutationFn: (payload: CalculateMarginPayloadProps) =>
      calculateMargin(payload),
  })
}

export const useCalculateUnitPrice = () => {
  const calculateUnitPrice = async (
    payload: CalculateUnitPricePayloadProps,
  ): Promise<{
    success: boolean
    message: string
    data?: CalculateUnitPriceProps
  }> => {
    try {
      const { data } = await api.post(
        CALCULATE_UNIT_PRICE_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: '',
        data: convertObjectToCamelCase(data) as CalculateUnitPriceProps,
      }
    } catch (err) {
      return {
        success: false,
        message: '',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.CALCULATE_UNIT_PRICE_URL.module],
    mutationFn: (payload: CalculateUnitPricePayloadProps) =>
      calculateUnitPrice(payload),
  })
}

export const useGetAddress = () => {
  const getAddress = async (
    payload: GetAddressPayloadProps,
  ): Promise<{
    success: boolean
    message: string
    data?: BaseAddressProps[]
  }> => {
    try {
      const { data } = await api.get(
        `${ADDRESS_URL}${payload.country}/${payload.postalCode}/`,
      )

      return {
        success: true,
        message: '',
        data: convertObjectToCamelCase(data) as BaseAddressProps[],
      }
    } catch (err) {
      return {
        success: false,
        message: '',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.ADDRESS.module],
    mutationFn: (payload: GetAddressPayloadProps) => getAddress(payload),
  })
}
