import { DocumentsList } from './documents.type'

const documentsList = {
  internal: [
    {
      // Factura de transporte
      fileTranslationId: 'logisticDocumentsInvoiceLabel',
      file: 'shipmentInvoice',
      configRequest: {
        fileField: 'shipment_invoice',
        method: 'PATCH',
      },
    },
    {
      // Remisión
      fileTranslationId: 'logisticDocumentsRemision',
      file: 'deliveryList',
      configRequest: {
        fileField: 'delivery_list',
        method: 'PATCH',
      },
    },
    {
      // Packing list
      fileTranslationId: 'logisticDocumentsPackingList',
      file: 'packingList',
      configRequest: {
        fileField: 'packing_list',
        method: 'PATCH',
      },
    },
    {
      // Bill of landing
      fileTranslationId: 'logisticDocumentsBillOfLanding',
      file: 'billOfLanding',
      configRequest: {
        fileField: 'bill_of_landing',
        method: 'PATCH',
      },
    },
    {
      // Salida de almacén
      fileTranslationId: 'logisticDocumentsWarehouseExit',
      file: 'warehouseExit',
      configRequest: {
        fileField: 'warehouse_exit',
        method: 'PATCH',
      },
    },
    {
      // Factura de impuestos
      fileTranslationId: 'logisticDocumentsTaxInvoice',
      file: 'shipmentReceipt',
      configRequest: {
        fileField: 'shipment_receipt',
        method: 'PATCH',
      },
    },
  ] as DocumentsList,
  external: [
    {
      // COO - Certificado de origen
      fileTranslationId: 'logisticDocumentsOriginCertificateLabel',
      file: 'originCertificate',
      configRequest: {
        fileField: 'origin_certificate',
        method: 'PATCH',
      },
    },
    {
      // COA - Certificado de calidad
      fileTranslationId: 'logisticDocumentsCustomsCertificateLabel',
      file: 'customsCertificate',
      configRequest: {
        fileField: 'customs_certificate',
        method: 'PATCH',
      },
    },
    {
      // Evidencia de entrega
      fileTranslationId: 'logisticDocumentsDeliveryProof',
      file: 'proofOfDelivery',
      configRequest: {
        fileField: 'proof_of_delivery',
        method: 'PATCH',
      },
    },
  ] as DocumentsList,
}

export default documentsList
