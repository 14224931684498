import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import type {
  GeneratePurchaseOrderPayload,
  GeneratePurchaseOrderPreviewResponse,
  GeneratePurchaseOrderResponse,
  SupplierOrderProps,
  SupplierOrderWithPagesProps,
  SupplierPaymentProps,
} from '@/types'

import {
  GENERATE_PURCHASE_ORDER_PDF_PREVIEW_URL,
  GENERATE_PURCHASE_ORDER_PREVIEW_URL,
  GENERATE_PURCHASE_ORDER_URL,
  SUPPLY_ORDER_PAYMENT_URL,
  SUPPLY_ORDER_URL,
  SUPPLY_ORDERS_URL,
} from './api.urls'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useGeneratePurchaseOrder = () => {
  const generatePurchaseOrder = async (
    payload: GeneratePurchaseOrderPayload,
  ): Promise<{
    success: boolean
    message: string
    data?: GeneratePurchaseOrderResponse
  }> => {
    try {
      const { data } = await api.post(
        GENERATE_PURCHASE_ORDER_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data: convertObjectToCamelCase(data) as GeneratePurchaseOrderResponse,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.GENERATE_PURCHASE_ORDER_PREVIEW.module],
    mutationFn: (payload: GeneratePurchaseOrderPayload) =>
      generatePurchaseOrder(payload),
  })
}

export const useGeneratePurchaseOrderPreview = (
  payload: GeneratePurchaseOrderPayload,
  isEnabled: boolean,
) => {
  const generatePurchaseOrderPreview = async (): Promise<{
    success: boolean
    message: string
    data?: GeneratePurchaseOrderPreviewResponse
  }> => {
    try {
      const { data } = await api.post(
        GENERATE_PURCHASE_ORDER_PREVIEW_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as GeneratePurchaseOrderPreviewResponse,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.GENERATE_PURCHASE_ORDER_PREVIEW.module, payload],
    queryFn: () => generatePurchaseOrderPreview(),
    enabled: isEnabled,
  })
}

export const useGeneratePurchaseOrderPDFPreview = (
  payload: GeneratePurchaseOrderPayload,
  isEnabled: boolean,
) => {
  const generatePurchaseOrderPDFPreview = async (): Promise<{
    success: boolean
    message: string
    data?: Blob
  }> => {
    try {
      const { data } = await api.post(
        GENERATE_PURCHASE_ORDER_PDF_PREVIEW_URL,
        convertObjectToSnakeCase(payload),
        {
          responseType: 'blob',
        },
      )

      return {
        success: true,
        message: 'successfully!',
        data,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [
      QUERIES_ADMIN.GENERATE_PURCHASE_ORDER_PDF_PREVIEW.module,
      payload,
    ],
    queryFn: () => generatePurchaseOrderPDFPreview(),
    enabled: isEnabled,
  })
}

export const useSupplierOrderList = (
  params: Record<string, string | number>,
  isEnabled = true,
) => {
  const getOrderSaleOrderList = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierOrderWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key] && copyParams?.[key] !== 'undefined') {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SUPPLY_ORDERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Supplier Order fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierOrderWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module, params],
    queryFn: () => getOrderSaleOrderList(),
    enabled: isEnabled,
  })
}

export const useSupplierOrderDetail = (uuid?: string) => {
  const getSupplierOrderDetail = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierOrderProps
  }> => {
    try {
      const { data } = await api.get(SUPPLY_ORDER_URL(`${uuid}`))

      return {
        success: true,
        message: 'Supplier Order fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get Supplier Order!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module, uuid],
    queryFn: () => getSupplierOrderDetail(),
    enabled: !!uuid,
  })
}

export const useSupplierOrderEdit = (uuid: string) => {
  const supplierOrderEdit = async (
    payload: Partial<SupplierOrderProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierOrderProps
  }> => {
    try {
      const { data } = await api.patch(
        SUPPLY_ORDER_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data: convertObjectToCamelCase(data) as SupplierOrderProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
    mutationFn: (payload: Partial<SupplierOrderProps>) =>
      supplierOrderEdit(payload),
  })
}

export const useSupplierPaymentEdit = (uuid: string) => {
  const supplierOrderEdit = async (
    payload: Partial<SupplierPaymentProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierPaymentProps
  }> => {
    try {
      const { data } = await api.patch(
        SUPPLY_ORDER_PAYMENT_URL(`${uuid}`),
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'successfully!',
        data: convertObjectToCamelCase(data) as SupplierPaymentProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_ORDER.module],
    mutationFn: (payload: Partial<SupplierPaymentProps>) =>
      supplierOrderEdit(payload),
  })
}
