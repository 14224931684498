import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, EditIcon, Text } from '@mercai/clever'
import { Accordion } from '@/pages/business/components'

import { CreateSelectProductsProps } from './create-select-products.type'

import styles from './create-select-products.module.css'
import { generateButtonClasses } from './create-select-products.constants'
import { ProductCard } from '@/pages/business/components/product-card'
import { DrawerSelectProducts } from '../drawer-select-products'

export const CreateSelectProducts = ({
  activeItems,
  setActiveItems,
  isComplete,
  values,
  errors,
  setValues,
}: CreateSelectProductsProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const [selected, setSelected] = useState<number>(0)

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      activeItems={activeItems}
      setActiveItems={setActiveItems}
      title={`2. ${t('selectProductsTitle')}`}
      id="catalog-products"
      key="catalog-products"
      isComplete={isComplete}
    >
      <div>
        <div className={styles.header}>
          <div className={styles['button-container']}>
            {!values?.file &&
              values?.items &&
              values?.items?.map((product, i) => {
                return (
                  <Button
                    key={`${product?.uuid}-${i}`}
                    model="pill"
                    variant="primary-outline"
                    className={generateButtonClasses(selected === i)}
                    onClick={() => setSelected(i)}
                  >
                    {`${t('catalogProductsButton')} ${i + 1}`}
                  </Button>
                )
              })}

            {!values?.items && values?.file && (
              <Text variant="text-base">
                <strong>{t('selectProductsAddedByFile')}</strong>
              </Text>
            )}

            {!values?.items && !values?.file && (
              <Text variant="text-base">
                <strong>{t('selectProductsAddInstruction')}</strong>
              </Text>
            )}
          </div>
          {values?.items || values?.file ? (
            <Button
              variant="secondary"
              model="square"
              size="small"
              onClick={() => setIsOpen(true)}
            >
              <EditIcon />
            </Button>
          ) : (
            <Button variant="success-light" onClick={() => setIsOpen(true)}>
              {t('selectProductsAddButton')}
            </Button>
          )}
        </div>

        {values?.items && values?.items.length > 0 && (
          <ProductCard
            actions={<></>}
            img={
              values?.items?.[selected]?.catalogProduct?.attributes
                ?.mainImage as string
            }
            title={
              values?.items?.[selected]?.catalogProduct?.attributes?.title ||
              '-'
            }
            category={
              values?.items?.[selected]?.catalogProduct?.category?.name || '-'
            }
            density={
              values?.items?.[selected]?.catalogProduct?.attributes?.density ||
              '-'
            }
            fluidity={
              values?.items?.[selected]?.catalogProduct?.attributes?.fluidity ||
              '-'
            }
            isSimilarAccepted={
              values?.items?.[selected]?.isSimilarAccepted || false
            }
            quantity={values?.items?.[selected]?.quantity}
            purchaseFrequency={values?.items?.[selected]?.purchaseFrequency}
            targetPrice={values?.items?.[selected]?.targetPrice}
            observation={values?.items?.[selected]?.observation}
          />
        )}

        {!!errors?.atLeastOne && (
          <Text variant="text-base" className={styles.error}>
            {errors.atLeastOne as string}
          </Text>
        )}

        <DrawerSelectProducts
          isOpen={isOpen}
          onHandleOpen={() => setIsOpen(false)}
          values={values}
          setValues={setValues}
        />
      </div>
    </Accordion>
  )
}
