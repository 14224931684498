import { useMutation, useQuery } from '@tanstack/react-query'

import { convertObjectToCamelCase } from '@/helpers'
import {
  IAConversationProps,
  IAConversationListWithPagesProps,
  IAConversationMetricsProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'

import {
  AI_CONVERSATIONS_URL,
  AI_CONVERSATION_URL,
  AI_CONVERSATIONS_EVALUATION_URL,
  AI_CONVERSATION_EMAIL_URL,
  AI_CONVERSATIONS_REGENERATE_URL,
  AI_CONVERSATION_METRICS_URL,
} from './api.urls'

export const useAIConversationList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getConversationList = async (): Promise<{
    success: boolean
    message: string
    data?: IAConversationListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(AI_CONVERSATIONS_URL, {
        params: {
          ...(formattedParams as Record<string, string>),
        },
      })

      return {
        success: true,
        message: 'Conversations fetched successfully!',
        data: data as IAConversationListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get conversations!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.AI_CONVERSATION.module, params],
    queryFn: () => getConversationList(),
  })
}

export const useAIConversationDetail = (uuid?: string, email?: string) => {
  const getConversationDetail = async (): Promise<{
    success: boolean
    message: string
    data?: IAConversationProps
  }> => {
    try {
      const { data } = await api.get(
        uuid ? AI_CONVERSATION_URL(uuid) : AI_CONVERSATION_EMAIL_URL(email),
      )

      return {
        success: true,
        message: 'Conversation fetched successfully!',
        data: data as IAConversationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get conversation!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.AI_CONVERSATION.module, uuid],
    queryFn: () => getConversationDetail(),
    enabled: !!uuid || !!email,
  })
}

export const useAICreateConversation = () => {
  const createConversation = async (
    payload?: Partial<IAConversationProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: IAConversationProps
  }> => {
    try {
      const { data } = await api.post(AI_CONVERSATIONS_URL, payload)

      return {
        success: true,
        message: 'Conversation created successfully!',
        data: data as IAConversationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create conversation!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_CONVERSATION.module],
    mutationFn: (payload?: Partial<IAConversationProps>) =>
      createConversation(payload),
  })
}

export const useAIUpdateConversation = () => {
  const updateConversation = async ({
    uuid,
    ...payload
  }: Partial<IAConversationProps>): Promise<{
    success: boolean
    message: string
    data?: IAConversationProps
  }> => {
    try {
      const { data } = await api.patch(AI_CONVERSATION_URL(uuid), payload)

      return {
        success: true,
        message: 'Conversation updated successfully!',
        data: data as IAConversationProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update conversation!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_CONVERSATION.module],
    mutationFn: (payload: Partial<IAConversationProps>) =>
      updateConversation(payload),
  })
}

export const useAIEvaluationConversation = () => {
  const evaluateConversation = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      await api.post(AI_CONVERSATIONS_EVALUATION_URL(uuid))

      return {
        success: true,
        message: 'Conversation evaluated successfully!',
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to evaluate conversation!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_EVALUATION.module],
    mutationFn: (uuid: string) => evaluateConversation(uuid),
  })
}

export const useAIRegenerateConversation = () => {
  const regenerateConversation = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
    data?: undefined
  }> => {
    try {
      const { data } = await api.post(AI_CONVERSATIONS_REGENERATE_URL(uuid))

      return {
        success: true,
        message: data,
        data: undefined,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to regenerate conversation!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_REGENERATE.module],
    mutationFn: (uuid: string) => regenerateConversation(uuid),
  })
}

export const useAIDeleteConversation = () => {
  const deleteConversation = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
  }> => {
    try {
      await api.delete(AI_CONVERSATION_URL(uuid))

      return {
        success: true,
        message: 'Conversation deleted successfully!',
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete conversation!',
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_CONVERSATION.module],
    mutationFn: (uuid: string) => deleteConversation(uuid),
  })
}

export const useAIConversationMetrics = () => {
  const getConversationMetrics = async (): Promise<{
    success: boolean
    message: string
    data?: IAConversationMetricsProps
  }> => {
    try {
      const { data } = await api.get(AI_CONVERSATION_METRICS_URL)

      return {
        success: true,
        message: 'Conversation metrics fetched successfully!',
        data: convertObjectToCamelCase(data) as IAConversationMetricsProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get conversation metrics!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.AI_CONVERSATION_METRICS.module],
    queryFn: getConversationMetrics,
  })
}
