import { CurrencyEnum } from './currency.type'
import { SupplierOrderProps } from './supplier-order.type'
import { SupplierProps } from './supplier.type'

export enum SupplierTransactionStatusProps {
  'pending' = 'pending',
  'paid' = 'paid',
}

export interface SupplierTransactionProps {
  uuid?: string
  status: keyof typeof SupplierTransactionStatusProps
  value: string
  currency: keyof typeof CurrencyEnum
  reference?: string
  supplier: SupplierProps
  supplyOrders: SupplierOrderProps[]
  destinationBank: string
  paymentAt?: string
  expectedPaymentDate?: string
  proofFile: string
  createdAt?: string
  updatedAt?: string
}

export interface SupplierTransactionWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: SupplierTransactionProps[]
}

export interface SupplierTransactionCreatePayload {
  supplyOrders: string[]
}
