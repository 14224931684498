import { ReactNode, useRef, useState, useEffect } from 'react'
import {
  Button,
  ExpandCircleDownIcon,
  Input,
  Label,
  Modal,
  Tag,
} from '@mercai/clever'

import { QuotationStatusEnum } from '@/types'
import { useTranslation } from 'react-i18next'
import { RfqStatusSelectProps } from './quotation-status-select.type'

import styles from './quotation-status-select.module.css'
import { QUERIES_ADMIN, useUpdateQuotation } from '@/services'
import { onErrorMessage } from '@/helpers'
import { useQueryClient } from '@tanstack/react-query'

export const QuotationStatusSelect = ({ quotation }: RfqStatusSelectProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  const queryClient = useQueryClient()

  const [invalidateStatus, setInvalidateStatus] = useState<'rejected' | ''>('')
  const [invalidateReason, setInvalidateReason] = useState('')
  const [invalidateReasonShowError, setInvalidateReasonShowError] =
    useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const popperRef = useRef<HTMLDivElement | null>(null)

  const stateObject: Record<QuotationStatusEnum, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_status_pending')}
        <ExpandCircleDownIcon style={{ fill: 'var(--warning-800)' }} />
      </Tag>
    ),
    active: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_status_active')}
        <ExpandCircleDownIcon style={{ fill: 'var(--primary-800)' }} />
      </Tag>
    ),
    accepted: (
      <Tag variant="success" model="light">
        {tEnums('quotation_status_accepted')}
        <ExpandCircleDownIcon style={{ fill: 'var(--success-800)' }} />
      </Tag>
    ),
    expired: (
      <Tag variant="basic" model="light">
        {tEnums('quotation_status_expired')}
        <ExpandCircleDownIcon style={{ fill: 'var(--basic-800)' }} />
      </Tag>
    ),
    rejected: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_status_rejected')}
        <ExpandCircleDownIcon style={{ fill: 'var(--danger-800)' }} />
      </Tag>
    ),
    pending_approval: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_status_pending_approval')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_status_cancelled')}
        <ExpandCircleDownIcon style={{ fill: 'var(--danger-800)' }} />
      </Tag>
    ),
  }

  const { mutateAsync: mutateAsyncUpdateQuotation, isPending } =
    useUpdateQuotation(quotation?.uuid || '')

  const togglePopper = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  const onHandleCloseInvalidateModal = () => {
    setInvalidateStatus('')
    setInvalidateReason('')
    setInvalidateReasonShowError(false)
  }

  const onHandleOpenInvalidateModal = (val: 'rejected' | '') => {
    setIsOpen(false)
    setInvalidateStatus(val)
    setInvalidateReason('')
  }

  const onSubmit = async (val: keyof typeof QuotationStatusEnum) => {
    setIsOpen(false)

    if (val === 'rejected' && !invalidateReason) {
      return setInvalidateReasonShowError(true)
    }

    const response = await mutateAsyncUpdateQuotation({
      ...quotation,
      status: val,
      logisticTransportCompanyUuid: quotation?.logisticTransportCompany?.uuid,
      buyerProfileUuid: quotation?.buyerProfile?.uuid,
      products:
        quotation?.products?.map((product) => ({
          ...product,
          catalogProductUuid: product.catalogProduct?.uuid,
          supplierUuid: product.supplier?.uuid,
        })) || [],
      ...(invalidateReason ? { rejectedReason: invalidateReason } : {}),
    })

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION.module],
      })
      onHandleCloseInvalidateModal()
    } else {
      onErrorMessage(
        t('quotationStatusSelectErrorTitle'),
        t('quotationStatusSelectErrorMessage'),
      )
    }
  }

  const allowedStatuses = Object.keys(QuotationStatusEnum).filter(
    (key) => !['accepted', 'expired', 'pending_approval'].includes(key),
  )

  const handleOnClick = (key: string) => {
    if (key === '' || key === 'rejected') {
      return onHandleOpenInvalidateModal(key)
    }

    onSubmit(key as keyof typeof QuotationStatusEnum)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.core}>
      <div
        ref={buttonRef}
        onClick={togglePopper}
        className={styles['status-button']}
      >
        {stateObject[quotation?.status || 'pending']}
      </div>
      {isOpen && (
        <div ref={popperRef} className={styles.popper}>
          <ul>
            {allowedStatuses.map((key) => (
              <li
                key={key}
                className={key === quotation?.status ? styles.active : ''}
                onClick={() => handleOnClick(key)}
              >
                {tEnums(`quotation_status_${key}`)}
              </li>
            ))}
          </ul>
        </div>
      )}
      <Modal.Root
        isOpen={!!invalidateStatus}
        onHandleOpen={onHandleCloseInvalidateModal}
        size="medium"
      >
        <Modal.Header title={t('quotationStatusSelectInvalidateModalTitle')} />
        <Modal.Body className={styles['modal-body']}>
          <div>
            <Label>
              {t('quotationStatusSelectInvalidateModalStatusLabel')}
            </Label>
            <Input
              className={styles['status-input']}
              value={
                invalidateStatus
                  ? tEnums(`quotation_status_${invalidateStatus}`)
                  : ''
              }
            />
          </div>
          <div>
            <Label className={styles['reason-label']}>
              {t('quotationStatusSelectInvalidateModalReasonLabel')}
              <span>*</span>
            </Label>
            <Input
              placeholder={t(
                'quotationStatusSelectInvalidateModalReasonPlaceholder',
              )}
              value={invalidateReason}
              onChange={(value) => setInvalidateReason(value)}
              error={invalidateReasonShowError ? t('requiredMessage') : ''}
              isTextArea
            />
          </div>
          <div className={styles['modal-footer']}>
            <Button variant="secondary" onClick={onHandleCloseInvalidateModal}>
              {t('quotationStatusSelectInvalidateModalCancelButton')}
            </Button>
            <Button
              variant="primary"
              onClick={() => onSubmit(invalidateStatus as 'rejected')}
              disabled={isPending}
            >
              {t('quotationStatusSelectInvalidateModalConfirmButton')}
            </Button>
          </div>
        </Modal.Body>
      </Modal.Root>
    </div>
  )
}
