import {
  Button,
  InputNumber,
  Label,
  Select,
  Radio,
  Tooltip,
  QuestionFillIcon,
  Input,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useValidationErrors } from '@/helpers'
import { useUserList } from '@/services'

import { schemaOrder } from './order-form.constants'

import type { OrderFormProps } from './order-form.type'

import styles from './order-form.module.css'
import { OrderInvoiceProps } from '@/types'

export const OrderForm = ({
  onChangeValues,
  onChangeShipmentValues,
  onChangePaymentValues,
  values,
  shipmentValues,
  paymentValues,
  onBack,
  onHandleSubmit,
}: OrderFormProps) => {
  const { t } = useTranslation('componentsOrderForm')

  const { data: dataUsers } = useUserList({
    params: {
      limit: 100,
      page: 1,
      isStaff: 'true',
    },
  })

  const { isValid, errors, onHandleListenErrors } = useValidationErrors(
    schemaOrder(t),
    { ...values, shipment: shipmentValues },
  )

  const onHandleValues = (key: string, value: string | number | boolean) => {
    if (key === 'purchaseOnCredit' && !value) {
      onChangeValues({
        ...values,
        purchaseOnCredit: value as boolean,
        financingCost: undefined,
      })
      return
    }

    onChangeValues({ ...values, [key]: value })
  }

  const onHandleShipmentValues = (
    key: string,
    value: string | number | boolean,
  ) => {
    onChangeShipmentValues({ ...shipmentValues, [key]: value })
  }

  const onHandleInvoicesValues = (
    key: string,
    value: string | number | boolean,
  ) => {
    onChangeValues({
      ...values,
      invoices: [
        { ...values?.invoices?.[0], [key]: value } as OrderInvoiceProps,
      ],
    })
  }

  const onHandlePaymentValues = (
    key: string,
    value: string | number | boolean,
  ) => {
    onChangePaymentValues({ ...paymentValues, [key]: value })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)
    onHandleSubmit()
  }

  const getErrorsValues = (key: string) => {
    return errors?.[key] as string
  }

  const getErrorsShipmentValues = (key: string) => {
    return (errors?.shipment as Record<string, string>)?.[key] as string
  }

  return (
    <>
      <div className={styles.core}>
        <div>
          <Label>{t('orderFormDollarOrderLabel')} </Label>
          <InputNumber
            placeholder={t('orderFormDollarOrderPlaceholder')}
            value={+(paymentValues?.dollarExchangeRate || 0)}
            error={getErrorsValues('dollarExchangeRate')}
            decimalPrecision={2}
            prefix={'MXN$ '}
            stepSize={1}
            showIcons
            onChange={(newValue) =>
              onHandlePaymentValues('dollarExchangeRate', newValue)
            }
          />
        </div>
        <div>
          <Label>
            {t('orderFormDiscountLabel')}{' '}
            <span>{t('orderFormOptionalLabel')}</span>
          </Label>
          <InputNumber
            placeholder={t('orderFormDiscountPlaceholder')}
            value={values?.discount ? +values.discount : 0}
            error={getErrorsValues('discount')}
            prefix={'$ '}
            stepSize={100}
            showIcons
            decimalPrecision={2}
            onChange={(newValue) => onHandleValues('discount', newValue)}
          />
        </div>
        <div>
          <Label>{t('orderFormTotalFreightPriceLabel')}</Label>
          <InputNumber
            placeholder={t('orderFormTotalFreightPricePlaceholder')}
            value={+(shipmentValues?.price || 0)}
            error={getErrorsShipmentValues('price')}
            prefix={'$ '}
            min={0}
            stepSize={100}
            showIcons
            decimalPrecision={2}
            onChange={(newValue) => onHandleShipmentValues('price', newValue)}
          />
        </div>

        <div>
          <Label>{t('orderFormExpectedDeliveryDaysLabel')} </Label>
          <InputNumber
            placeholder={t('orderFormExpectedDeliveryDaysPlaceholder')}
            value={+(shipmentValues?.expectedDeliveryDays || 0)}
            error={getErrorsShipmentValues('expectedDeliveryDays')}
            decimalPrecision={0}
            min={0}
            suffix={t('orderFormExpectedDeliveryDaysSuffix')}
            stepSize={1}
            showIcons
            onChange={(newValue) =>
              onHandleShipmentValues('expectedDeliveryDays', newValue)
            }
          />
        </div>

        <div>
          <div className={styles['label-row']}>
            <Label>{t('orderFormIncludeIvaLabel')}</Label>

            <Tooltip
              text={t('orderFormIncludeIvaDescription')}
              widthText="15rem"
            >
              <QuestionFillIcon />
            </Tooltip>
          </div>

          <Radio
            value={!!values?.includeIva}
            onChange={() => onHandleValues('includeIva', true)}
          >
            {t('orderFormIncludeIvaOptionsYes')}
          </Radio>

          <Radio
            value={!values?.includeIva}
            onChange={() => onHandleValues('includeIva', false)}
          >
            {t('orderFormIncludeIvaOptionsNo')}
          </Radio>
        </div>

        <div>
          <div className={styles['label-row']}>
            <Label>{t('orderFormDeliveryLabel')}</Label>

            <Tooltip
              text={t('orderFormDeliveryDescription')}
              widthText="15rem"
              orientation="right"
            >
              <QuestionFillIcon />
            </Tooltip>
          </div>

          <Radio
            value={shipmentValues?.deliveryOption === 'pick_up'}
            onChange={() => onHandleShipmentValues('deliveryOption', 'pick_up')}
          >
            {t('orderFormDeliveryOptionsPickUp')}
          </Radio>

          <Radio
            value={shipmentValues?.deliveryOption === 'receive_at_address'}
            onChange={() =>
              onHandleShipmentValues('deliveryOption', 'receive_at_address')
            }
          >
            {t('orderFormDeliveryOptionsReceiveAtAddress')}
          </Radio>
        </div>

        <div className={styles['card-backoffice']}>
          <div>
            <Label>{t('orderFormInvoiceIdLabel')}</Label>
            <Input
              value={values?.invoices?.[0]?.invoiceId}
              onChange={(newValue) =>
                onHandleInvoicesValues('invoiceId', newValue)
              }
              placeholder={t('orderFormInvoiceIdPlaceholder')}
            />
          </div>

          <div>
            <Label>{t('orderFormKeyAccountManagerLabel')}</Label>
            <Select
              value={`${values?.keyAccountManagerId || ''}`}
              onChange={(newValue) =>
                onHandleValues('keyAccountManagerId', newValue)
              }
              placeholder={t('orderFormKeyAccountManagerPlaceholder')}
              error={getErrorsValues('keyAccountManagerId')}
              options={
                dataUsers?.data?.results?.map((user) => ({
                  label: user.name,
                  value: `${user.id}`,
                })) || []
              }
            />
          </div>

          <div>
            <Label className={styles['tooltip-wrap']}>
              {t('orderFormWarehouseCostLabel')}
              <Tooltip text={t('orderFormCIADescription')} widthText="15rem">
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('orderFormWarehouseCostPlaceholder')}
              value={+(values?.warehouseCost || 0)}
              error={getErrorsValues('warehouseCost')}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) => onHandleValues('warehouseCost', newValue)}
            />
          </div>

          <div>
            <Label className={styles['tooltip-wrap']}>
              {t('orderFormFirstMileFreightCostLabel')}
              <Tooltip text={t('orderFormCIADescription')} widthText="15rem">
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('orderFormFirstMileFreightCostPlaceholder')}
              value={+(shipmentValues?.firstMileCost || 0)}
              error={getErrorsShipmentValues('firstMileCost')}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleShipmentValues('firstMileCost', newValue)
              }
            />
          </div>
          <div>
            <Label className={styles['tooltip-wrap']}>
              {t('orderFormLastMileFreightCostLabel')}
              <Tooltip text={t('orderFormCIADescription')} widthText="15rem">
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('orderFormLastMileFreightCostPlaceholder')}
              value={+(shipmentValues?.lastMileCost || 0)}
              error={getErrorsShipmentValues('lastMileCost')}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleShipmentValues('lastMileCost', newValue)
              }
            />
          </div>
          <div>
            <Label className={styles['tooltip-wrap']}>
              {t('orderFormCustomsFreightCostLabel')}
              <Tooltip text={t('orderFormCIADescription')} widthText="15rem">
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('orderFormCustomsFreightCostPlaceholder')}
              value={+(shipmentValues?.customsCost || 0)}
              error={getErrorsShipmentValues('customsCost')}
              prefix={'$ '}
              stepSize={100}
              min={0}
              showIcons
              decimalPrecision={2}
              onChange={(newValue) =>
                onHandleShipmentValues('customsCost', newValue)
              }
            />
          </div>

          <div>
            <div className={styles['label-row']}>
              <Label>{t('orderFormPurchaseOnCreditLabel')}</Label>
              <Tooltip
                text={t('orderFormPurchaseOnCreditDescription')}
                widthText="15rem"
              >
                <QuestionFillIcon />
              </Tooltip>
            </div>

            <Radio
              value={!!values?.purchaseOnCredit}
              onChange={() => onHandleValues('purchaseOnCredit', true)}
            >
              {t('orderFormPurchaseOnCreditOptionsYes')}
            </Radio>

            <Radio
              value={!values?.purchaseOnCredit}
              onChange={() => onHandleValues('purchaseOnCredit', false)}
            >
              {t('orderFormPurchaseOnCreditOptionsNo')}
            </Radio>
          </div>

          {values?.purchaseOnCredit && (
            <div>
              <Label className={styles['tooltip-wrap']}>
                {t('orderFormFinancingCostLabel')}
                <Tooltip
                  text={t('orderFormCIADescription')}
                  widthText="15rem"
                  orientation="left"
                >
                  <QuestionFillIcon />
                </Tooltip>
              </Label>
              <InputNumber
                placeholder={t('orderFormFinancingCostPlaceholder')}
                value={
                  values?.financingCost
                    ? +(values?.financingCost || 0) * 100
                    : 0
                }
                error={getErrorsValues('financingCost')}
                suffix={'%'}
                stepSize={1}
                min={0}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues(
                    'financingCost',
                    (+(newValue || 1) / 100).toFixed(4),
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
