import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Text, PlusIcon } from '@mercai/clever'

import { useParamsState, trackEvent } from '@/helpers'

import {
  useQuotationRequestClassification,
  useQuotationRequestSlas,
} from '@/services'

import { TableQuotationRequest } from './components'

import type { QuotationRequestListProps } from './list.type'

import styles from './list.module.css'
import { BusinessMetricCards } from '../../components'

import { metricsByTabs } from './list.constants'

import { Tabs } from '@/components'

import { BusinessMetricCardProps } from '../../components/business-metric-card/business-metric-card.type'
import { ROUTES_ADMIN } from '@/router'

export const QuotationRequestV2List = ({
  buyerProfile,
}: QuotationRequestListProps) => {
  const { t } = useTranslation('screenQuotationRequestListV2')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)

  const [filter, setFilter] = useParamsState({
    tab: 'all',
  })

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else if (changedKey === 'tab') {
      setFilter({
        ...filter,
        [changedKey]: `${newValue}`,
        page: '1',
        classification_group: '',
      })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const { data: dataClassification, isLoading: isLoadingClassification } =
    useQuotationRequestClassification()

  const { data: dataSlas, isLoading: isLoadingSlas } = useQuotationRequestSlas()

  const metricsComponents: Record<string, BusinessMetricCardProps> = {
    'sla-rfq-created-to-processed': {
      change: dataSlas?.data?.createdToProcessedSlaVariation || 0,
      changeSuffix: ' h',
      label: t('quotationRequestSlaRfqCreatedToProcessedGeneral'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.createdToProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
      positiveIsBad: true,
    },
    'sla-rfq-pending-to-approved-for-quotation': {
      change: dataSlas?.data?.pendingToApprovedForQuotationSlaVariation || 0,
      changeSuffix: ' h',
      label: t('quotationRequestSlaRfqPendingToApprovedForQuotationGeneral'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.pendingToApprovedForQuotationSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
      positiveIsBad: true,
    },
    'conversion-rfq-pending-to-approved-for-quotation': {
      label: t('quotationRequestConversionRfqPendingToApprovedForQuotation'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.pendingToApprovedForQuotationConversion || 0).toFixed(2)}%`,
      change:
        dataSlas?.data?.pendingToApprovedForQuotationConversionVariation || 0,
      changeSuffix: ' p.p',
    },
    'number-of-customers-acquired-in-month': {
      label: t('quotationRequestNumberOfCustomersAcquiredInMonth'),
      isLoading: isLoadingSlas,
      value: `${dataSlas?.data?.newUsers || 0}`,
      change: dataSlas?.data?.newUsersVariation || 0,
    },
    'sla-rfq-approved-for-quotation-to-processed': {
      change: dataSlas?.data?.approvedForQuotationToProcessedSlaVariation || 0,
      label: t('quotationRequestSlaRfqApprovedForQuotationToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.approvedForQuotationToProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
      positiveIsBad: true,
      changeSuffix: ' h',
    },
    'conversion-rfq-approved-for-quotation-to-processed': {
      label: t('quotationRequestConversionRfqApprovedForQuotationToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.approvedForQuotationToProcessedConversion || 0).toFixed(2)}%`,
      change:
        dataSlas?.data?.approvedForQuotationToProcessedConversionVariation || 0,
      changeSuffix: ' p.p',
    },
    'sla-rfq-created-to-automatic-processed': {
      label: t('quotationRequestSlaCreatedToAutomaticProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.createdToAutomaticProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
      change: dataSlas?.data?.createdToAutomaticProcessedSlaVariation || 0,
      positiveIsBad: true,
      changeSuffix: ' h',
    },
    'conversion-rfq-qualified-to-automatic-processed': {
      change:
        dataSlas?.data?.createdToAutomaticProcessedConversionVariation || 0,
      label: t('quotationRequestConversionCreatedToAutomaticProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.createdToAutomaticProcessedConversion || 0).toFixed(2)}%`,
      changeSuffix: ' p.p',
    },
    'sla-rfq-qualified-to-processed': {
      change: dataSlas?.data?.qualifiedToProcessedSlaVariation || 0,
      label: t('quotationRequestSlaQualifiedToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.qualifiedToProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
      positiveIsBad: true,
      changeSuffix: ' h',
    },
    'conversion-rfq-qualified-to-processed': {
      change: dataSlas?.data?.qualifiedToProcessedConversionVariation || 0,
      label: t('quotationRequestConversionQualifiedToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.qualifiedToProcessedConversion || 0).toFixed(2)}%`,
      changeSuffix: ' p.p',
    },
  }

  useEffect(() => {
    trackEvent('adminRfqListPageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-content']}>
          <div>
            <Text variant="title-large">RFQ</Text>
            <div>
              <Text variant="text-base" className={styles['sub-title']}>
                {t('quotationRequestSubtitle')}
              </Text>
            </div>
          </div>
          <div className={styles['header-actions']}>
            <Button href={ROUTES_ADMIN.BUSINESS.RFQ.CREATE.fullPath()}>
              <PlusIcon />
              {t('quotationRequestTableNewRFQButton')}
            </Button>
          </div>
        </div>
      </header>

      <Tabs
        isLoading={isLoadingClassification}
        hasCount
        items={[
          {
            title: t('quotationRequestTabAll'),
            isActive: !filter.tab || filter.tab === 'all',
            onSelect: () => handleFilter('tab', 'all'),
            count: dataClassification?.data?.total || 0,
          },
          {
            title: t('quotationRequestTabPendingAcquisition'),
            isActive: filter.tab === 'pending-acquisition',
            onSelect: () => handleFilter('tab', 'pending-acquisition'),
            count: dataClassification?.data?.pendingAcquisition || 0,
          },
          {
            title: t('quotationRequestTabAutomatingRfqs'),
            isActive: filter.tab === 'automating-rfqs',
            onSelect: () => handleFilter('tab', 'automating-rfqs'),
            count: dataClassification?.data?.automatingRfqs || 0,
          },
          {
            title: t('quotationRequestTabPendingSupply'),
            isActive: filter.tab === 'pending-supply',
            onSelect: () => handleFilter('tab', 'pending-supply'),
            count: dataClassification?.data?.pendingSupply || 0,
          },
          {
            title: t('quotationRequestTabInvalid'),
            isActive: filter.tab === 'invalid',
            onSelect: () => handleFilter('tab', 'invalid'),
            count: dataClassification?.data?.invalid || 0,
          },
          {
            title: t('quotationRequestTabExpired'),
            isActive: filter.tab === 'expired',
            onSelect: () => handleFilter('tab', 'expired'),
            count: dataClassification?.data?.expired || 0,
          },
        ]}
      />

      <BusinessMetricCards
        metrics={metricsByTabs[filter.tab || 'all']?.map(
          (key) => metricsComponents[key],
        )}
      />

      <TableQuotationRequest
        filter={filter}
        buyerProfile={buyerProfile}
        isOpenDrawerForm={isOpenDrawerForm}
        setIsOpenDrawerForm={setIsOpenDrawerForm}
      />
    </div>
  )
}
