import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  FilterIcon,
  TableColumns,
  Tag,
  Text,
  Select,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  PlusIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'
import { Header } from '@/components'
import {
  formatDate,
  useParamsState,
  useCookieState,
  trackEvent,
} from '@/helpers'
import { ROUTES_ADMIN } from '@/router'
import { useProfileList } from '@/services'
import { ProfileProps } from '@/types'

import {
  ProfileDrawerFilter,
  ProfileDrawerForm,
  DrawerFormMerge,
} from './components'

import { InputSearch, TagAccountLevel, TagMoscow } from '../components'

import styles from './list.module.css'

export const ProfileList = () => {
  const { t } = useTranslation('screenProfileList')
  const { t: tEnums } = useTranslation('enums')

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [isOpenDrawerFormMerge, setIsOpenDrawerFormMerge] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/customer-list-columns',
    [
      'name',
      'phone',
      'email',
      'customerLevel',
      'moscowClassification',
      'companyName',
      'type',
      'trafficSource',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    customerLevel: 'ALL',
    createdAtBefore: '',
    createdAtAfter: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key === 'customerLevel' &&
        filter[key] !== 'ALL',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useProfileList({ params: filter })

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminLeadsListPageView')
  }, [])

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const columns: TableColumnsColumnProps<ProfileProps>[] = [
    {
      title: t('profileTableName'),
      key: 'name',
      dataKey: 'name',
      width: '16rem',
      render: ({ record }) => (
        <Text>
          {record?.users
            ? record?.users?.map((user) => user.name)?.join(', ')
            : '-'}
        </Text>
      ),
    },
    {
      title: t('profileTableCompany'),
      key: 'companyName',
      dataKey: 'companyName',
      width: '18rem',
    },
    {
      title: t('profileTablePhone'),
      key: 'phone',
      dataKey: 'phone',
      width: '9rem',
    },
    {
      title: t('profileTableEmail'),
      key: 'email',
      dataKey: 'email',
      width: '20rem',
    },
    {
      title: t('profileTableCustomerLevel'),
      key: 'customerLevel',
      dataKey: 'customerLevel',
      render: ({ record }) => (
        <TagAccountLevel accountLevelSlug={record?.customerLevel?.slug} />
      ),
    },
    {
      title: t('profileTableMoscow'),
      key: 'moscowClassification',
      dataKey: 'moscowClassification',
      render: ({ value }) => <TagMoscow moscowClassification={value} />,
    },
    {
      title: t('profileTableSellerName'),
      key: 'keyAccountManager.email',
      dataKey: 'keyAccountManager.email',
      width: '18rem',
    },
    {
      title: t('profileTableType'),
      key: 'type',
      dataKey: 'type',
      width: '14rem',
      render: ({ value }) => (value ? tEnums(`profile_type_${value}`) : '-'),
    },
    {
      title: t('profileTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('profileTableTrafficSource'),
      key: 'trafficSource',
      dataKey: 'trafficSource',
      width: '14rem',
      render: ({ value }) =>
        value ? tEnums(`profile_traffic_source_${value}`) : '-',
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'ALL')}
            isActive={filter.customerLevel === 'ALL'}
          >
            {t('buttonFilterAll')}
          </Button>
          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'UQL')}
            isActive={filter.customerLevel === 'UQL'}
          >
            {t('buttonFilterUQL')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'MQL')}
            isActive={filter.customerLevel === 'MQL'}
          >
            {t('buttonFilterMQL')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'SQL')}
            isActive={filter.customerLevel === 'SQL'}
          >
            {t('buttonFilterSQL')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'customer')}
            isActive={filter.customerLevel === 'customer'}
          >
            {t('buttonFilterCustomer')}
          </Button>

          <Button
            variant="secondary"
            model="pill"
            onClick={() => handleFilter('customerLevel', 'disqualified')}
            isActive={filter.customerLevel === 'disqualified'}
          >
            {t('buttonFilterDisqualified')}
          </Button>
        </div>

        <div>
          <div className={styles.filter}>
            <div className={styles['filter-group']}>
              <InputSearch
                value={filter.search as string}
                onChange={(value) => handleFilter('search', value)}
                className={styles['search-input']}
              />

              <Button
                variant="secondary"
                onClick={() => setIsOpenDrawerFilter(true)}
              >
                <FilterIcon />
                {t('profileTableFilterButton')}

                {getContentTotalFiltered()}
              </Button>

              <Button
                variant="light"
                onClick={() => setIsOpenDrawerFormMerge(true)}
              >
                {t('profileTableMergeAccounts')}
              </Button>
            </div>

            <div className={styles['filter-group']}>
              <Button onClick={onHandleNewRegister}>
                <PlusIcon />
                {t('profileTableNewRegister')}
              </Button>

              <DropDown.Root>
                <DropDown.ButtonTrigger
                  variant="secondary"
                  model="square"
                  hasArrow={false}
                >
                  <SettingIcon />
                </DropDown.ButtonTrigger>

                <DropDown.Content orientation="bottom-right">
                  {columns?.map((column) => (
                    <DropDown.Item key={column.dataKey}>
                      <Checkbox
                        onChange={() => onHandleColumnsShow(column.key)}
                        value={getColumnsShow(column.key)}
                      >
                        {column.title}
                      </Checkbox>
                    </DropDown.Item>
                  ))}
                </DropDown.Content>
              </DropDown.Root>
            </div>
          </div>

          <TableColumns<ProfileProps>
            isLoading={isLoading}
            columns={memoizedColumns}
            fixedColumns={[
              {
                title: t('profileTableActions'),
                key: 'uuid',
                dataKey: 'uuid',
                render: ({ record }) => (
                  <div className={styles['action-buttons']}>
                    <Button
                      variant="secondary"
                      onClick={() => onHandleEditRegister(record.uuid)}
                    >
                      {t('profileTableActionsEdit')}
                    </Button>
                    <Button
                      variant="secondary"
                      href={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record.uuid)}
                    >
                      {t('profileTableActionsView')}
                    </Button>
                  </div>
                ),
                renderLoading: (
                  <div className={styles['action-buttons']}>
                    <Skeleton height="2rem" width="5.15rem" />
                    <Skeleton height="2rem" width="5.7rem" />
                  </div>
                ),
              },
            ]}
            data={data?.data?.results || []}
          />

          <div className={styles.pagination}>
            <div className={styles['pagination-show-registers']}>
              <Text>
                {t('profileTableShowRegisters', {
                  showNumber:
                    (data?.data?.count || 0) < +filter.limit
                      ? data?.data?.count
                      : +filter.limit,
                  totalNumber: data?.data?.count || 0,
                })}
              </Text>

              <Select
                value={filter.limit}
                onChange={(value) => handleFilter('limit', value)}
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' },
                ]}
              />
            </div>

            <Pagination
              totalPages={totalPages}
              onChange={(page) => handleFilter('page', `${page}`)}
              currentPage={+filter.page as number}
            />
          </div>
        </div>
      </div>

      <ProfileDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <ProfileDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        profileUuid={queryParams.uuid}
      />

      <DrawerFormMerge
        isOpen={isOpenDrawerFormMerge}
        onClose={() => setIsOpenDrawerFormMerge(false)}
      />
    </div>
  )
}
