import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Button, DrawerStepperProgress } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  onSuccessMessage,
  onErrorMessage,
} from '@/helpers'

import {
  useUpdateSupplier,
  QUERIES_ADMIN,
  useCreateSupplier,
  useSupplierDetail,
  useSupplierProductsList,
  useDeleteSupplierProduct,
  useUpdateSupplierProduct,
  useCreateSupplierProduct,
} from '@/services'

import {
  CatalogProductProps,
  SupplierProductProps,
  SupplierProps,
} from '@/types'

// import { schemaSupplier } from './drawer-form.constants'

import {
  NewSupplierProduct,
  schemaSupplier,
  SupplierForm,
  SupplierProductList,
} from './components'

import { schemaSupplierProducts, SupplierProductForm } from '@/components'

import type { DrawerSupplierFormProps } from './drawer-form.type'

export const SupplierDrawerForm = ({
  isOpen,
  onHandleOpen,
  supplierUuid,
  noSupplierForm,
}: DrawerSupplierFormProps) => {
  const { t } = useTranslation('screenSupplierList')

  const isNewRegister = !supplierUuid

  const queryClient = useQueryClient()

  const [uuid, setUuid] = useState<string>('')

  const [data, setData] = useState<Partial<SupplierProps>>({})

  const [productItem, setProductItem] = useState<CatalogProductProps>()

  const [dataSupplierProduct, setDataSupplierProduct] =
    useState<SupplierProductProps>()

  const {
    isValid: isValidSupplier,
    errors: supplierErrors,
    onHandleListenErrors: onHandleListenErrorsSupplier,
  } = useValidationErrors(schemaSupplier(t), data)

  const {
    isValid: isValidSupplierProducts,
    errors: supplierProductsErrors,
    onHandleListenErrors: onHandleListenErrorsSupplierProducts,
  } = useValidationErrors(schemaSupplierProducts(t), dataSupplierProduct)

  const { data: dataSupplier } = useSupplierDetail(supplierUuid || '')

  const { data: dataSupplierProductsList } = useSupplierProductsList({
    params: { supplierUuid: supplierUuid || uuid, limit: 99999 },
  })

  const { mutateAsync: mutateAsyncUpdateSupplier } = useUpdateSupplier()
  const { mutateAsync: mutateAsyncCreateSupplier } = useCreateSupplier()

  const { mutateAsync: mutateAsyncCreateSupplierProduct } =
    useCreateSupplierProduct()
  const { mutateAsync: mutateAsyncUpdateSupplierProduct } =
    useUpdateSupplierProduct()
  const { mutateAsync: mutateAsyncDeleteSupplierProduct } =
    useDeleteSupplierProduct()

  const onHandleComplete = () => {
    setData({})
    setDataSupplierProduct(undefined)
    setProductItem(undefined)
    onHandleOpen(false)
  }

  const onSubmitSupplierForm = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: () => void
  }) => {
    if (!isValidSupplier) {
      onHandleListenErrorsSupplier(true)
      return
    }

    onHandleListenErrorsSupplier(false)

    let response: {
      success: boolean
      message: string
      data?: SupplierProps
    } = {
      success: false,
      message: '',
      data: {} as SupplierProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateSupplier(data as SupplierProps)
    } else {
      response = await mutateAsyncUpdateSupplier(data as SupplierProps)
    }

    if (response.success) {
      setUuid(response?.data?.uuid || '')
      onNavigateToNextStep()

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER.module],
      })
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('supplierFormErrorCreateTitle'),
          t('supplierFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('supplierFormErrorUpdateTitle'),
          t('supplierFormErrorUpdateMessage'),
        )
      }
    }
  }

  const onSubmitSupplierProductsForm = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: () => void
  }) => {
    if (!isValidSupplierProducts) {
      onHandleListenErrorsSupplierProducts(true)
      return
    }

    onHandleListenErrorsSupplierProducts(false)

    let response: {
      success: boolean
      message: string
      data?: SupplierProductProps
    } = {
      success: false,
      message: '',
      data: {} as SupplierProductProps,
    }

    if (!dataSupplierProduct?.uuid) {
      response = await mutateAsyncCreateSupplierProduct(
        dataSupplierProduct as Partial<SupplierProductProps>,
      )
    } else {
      response = await mutateAsyncUpdateSupplierProduct(
        dataSupplierProduct as Partial<SupplierProductProps>,
      )
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(
          t('supplierFormSuccessCreateTitle'),
          t('supplierFormSuccessCreateMessage'),
        )
      } else {
        onSuccessMessage(
          t('supplierFormSuccessUpdateTitle'),
          t('supplierFormSuccessUpdateMessage'),
        )
      }

      onNavigateToNextStep()

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.SUPPLIER.module],
      })
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('supplierFormErrorCreateTitle'),
          t('supplierFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('supplierFormErrorUpdateTitle'),
          t('supplierFormErrorUpdateMessage'),
        )
      }
    }
  }

  const onDeleteSupplierProduct = async (uuid: string) => {
    await mutateAsyncDeleteSupplierProduct(uuid)
  }

  useEffect(() => {
    if (isNewRegister) {
      setData({})
      setDataSupplierProduct(undefined)
      setProductItem(undefined)
    } else {
      if (dataSupplier?.data) {
        setData({
          ...dataSupplier.data,
        })
      }
    }
  }, [isOpen, dataSupplier, supplierUuid])

  return (
    <DrawerStepperProgress
      translations={{
        optional: t('supplierFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister
          ? t('supplierFormTitleCreate')
          : t('supplierFormTitleUpdate')
      }
      description={
        isNewRegister
          ? t('supplierFormSubTitleCreate')
          : t('supplierFormSubTitleUpdate')
      }
      initialStep={noSupplierForm ? 'supplier_products_list' : 'root'}
      totalSteps={noSupplierForm ? 1 : 2}
      steps={{
        root: {
          title: t('supplierFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          stepNumber: 0,
          component: (
            <SupplierForm
              errors={supplierErrors as Record<string, string>}
              values={data || {}}
              onChangeValues={setData}
            />
          ),
          footer: ({ onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={() => onHandleOpen(false)}>
                {t('supplierFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() =>
                  onSubmitSupplierForm({
                    onNavigateToNextStep: () =>
                      onNavigateToNextStep('supplier_products_list'),
                  })
                }
              >
                {t('supplierFormNavigateButtonSaveAndNext')}
              </Button>
            </>
          ),
        },
        supplier_products_list: {
          title: t('supplierProductsFormTitle'),
          isOptional: false,
          backNavigate: noSupplierForm ? 'supplier_products_list' : 'root',
          stepNumber: 1,
          component: ({ onNavigateToNextStep }) => (
            <SupplierProductList
              onClickNewProduct={() =>
                onNavigateToNextStep('new_supplier_products')
              }
              onClickRemoveProduct={({ uuid }) =>
                onDeleteSupplierProduct(uuid || '')
              }
              onSelectedProduct={(supplierProduct) => {
                setDataSupplierProduct(supplierProduct)
                onNavigateToNextStep('supplier_product')
              }}
              supplierProductItems={
                dataSupplierProductsList?.data?.results || []
              }
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              {!noSupplierForm && (
                <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                  {t('supplierProductsListNavigateButtonBack')}
                </Button>
              )}

              <Button onClick={onHandleComplete}>
                {t('supplierProductsListNavigateButtonSave')}
              </Button>
            </>
          ),
        },
        new_supplier_products: {
          title: t('newSupplierProductsFormTitle'),
          description: t('newSupplierProductsFormDescription'),
          isOptional: false,
          backNavigate: 'supplier_products_list',
          stepNumber: 1,
          component: (
            <NewSupplierProduct
              productItem={productItem}
              onSelectProductItem={setProductItem}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('newSupplierProductsFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() => onNavigateToNextStep('supplier_product')}
                disabled={!productItem}
              >
                {t('newSupplierProductsFormNavigateButtonSave')}
              </Button>
            </>
          ),
        },
        supplier_product: {
          isOptional: false,
          backNavigate: 'new_supplier_products',
          stepNumber: 1,
          component: (
            <SupplierProductForm
              supplierUuid={supplierUuid || uuid}
              values={dataSupplierProduct || {}}
              productItem={productItem}
              onChangeValues={setDataSupplierProduct}
              errors={supplierProductsErrors as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  if (!dataSupplierProduct?.catalogProduct) {
                    return onNavigateToPreviousStep()
                  }
                  setDataSupplierProduct(undefined)
                  onNavigateToNextStep('supplier_products_list')
                }}
              >
                {t('supplierProductsFormNavigateButtonBack')}
              </Button>

              <Button
                onClick={() =>
                  onSubmitSupplierProductsForm({
                    onNavigateToNextStep: () =>
                      onNavigateToNextStep('supplier_products_list'),
                  })
                }
              >
                {t('supplierProductsFormNavigateButtonSave')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
